import { useEffect, useState } from "react"
import CustomDropdown from "../../../components/custom-dropdown"
import { Constant } from "../../../utils"
import Data from "../../../utils/dummy-data"

const EstimationForm = ({selectedData, formType}) => {
  
  const [EstimationName, setEstimationsName] = useState(null)
  const [Estimation, setEstimation] = useState(null)

  useEffect(() =>{
    if(selectedData){
      const selectedEstimationName = Data.find((e) => e.contacts_name === selectedData?.contacts_name)  // replace with actual Reporting Manager
      const selectedEstimation = Data.find((e) => e.contacts_name === selectedData?.contacts_name)
        setEstimationsName(selectedEstimationName || null)
        setEstimation(selectedEstimation || null)
      }
    },[selectedData, Data])

    const clearInputValues = () =>{
      setEstimationsName(null)
      setEstimation(null)
    }

  return (
    <>
    <div className="outer-modal-contentbox">
      <div
        className="offcanvas offcanvas-end offcanvas-large"
        tabindex="-1"
        id="offcanvas_add"
      >
        <div className="offcanvas-header border-bottom">
          <h5 className="fw-semibold">{formType === Constant.FORM_TYPE_EDIT
              ? "Update Estimation"
              : formType === Constant.FORM_TYPE_NEW
              ? "Add Estimation"
              : "Estimation Details"}</h5>
          <button
            type="button"
            className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              clearInputValues()
              return false
            }}
          >
            <i className="ti ti-x"></i>
          </button>
        </div>

        <div className="offcanvas-body">
          <form>
            <div className="row">
              <div className="col-md-6">
              </div>
              <div className="col-md-"></div>
              <div className="col-md-8">
                <div className="mb-3">
                  <label className="col-form-label">
                    Estimation Name <span className="text-danger">*</span>
                  </label>
                  <CustomDropdown
                  options={Data}
                  label="contacts_name"
                  id="email"
                  selectedValue={EstimationName}
                  setSelectedValue={setEstimationsName}
                  placeholder="Estimation Name"
                  isDisabled={formType === Constant.FORM_TYPE_VIEW}
                  />
                </div>
              </div>
              <div className="col-md-"></div>
              <div className="col-md-8">
                <div className="mb-3">
                  <label className="col-form-label">
                    Estimations <span className="text-danger">*</span>
                  </label>
                  <CustomDropdown
                  options={Data}
                  label="contacts_name"
                  id="email"
                  selectedValue={Estimation}
                  setSelectedValue={setEstimationsName}
                  placeholder="Estimation"
                  isDisabled={formType === Constant.FORM_TYPE_VIEW}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
            {formType !== Constant.FORM_TYPE_VIEW && (
              <button
                type="button"
                className="btn btn-primary"            
                disabled={formType === Constant.FORM_TYPE_VIEW}
              >
                {formType === Constant.FORM_TYPE_EDIT ? "Update" : "Submit"}
              </button>
            )}
            </div>
          </form>
        </div>
      </div>
    </div>

    </>
  )
}

export default EstimationForm

import React, { useEffect, useState } from "react"
import { Common, Constant } from "../../utils"
import CustomAlert from "../custom-alerts/CustomAlert"
import { urls } from "../../apis/urls"
import API from "../../apis"
const AddProductType = ({
  hideAddProductTypeForm,
  formType,
  navigate,
  showAddProductTypeForm,
  selectedData
}) => {

  const [loading, setLoading] = useState(false)
  const [productType, setProductType] = useState("")
  const [alertTitle, setAlertTitle] = useState("")
  const [alertDesc, setAlertDesc] = useState("")
  const [isALert, setIsAlert] = useState(false)
  const [customAlertType, setCustomAlertType] = useState("")

  const CustomAlertTexts = (title, desc, alertType) => {
    setAlertTitle(title ?? "")
    setAlertDesc(desc ?? "")
    setCustomAlertType(alertType)
    setIsAlert(true)
  }

  useEffect(() =>{
    setProductType(selectedData?.productType)
  },[selectedData])

  const clearInputValues = () =>{
    setProductType("")
  }

  const handleProductType = (event) => setProductType(event.target.value)

  const checkValidations = () => {
    if (!productType) {
      CustomAlertTexts("Error", "Product Type is required", "error")
      return false
    }
    submitData()
  }

  const submitData =  () => {
    setLoading(true)
    const params = {
      pk_ProductTypeId: formType === Constant.FORM_TYPE_NEW ? 0 : 0,
      productType: productType,
      fk_UsrOrgDirId: Common.getOrganisationID(),
      isActive: true,
      createdBy: Common.getLoggedInUserID(),
      isFlag: 0,
    }
    console.log("params - ", params)
    API.postRequestWithJsonForOnline(urls.createProductType +`?tockenKey=${Common.getTokenKey()}`,params)
      .then(handleResponse)
      .catch(() => {
        setLoading(false)
      })
  }

  const handleResponse = (response) => {
    setLoading(false)
    if (response.result.response_Status === Constant.STATUS_SUCCESS) {
      clearInputValues()
      CustomAlertTexts(response.result.response_Status, response.result.response_Message, response.result.response_Status)
      location.reload()
    } else if (response.result.response_Status === Constant.STATUS_ERROR) {      
      CustomAlertTexts(response.result.response_Status, response.result.response_Message, response.result.response_Status)
    } else {
      Common.doLogout(navigate)
    }
  }

  return (
    <>
      {isALert && (
        <CustomAlert
          alertTitle={alertTitle}
          alertDesc={alertDesc}
          onOkClick={() => setIsAlert(false)}
          customAlertType={customAlertType}
        />
      )}

      <div className="outer-modal-contentbox">
        <div className="offcanvas-header border-bottom">
          <h5 className="fw-semibold">
            {formType === Constant.FORM_TYPE_EDIT
              ? "Update Product Type"
              : formType === Constant.FORM_TYPE_NEW
              ? "Add Product Type"
              : formType === Constant.FORM_TYPE_VIEW
              ? "Product Type Details"
              : ""}
          </h5>

          {showAddProductTypeForm === true ? (
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              onClick={hideAddProductTypeForm}
            />
          ) : (
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                clearInputValues()
                return false
              }}
              aria-label="Close"
            >
              <i className="ti ti-x"></i>
            </button>
          )}
        </div>

        <div className="modal-content">
          <div
            className={`modal-body ${
              !showAddProductTypeForm ? "customModal" : ""
            }`}
          >
            <form>
              <div className="mb-3">
                <label className="col-form-label">Add Product Type:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Product Type"
                  onChange={handleProductType}
                  value={productType}
                  disabled={formType == Constant.FORM_TYPE_VIEW}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="recipient-name" className="col-form-label">
                  Status:
                </label>
                <select
                  class="form-select"
                  // onChange={onActiveStatus}
                  // value={isActive}
                  disabled
                >
                  <option value="active">Active</option>
                  <option value="isactive">In Active</option>
                </select>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            {formType !== Constant.FORM_TYPE_VIEW && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={checkValidations}
                disabled={formType === Constant.FORM_TYPE_VIEW}
              >
                {formType === Constant.FORM_TYPE_EDIT ? "Update" : "Submit"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddProductType

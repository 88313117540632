import React, { useState } from "react"
import "react-perfect-scrollbar/dist/css/styles.css"
import VerticalMenuComponent from "../../../components/VerticalMenuComponent"
import TopMenuComponent from "../../../components/TopMenuComponent"
import CustomTableGrid from "../../../components/custom-table-grid"
import CustomDataTableComponent from "../../../components/page-components/CustomDataTableComponent"
import { Constant } from "../../../utils"
import Data from "../../../utils/dummy-data"
import InvoiceForm from "../invoice-form"
import TopPageHeader from "../../../components/TopPageHeader"

export default function InvoiceList() {
  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [formType, setFormType] = useState(Constant.FORM_TYPE_NEW)
  const [selectedData, setSelectedData] = useState(null)
  const [isGridView, setISGridView] = useState(0)

  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  const columns = [
	{
		name: "Invoice ID",
		selector: (row) => row.id,
		cell: (row) => (
		  <div className="d-flex flex-column">
			<span className="text-default">{row.id}</span>
		  </div>
		),
		sortable: true,
	  },

    {
      name: "Client",
      selector: (row) => row.contacts_name,
      cell: (row) => (
        <div className="d-flex flex-column">
          <span className="text-default">{row.contacts_name}</span>
        </div>
      ),
      sortable: true,
    },
	{
		name: "Amount",
		selector: (row) => row.id,
		sortable: true,
	  },
    {
      name: "Project",
      selector: (row) => row.company_name,
      cell: (row) => (
        <div className="d-flex flex-column">
          <span className="text-default">{row.company_name}</span>
          <span className="text-default">{row.company_address}</span>
        </div>
      ),
      sortable: true,
    },

    {
      name: "Date",
      selector: (row) => row.created_date,
      sortable: true,
    },

    {
      name: "Expiry Date",
      selector: (row) => row.created_date,
      sortable: true,
    },

    {
      name: "Invoice Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        let statusClass = ""
        let statusName = ""
        if (row.status === "0") {
          statusClass = "badge-success-light"
          statusName = "Closed"
        } else if (row.status === "1") {
          statusClass = "badge-purple-light"
          statusName = "Lost"
        } else if (row.status === "2") {
          statusClass = "badge-danger-light"
          statusName = "Not Contacted"
        } else {
          statusClass = ["badge-tag", "bg-warning-light"]
          statusName = "Contacted"
        }
        return (
          <span className={`badge badge-tag ${statusClass}`}>{statusName}</span>
        )
      },
    },
    {
      name: "Action",
      button: true,
      sortable: true,
      cell: (row) => (
        <div className="outer-action-box">
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-original-title="Edit"
          >
            <a
              className="dropdown-item"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas_add"
              href="#!"
              onClick={() => {
                setSelectedData({ ...row })
                setFormType(Constant.FORM_TYPE_EDIT)
              }}
            >
              <i className="ti ti-edit text-blue"></i>
            </a>
          </div>

          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-original-title="View"
          >
            <a
              className="dropdown-item"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas_add"
              href="#"
              onClick={() => {
                setSelectedData({ ...row })
                setFormType(Constant.FORM_TYPE_VIEW)
              }}
            >
              <i class="ti ti-eye text-blue-light"></i>
            </a>
          </div>
        </div>
      ),
    },
  ]

  const filterTable = (event) => {
    setSearchValue(event.target.value)
  }

  let rowData = Data.filter((input) => {
    const lowerSearchTerm = searchValue.toLowerCase()
    const leadName = input.contacts_name
      .toLocaleLowerCase()
      .includes(lowerSearchTerm)
    const companyName = input.contacts_name
      .toLocaleLowerCase()
      .includes(lowerSearchTerm)
    const leadCreated = input.created_date
      .toLocaleLowerCase()
      .includes(lowerSearchTerm)

    const leadEmail = Array.isArray(input.email)
      ? input.email.some((email) =>
          email.toLowerCase().includes(lowerSearchTerm)
        )
      : input.email.toLowerCase().includes(lowerSearchTerm)
    const leadPhone = Array.isArray(input.name)
      ? input.phone.some((phone) =>
          phone.toLowerCase().includes(lowerSearchTerm)
        )
      : input.phone.toLowerCase().includes(lowerSearchTerm)

    return leadName || companyName || leadCreated || leadEmail || leadPhone
  })

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />
        {/* page wrapper  */}

        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">                  
                  <TopPageHeader 
                    pageTitle={"Invoice"}
                    countTitle={110}
                  />
                </div>
                {/* <!-- /Page Header --> */}

                <div className="card">
                  <div className="card-header">
                    {/* <!-- Search --> */}
                    <div className="row align-items-center">
                      <div className="col-sm-4">
                        <div className="icon-form mb-3 mb-sm-0">
                          <span className="form-icon">
                            <i className="ti ti-search"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={filterTable}
                          />
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="d-flex align-items-center flex-wrap row-gap-2 justify-content-sm-end">
                          <div className="dropdown me-2">
                            <a
                              href="javascript:void(0);"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                            >
                              <i className="ti ti-package-export me-2"></i>
                              Export
                            </a>
                            <div className="dropdown-menu  dropdown-menu-end">
                              <ul>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    <i className="ti ti-file-type-xls text-green me-1"></i>
                                    Export as Excel
                                  </a>
                                </li>

                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    <i className="ti ti-file-type-xls text-green me-1"></i>
                                    Print{" "}
                                  </a>
                                </li>

                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    <i className="ti ti-file-type-xls text-green me-1"></i>
                                    Copy To Clipboard{" "}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvas_add"
                            onClick={() => setFormType(Constant.FORM_TYPE_NEW)}
                          >
                            <i className="ti ti-square-rounded-plus me-2"></i>
                            Add Invoice
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Search --> */}
                  </div>
                  <div className="card-body">
                    {/* <!-- Filter --> */}
                    <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2 mb-4">
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="dropdown me-2">
                          <a
                            href="javascript:void(0);"
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            <i className="ti ti-sort-ascending-2 me-2"></i>Sort
                          </a>
                          <div className="dropdown-menu  dropdown-menu-start">
                            <ul>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Ascending
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Descending
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Recently Viewed
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Recently Added
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="icon-form">
                          <span className="form-icon">
                            <i className="ti ti-calendar"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="form-sorts dropdown me-2">
                          <a
                            href="javascript:void(0);"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                          >
                            <i className="ti ti-filter-share"></i>Filter
                          </a>
                          <div className="filter-dropdown-menu dropdown-menu  dropdown-menu-md-end p-3">
                            <div className="filter-set-view">
                              <div className="filter-set-head">
                                <h4>
                                  <i className="ti ti-filter-share"></i>Filter
                                </h4>
                              </div>
                              <div className="accordion" id="accordionExample">
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseTwo"
                                      aria-expanded="true"
                                      aria-controls="collapseTwo"
                                    >
                                      contacts Name
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse show"
                                    id="collapseTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              Collins
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Konopelski
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Adams
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Schumm
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Wisozk
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Heller
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Gutkowski
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Walter
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#company"
                                      aria-expanded="false"
                                      aria-controls="company"
                                    >
                                      Company Name
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="company"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <div className="mb-2 icon-form">
                                        <span className="form-icon">
                                          <i className="ti ti-search"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Company"
                                        />
                                      </div>
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              NovaWave LLC
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              BlueSky Industries
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Silver Hawk
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Summit Peak
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              RiverStone Ventur
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#Status"
                                      aria-expanded="false"
                                      aria-controls="Status"
                                    >
                                      contacts Status
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="Status"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              Closed
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Not Contacted
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Contacted
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseOne"
                                      aria-expanded="false"
                                      aria-controls="collapseOne"
                                    >
                                      Created Date
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="collapseOne"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              25 Sep 2023, 01:22 pm
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              25 Sep 2023, 01:22 pm
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              25 Sep 2023, 01:22 pm
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#owner"
                                      aria-expanded="false"
                                      aria-controls="owner"
                                    >
                                      contacts Owner
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="owner"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <div className="mb-2 icon-form">
                                        <span className="form-icon">
                                          <i className="ti ti-search"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Owner"
                                        />
                                      </div>
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              Hendry
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Guillory
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Jami
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Theresa
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Espinosa
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="filter-reset-btns">
                                <div className="row">
                                  <div className="col-6">
                                    <a href="#" className="btn btn-light">
                                      Reset
                                    </a>
                                  </div>
                                  <div className="col-6">
                                    <a
                                      href="contacts.html"
                                      className="btn btn-primary"
                                    >
                                      Filter
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="view-icons">
                          <a
                            href="#!"
                            className={`${isGridView === 0 ? "active" : ""}`}
                            onClick={() => setISGridView(0)}
                          >
                            <i className="ti ti-list-tree"></i>
                          </a>
                          <a
                            href="#!"
                            className={`${isGridView === 1 ? "active" : ""}`}
                            onClick={() => setISGridView(1)}
                          >
                            <i className="ti ti-grid-dots"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- contacts List --> */}
                    {isGridView === 0 ? (
                      <CustomDataTableComponent
                        rowData={rowData}
                        columns={columns}
                      />
                    ) : (
                      <CustomTableGrid data={rowData} />
                    )}
                    {/* <!-- /contacts List --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add Team Form --> */}
        <InvoiceForm selectedData={selectedData} formType={formType} />
        {/* <!-- /Add Team Form --> */}
      </div>
    </>
  )
}

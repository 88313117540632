import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"
import CustomModal from "../../components/custom-modal"
import SettingsHeader from "../../components/page-components/settings-header"
import crmLeftMenuData from "../../store/static-data/crm-settings-left-menu-data"
import { Link, NavLink } from "react-router-dom"

export default function Storage() {
  const [isOpen, setIsOpen] = useState(false)
  const [isFileSettingsShow, setIsFileSettingsShow] = useState(false)

  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  const [isChecked, setIsChecked] = useState(false)
  const [isSecondChecked, setIsSecondChecked] = useState(false)

  const handleToggle = (num) => {
    if(num === 1){
      setIsChecked((prev) => !prev)
    }
    else{
      setIsSecondChecked((prev) => !prev)
    }
  }

  const menuData = crmLeftMenuData()
  console.log(menuData)

  const fileSetingsBox = () => {
    return (
      <div className="outer-modal-contentbox">
        <div class="modal-content">
            <div className="border-bottom mb-3 pb-3 d-flex align-items-center justify-between">
              <div style={{width:"100%"}}>
                <h5 className="fw-semibold mb-1">File Settings</h5>
                <p>Provide the files information below</p>
              </div>
              <button type="button" class="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle" onClick={() => setIsFileSettingsShow(false)} ></button>
            </div>
            <div className="border-bottom mb-3 border-0 mb-0">
              <div className="row">
                <div className="col-md-8">
                  <div className="mb-3">
                    <h6>Allowed Files</h6>
                    <p>Select allowed files</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <input
                      class="input-tags form-control"
                      type="text"
                      data-role="tagsinput"
                      name="Label"
                      value="JPG, PNG, GIF"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-8">
                  <div className="mb-3">
                    <h6>Max File Size</h6>
                    <p>Select size of the files</p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <input type="text" className="form-control" value="5000MB" />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <button className="btn btn-primary">Save</button>
            </div>
        </div>

      </div>
    )
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}

        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h4 className="page-title">Settings</h4>
                    </div>
                    <div className="col-4 text-end">
                      <div className="head-icons">
                        <a
                          href="storage.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                {/* <!-- Settings Menu --> */}
                    <SettingsHeader/>
                {/* <!-- /Settings Menu --> */}

                <div className="row">
                  <div className="col-xl-3 col-lg-12 theiaStickySidebar">
                    {/* <!-- Settings Sidebar --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="settings-sidebar">
                          <h4 className="fw-semibold mb-3">Other Settings</h4>
                          <div className="list-group list-group-flush settings-sidebar">
                              {
                                menuData?.map((menu) =>{
                                  return(
                                    <NavLink className="fw-medium" to={menu.menuUrl} target={menu.menuName === "Ban IP Address" ? "_blank" :"_self"} >{menu.menuName}</NavLink>
                                  )
                                })
                              }
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Sidebar --> */}
                  </div>

                  <div className="col-xl-9 col-lg-12">
                    {/* <!-- Settings Info --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="mb-4">
                          <h4 className="fs-20">Storage</h4>
                        </div>
                        <div className="row">
                          {/* <!-- Storage --> */}
                          <div className="col-xxl-4 col-sm-6">
                            <div className="border rounded p-3 d-flex align-items-center justify-content-between mb-4">
                              <div className="d-flex align-items-center">
                                <span className="avatar avatar-lg bg-light-100 border flex-shrink-0 me-2">
                                  <img
                                    src="/img/icons/storage-icon-01.svg"
                                    className="w-auto h-auto"
                                    alt=""
                                  />
                                </span>

                                <h6 className="fw-semibold">WorkX Storage</h6>
                              </div>
                              <div className="d-flex align-items-center">
                                <a
                                  href="javascript:void(0);"
                                  className="me-2"
                                  onClick={() => setIsFileSettingsShow(true)}
                                >
                                  <i className="ti ti-settings fs-20"></i>
                                </a>
                                <div className="status-toggle">
      <input
        type="checkbox"
        id="loc"
        className="check"
        checked={isChecked}
        onChange={() => handleToggle(1)}
        role="switch"
      />
      <label htmlFor="loc" className="checktoggle"></label>
    </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /Storage --> */}

                          {/* <!-- Storage --> */}
                          <div className="col-xxl-4 col-sm-6">
                            <div className="border rounded p-3 d-flex align-items-center justify-content-between mb-4">
                              <div className="d-flex align-items-center">
                                <span className="avatar avatar-lg bg-light-100 border flex-shrink-0 me-2">
                                  <img
                                    src="/img/icons/storage-icon-02.svg"
                                    className="w-auto h-auto"
                                    alt=""
                                  />
                                </span>
                                <h6 className="fw-semibold">AWS</h6>
                              </div>
                              <div className="d-flex align-items-center">
                                <a
                                  href="javascript:void(0);"
                                  className="me-2"
                                  // data-bs-toggle="modal"
                                  // data-bs-target="#add_settings"
                                >
                                  <i className="ti ti-settings fs-20"></i>
                                </a>
                                <div className="status-toggle">
                                  <input
                                    type="checkbox"
                                    id="aws"
                                    className= "check"
                                    checked={isSecondChecked}
                                  onChange={() => handleToggle(2)}

                                  />
                                  <label
                                    for="aws"
                                    className="checktoggle"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /Storage --> */}
                        </div>
                        <div className="d-flex align-items-center">
                          <a href="#" className="btn btn-light me-2">
                            Cancel
                          </a>
                          <button type="submit" className="btn btn-primary">
                            Save Changes
                          </button>
                        </div>

                        {/* File Settings Custom Modal  */}
                        {isFileSettingsShow && (
                          <CustomModal children={fileSetingsBox()}/>
                        )}
                      </div>
                    </div>
                    {/* <!-- /Settings Info --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- AWS Settings --> */}
        <div
          className="modal custom-modal fade"
          id="add_settings"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">AWS Settings</h5>
                <div className="d-flex align-items-center mod-toggle">
                  <div className="status-toggle">
                    <input
                      type="checkbox"
                      id="toggle"
                      className="check"
                      checked=""
                    />
                    <label for="toggle" className="checktoggle"></label>
                  </div>
                  <button
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <form action="storage.html">
                  <div className="mb-3">
                    <label className="col-form-label">
                      AWS Access Key <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Secret Key <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Bucket Name <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Region <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Base URL <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="modal-btn">
                    <a
                      href="#"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /AWS Settings --> */}
      </div>
    </>
  )
}

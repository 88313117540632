import React, { useState, useEffect } from "react"
import { Common, Constant } from "../../utils"
import CustomAlert from "../custom-alerts/CustomAlert"
import { urls } from "../../apis/urls"
import API from "../../apis"
const AddProductCategory = ({
  showAddProductCategoryForm,
  formType,
  hideAddProductCategoryForm,
  selectedData,
}) => {
  const [loading, setLoading] = useState(false)
  const [productCategory, setProductCategory] = useState("")
  const [alertTitle, setAlertTitle] = useState("")
  const [alertDesc, setAlertDesc] = useState("")
  const [isALert, setIsAlert] = useState(false)
  const [customAlertType, setCustomAlertType] = useState("")

  useEffect(() => {
    setProductCategory(selectedData?.productCategory)
  }, [selectedData])

  console.log("selectedData ::", selectedData)

  const handleProductCategory = (event) =>
    setProductCategory(event.target.value)

  const clearInputValues = () => {
    setProductCategory("")
  }

  const CustomAlertTexts = (title, desc, alertType) => {
    setAlertTitle(title ?? "")
    setAlertDesc(desc ?? "")
    setCustomAlertType(alertType)
    setIsAlert(true)
  }

  const checkValidations = async () => {
    if (!productCategory) {
      CustomAlertTexts("Error", "Product Category is required", "error")
      return false
    }
    submitData()
  }

  const submitData = async () => {
    setLoading(true)
    const params = {
      pk_ProductCategoryId:0,
      productCategory: productCategory,
      fk_UsrOrgDirId: Common.getOrganisationID(),
      isActive: true,
      createdBy: Common.getLoggedInUserID(),
      isFlag: 0,
    }
    console.log("params - ", params)
    API.postRequestWithJsonForOnline(
      urls.createProductCategory + `?tockenKey=${Common.getTokenKey()}`,
      params
    )
      .then(handleResponse)
      .catch(() => {
        setLoading(false)
      })
  }

  const handleResponse = (response) => {
    setLoading(false)
    if (response.result.response_Status === Constant.STATUS_SUCCESS) {
      clearInputValues()
      CustomAlertTexts(
        response.result.response_Status,
        response.result.response_Message,
        response.result.response_Status
      )
      location.reload()
    } else if (response.result.response_Status === Constant.STATUS_ERROR) {
      CustomAlertTexts(
        response.result.response_Status,
        response.result.response_Message,
        response.result.response_Status
      )
    } else {
      Common.doLogout(navigate)
    }
  }
 
  return (
    <>
      {isALert && (
        <CustomAlert
          alertTitle={alertTitle}
          alertDesc={alertDesc}
          onOkClick={() => setIsAlert(false)}
          customAlertType={customAlertType}
        />
      )}

      <div className="outer-modal-contentbox">
        <div className="offcanvas-header border-bottom">
          <h5 className="fw-semibold">
            {formType === Constant.FORM_TYPE_EDIT
              ? "Update Product Category"
              : formType === Constant.FORM_TYPE_NEW
              ? "Add Product Category"
              : formType === Constant.FORM_TYPE_VIEW
              ? "Product Category Details"
              : ""}
          </h5>

          {showAddProductCategoryForm === true ? (
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              onClick={hideAddProductCategoryForm}
            />
          ) : (
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                clearInputValues()
                return false
              }}
              aria-label="Close"
            >
              <i className="ti ti-x"></i>
            </button>
          )}
        </div>

        <div className="modal-content">
          <div
            className={`modal-body ${
              !showAddProductCategoryForm ? "customModal" : ""
            }`}
          >
            <form>
              <div className="mb-3">
                <label className="col-form-label">Add Product Category:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Add Product Category"
                  onChange={handleProductCategory}
                  value={productCategory}
                  disabled={formType == Constant.FORM_TYPE_VIEW}
                />
              </div>

              <div className="mb-3">
                <label htmlFor="recipient-name" className="col-form-label">
                  Status:
                </label>
                <select class="form-select" disabled>
                  <option value="active">Active</option>
                  <option value="isactive">In Active</option>
                </select>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            {formType !== Constant.FORM_TYPE_VIEW && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={checkValidations}
                disabled={formType === Constant.FORM_TYPE_VIEW}
              >
                {formType === Constant.FORM_TYPE_EDIT ? "Update" : "Submit"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default AddProductCategory

import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"
import CustomDataTableComponent from "../../components/page-components/CustomDataTableComponent"
import Data from "../../utils/dummy-data"


export default function Sources() {

  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  const columns = [
     {
       name: (
         <label class="checkboxs">
           <input type="checkbox" id="select-all" />
           <span class="checkmarks"></span>
         </label>
       ),
       selector: (row) => <input type="checkbox" />,
       sortable: false,
       cell: (row) => (
         <label className="checkboxs">
           <input type="checkbox" />
           <span className="checkmarks"></span>
         </label>
       ),
     },
     {
       name: "Stage Name",
       selector: (row) => row.contacts_name,
       cell: (row) => (
         <div className="d-flex flex-column">
           <span className="text-default">{row.contacts_name}</span>
         </div>
       ),
       sortable: true,
     },
  
     {
       name: "Stage Status",
       selector: (row) => row.status,
       sortable: true,
       cell: (row) => {
         let statusClass = ""
         let statusName = ""
         if (row.status === "0") {
           statusClass = "badge-success-light"
           statusName = "Closed"
         } else if (row.status === "1") {
           statusClass = "badge-purple-light"
           statusName = "Lost"
         } else if (row.status === "2") {
           statusClass = "badge-danger-light"
           statusName = "Not Contacted"
         } else {
           statusClass = ["badge-tag", "bg-warning-light"]
           statusName = "Contacted"
         }
         return (
           <span className={`badge badge-tag ${statusClass}`}>{statusName}</span>
         )
       },
     },
     {
       name: "Created By",
       selector: (row) => row.created_date,
       sortable: true,
     },
     {
       name: "Created Date",
       selector: (row) => row.created_date,
       sortable: true,
     },
     {
       name: "Action",
       button: true,
       sortable: true,
       cell: (row) => (
         <div className="outer-action-box">
           <div
             data-bs-toggle="tooltip"
             data-bs-placement="top"
             data-bs-original-title="Edit"
           >
             <a
               className="dropdown-item"
               data-bs-toggle="offcanvas"
               data-bs-target="#offcanvas_add"
               href="#!"
               onClick={() => {
                 setSelectedData({ ...row })
                 setFormType(Constant.FORM_TYPE_EDIT)
               }}
             >
               <i className="ti ti-edit text-blue"></i>
             </a>
           </div>
 
           <div
             data-bs-toggle="tooltip"
             data-bs-placement="top"
             data-bs-original-title="View"
           >
             <a
               className="dropdown-item"
               data-bs-toggle="offcanvas"
               data-bs-target="#offcanvas_add"
               href="#"
               onClick={() => {
                 setSelectedData({ ...row })
                 setFormType(Constant.FORM_TYPE_VIEW)
               }}
             >
               <i class="ti ti-eye text-blue-light"></i>
             </a>
           </div>
         </div>
       ),
     },
   ]

  const filterTable = (event) => {
    setSearchValue(event.target.value)
  }

  let rowData = Data.filter((input) => {
    const lowerSearchTerm = searchValue.toLowerCase()
    const leadName = input.contacts_name
      .toLocaleLowerCase()
      .includes(lowerSearchTerm)
    const companyName = input.contacts_name
      .toLocaleLowerCase()
      .includes(lowerSearchTerm)
    const leadCreated = input.created_date
      .toLocaleLowerCase()
      .includes(lowerSearchTerm)

    const leadEmail = Array.isArray(input.email)
      ? input.email.some((email) =>
          email.toLowerCase().includes(lowerSearchTerm)
        )
      : input.email.toLowerCase().includes(lowerSearchTerm)
    const leadPhone = Array.isArray(input.name)
      ? input.phone.some((phone) =>
          phone.toLowerCase().includes(lowerSearchTerm)
        )
      : input.phone.toLowerCase().includes(lowerSearchTerm)

    return leadName || companyName || leadCreated || leadEmail || leadPhone
  })

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h4 className="page-title">
                      Contact Stages<span className="count-title">123</span>
                      </h4>
                    </div>
                    <div className="col-4 text-end">
                      <div className="head-icons">
                        <a
                          href="sources.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                <div className="card">
                  <div className="card-header">
                    {/* <!-- Search --> */}
                    <div className="row align-items-center">
                      <div className="col-sm-4">
                        <div className="icon-form mb-3 mb-sm-0">
                          <span className="form-icon">
                            <i className="ti ti-search"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Contact Stages"
                            onChange={filterTable}
                          />
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="text-sm-end">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary "
                            data-bs-toggle="modal"
                            data-bs-target="#add_source"
                          >
                            <i className="ti ti-square-rounded-plus me-2"></i>
                            Add New Stages
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Search --> */}
                  </div>
                  <div className="card-body">
                    {/* <!-- Contact List --> */}                    
                      <CustomDataTableComponent
                        rowData={rowData}
                        columns={columns}
                      />
                    {/* <!-- /Contact List --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add New Reason --> */}
        <div className="modal fade" id="add_source" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add New Stage</h5>
                <button className="btn-close custom-btn-close border p-1 me-0 text-dark" data-bs-dismiss="modal" aria-label="Close">
                  <i className="ti ti-x"></i>
                </button>
              </div>
              <form action="sources.html">
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Stage  Name <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-0">
                    <label className="col-form-label">Status</label>
                    <div className="d-flex align-items-center">
                      <div className="me-2">
                        <input
                          type="radio"
                          className="status-radio"
                          id="add-active"
                          name="status"
                          checked=""
                        />
                        <label for="add-active">Active</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="status-radio"
                          id="add-inactive"
                          name="status"
                        />
                        <label for="add-inactive">Inactive</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex align-items-center justify-content-end m-0">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Create
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- /Add New Reason --> */}

        {/* <!-- Edit Reason --> */}
        <div className="modal fade" id="edit_stage" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Stage</h5>
                <button
                  className="btn-close custom-btn-close border p-1 me-0 text-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x"></i>
                </button>
              </div>
              <form action="sources.html">
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Reason  Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value="Phone Calls"
                    />
                  </div>
                  <div className="mb-0">
                    <label className="col-form-label">Status</label>
                    <div className="d-flex align-items-center">
                      <div className="me-2">
                        <input
                          type="radio"
                          className="status-radio"
                          id="edit-active"
                          name="status"
                          checked=""
                        />
                        <label for="edit-active">Active</label>
                      </div>
                      <div>
                        <input
                          type="radio"
                          className="status-radio"
                          id="edit-inactive"
                          name="status"
                        />
                        <label for="edit-inactive">Inactive</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex align-items-center justify-content-end m-0">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Save Changes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- /Edit Reason --> */}

        {/* <!-- Delete Source --> */}

        {/* <div className="modal fade" id="delete_source" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  <div className="avatar avatar-xl bg-danger-light rounded-circle mb-3">
                    <i className="ti ti-trash-x fs-36 text-danger"></i>
                  </div>
                  <h4 className="mb-2">Remove Source?</h4>
                  <p className="mb-0">AAre you sure you want to remove it.</p>
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <a href="sources.html" className="btn btn-danger">
                      Yes, Delete it
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <!-- /Delete Source --> */}
      </div>
    </>
  )
}

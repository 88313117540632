import "./style.css"

const CustomModal = ({
    children
}) => {
  return (
    <>
        <div className="custom-modal" id="Modal-box">
          <div className="inner-modal-content" >
            {children}
          </div>
        </div>
    </>
  )
}
export default CustomModal

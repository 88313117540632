import React from "react"
import AddContact from "../../../components/page-components/contacts/AddContact"

const ContactForm = ({
  formType,
  selectedData,
  setFormType,
  addContacts,
  setContactsValue,
  contactsvalue,
  countryListData
}) => {

  return (
    <>
      <div
        className="offcanvas offcanvas-end offcanvas-large"
        tabindex="-1"
        id="offcanvas_add"
      >         
        <AddContact
          formType={formType}
          selectedData={selectedData}
          setFormType={setFormType}
          addContacts={addContacts}
          setContactsValue={setContactsValue}
          contactsvalue={contactsvalue}
          countryListData={countryListData}
        />
      </div>
    </>
  )
}

export default ContactForm

import { useEffect, useState } from "react"
import CustomDropdown from "../../../components/custom-dropdown"
import { Constant } from "../../../utils"
import Data from "../../../utils/dummy-data"
import Creatable from 'react-select/creatable'

const TeamForm = ({ selectedData, formType }) => {
  const [reportingManager, setReportingManager] = useState(null)
  const [teamMember, setTeamMember] = useState(null)
  const [selectedTeam, setSelectedTeamMember] = useState([])

  const selectedTeamMemberOptions = selectedTeam.map(member => ({
    value: member,
    label: member,
  }))

  useEffect(() => {
    if (selectedData) {      
      const selectedReportingManager = Data.find((e) => e.contacts_name === selectedData?.contacts_name)
      setReportingManager(selectedReportingManager || null)
      setSelectedTeamMember(selectedData?.teamMember || [])
    }
  }, [selectedData])

  const clearInputValues = () => {
    setReportingManager(null)
    setTeamMember(null)
    setSelectedTeamMember([])
  }

  return (
    <>
      <div className="outer-modal-contentbox">
        <div
          className="offcanvas offcanvas-end offcanvas-large"
          tabindex="-1"
          id="offcanvas_add"
        >
          <div className="offcanvas-header border-bottom">
            <h5 className="fw-semibold">
              {formType === Constant.FORM_TYPE_EDIT
                ? "Update Team"
                : formType === Constant.FORM_TYPE_NEW
                ? "Add Team"
                : "Team Details"}
            </h5>
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                clearInputValues()
                return false
              }}
            >
              <i className="ti ti-x"></i>
            </button>
          </div>

          <div className="offcanvas-body">
            <form>
              <div className="row">
                <div className="col-md-8">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Reporting Manager <span className="text-danger">*</span>
                    </label>
                    <CustomDropdown
                      options={Data}
                      label="contacts_name"
                      id="email"
                      selectedValue={reportingManager}
                      setSelectedValue={setReportingManager}
                      placeholder="Reporting Manager"
                      isDisabled={formType === Constant.FORM_TYPE_VIEW}
                    />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Team Member <span className="text-danger">*</span>
                    </label>
                    <Creatable
                      options={selectedTeamMemberOptions}
                      isSearchable
                      isMulti
                      isDisabled={formType === Constant.FORM_TYPE_VIEW}
                      value={selectedTeamMemberOptions} 
                      onChange={(selected) => {
                        setSelectedTeamMember(selected.map(item => item.value))
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center">
                {formType !== Constant.FORM_TYPE_VIEW && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    // onClick={checkValidations}
                    disabled={formType === Constant.FORM_TYPE_VIEW}
                  >
                    {formType === Constant.FORM_TYPE_EDIT ? "Update" : "Submit"}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default TeamForm

import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"

export default function FileManager() {
	const [isOpen, setIsOpen] = useState(false)
	const openSideNav = () => {
		setIsOpen(!isOpen)
	}

	return (
		<>
			<div className={`main-wrapper ${isOpen && "slide-nav"}`}>
				{/* header component  */}
				<VerticalMenuComponent />
				<TopMenuComponent openSideNav={openSideNav} />

				{/* page wrapper  */}
				<div className="page-wrapper notes-page-wrapper file-manager">
					<div className="content">
						<div className="page-header page-add-notes d-flex align-items-center justify-content-between">
							<div className="add-item d-flex align-items-center">
								<div className="page-title">
									<h4>File Manager</h4>
									<p>Manage your files</p>
								</div>
								<a
									id="toggle_btn2"
									className="notes-tog"
									href="javascript:void(0);"
								>
									<i className="fas fa-chevron-left"></i>
								</a>
							</div>
							<div className="d-sm-flex align-items-center">
								<div className="form-sort">
									{/* <span>
										<i data-feather="sliders" className="info-img"></i>
									</span> */}
									<select className="form-select">
										<option>Owned By Me</option>
										<option>Owned by Anyone</option>
										<option>Not Owned by Me</option>
									</select>
								</div>
								<a href="#" className="btn btn-primary btn-added" data-bs-toggle="modal"data-bs-target="#upload-file">
									<span className="me-1 d-flex align-items-center">
										<i data-feather="upload" className="feather-16"></i>
									</span>
									Upload Files
								</a>								
							</div>
						</div>

						<div className="row">
							<div className="col-lg-3 col-md-12 sidebars-right theiaStickySidebar section-bulk-widget">
								<aside className="card file-manager-sidebar mb-0">
									<h5 className="d-flex align-items-center">
										<span className="me-2 d-flex align-items-center">
											<i data-feather="folder" className="feather-20"></i>
										</span>
										Files
									</h5>
									<div className="dropdown">
										<a
											href="javascript:void(0);"
											data-bs-toggle="dropdown"
											aria-expanded="false"
											className="dropset btn btn-primary mb-3 btn-icon"
										>
											<span className="me-1 w-auto btn-icon d-flex align-items-center">
												<i
													data-feather="plus-circle"
													className="feather-16"
												></i>
											</span>
											New
										</a>
										<ul className="dropdown-menu">
											<li data-bs-toggle="modal" data-bs-target="#upload-file">
												<a href="javascript:void(0);" className="dropdown-item">
													<i
														data-feather="upload-cloud"
														className="feather-16 me-2"
													></i>
													Upload File
												</a>
											</li>
											<li
												data-bs-toggle="modal"
												data-bs-target="#upload-folder"
											>
												<a href="javascript:void(0);" className="dropdown-item">
													<i
														data-feather="folder"
														className="feather-16 me-2"
													></i>
													Upload Folder
												</a>
											</li>
											<li
												data-bs-toggle="modal"
												data-bs-target="#create-folder"
											>
												<a href="javascript:void(0);" className="dropdown-item">
													<i
														data-feather="folder-minus"
														className="feather-16 me-2"
													></i>
													Create folder
												</a>
											</li>
										</ul>
									</div>

									<ul className="mb-3">
										<li>
											<a href="file-manager.html" className="active">
												<span className="me-2 btn-icon">
													<i
														data-feather="file-text"
														className="feather-16"
													></i>
												</span>
												My Files
											</a>
										</li>
										<li>
											<a href="javascript:void(0);">
												<span className="me-2 btn-icon">
													<i data-feather="star" className="feather-16"></i>
												</span>
												Google Drive
											</a>
										</li>
										<li>
											<a href="javascript:void(0);">
												<span className="me-2 btn-icon">
													<i data-feather="send" className="feather-16"></i>
												</span>
												Dropbox
											</a>
										</li>
										<li>
											<a href="javascript:void(0);">
												<span className="me-2 btn-icon">
													<i data-feather="file" className="feather-16"></i>
												</span>
												Shared With Me
											</a>
										</li>
										<li>
											<a href="javascript:void(0);">
												<span className="me-2 btn-icon">
													<i data-feather="file" className="feather-16"></i>
												</span>
												Document
											</a>
										</li>
										<li>
											<a href="javascript:void(0);">
												<span className="me-2 btn-icon">
													<i data-feather="clock" className="feather-16"></i>
												</span>
												Recent
											</a>
										</li>
										<li>
											<a href="javascript:void(0);">
												<span className="me-2 btn-icon">
													<i data-feather="star" className="feather-16"></i>
												</span>
												Favourites
											</a>
										</li>
										<li>
											<a href="javascript:void(0);">
												<span className="me-2 btn-icon">
													<i data-feather="target" className="feather-16"></i>
												</span>
												Archived
											</a>
										</li>
										<li>
											<a href="javascript:void(0);">
												<span className="me-2 btn-icon">
													<i data-feather="trash-2" className="feather-16"></i>
												</span>
												Deleted
											</a>
										</li>
										<li>
											<a href="javascript:void(0);">
												<span className="me-2 btn-icon">
													<i data-feather="settings" className="feather-16"></i>
												</span>
												Settings
											</a>
										</li>
									</ul>
									<div className="d-flex align-items-center justify-content-between">
										<div className="d-flex align-items-center">
											<span className="me-2 btn-icon">
												<i data-feather="hard-drive" className="feather-16"></i>
											</span>
											<h6>Storage</h6>
										</div>
										<span>70%</span>
									</div>
									<div className="progress my-2">
										<div className="progress-bar progress-bar bg-danger" role="progressbar" style={{ width: "75%" }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
									<span>78.5 GB of 1 TB Free Used</span>
								</aside>
							</div>

							<div className="col-lg-9 budget-role-notes">
								<div className="row">
									<div className="col-12">
										<div className="section-bulk-wrap">
											<div className="bulk-action-type col-lg-6 col-md-6">
												<div className="form-sort select-bluk">
													<select className="form-select">
														<option>Sort by Date</option>
														<option>Sort By Relevance</option>
														<option>Sort By Size</option>
														<option>Order Ascending</option>
														<option>Order Descending</option>
														<option>Upload Time</option>
													</select>
												</div>
												<div
													className="search-set me-2"
													id="dropdownSort"
													data-bs-toggle="dropdown"
													data-bs-auto-close="outside"
												>
													<div className="search-input">
														<a href="" className="btn btn-searchset">
															<i
																data-feather="search"
																className="feather-search"
															></i>
														</a>
														<div className="dataTables_filter">
															<label>
																{" "}
																<input
																	type="search"
																	className="form-control form-control-sm width-auto"
																	placeholder="Search"
																/>
															</label>
														</div>
													</div>
												</div>

												<div
													className="dropdown-menu search-dropdown"
													aria-labelledby="dropdownMenuClickable"
												>
													<div className="search-info">
														<h6>
															<span>
																<i
																	data-feather="search"
																	className="feather-16"
																></i>
															</span>
															Recent Searches
														</h6>
														<ul className="search-tags">
															<li>
																<a href="javascript:void(0);">Filename</a>
															</li>
															<li>
																<a href="javascript:void(0);">Excel Files</a>
															</li>
														</ul>
													</div>
													<div className="search-info">
														<h6>Search Results</h6>
														<p className="d-flex align-items-center justify-content-between">
															Sportsmodel.pdf
															<i
																data-feather="chevron-right"
																className="feather-16"
															></i>
														</p>
														<p className="d-flex align-items-center justify-content-between">
															Projectdetails.xls
															<i
																data-feather="chevron-right"
																className="feather-16"
															></i>
														</p>
													</div>
												</div>
											</div>
											<div className="d-sm-flex align-items-center col-lg-6 col-md-6 justify-content-end">
												<div className="form-sort me-3">
													{/* <i data-feather="filter" className="fa-filter"></i> */}
													<select className="form-select">
														<option>Recent</option>
														<option>Last Week</option>
														<option>Last Month</option>
													</select>
												</div>
												<div className="form-sort">
													{/* <i data-feather="file-text" className="fa-filter"></i> */}
													<select className="form-select">
														<option>All File types</option>
														<option>Folders</option>
														<option>PDF</option>
														<option>Images</option>
														<option>Videos</option>
														<option>Audios</option>
														<option>Excel</option>
													</select>
												</div>
											</div>
										</div>
									</div>
								</div>

								{/* <!-- /Overview --> */}
								<div className="overview seprator-lg">
									<h4 className="mb-2">Overview</h4>
									<div className="row g-3">
										<div className="col-sm-6 col-md-3">
											<div className="detail">
												<a
													href="javascript:void(0);"
													className="d-flex align-items-center justify-content-center bg-light-orange bg p-4"
												>
													<span className="d-flex align-items-center justify-content-center">
														<img src="/img/icons/folder.svg" alt="Folder" />
													</span>
												</a>
												<div className="d-flex align-items-center justify-content-between info">
													<h6>
														<a href="javascript:void(0);">Folders</a>
													</h6>
													<span>300 Files</span>
												</div>
											</div>
										</div>
										<div className="col-sm-6 col-md-3">
											<div className="detail">
												<a
													href="javascript:void(0);"
													className="d-flex align-items-center justify-content-center bg-light-red bg p-4"
												>
													<span className="d-flex align-items-center justify-content-center">
														<img src="/img/icons/pdf-02.svg" alt="Folder" />
													</span>
												</a>
												<div className="d-flex align-items-center justify-content-between info">
													<h6>
														<a href="javascript:void(0);">PDF</a>
													</h6>
													<span>50 Files</span>
												</div>
											</div>
										</div>
										<div className="col-sm-6 col-md-3">
											<div className="detail">
												<a
													href="javascript:void(0);"
													className="d-flex align-items-center justify-content-center bg-light-green bg p-4"
												>
													<span className="d-flex align-items-center justify-content-center">
														<img src="/img/icons/image.svg" alt="Folder" />
													</span>
												</a>
												<div className="d-flex align-items-center justify-content-between info">
													<h6>
														<a href="javascript:void(0);">Images</a>
													</h6>
													<span>240 Files</span>
												</div>
											</div>
										</div>
										<div className="col-sm-6 col-md-3">
											<div className="detail">
												<a
													href="javascript:void(0);"
													className="d-flex align-items-center justify-content-center bg-light-red bg p-4"
												>
													<span className="d-flex align-items-center justify-content-center">
														<img src="/img/icons/video.svg" alt="Folder" />
													</span>
												</a>
												<div className="d-flex align-items-center justify-content-between info">
													<h6>
														<a href="javascript:void(0);">Videos</a>
													</h6>
													<span>30 Files</span>
												</div>
											</div>
										</div>
										<div className="col-sm-6 col-md-3">
											<div className="detail">
												<a
													href="javascript:void(0);"
													className="d-flex align-items-center justify-content-center bg-light-orange bg p-4"
												>
													<span className="d-flex align-items-center justify-content-center">
														<img src="/img/icons/audio.svg" alt="Folder" />
													</span>
												</a>
												<div className="d-flex align-items-center justify-content-between info">
													<h6>
														<a href="javascript:void(0);">Audios</a>
													</h6>
													<span>100 Files</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <!-- /Overview --> */}

								{/* <!-- Accordian --> */}
								<div className="accordion" id="accordionPanelsStayOpenExample">
									<div className="accordion-item seprator-lg">
										<h4
											className="accordion-header d-flex align-items-center justify-content-between"
											id="panelsStayOpen-headingOne"
										>
											Folders
											<a
												href="javascript:void(0);"
												className="accordion-button a-auto"
												data-bs-toggle="collapse"
												data-bs-target="#panelsStayOpen-collapseOne"
												aria-expanded="true"
												aria-controls="panelsStayOpen-collapseOne"
											></a>
										</h4>
										<div
											id="panelsStayOpen-collapseOne"
											className="accordion-collapse collapse show"
											aria-labelledby="panelsStayOpen-headingOne"
										>
											<div className="accordion-body">
												<div className="owl-carousel folders-carousel owl-theme">
													<div className="folders p-3">
														<div className="d-flex align-items-center justify-content-between head">
															<div className="d-flex align-items-center">
																<img
																	src="/img/icons/folder.svg"
																	alt="Folder"
																	className="me-2"
																/>
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">
																		Project Details
																	</a>
																</h6>
															</div>
															<div className="dropdown">
																<a
																	href="javascript:void(0);"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																	className="dropset"
																>
																	<i className="fa fa-ellipsis-v"></i>
																</a>
																<ul className="dropdown-menu">
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Details
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Share
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Copy
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Move
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Download
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Rename
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Archeived
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Delete
																		</a>
																	</li>
																</ul>
															</div>
														</div>
														<div className="d-flex align-items-center justify-content-start project-plan my-3">
															<label>Project plan</label>
															<ul className="d-flex">
																<li className="d-flex align-items-center">
																	154 KB
																</li>
																<li className="d-flex align-items-center">
																	8 Files
																</li>
															</ul>
														</div>
														<div className="d-flex align-items-center justify-content-between avatar-wrap">
															<div className="group-avatar">
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 1"
																		data-bs-original-title="Member 1"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-02.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 2"
																		data-bs-original-title="Member 2"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-01.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="count d-block">
																	<a href="javascript:void(0);">1 Members</a>
																</span>
															</div>
															<a href="javascript:void(0);" className="">
																<i
																	data-feather="star"
																	className="feather-16"
																></i>
															</a>
														</div>
													</div>
													<div className="folders p-3">
														<div className="d-flex align-items-center justify-content-between head">
															<div className="d-flex align-items-center">
																<img
																	src="/img/icons/folder.svg"
																	alt="Folder"
																	className="me-2"
																/>
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">
																		Project Details
																	</a>
																</h6>
															</div>
															<div className="dropdown">
																<a
																	href="javascript:void(0);"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																	className="dropset"
																>
																	<i className="fa fa-ellipsis-v"></i>
																</a>
																<ul className="dropdown-menu">
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Details
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Share
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Copy
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Move
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Download
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Rename
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Archeived
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Delete
																		</a>
																	</li>
																</ul>
															</div>
														</div>
														<div className="d-flex align-items-center justify-content-start project-plan my-3">
															<label>Project plan</label>
															<ul className="d-flex">
																<li className="d-flex align-items-center">
																	154 KB
																</li>
																<li className="d-flex align-items-center">
																	8 Files
																</li>
															</ul>
														</div>
														<div className="d-flex align-items-center justify-content-between avatar-wrap">
															<div className="group-avatar">
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 1"
																		data-bs-original-title="Member 1"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-06.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 2"
																		data-bs-original-title="Member 2"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-14.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 3"
																		data-bs-original-title="Member 3"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-15.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 4"
																		data-bs-original-title="Member 4"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-16.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 5"
																		data-bs-original-title="Member 5"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-17.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="count d-block">
																	<a href="javascript:void(0);">+9 Members</a>
																</span>
															</div>
															<a href="javascript:void(0);" className="">
																<i
																	data-feather="star"
																	className="feather-16"
																></i>
															</a>
														</div>
													</div>
													<div className="folders p-3">
														<div className="d-flex align-items-center justify-content-between head">
															<div className="d-flex align-items-center">
																<img
																	src="/img/icons/folder.svg"
																	alt="Folder"
																	className="me-2"
																/>
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">
																		Project Details
																	</a>
																</h6>
															</div>
															<div className="dropdown">
																<a
																	href="javascript:void(0);"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																	className="dropset"
																>
																	<i className="fa fa-ellipsis-v"></i>
																</a>
																<ul className="dropdown-menu">
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Details
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Share
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Copy
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Move
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Download
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Rename
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Archeived
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Delete
																		</a>
																	</li>
																</ul>
															</div>
														</div>
														<div className="d-flex align-items-center justify-content-start project-plan my-3">
															<label>Project plan</label>
															<ul className="d-flex">
																<li className="d-flex align-items-center">
																	154 KB
																</li>
																<li className="d-flex align-items-center">
																	8 Files
																</li>
															</ul>
														</div>
														<div className="d-flex align-items-center justify-content-between avatar-wrap">
															<div className="group-avatar">
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 1"
																		data-bs-original-title="Member 1"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-18.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 2"
																		data-bs-original-title="Member 2"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-05.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="count d-block">
																	<a href="javascript:void(0);">+2 Members</a>
																</span>
															</div>
															<a href="javascript:void(0);" className="">
																<i
																	data-feather="star"
																	className="feather-16"
																></i>
															</a>
														</div>
													</div>
													<div className="folders p-3">
														<div className="d-flex align-items-center justify-content-between head">
															<div className="d-flex align-items-center">
																<img
																	src="/img/icons/folder.svg"
																	alt="Folder"
																	className="me-2"
																/>
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">
																		Project Details
																	</a>
																</h6>
															</div>
															<div className="dropdown">
																<a
																	href="javascript:void(0);"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																	className="dropset"
																>
																	<i className="fa fa-ellipsis-v"></i>
																</a>
																<ul className="dropdown-menu">
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Details
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Share
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Copy
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Move
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Download
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Rename
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Archeived
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			Delete
																		</a>
																	</li>
																</ul>
															</div>
														</div>
														<div className="d-flex align-items-center justify-content-start project-plan my-3">
															<label>Project plan</label>
															<ul className="d-flex">
																<li className="d-flex align-items-center">
																	154 KB
																</li>
																<li className="d-flex align-items-center">
																	8 Files
																</li>
															</ul>
														</div>
														<div className="d-flex align-items-center justify-content-between avatar-wrap">
															<div className="group-avatar">
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 1"
																		data-bs-original-title="Member 1"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-01.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 2"
																		data-bs-original-title="Member 2"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-02.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 3"
																		data-bs-original-title="Member 3"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-03.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="avatar">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="tooltip"
																		data-bs-placement="right"
																		aria-label="Member 4"
																		data-bs-original-title="Member 4"
																	>
																		<img
																			src="/img/profiles/avatar/avatar-04.jpg"
																			alt="Avatar"
																		/>
																	</a>
																</span>
																<span className="count d-block">
																	<a href="javascript:void(0);">1 Members</a>
																</span>
															</div>
															<a href="javascript:void(0);" className="">
																<i
																	data-feather="star"
																	className="feather-16"
																></i>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion-item seprator-lg">
										<h4
											className="accordion-header d-flex align-items-center justify-content-between"
											id="panelsStayOpen-headingTwo"
										>
											Files
											<a
												href="javascript:void(0);"
												className="accordion-button w-auto"
												data-bs-toggle="collapse"
												data-bs-target="#panelsStayOpen-collapseTwo"
												aria-expanded="false"
												aria-controls="panelsStayOpen-collapseTwo"
											></a>
										</h4>
										<div
											id="panelsStayOpen-collapseTwo"
											className="accordion-collapse collapse show"
											aria-labelledby="panelsStayOpen-headingTwo"
										>
											<div className="accordion-body">
												<div className="owl-carousel files-carousel owl-theme">
													<div className="folders p-3">
														<div className="d-flex align-items-center justify-content-between head">
															<div className="d-flex align-items-center">
																<img
																	src="/img/icons/pdf-02.svg"
																	alt="File"
																	className="me-2"
																/>
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">hsa.pdf</a>
																</h6>
															</div>
															<div className="d-flex align-items-center">
																<a href="javascript:void(0);" className="">
																	<i className="fa fa-star me-2"></i>
																</a>
																<div className="dropdown">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																		className="dropset"
																	>
																		<i className="fa fa-ellipsis-v"></i>
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Details
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Share
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Copy
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Move
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Download
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Rename
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Archeived
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Delete
																			</a>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="d-flex align-items-center justify-content-start project-plan mt-3">
															<label>12 Jul</label>
															<ul className="d-flex">
																<li className="d-flex align-items-center">
																	85 MB
																</li>
															</ul>
														</div>
													</div>
													<div className="folders p-3">
														<div className="d-flex align-items-center justify-content-between head">
															<div className="d-flex align-items-center">
																<img
																	src="/img/icons/pdf-02.svg"
																	alt="File"
																	className="me-2"
																/>
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">Haird.pdf</a>
																</h6>
															</div>
															<div className="d-flex align-items-center">
																<a href="javascript:void(0);" className="">
																	<i className="fa fa-star me-2"></i>
																</a>
																<div className="dropdown">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																		className="dropset"
																	>
																		<i className="fa fa-ellipsis-v"></i>
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Details
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Share
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Copy
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Move
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Download
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Rename
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Archeived
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Delete
																			</a>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="d-flex align-items-center justify-content-start project-plan mt-3">
															<label>14 Jul</label>
															<ul className="d-flex">
																<li className="d-flex align-items-center">
																	4 MB
																</li>
															</ul>
														</div>
													</div>
													<div className="folders p-3">
														<div className="d-flex align-items-center justify-content-between head">
															<div className="d-flex align-items-center">
																<img
																	src="/img/icons/xls.svg"
																	alt="File"
																	className="me-2"
																/>
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">
																		Estimation.xls
																	</a>
																</h6>
															</div>
															<div className="d-flex align-items-center">
																<a href="javascript:void(0);" className="">
																	<i className="fa fa-star me-2"></i>
																</a>
																<div className="dropdown">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																		className="dropset"
																	>
																		<i className="fa fa-ellipsis-v"></i>
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Details
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Share
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Copy
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Move
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Download
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Rename
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Archeived
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Delete
																			</a>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="d-flex align-items-center justify-content-start project-plan mt-3">
															<label>14 Jul</label>
															<ul className="d-flex">
																<li className="d-flex align-items-center">
																	500 KB
																</li>
															</ul>
														</div>
													</div>
													<div className="folders p-3">
														<div className="d-flex align-items-center justify-content-between head">
															<div className="d-flex align-items-center">
																<img
																	src="/img/icons/pdf-02.svg"
																	alt="File"
																	className="me-2"
																/>
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">hsa.pdf</a>
																</h6>
															</div>
															<div className="d-flex align-items-center">
																<a href="javascript:void(0);" className="">
																	<i className="fa fa-star me-2"></i>
																</a>
																<div className="dropdown">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																		className="dropset"
																	>
																		<i className="fa fa-ellipsis-v"></i>
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Details
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Share
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Copy
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Move
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Download
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Rename
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Archeived
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Delete
																			</a>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="d-flex align-items-center justify-content-start project-plan mt-3">
															<label>12 Jul</label>
															<ul className="d-flex">
																<li className="d-flex align-items-center">
																	85 MB
																</li>
															</ul>
														</div>
													</div>
													<div className="folders p-3">
														<div className="d-flex align-items-center justify-content-between head">
															<div className="d-flex align-items-center">
																<img
																	src="/img/icons/pdf-02.svg"
																	alt="File"
																	className="me-2"
																/>
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">Haird.pdf</a>
																</h6>
															</div>
															<div className="d-flex align-items-center">
																<a href="javascript:void(0);" className="">
																	<i className="fa fa-star me-2"></i>
																</a>
																<div className="dropdown">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																		className="dropset"
																	>
																		<i className="fa fa-ellipsis-v"></i>
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Details
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Share
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Copy
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Move
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Download
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Rename
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Archeived
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Delete
																			</a>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="d-flex align-items-center justify-content-start project-plan mt-3">
															<label>14 Jul</label>
															<ul className="d-flex">
																<li className="d-flex align-items-center">
																	4 MB
																</li>
															</ul>
														</div>
													</div>
													<div className="folders p-3">
														<div className="d-flex align-items-center justify-content-between head">
															<div className="d-flex align-items-center">
																<img
																	src="/img/icons/xls.svg"
																	alt="File"
																	className="me-2"
																/>
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">
																		Estimation.xls
																	</a>
																</h6>
															</div>
															<div className="d-flex align-items-center">
																<a href="javascript:void(0);" className="">
																	<i className="fa fa-star me-2"></i>
																</a>
																<div className="dropdown">
																	<a
																		href="javascript:void(0);"
																		data-bs-toggle="dropdown"
																		aria-expanded="false"
																		className="dropset"
																	>
																		<i className="fa fa-ellipsis-v"></i>
																	</a>
																	<ul className="dropdown-menu">
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Details
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Share
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Copy
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Move
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Download
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Rename
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Archeived
																			</a>
																		</li>
																		<li>
																			<a
																				href="javascript:void(0);"
																				className="dropdown-item"
																			>
																				Delete
																			</a>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="d-flex align-items-center justify-content-start project-plan mt-3">
															<label>14 Jul</label>
															<ul className="d-flex">
																<li className="d-flex align-items-center">
																	500 KB
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className="accordion-item mb-4">
										<h4
											className="accordion-header d-flex align-items-center justify-content-between"
											id="panelsStayOpen-headingThree"
										>
											Videos
											<a
												href="javascript:void(0);"
												className="accordion-button w-auto"
												data-bs-toggle="collapse"
												data-bs-target="#panelsStayOpen-collapseThree"
												aria-expanded="false"
												aria-controls="panelsStayOpen-collapseThree"
											></a>
										</h4>
										<div
											id="panelsStayOpen-collapseThree"
											className="accordion-collapse collapse show"
											aria-labelledby="panelsStayOpen-headingThree"
										>
											<div className="accordion-body">
												<div className="owl-carousel video-section">
													<div className="item">
														<div>
															<video
																width="100"
																height="100"
																className="js-player"
																crossorigin
																playsinline
																poster="/img/file-manager/video1.jpg"
															>
																<source
																	src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-720p.mp4"
																	type="video/mp4"
																/>
															</video>
														</div>

														<div className="info">
															<div className="d-flex align-items-center justify-content-between">
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">Demo_dw</a>
																</h6>
																<div className="d-flex align-items-center">
																	<a
																		href="javascript:void(0);"
																		className="d-flex align-items-center"
																	>
																		<i
																			data-feather="star"
																			className="feather-16 me-2"
																		></i>
																	</a>
																	<div className="dropdown">
																		<a
																			href="javascript:void(0);"
																			data-bs-toggle="dropdown"
																			aria-expanded="false"
																			className="dropset"
																		>
																			<i className="fa fa-ellipsis-v"></i>
																		</a>
																		<ul className="dropdown-menu">
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Details
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Share
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Copy
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Move
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Download
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Rename
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Archeived
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Delete
																				</a>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
															<div className="d-flex align-items-center justify-content-start project-plan">
																<label>12 Jul</label>
																<ul className="d-flex">
																	<li className="d-flex align-items-center">
																		5 MB
																	</li>
																</ul>
															</div>
														</div>
													</div>
													<div className="item">
														<div>
															<video
																className="js-player"
																crossorigin
																playsinline
																poster="/img/file-manager/video2.jpg"
															>
																<source
																	src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-720p.mp4"
																	type="video/mp4"
																/>
															</video>
														</div>
														<div className="info">
															<div className="d-flex align-items-center justify-content-between">
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">
																		Android_bike.mp4
																	</a>
																</h6>
																<div className="d-flex align-items-center">
																	<a
																		href="javascript:void(0);"
																		className="d-flex align-items-center"
																	>
																		<i
																			data-feather="star"
																			className="feather-16 me-2"
																		></i>
																	</a>
																	<div className="dropdown">
																		<a
																			href="javascript:void(0);"
																			data-bs-toggle="dropdown"
																			aria-expanded="false"
																			className="dropset"
																		>
																			<i className="fa fa-ellipsis-v"></i>
																		</a>
																		<ul className="dropdown-menu">
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Details
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Share
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Copy
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Move
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Download
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Rename
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Archeived
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Delete
																				</a>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
															<div className="d-flex align-items-center justify-content-start project-plan">
																<label>14 Jul</label>
																<ul className="d-flex">
																	<li className="d-flex align-items-center">
																		23 MB
																	</li>
																</ul>
															</div>
														</div>
													</div>
													<div className="item">
														<div>
															<video
																className="js-player"
																crossorigin
																playsinline
																poster="/img/file-manager/video3.jpg"
															>
																<source
																	src="https://cdn.plyr.io/static/demo/View_From_A_Blue_Moon_Trailer-720p.mp4"
																	type="video/mp4"
																/>
															</video>
														</div>
														<div className="info">
															<div className="d-flex align-items-center justify-content-between">
																<h6 className="popup-toggle">
																	<a href="javascript:void(0);">
																		Demoparticles.mp4
																	</a>
																</h6>
																<div className="d-flex align-items-center">
																	<a
																		href="javascript:void(0);"
																		className="d-flex align-items-center"
																	>
																		<i
																			data-feather="star"
																			className="feather-16 me-2"
																		></i>
																	</a>
																	<div className="dropdown">
																		<a
																			href="javascript:void(0);"
																			data-bs-toggle="dropdown"
																			aria-expanded="false"
																			className="dropset"
																		>
																			<i className="fa fa-ellipsis-v"></i>
																		</a>
																		<ul className="dropdown-menu">
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Details
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Share
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Copy
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Move
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Download
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Rename
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Archeived
																				</a>
																			</li>
																			<li>
																				<a
																					href="javascript:void(0);"
																					className="dropdown-item"
																				>
																					Delete
																				</a>
																			</li>
																		</ul>
																	</div>
																</div>
															</div>
															<div className="d-flex align-items-center justify-content-start project-plan">
																<label>14 Jul</label>
																<ul className="d-flex">
																	<li className="d-flex align-items-center">
																		173 MB
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <!-- /Accordian --> */}

								<div className="card p-4 bg-white all-files mb-0">
									<div className="seprator-lg d-lg-flex align-items-center justify-content-between">
										<h4>All Files</h4>
									</div>

									<div className="table-responsive">
										<table className="table datanew">
											<thead>
												<tr>
													<th>
														<label className="checkboxs">
															<input type="checkbox" id="select-all" />
															<span className="checkmarks"></span>
														</label>
													</th>
													<th>Name</th>
													<th>Last Modified</th>
													<th>Size</th>
													<th>Owned Memebr</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>
														<label className="checkboxs">
															<input type="checkbox" />
															<span className="checkmarks"></span>
														</label>
													</td>
													<td className="productimgname">
														<a
															href="javascript:void(0);"
															className="product-img d-flex align-items-center"
														>
															<img
																src="/img/icons/folder.svg"
																alt="Product"
																className="me-2"
															/>
															<span>Digimed</span>
														</a>
													</td>
													<td>
														Today 08:30 AM <br />
														by Angel
													</td>
													<td>200 MB</td>
													<td>
														<a
															href="javascript:void(0);"
															className="product-img d-flex align-items-center"
														>
															<img
																src="/img/profiles/avatar/avatar-06.jpg"
																alt="Product"
																className="me-2 rounded-circle"
															/>
															<span>Nolan Christopher</span>
														</a>
													</td>
													<td>
														<div className="d-flex align-items-center">
															<a href="javascript:void(0);" className="">
																<i
																	data-feather="star"
																	className="feather-16 me-2 color-primary"
																></i>
															</a>
															<div className="dropdown">
																<a
																	href="javascript:void(0);"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																	className="dropset"
																>
																	<i className="fa fa-ellipsis-v"></i>
																</a>
																<ul className="dropdown-menu">
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			<i
																				data-feather="trash-2"
																				className="feather-14 me-2"
																			></i>
																			Permanent Delete
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			<i
																				data-feather="edit"
																				className="feather-14 me-2"
																			></i>
																			Restore File
																		</a>
																	</li>
																</ul>
															</div>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<label className="checkboxs">
															<input type="checkbox" />
															<span className="checkmarks"></span>
														</label>
													</td>
													<td className="productimgname">
														<a
															href="javascript:void(0);"
															className="product-img d-flex align-items-center"
														>
															<img
																src="/img/icons/xls.svg"
																alt="Product"
																className="me-2"
															/>
															<span>Estimation</span>
														</a>
													</td>
													<td>Today 09:20 AM</td>
													<td>140 MB</td>
													<td>
														<a
															href="javascript:void(0);"
															className="product-img d-flex align-items-center"
														>
															<img
																src="/img/profiles/avatar/avatar-05.jpg"
																alt="Product"
																className="me-2 rounded-circle"
															/>
															<span>Nolan Harris</span>
														</a>
													</td>
													<td>
														<div className="d-flex align-items-center">
															<a href="javascript:void(0);" className="">
																<i
																	data-feather="star"
																	className="feather-16 me-2 color-primary"
																></i>
															</a>
															<div className="dropdown">
																<a
																	href="javascript:void(0);"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																	className="dropset"
																>
																	<i className="fa fa-ellipsis-v"></i>
																</a>
																<ul className="dropdown-menu">
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			<i
																				data-feather="trash-2"
																				className="feather-14 me-2"
																			></i>
																			Permanent Delete
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			<i
																				data-feather="edit"
																				className="feather-14 me-2"
																			></i>
																			Restore File
																		</a>
																	</li>
																</ul>
															</div>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<label className="checkboxs">
															<input type="checkbox" />
															<span className="checkmarks"></span>
														</label>
													</td>
													<td className="productimgname">
														<a
															href="javascript:void(0);"
															className="product-img d-flex align-items-center"
														>
															<img
																src="/img/icons/pdf-02.svg"
																alt="Product"
																className="me-2"
															/>
															<span>Intro.pdf</span>
														</a>
													</td>
													<td>27 July 2023</td>
													<td>70 MB</td>
													<td>
														<a
															href="javascript:void(0);"
															className="product-img d-flex align-items-center"
														>
															<img
																src="/img/profiles/avatar/avatar-10.jpg"
																alt="Product"
																className="me-2 rounded-circle"
															/>
															<span>Me</span>
														</a>
													</td>
													<td className="text-end">
														<div className="d-flex align-items-center">
															<a href="javascript:void(0);" className="">
																<i
																	data-feather="star"
																	className="feather-16 me-2 color-primary"
																></i>
															</a>
															<div className="dropdown">
																<a
																	href="javascript:void(0);"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																	className="dropset"
																>
																	<i className="fa fa-ellipsis-v"></i>
																</a>
																<ul className="dropdown-menu">
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			<i
																				data-feather="trash-2"
																				className="feather-14 me-2"
																			></i>
																			Permanent Delete
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			<i
																				data-feather="edit"
																				className="feather-14 me-2"
																			></i>
																			Restore File
																		</a>
																	</li>
																</ul>
															</div>
														</div>
													</td>
												</tr>

												<tr>
													<td>
														<label className="checkboxs">
															<input type="checkbox" />
															<span className="checkmarks"></span>
														</label>
													</td>
													<td className="productimgname">
														<a
															href="javascript:void(0);"
															className="product-img d-flex align-items-center"
														>
															<img
																src="/img/icons/video.svg"
																alt="Product"
																className="me-2"
															/>
															<span>Demoworrking.mp4</span>
														</a>
													</td>
													<td>
														24 July 2023
														<br />
														08:25 AM
													</td>
													<td>180 MB</td>
													<td>
														<a
															href="javascript:void(0);"
															className="product-img d-flex align-items-center"
														>
															<img
																src="/img/profiles/avatar/avatar-02.jpg"
																alt="Product"
																className="me-2 rounded-circle"
															/>
															<span>Daniel</span>
														</a>
													</td>
													<td>
														<div className="d-flex align-items-center">
															<a href="javascript:void(0);" className="">
																<i
																	data-feather="star"
																	className="feather-16 me-2 color-primary"
																></i>
															</a>
															<div className="dropdown">
																<a
																	href="javascript:void(0);"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																	className="dropset"
																>
																	<i className="fa fa-ellipsis-v"></i>
																</a>
																<ul className="dropdown-menu">
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			<i
																				data-feather="trash-2"
																				className="feather-14 me-2"
																			></i>
																			Permanent Delete
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			<i
																				data-feather="edit"
																				className="feather-14 me-2"
																			></i>
																			Restore File
																		</a>
																	</li>
																</ul>
															</div>
														</div>
													</td>
												</tr>
												<tr>
													<td>
														<label className="checkboxs">
															<input type="checkbox" />
															<span className="checkmarks"></span>
														</label>
													</td>
													<td className="productimgname">
														<a
															href="javascript:void(0);"
															className="product-img d-flex align-items-center"
														>
															<img
																src="/img/icons/audio.svg"
																alt="Product"
																className="me-2"
															/>
															<span>voice.mp3</span>
														</a>
													</td>
													<td>27 July 2023</td>
													<td>80 MB</td>
													<td>
														<a
															href="javascript:void(0);"
															className="product-img d-flex align-items-center"
														>
															<img
																src="/img/profiles/avatar/avatar-07.jpg"
																alt="Product"
																className="me-2 rounded-circle"
															/>
															<span>Henriques</span>
														</a>
													</td>
													<td className="text-end">
														<div className="d-flex align-items-center">
															<a href="javascript:void(0);" className="">
																<i
																	data-feather="star"
																	className="feather-16 me-2 color-primary"
																></i>
															</a>
															<div className="dropdown">
																<a
																	href="javascript:void(0);"
																	data-bs-toggle="dropdown"
																	aria-expanded="false"
																	className="dropset"
																>
																	<i className="fa fa-ellipsis-v"></i>
																</a>
																<ul className="dropdown-menu">
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			<i
																				data-feather="trash-2"
																				className="feather-14 me-2"
																			></i>
																			Permanent Delete
																		</a>
																	</li>
																	<li>
																		<a
																			href="javascript:void(0);"
																			className="dropdown-item"
																		>
																			<i
																				data-feather="edit"
																				className="feather-14 me-2"
																			></i>
																			Restore File
																		</a>
																	</li>
																</ul>
															</div>
														</div>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* <!-- Files Toogle Slide --> */}
			<div className="toggle-sidebar">
				<div className="d-flex align-items-center justify-content-between head">
					<h4>File Preview</h4>
					<div className="d-flex align-items-center">
						<a
							href="javascript:void(0);"
							className="me-2 d-flex align-items-center"
						>
							<i className="fa fa-star"></i>
						</a>
						<a
							href="javascript:void(0);"
							className="me-2 d-flex align-items-center"
						>
							<i
								data-feather="trash-2"
								className="feather-16 text-center text-danger"
							></i>
						</a>
						<a
							href="javascript:void(0);"
							className="sidebar-closes d-flex align-items-center"
							aria-hidden="true"
						>
							<i
								data-feather="x-circle"
								className="feather-26 color-primary"
							></i>
						</a>
					</div>
				</div>
				<div className="text-center">
					<a href="javascript:void(0);">
						<img src="/img/file-manager/folder-lg.png" alt="Folder" />
					</a>
					<h5>Website Backup for the Design team</h5>
					<p>File Size : 616 MB</p>
				</div>

				<div
					className="nav nav-tabs d-flex align-items-center justify-content-between py-4 mb-4"
					id="nav-tab"
					role="tablist"
				>
					<a
						className="nav-link flex-fill active btn btn-light me-2 text-center"
						id="nav-home-tab"
						data-bs-toggle="tab"
						href="#nav-home"
						role="tab"
						aria-controls="nav-home"
						aria-selected="true"
					>
						<i data-feather="list" className="feather-16 me-2 text-center"></i>
						Details
					</a>
					<a
						className="nav-link flex-fill btn btn-light"
						id="nav-profile-tab"
						data-bs-toggle="tab"
						href="#nav-profile"
						role="tab"
						aria-controls="nav-profile"
						aria-selected="false"
					>
						<i data-feather="clock" className="feather-16 me-2"></i>Activity
					</a>
				</div>
				<div className="tab-content" id="nav-tabContent">
					<div
						className="tab-pane fade show active"
						id="nav-home"
						role="tabpanel"
						aria-labelledby="nav-home-tab"
					>
						<h5 className="mb-4 d-flex align-items-center">
							<i data-feather="edit" className="feather-20 me-2"></i>Properties
						</h5>
						<ul className="seprator-lg">
							<li className="mb-4">
								<h6>File Name</h6>
								<p>Website Backup for the Designteam</p>
							</li>
							<li className="mb-4">
								<h6>File Type</h6>
								<p>Folder</p>
							</li>
							<li className="mb-4">
								<h6>Size</h6>
								<p>616 MB</p>
							</li>
							<li className="mb-4">
								<h6>Created</h6>
								<p>22 July 2023, 08:30 PM</p>
							</li>
							<li className="mb-4">
								<h6>Location</h6>
								<p className="location d-inline-flex align-items-center">
									<i data-feather="hard-drive" className="feather-16 me-1"></i>
									Drive
								</p>
							</li>
							<li className="mb-4">
								<h6>File Name</h6>
								<p>23 July 2023, 08:30 PM</p>
							</li>
							<li className="mb-4">
								<h6>Opened On</h6>
								<p>28 July 2023, 06:40 PM</p>
							</li>
							<li>
								<div className="row">
									{/* <!-- Editor --> */}
									<div className="col-lg-12">
										<div className="input-blocks summer-description-box transfer">
											<label>Description</label>
											<div id="summernote3"></div>
											<p>Maximum 60 Characters</p>
										</div>
									</div>
									{/* <!-- /Editor --> */}
								</div>
							</li>
						</ul>
						<h5 className="mb-4 d-flex align-items-center">
							<i data-feather="user" className="feather-20 me-2"></i>Who has
							access
						</h5>
						<div className="d-flex align-items-center justify-content-between avatar-wrap">
							<div className="avatar-access d-flex align-items-center mb-4">
								<span>
									<a
										href="javascript:void(0);"
										data-bs-toggle="tooltip"
										data-bs-placement="right"
										aria-label="Member 1"
										data-bs-original-title="Member 1"
									>
										<img
											src="/img/profiles/avatar/avatar-01.jpg"
											alt="Avatar"
											className="avatar-md"
										/>
									</a>
								</span>
								<span>
									<a
										href="javascript:void(0);"
										data-bs-toggle="tooltip"
										data-bs-placement="right"
										aria-label="Member 2"
										data-bs-original-title="Member 2"
									>
										<img
											src="/img/profiles/avatar/avatar-02.jpg"
											alt="Avatar"
											className="avatar-md"
										/>
									</a>
								</span>
								<span>
									<a
										href="javascript:void(0);"
										data-bs-toggle="tooltip"
										data-bs-placement="right"
										aria-label="Member 3"
										data-bs-original-title="Member 3"
									>
										<img
											src="/img/profiles/avatar/avatar-03.jpg"
											alt="Avatar"
											className="avatar-md"
										/>
									</a>
								</span>
								<span>
									<a
										href="javascript:void(0);"
										data-bs-toggle="tooltip"
										data-bs-placement="right"
										aria-label="Member 4"
										data-bs-original-title="Member 4"
									>
										<img
											src="/img/profiles/avatar/avatar-04.jpg"
											alt="Avatar"
											className="avatar-md"
										/>
									</a>
								</span>
								<span>
									<a
										href="javascript:void(0);"
										className="avatar-md add d-flex align-items-center justify-content-center"
									>
										<i data-feather="plus" className="feather-16 me-1"></i>
									</a>
								</span>
							</div>
						</div>
						<p>Owned by Andrew. Shared with James, Fin, Davis</p>
					</div>
					<div
						className="tab-pane fade"
						id="nav-profile"
						role="tabpanel"
						aria-labelledby="nav-profile-tab"
					>
						<h5 className="mb-4 d-flex align-items-center">
							<i data-feather="calendar" className="feather-20 me-2"></i>This
							Week
						</h5>
						<ul className="mb-4">
							<li className="mb-4">
								<div className="d-flex align-items-center mb-2">
									<a href="javascript:void(0);" className="me-3">
										<img
											src="/img/profiles/avatar/avatar-01.jpg"
											alt="Avatar"
											className="avatar-md"
										/>
									</a>
									<p>
										Andrew commented on 1 items <br />
										3:39 PM Jul 19
									</p>
								</div>
								<p className="d-flex align-items-center location border-0">
									<img
										src="/img/icons/folder.svg"
										alt="Folder"
										className="me-2"
									/>
									Website Backup for the Design team
								</p>
							</li>
							<li className="mb-4">
								<div className="d-flex align-items-center mb-2">
									<a href="javascript:void(0);" className="me-3">
										<img
											src="/img/profiles/avatar/avatar-02.jpg"
											alt="Avatar"
											className="avatar-md"
										/>
									</a>
									<p>
										Drake shared an item
										<br />
										3:39 PM Jul 19
									</p>
								</div>
								<p className="d-flex align-items-center location border-0">
									<img
										src="/img/icons/folder.svg"
										alt="Folder"
										className="me-2"
									/>
									Website Backup for the Design team
								</p>
							</li>
							<li className="mb-2">
								<div className="d-flex align-items-center mb-2">
									<a href="javascript:void(0);" className="me-3">
										<img
											src="/img/profiles/avatar/avatar-03.jpg"
											alt="Avatar"
											className="avatar-md"
										/>
									</a>
									<div>
										<p className="mb-0 text-secondary">Melvin</p>
										<p className="mb-0">Commentor</p>
									</div>
								</div>
							</li>
							<li className="mb-2">
								<div className="d-flex align-items-center mb-2">
									<a href="javascript:void(0);" className="me-3">
										<img
											src="/img/profiles/avatar/avatar-04.jpg"
											alt="Avatar"
											className="avatar-md"
										/>
									</a>
									<div>
										<p className="mb-0 text-secondary">Drake</p>
										<p className="mb-0">Editor</p>
									</div>
								</div>
							</li>
						</ul>
						<h5 className="mb-4 d-flex align-items-center">
							<i data-feather="calendar" className="feather-20 me-2"></i>Last
							Month
						</h5>
						<ul className="mb-4">
							<li className="mb-4">
								<div className="d-flex align-items-center mb-2">
									<a href="javascript:void(0);" className="me-3">
										<img
											src="/img/profiles/avatar/avatar-01.jpg"
											alt="Avatar"
											className="avatar-md"
										/>
									</a>
									<p>
										Andrew commented on 1 items <br />
										3:39 PM Jul 19
									</p>
								</div>
								<p className="d-flex align-items-center location border-0">
									<img
										src="/img/icons/folder.svg"
										alt="Folder"
										className="me-2"
									/>
									Website Backup for the Design team
								</p>
							</li>
							<li className="mb-4">
								<div className="d-flex align-items-center mb-2">
									<a href="javascript:void(0);" className="me-3">
										<img
											src="/img/profiles/avatar/avatar-02.jpg"
											alt="Avatar"
											className="avatar-md"
										/>
									</a>
									<p>
										Drake shared an item
										<br />
										3:39 PM Jul 19
									</p>
								</div>
								<p className="d-flex align-items-center location border-0">
									<img
										src="/img/icons/folder.svg"
										alt="Folder"
										className="me-2"
									/>
									Website Backup for the Design team
								</p>
							</li>
							<li className="mb-2">
								<div className="d-flex align-items-center mb-2">
									<a href="javascript:void(0);" className="me-3">
										<img
											src="/img/profiles/avatar/avatar-03.jpg"
											alt="Avatar"
											className="avatar-md"
										/>
									</a>
									<div>
										<p className="mb-0 text-secondary">Melvin</p>
										<p className="mb-0">Commentor</p>
									</div>
								</div>
							</li>
							<li className="mb-2">
								<div className="d-flex align-items-center mb-2">
									<a href="javascript:void(0);" className="me-3">
										<img
											src="/img/profiles/avatar/avatar-04.jpg"
											alt="Avatar"
											className="avatar-md"
										/>
									</a>
									<div>
										<p className="mb-0 text-secondary">Drake</p>
										<p className="mb-0">Editor</p>
									</div>
								</div>
							</li>
						</ul>
						<a href="javascript:void(0);" className="text-primary show-all">
							<i data-feather="plus-circle" className="feather-20 me-2"></i>Show
							All
						</a>
					</div>
				</div>
			</div>
			{/* <!-- Files Toogle Slide --> */}

			{/* <!-- Upload File --> */}
			<div
				className="modal fade custom-modal file-manager-modal upload-modal"
				id="upload-file"
				aria-labelledby="upload-file"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title">Upload File</h5>
							<button
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<i className="ti ti-x"></i>
							</button>
						</div>
						<div className="modal-body">
							<div className="drag-drop text-center mb-4">
								<div className="upload">
									<a href="#">
										<img src="/img/icons/drag-drop.svg" alt="" />
									</a>
									<p>
										Drag and drop a <a href="#">file to upload</a>
									</p>
								</div>
								<input type="file" multiple="" />
							</div>

							<div className="d-flex align-items-center justify-content-between">
								<p>3 of 1 files Uploaded</p>
								<span>70%</span>
							</div>
							<div className="progress mt-2 mb-4">
								<div
									className="progress-bar progress-bar bg-success"
									role="progressbar"
									style={{ width: "75%" }}
									aria-valuenow="75"
									aria-valuemin="0"
									aria-valuemax="100"
								></div>
							</div>

							<ul>
								<li className="d-flex align-items-center justify-content-between">
									<div className="d-flex align-items-center w-85">
										<img
											src="/img/icons/folder.svg"
											alt="Folder"
											className="me-2"
										/>
										<div className="flex-fill">
											<h6>
												<a href="javascript:void(0);">
													latest-version.zip
													<i
														data-feather="check-circle"
														className="ms-2 feather-16"
													></i>
												</a>
											</h6>
											<span>616 MB</span>
										</div>
									</div>
									<a
										href="javascript:void(0);"
										className="text-danger text-right"
									>
										<i data-feather="trash-2" className="feather-16"></i>
									</a>
								</li>
								<li className="d-flex align-items-center justify-content-between">
									<div className="d-flex align-items-center w-85">
										<img
											src="/img/icons/xls.svg"
											alt="Folder"
											className="me-2"
										/>
										<div className="flex-fill">
											<h6>
												<a href="javascript:void(0);">
													Update work history.xls
												</a>
											</h6>
											<span>616 MB</span>
											<div className="progress mt-2">
												<div
													className="progress-bar bg-danger"
													role="progressbar"
													style={{ width: "75%" }}
													aria-valuenow="75"
													aria-valuemin="0"
													aria-valuemax="100"
												></div>
											</div>
										</div>
									</div>
									<div className="d-flex align-items-center">
										<a
											href="javascript:void(0);"
											className="text-danger me-2 d-flex align-items-center"
										>
											<i data-feather="trash-2" className="feather-16"></i>
										</a>
										<a
											href="javascript:void(0);"
											className="text-default d-flex align-items-center"
										>
											<i data-feather="pause-circle" className="feather-16"></i>
										</a>
									</div>
								</li>
								<li className="d-flex align-items-center justify-content-between">
									<div className="d-flex align-items-center w-85">
										<img
											src="/img/icons/zip.svg"
											alt="Folder"
											className="me-2"
										/>
										<div className="flex-fill">
											<h6>
												<a href="javascript:void(0);">Updated Project.zip</a>
											</h6>
											<span>616 MB</span>
											<div className="progress mt-2">
												<div
													className="progress-bar"
													role="progressbar"
													aria-valuenow="0"
													aria-valuemin="0"
													aria-valuemax="100"
												></div>
											</div>
										</div>
									</div>
									<div className="d-flex align-items-center">
										<a
											href="javascript:void(0);"
											className="text-danger me-2 d-flex align-items-center"
										>
											<i data-feather="trash-2" className="feather-16"></i>
										</a>
										<a
											href="javascript:void(0);"
											className="text-default d-flex align-items-center"
										>
											<i data-feather="play-circle" className="feather-16"></i>
										</a>
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- /Upload File --> */}

			{/* <!-- Upload Folder --> */}
			<div
				className="modal fade custom-modal file-manager-modal upload-modal"
				id="upload-folder"
				aria-labelledby="upload-folder"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5>Upload File</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="drag-drop text-center mb-4">
								<div className="upload">
									<a href="#">
										<img src="/img/icons/drag-drop.svg" alt="" />
									</a>
									<p>
										Drag and drop a <a href="#">file to upload</a>
									</p>
								</div>
								<input type="file" multiple="" />
							</div>

							<div className="d-flex align-items-center justify-content-between">
								<p>3 of 3 files Uploaded</p>
								<span>100%</span>
							</div>
							<div className="progress mt-2 mb-4">
								<div
									className="progress-bar progress-bar bg-success"
									role="progressbar"
									style={{ width: "100%" }}
									aria-valuenow="100"
									aria-valuemin="0"
									aria-valuemax="100"
								></div>
							</div>

							<ul>
								<li className="d-flex align-items-center justify-content-between">
									<div className="d-flex align-items-center w-85">
										<img
											src="/img/icons/folder.svg"
											alt="Folder"
											className="me-2"
										/>
										<div className="flex-fill">
											<h6>
												<a href="javascript:void(0);">
													latest-version
													<i
														data-feather="check-circle"
														className="ms-2 feather-16"
													></i>
												</a>
											</h6>
											<span>616 MB</span>
										</div>
									</div>
									<a
										href="javascript:void(0);"
										className="text-danger text-right"
									>
										<i data-feather="trash-2" className="feather-16"></i>
									</a>
								</li>
								<li className="d-flex align-items-center justify-content-between">
									<div className="d-flex align-items-center w-85">
										<img
											src="/img/icons/xls.svg"
											alt="Folder"
											className="me-2"
										/>
										<div className="flex-fill">
											<h6>
												<a href="javascript:void(0);">
													Update work history.xls
													<i data-feather="trash-2" className="feather-16"></i>
												</a>
											</h6>
											<span>16 MB</span>
										</div>
									</div>
									<div className="d-flex align-items-center">
										<a href="javascript:void(0);" className="text-danger me-2">
											<i data-feather="trash-2" className="feather-16"></i>
										</a>
										<a href="javascript:void(0);" className="text-default">
											<i data-feather="pause-circle" className="feather-16"></i>
										</a>
									</div>
								</li>
								<li className="d-flex align-items-center justify-content-between">
									<div className="d-flex align-items-center w-85">
										<img
											src="/img/icons/zip.svg"
											alt="Folder"
											className="me-2"
										/>
										<div className="flex-fill">
											<h6>
												<a href="javascript:void(0);">
													updated project.zip
													<i data-feather="trash-2" className="feather-16"></i>
												</a>
											</h6>
											<span>14 MB</span>
										</div>
									</div>
									<div className="d-flex align-items-center">
										<a href="javascript:void(0);" className="text-danger me-2">
											<i data-feather="trash-2" className="feather-16"></i>
										</a>
										<a href="javascript:void(0);" className="text-default">
											<i data-feather="play-circle" className="feather-16"></i>
										</a>
									</div>
								</li>
							</ul>
						</div>
						<div className="modal-footer d-sm-flex justify-content-end">
							<button
								type="button"
								className="btn btn-secondary"
								data-bs-dismiss="modal"
							>
								Clear
							</button>
							<button type="button" className="btn btn-primary">
								Upload
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- /Upload Folder --> */}

			{/* <!-- Upload Folder --> */}
			<div
				className="modal fade custom-modal file-manager-modal upload-message"
				id="upload-message"
				aria-labelledby="upload-message"
			>
				<div className="modal-dialog">
					<div className="modal-content">
						<div className="modal-header">
							<h5>Upload File</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="d-flex align-items-center justify-content-between">
								<p>3 of 3 files Uploaded</p>
								<span>100%</span>
							</div>
							<div className="progress mt-2 mb-4">
								<div
									className="progress-bar progress-bar bg-success"
									role="progressbar"
									style={{ width: "100%" }}
									aria-valuenow="100"
									aria-valuemin="0"
									aria-valuemax="100"
								></div>
							</div>
						</div>
						<div className="modal-footer d-sm-flex justify-content-end">
							<button
								type="button"
								className="btn btn-secondary"
								data-bs-dismiss="modal"
							>
								Clear
							</button>
							<button type="button" className="btn btn-primary">
								Upload
							</button>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- /Upload Folder --> */}

			{/* <!-- Create Folder --> */}
			<div
				className="modal fade custom-modal file-manager-modal"
				id="create-folder"
				aria-labelledby="create-folder"
			>
				<div className="modal-dialog modal-dialog-centered">
					<div className="modal-content">
						<div className="modal-header">
							<h5>Create Folder</h5>
							<button
								type="button"
								className="btn-close"
								data-bs-dismiss="modal"
								aria-label="Close"
							>
								<span aria-hidden="true">×</span>
							</button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-12">
									<label className="form-label">Folder Name</label>
									<input type="text" className="form-control" />
								</div>
							</div>
							<div className="modal-footer d-sm-flex justify-content-end">
								<button
									type="button"
									className="btn btn-secondary me-2"
									data-bs-dismiss="modal"
								>
									Cancel
								</button>
								<button type="button" className="btn btn-primary">
									Create Folder
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"

export const stateListAdapter = createEntityAdapter()

export const fetchStateList = createAsyncThunk(
    Preferences.STATE_LIST,
    async ({countryID}, thunkApi) => {
        let countryParam = countryID ? `&fk_countryId=${countryID}` : ''
        const params = `?fk_UsrOrgDirId=${
                JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
              }${countryParam}&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getStateList, params)
        if (response?.result?.response_Status === 'success') {
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status == 'error') {
            alert(response?.result?.response_Message) 
        } else {
            alert('Something went wrong with getStateData')
        }
    }
)

export const initialAppState = stateListAdapter.getInitialState({
    loading: 'idel',
    stateListData: [],
    filterData: [],
    error: []
})

export const stateListSlice = createSlice({
    name: Preferences.STATE_LIST,
    initialState: initialAppState,
    reducers: {
        resetStateListFilter: state => {
            state.filterData = state.stateListData
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchStateList.pending, state => {
            state.loading = 'loading'
        })
        builder.addCase(fetchStateList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.stateListData = action.payload ?? []
            state.error = null
        })
        builder.addCase(fetchStateList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    }
})

export const stateListReducer = stateListSlice.reducer

export const getStateListLoading = state => {
    return state.stateListReducer.loading === 'loading' ? true : false
}

export const getStateListError = state => {
    return state.stateListReducer.error
}

export const getStateListFilterData = state => {
    return state.stateListReducer.filterData
}

export const getStateListData = state => {
    return state.stateListReducer.stateListData
}

export const getStateListDataByID = (state, {stateID}) => {
    return state.stateListReducer.filterData.filter(e => e?.pk_stateId == stateID)
}
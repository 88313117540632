import React, { useState } from "react"
import DataTable from "react-data-table-component"

export default function CustomDataTableComponent({
  rowData,
  columns,
}) {

  const customStyles = {
    rows: {
      style: {
        fontSize: "14px",
      },
    },
    cells: {
      style: {
        fontSize: "14px",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
      },
    },
  }

  
 const selectableRows = () => {    
    return (
      <label className="checkboxs">
        <input type="checkbox"/>
        <span class="checkmarks"></span>
      </label>
    )
  }

  return (
    <>
      <div className="table-responsive custom-table">
        <table className="table">
          <DataTable
            columns={columns}
            data={rowData}
            pagination
            customStyles={customStyles}
            paginationRowsPerPageOptions={[10, 20, 30, 100]}
            sortIcon={<i className="fa-solid fa-arrow-down-a-z upDownArrow"></i>}
            className="custom-table"
            selectableRows
            selectableRowsComponent={selectableRows}
            responsive={true}            
          />
        </table>
      </div>
    </>
  )
}

import { AgCharts } from 'ag-charts-react';
import React, {useState} from 'react'
const HorizontalStageChart = () =>{

     const getData = [
       {
         quarter: "Conversations",
         iphone: 140,
         mac: 16,
         ipad: 14,
         wearables: 12,
         services: 20,
       },
       {
         quarter: "Follow UP",
         iphone: 124,
         mac: 20,
         ipad: 14,
         wearables: 12,
         services: 30,
       },
       {
         quarter: "In Pipeline",
         iphone: 112,
         mac: 20,
         ipad: 18,
         wearables: 14,
         services: 36,
       },
     ];
   
       const [options, setOptions] = useState({
         title: {
           text: "",
         },
         subtitle: {
           text: "",
         },
         data: getData,
         series: [
           {
             type: "bar",
             direction: "horizontal",
             xKey: "quarter",
             yKey: "iphone",
             yName: "iPhone",
           },
           {
             type: "bar",
             direction: "horizontal",
             xKey: "quarter",
             yKey: "mac",
             yName: "Mac",
           },
           {
             type: "bar",
             direction: "horizontal",
             xKey: "quarter",
             yKey: "ipad",
             yName: "iPad",
           },
           {
             type: "bar",
             direction: "horizontal",
             xKey: "quarter",
             yKey: "wearables",
             yName: "Wearables",
           },
           {
             type: "bar",
             direction: "horizontal",
             xKey: "quarter",
             yKey: "services",
             yName: "Services",
           },
         ],
       });

    return(
        <>
            <AgCharts options={options}/>
        </>
    )
}
export default HorizontalStageChart
import { useDispatch } from "react-redux"
import { filterContactListData, resetContactListData, updateFilterContactListData } from "../slice/contact-slice"

const ContactListFilter = ({
    filterData,
    isGridView,
    setIsGridView,
    contactListData,
    emailListData,
    phoneListData,
    statusListData,
}) => {

    const dispatch = useDispatch()
    const handleContactNameFilter = (contactData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterContactName) filterData.filterContactName = []
            filterData?.filterContactName.push(contactData)
        }
        else {
            if (filterData?.filterContactName) {
                const index = filterData.filterContactName.indexOf(contactData)
                if (index !== -1) {
                    filterData.filterContactName.splice(index, 1)
                }
            }
        }
    }

    const handleContactEmailFilter = (emailData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterContactEmail) filterData.filterContactEmail = []
            filterData?.filterContactEmail.push(emailData)
        }
        else {
            if (filterData?.filterContactEmail) {
                const index = filterData.filterContactEmail.indexOf(emailData)
                if (index !== -1) {
                    filterData.filterContactEmail.splice(index, 1)
                }
            }
        }
    }

    const handleContactPhoneFilter = (phoneData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterContactPhone) filterData.filterContactPhone = []
            filterData?.filterContactPhone.push(phoneData)
        }
        else {
            if (filterData?.filterContactPhone) {
                const index = filterData.filterContactPhone.indexOf(phoneData)
                if (index !== -1) {
                    filterData.filterContactPhone.splice(index, 1)
                }
            }
        }
    }

    const handleContactStatusFilter = (statusData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterContactStatus) filterData.filterContactStatus = []
            filterData?.filterContactStatus.push(statusData)
        }
        else {
            if (filterData?.filterContactStatus) {
                const index = filterData.filterContactStatus.indexOf(statusData)
                if (index !== -1) {
                    filterData.filterContactStatus.splice(index, 1)
                }
            }
        }
    }

    return (
      <>
          <div className="d-flex align-items-center flex-wrap row-gap-2">
            <div className="form-sorts dropdown me-2">
              <a
                href="javascript:void(0);"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
              >
                <i className="ti ti-filter-share"></i>Filter
              </a>
              <div className="filter-dropdown-menu dropdown-menu  dropdown-menu-md-end p-3">
                <div className="filter-set-view">
                  <div className="filter-set-head">
                    <h4>
                      <i className="ti ti-filter-share"></i>Filter
                    </h4>
                  </div>
                  <div className="accordion" id="accordionExample">
                    <div className="filter-set-content">
                      <div className="filter-set-content-head">
                        <a
                          href="#"
                          data-bs-toggle="collapse"
                          data-bs-target="#contactName"
                          aria-expanded="true"
                          aria-controls="contactName"
                        >
                          Name
                        </a>
                      </div>
                      <div
                        className="filter-set-contents accordion-collapse collapse show"
                        id="contactName"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="filter-content-list">
                          <ul>
                            {contactListData?.map((contactData, index) => {
                              return (
                                <li key={index}>
                                  <div className="filter-checks">
                                    <label className="checkboxs">
                                      <input
                                        type="checkbox"
                                        value="checkbox"
                                        onChange={(isChecked) =>
                                          handleContactNameFilter(contactData, isChecked)
                                        }
                                      />
                                      <span className="checkmarks"></span>
                                      {contactData?.contactName}
                                    </label>
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filter-set-content">
                      <div className="filter-set-content-head">
                        <a
                          href="#"
                          className="collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#contactEmail"
                          aria-expanded="false"
                          aria-controls="contactEmail"
                        >
                          Email
                        </a>
                      </div>
                      <div
                        className="filter-set-contents accordion-collapse collapse"
                        id="contactEmail"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="filter-content-list">
                          <ul>
                            {emailListData.map((emailData, index) => (
                              <li key={index}>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input 
                                      type="checkbox"
                                      value="checkbox"
                                      onChange={(isChecked) =>
                                        handleContactEmailFilter(emailData, isChecked)
                                      }
                                    />
                                    <span className="checkmarks"></span>
                                    {emailData.emailId}
                                  </label>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filter-set-content">
                      <div className="filter-set-content-head">
                        <a
                          href="#"
                          className="collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#contactPhone"
                          aria-expanded="false"
                          aria-controls="contactPhone"
                        >
                          Phone Number
                        </a>
                      </div>
                      <div
                        className="filter-set-contents accordion-collapse collapse"
                        id="contactPhone"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="filter-content-list">
                          <ul>
                            {phoneListData.map((phoneData, index) => (
                              <li key={index}>
                                <div className="filter-checks">
                                  <label className="checkboxs">
                                    <input 
                                      type="checkbox"
                                      value="checkbox"
                                      onChange={(isChecked) =>
                                        handleContactPhoneFilter(phoneData, isChecked)
                                      }
                                    />
                                    <span className="checkmarks"></span>
                                    {phoneData.phoneNumber}
                                  </label>
                                </div>
                              </li>
                            ))}            
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="filter-set-content">
                      <div className="filter-set-content-head">
                        <a
                          href="#"
                          className="collapsed"
                          data-bs-toggle="collapse"
                          data-bs-target="#contactStatus"
                          aria-expanded="false"
                          aria-controls="contactStatus"
                        >
                          Status
                        </a>
                      </div>
                      <div
                        className="filter-set-contents accordion-collapse collapse"
                        id="contactStatus"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="filter-content-list">
                          <ul>
                            {statusListData?.map((statusData, index) => {
                              return (
                                <li key={index}>
                                  <div className="filter-checks">
                                    <label className="checkboxs">
                                      <input
                                        type="checkbox"
                                        value="checkbox"
                                        onChange={(isChecked) =>
                                          handleContactStatusFilter(statusData, isChecked)
                                        }
                                      />
                                      <span className="checkmarks"></span>
                                      {statusData?.statusName}
                                    </label>
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-reset-btns">
                    <div className="row">
                      <div className="col-6" onClick={() => window.location.reload()}>
                        <a href="#" className="btn btn-light">
                          Reset
                        </a>
                      </div>
                      <div className="col-6" onClick={() => {
                          dispatch(resetContactListData())
                          dispatch(filterContactListData(filterData))
                      }}>
                        <a
                          href="javascript:void(0)"
                          className="btn btn-primary"
                        >
                          Filter
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="view-icons">
              <a
                href="#!"
                className={`${isGridView === 0 ? "active" : ""}`}
                onClick={() => setIsGridView(0)}
              >
                <i className="ti ti-list-tree"></i>
              </a>
              <a
                href="#!"
                className={`${isGridView === 1 ? "active" : ""}`}
                onClick={() => setIsGridView(1)}
              >
                <i className="ti ti-grid-dots"></i>
              </a>
            </div>
          </div>
      </>
    )
}

export default ContactListFilter
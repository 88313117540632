import AddProduct from "../../../components/page-components/AddProduct"

const ProductForm = ({
    navigate,
    formType,
    selectedData
}) => {
    return (
        <>
            <div className="offcanvas offcanvas-end offcanvas-large" tabindex="-1" id="offcanvas_add">
                <AddProduct
                    navigate={navigate}
                    formType={formType}
                    selectedData={selectedData}/>
            </div>
        </>
    )
}

export default ProductForm
export const FORM_TYPE_NEW = 'new'
export const FORM_TYPE_VIEW = 'view'
export const FORM_TYPE_EDIT = 'edit'
export const FORM_TYPE_MODAL = 'modal'

export const STATUS_SUCCESS = 'success'
export const STATUS_ERROR = 'error'
export const STATUS_WARNING = "warning"
export const forgotAccountURL = "https://sso.workxcrm.com"
export const createAccountURL = "https://sso.workxcrm.com/Register"
export const profileURL = "https://sso.workxcrm.com"
export const banIpURL = "https://sso.workxcrm.com" 



export const selectedLeadListColumns = [
    {"headerName":"Source","headerValue":"enqSourceName"},
    {"headerName":"Full Name","headerValue":"fullName"},
    {"headerName":"Product","headerValue":"productName"},
    {"headerName":"Brand","headerValue":"fk_BrandId"},
    {"headerName":"Country","headerValue":"countryName"},
    {"headerName":"Status", "headerValue":"statusName"},
    {"headerName":"Created By", "headerValue":"createdByName"},
    {"headerName":"Created Date", "headerValue":"createdDate"}
  ]

export const selectedContactListColumns = [
    {"headerName":"Contact Name", "headerValue":"contactName"},
    {"headerName":"Contact Email", "headerValue":"emailIds"},
    {"headerName":"Contact Phone", "headerValue":"phoneNumbers"},
    {"headerName":"Status", "headerValue":"statusName"},
    {"headerName":"Created By", "headerValue":"createdBy"},
    {"headerName":"Created Date", "headerValue":"createdDate"},
  ]

export const selectedProductTypeListColumns = []
export const selectedProductCategoryListColumns = []
export const selectedProductListColumns = []
export const selectedBrandListColumns = []
export const selectedTeamListColumns = []

import React,{useState} from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"

export default function Notes() {

  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`} >
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav}/>

        {/* page wrapper  */}
        <div className="page-wrapper notes-page-wrapper">
          <div className="content">
            <div className="page-header page-add-notes  flex-sm-row flex-column">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>Notes</h4>
                  <h6 className="mb-0">Manage your notes</h6>
                </div>
                <a
                  id="toggle_btn2"
                  className="notes-tog"
                  href="javascript:void(0);"
                >
                  <i className="fas fa-chevron-left"></i>
                </a>
              </div>
              <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-start">
                <div className="form-sort me-2 mb-sm-0 mb-3">
                  {/* <i data-feather="sliders" className="info-img"></i> */}
                  <select className="form-select">
                    <option>Sort by Date</option>
                    <option>Ascending </option>
                    <option>Descending</option>
                    <option>Recently Viewed </option>
                    <option>Recently Added</option>
                    <option>Creation Date </option>
                  </select>
                </div>
                <div className="page-btn">
                  <a
                    href="#"
                    className="btn btn-added"
                    data-bs-toggle="modal"
                    data-bs-target="#note-units"
                  >
                    <i className="ti ti-circle-plus me-2"></i> Add Note
                  </a>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3 col-md-12 sidebars-right theiaStickySidebar section-bulk-widget">
                <div className="notes-dash">
                  <div className="notes-top-head">
                    <h5>
                      {" "}
                      <i
                        data-feather="file-text"
                        className="feather-file-text"
                      ></i>{" "}
                      Notes
                    </h5>
                  </div>
                  <div className="notes-top-head-submenu">
                    <div
                      className="nav flex-column nav-pills todo-inbox"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        className="nav-link todo-tab todo-inbox active d-flex align-items-center"
                        id="v-pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-profile"
                        aria-selected="true"
                      >
                        <i data-feather="inbox" className="feather-inbox"></i>
                        All Notes
                        <span className="ms-auto">
                          <span>2</span>
                        </span>
                      </button>
                      <button
                        className="nav-link todo-tab-btn todo-inbox"
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                      >
                        {" "}
                        <i
                          data-feather="star"
                          className="feather-star"
                        ></i>{" "}
                        Important
                      </button>
                      <button
                        className="nav-link todo-tab todo-inbox mb-0"
                        id="v-pills-settings-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-settings"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-settings"
                        aria-selected="false"
                      >
                        {" "}
                        <i
                          data-feather="trash-2"
                          className="feather-trash-2"
                        ></i>
                        Trash
                      </button>
                    </div>
                  </div>
                  <div className="content-submenu-tag">
                    <h6>Tags</h6>
                    <ul className="tags-list">
                      <li className="personal">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          Pending
                        </a>
                      </li>
                      <li className="social">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          Onhold
                        </a>
                      </li>
                      <li className="public">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          Inprogress
                        </a>
                      </li>

                      <li className="work">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          Done
                        </a>
                      </li>
                    </ul>

                    <h6>Priority</h6>
                    <ul className="priority-list">
                      <li className="medium">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          Medium
                        </a>
                      </li>
                      <li className="high">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          High
                        </a>
                      </li>
                      <li className="low">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          Low
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-9 budget-role-notes">
                <div className="section-bulk-wrap">
                  <div className="bulk-action-type">
                    <div className="form-sort select-bluk ">
                      <select className="form-select">
                        <option>Bulk Actions</option>
                        <option>Delete Marked</option>
                        <option>Unmark All</option>
                        <option>Mark All</option>
                      </select>
                    </div>
                    <a href="javascript:void(0);" className="btn btn-added">
                      Apply
                    </a>
                    <div className="search-set">
                      <div className="search-input">
                        <a
                          href="javascript:void(0);"
                          className="btn btn-searchset"
                        >
                          <i
                            data-feather="search"
                            className="feather-search"
                          ></i>
                        </a>
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            {" "}
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder="Search"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-sort">
                    {/* <i data-feather="filter" className="feather-filter"></i> */}
                    <select className="form-select">
                      <option>Recent</option>
                      <option>Last Modified</option>
                      <option>Last Modified by me</option>
                    </select>
                  </div>
                </div>

                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div className="section-notes-slider">
                      <div className="row">
                        <div className="notes-content">
                          <div className="notes-header">
                            <h3>Important Notes </h3>
                          </div>
                          <div className="notes-close">
                            <a href="javascript:void(0);">
                              <i className="fas fa-times"></i> Close{" "}
                            </a>
                          </div>
                        </div>
                        <div className="col-md-12" id="notes-important">
                          <div className="notes-slider owl-carousel">
                            <div className="notes-card">
                              <div className="notes-card-body">
                                <p className="badged">
                                  <i className="fas fa-circle"></i> Low
                                </p>
                                <a
                                  href="javascript:void(0);"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </a>
                                <div className="dropdown-menu notes-menu dropdown-menu-end">
                                  <a
                                    href="#"
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit-note-units"
                                  >
                                    <span>
                                      <i data-feather="edit"></i>
                                    </span>
                                    Edit
                                  </a>
                                  <a
                                    href="#"
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-note-units"
                                  >
                                    <span>
                                      <i data-feather="trash-2"></i>
                                    </span>
                                    Delete
                                  </a>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    <span>
                                      <i data-feather="star"></i>
                                    </span>
                                    Not Important
                                  </a>
                                  <a
                                    href="#"
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#view-note-units"
                                  >
                                    <span>
                                      <i data-feather="eye"></i>
                                    </span>
                                    View
                                  </a>
                                </div>
                              </div>
                              <div className="notes-slider-content">
                                <h4>
                                  <a href="javascript:void(0);">
                                    Plan a trip to another country
                                  </a>
                                </h4>
                                <p>
                                  Space, the final frontier. These are the
                                  voyages of the Starship Enterprise.
                                </p>
                              </div>
                              <div className="notes-slider-widget">
                                <div className="notes-logo">
                                  <a href="javascript:void(0);">
                                    <span>
                                      <img
                                        src="/img/profiles/avatar-01.jpg"
                                        alt="Profile"
                                        className="img-fluid"
                                      />
                                    </span>
                                  </a>
                                  <div className="d-flex">
                                    <span className="low-square">
                                      <i className="fas fa-square"></i>
                                    </span>
                                    <p> Personal</p>
                                  </div>
                                </div>
                                <div className="notes-star-delete">
                                  <a href="javascript:void(0);">
                                    <span>
                                      <i className="fas fa-star"></i>
                                    </span>
                                  </a>
                                  <a href="javascript:void(0);">
                                    <span>
                                      <i data-feather="trash-2"></i>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="notes-card medium">
                              <div className="notes-card-body">
                                <p className="badged medium">
                                  <i className="fas fa-circle"></i> Medium
                                </p>
                                <a
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </a>
                                <div className="dropdown-menu notes-menu dropdown-menu-end">
                                  <a
                                    href="#"
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit-note-units"
                                  >
                                    <span>
                                      <i data-feather="edit"></i>
                                    </span>
                                    Edit
                                  </a>
                                  <a
                                    href="#"
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-note-units"
                                  >
                                    <span>
                                      <i data-feather="trash-2"></i>
                                    </span>
                                    Delete
                                  </a>
                                  <a href="#" className="dropdown-item">
                                    <span>
                                      <i data-feather="star"></i>
                                    </span>
                                    Not Important
                                  </a>
                                  <a
                                    href="#"
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#view-note-units"
                                  >
                                    <span>
                                      <i data-feather="eye"></i>
                                    </span>
                                    View
                                  </a>
                                </div>
                              </div>
                              <div className="notes-slider-content">
                                <h4>
                                  <a href="javascript:void(0);">
                                    Improve touch typing
                                  </a>
                                </h4>
                                <p>
                                  Well, the way they make shows is, they make
                                  one show.
                                </p>
                              </div>
                              <div className="notes-slider-widget">
                                <div className="notes-logo">
                                  <a href="javascript:void(0);">
                                    <span>
                                      <img
                                        src="/img/profiles/avatar-02.jpg"
                                        alt="Profile"
                                        className="img-fluid"
                                      />
                                    </span>
                                  </a>
                                  <div className="d-flex">
                                    <span className="medium-square">
                                      <i className="fas fa-square"></i>
                                    </span>
                                    <p className="medium"> Work</p>
                                  </div>
                                </div>
                                <div className="notes-star-delete">
                                  <a href="javascript:void(0);">
                                    <span>
                                      <i className="fas fa-star"></i>
                                    </span>
                                  </a>
                                  <a href="javascript:void(0);">
                                    <span>
                                      <i data-feather="trash-2"></i>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="notes-card high">
                              <div className="notes-card-body">
                                <p className="badged high">
                                  <i className="fas fa-circle"></i> High
                                </p>
                                <a
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </a>
                                <div className="dropdown-menu notes-menu dropdown-menu-end">
                                  <a
                                    href="#"
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit-note-units"
                                  >
                                    <span>
                                      <i data-feather="edit"></i>
                                    </span>
                                    Edit
                                  </a>
                                  <a
                                    href="#"
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-note-units"
                                  >
                                    <span>
                                      <i data-feather="trash-2"></i>
                                    </span>
                                    Delete
                                  </a>
                                  <a href="#" className="dropdown-item">
                                    <span>
                                      <i data-feather="star"></i>
                                    </span>
                                    Not Important
                                  </a>
                                  <a
                                    href="#"
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#view-note-units"
                                  >
                                    <span>
                                      <i data-feather="eye"></i>
                                    </span>
                                    View
                                  </a>
                                </div>
                              </div>
                              <div className="notes-slider-content">
                                <h4>
                                  <a href="javascript:void(0);">
                                    Learn calligraphy
                                  </a>
                                </h4>
                                <p>
                                  Calligraphy, the art of beautiful handwriting.
                                  The term may derive from the Greek words.{" "}
                                </p>
                              </div>
                              <div className="notes-slider-widget">
                                <div className="notes-logo">
                                  <a href="javascript:void(0);">
                                    <span>
                                      <img
                                        src="/img/profiles/avatar-03.jpg"
                                        alt="Profile"
                                        className="img-fluid"
                                      />
                                    </span>
                                  </a>
                                  <div className="d-flex">
                                    <span className="high-square">
                                      <i className="fas fa-square"></i>
                                    </span>
                                    <p className="high"> Social</p>
                                  </div>
                                </div>
                                <div className="notes-star-delete">
                                  <a href="javascript:void(0);">
                                    <span>
                                      <i className="fas fa-star"></i>
                                    </span>
                                  </a>
                                  <a href="javascript:void(0);">
                                    <span>
                                      <i data-feather="trash-2"></i>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>

                            <div className="notes-card medium">
                              <div className="notes-card-body">
                                <p className="badged medium">
                                  <i className="fas fa-circle"></i> Medium
                                </p>
                                <a
                                  href="#"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <i className="fas fa-ellipsis-v"></i>
                                </a>
                                <div className="dropdown-menu notes-menu dropdown-menu-end">
                                  <a
                                    href="#"
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#edit-note-units"
                                  >
                                    <span>
                                      <i data-feather="edit"></i>
                                    </span>
                                    Edit
                                  </a>
                                  <a
                                    href="#"
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete-note-units"
                                  >
                                    <span>
                                      <i data-feather="trash-2"></i>
                                    </span>
                                    Delete
                                  </a>
                                  <a href="#" className="dropdown-item">
                                    <span>
                                      <i data-feather="star"></i>
                                    </span>
                                    Not Important
                                  </a>
                                  <a
                                    href="#"
                                    className="dropdown-item"
                                    data-bs-toggle="modal"
                                    data-bs-target="#view-note-units"
                                  >
                                    <span>
                                      <i data-feather="eye"></i>
                                    </span>
                                    View
                                  </a>
                                </div>
                              </div>
                              <div className="notes-slider-content">
                                <h4>
                                  <a href="javascript:void(0);">
                                    Improve touch typing
                                  </a>
                                </h4>
                                <p>
                                  Well, the way they make shows is, they make
                                  one show.
                                </p>
                              </div>
                              <div className="notes-slider-widget">
                                <div className="notes-logo">
                                  <a href="javascript:void(0);">
                                    <span>
                                      <img
                                        src="/img/profiles/avatar-03.jpg"
                                        alt="Profile"
                                        className="img-fluid"
                                      />
                                    </span>
                                  </a>
                                  <div className="d-flex">
                                    <span className="medium-square">
                                      <i className="fas fa-square"></i>
                                    </span>
                                    <p className="medium"> Work</p>
                                  </div>
                                </div>
                                <div className="notes-star-delete">
                                  <a href="javascript:void(0);">
                                    <span>
                                      <i className="fas fa-star"></i>
                                    </span>
                                  </a>
                                  <a href="javascript:void(0);">
                                    <span>
                                      <i data-feather="trash-2"></i>
                                    </span>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="section-card-body" id="notes-trash">
                      <div className="row">
                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged medium">
                                <i className="fas fa-circle"></i> Medium
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Backup Files EOD
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                25 Jul 2023
                              </p>
                              <p>
                                Project files should be took backup before end
                                of the day.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-26.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="medium-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="medium"> Work</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged high">
                                <i className="fas fa-circle"></i> High
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Download Server Logs
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                27 Jul 2023
                              </p>
                              <p>
                                Server log is a text document that contains a
                                record of all activity.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-27.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="high-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="high"> Social</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged low">
                                <i className="fas fa-circle"></i> Low
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Team meet at Starbucks
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                13 Aug 2023
                              </p>
                              <p>
                                Meeting all teamets at Starbucks for identifying
                                them all.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-28.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="low-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="low"> Personal</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged high">
                                <i className="fas fa-circle"></i> High
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Create a compost pile
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                23 Aug 2023
                              </p>
                              <p>
                                Compost pile refers to fruit and vegetable
                                scraps, used tea, coffee grounds etc..
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-29.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="high-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="high"> Social</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged low">
                                <i className="fas fa-circle"></i> Low
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Take a hike at a local park
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                04 Sep 2023
                              </p>
                              <p>
                                Hiking involves a long energetic walk in a
                                natural environment.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-30.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="low-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="low"> Personal</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged medium">
                                <i className="fas fa-circle"></i> Medium
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Research a topic interested
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                17 Sep 2023
                              </p>
                              <p>
                                Research a topic interested by listen actively
                                and attentively.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-31.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="medium-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="medium"> Work</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <div className="section-card-body pt-0" id="notes-trash2">
                      <div className="row">
                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged medium">
                                <i className="fas fa-circle"></i> Medium
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Backup Files EOD
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                25 Jul 2023
                              </p>
                              <p>
                                Project files should be took backup before end
                                of the day.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-26.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="medium-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="medium"> Work</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged high">
                                <i className="fas fa-circle"></i> High
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Download Server Logs
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                27 Jul 2023
                              </p>
                              <p>
                                Server log is a text document that contains a
                                record of all activity.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-27.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="high-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="high"> Social</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged low">
                                <i className="fas fa-circle"></i> Low
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Team meet at Starbucks
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                13 Aug 2023
                              </p>
                              <p>
                                Meeting all teamets at Starbucks for identifying
                                them all.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-28.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="low-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="low"> Personal</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged high">
                                <i className="fas fa-circle"></i> High
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Create a compost pile
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                23 Aug 2023
                              </p>
                              <p>
                                Compost pile refers to fruit and vegetable
                                scraps, used tea, coffee grounds etc..
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-29.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="high-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="high"> Social</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged low">
                                <i className="fas fa-circle"></i> Low
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Take a hike at a local park
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                04 Sep 2023
                              </p>
                              <p>
                                Hiking involves a long energetic walk in a
                                natural environment.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-30.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="low-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="low"> Personal</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged medium">
                                <i className="fas fa-circle"></i> Medium
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Research a topic interested
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                17 Sep 2023
                              </p>
                              <p>
                                Research a topic interested by listen actively
                                and attentively.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-31.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="medium-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="medium"> Work</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-settings"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                  >
                    <div className="row">
                      <div className="col-12 d-flex align-items-center justify-content-end">
                        <a href="#" className="btn btn-cancel notes-trash-btn">
                          <span>
                            {" "}
                            <i
                              data-feather="trash-2"
                              className="feather-trash-2"
                            ></i>{" "}
                          </span>
                          Restore all
                        </a>
                      </div>
                    </div>
                    <div className="section-card-body pt-0" id="notes-trash3">
                      <div className="row">
                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged medium">
                                <i className="fas fa-circle"></i> Medium
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Permanent Delete
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Restore Task
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Backup Files EOD
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                25 Jul 2023
                              </p>
                              <p>
                                Project files should be took backup before end
                                of the day.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-31.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="medium-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="medium"> Work</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged high">
                                <i className="fas fa-circle"></i> High
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Permanent Delete
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Restore Task
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Download Server Logs
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                27 Jul 2023
                              </p>
                              <p>
                                Server log is a text document that contains a
                                record of all activity.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-29.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="high-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="high"> Social</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 d-flex">
                          <div className="notes-card notes-card-details w-100">
                            <div className="notes-card-body">
                              <p className="badged low">
                                <i className="fas fa-circle"></i> Low
                              </p>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Permanent Delete
                                </a>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Restore Task
                                </a>
                              </div>
                            </div>
                            <div className="notes-wrap-content">
                              <h4>
                                <a href="javascript:void(0);">
                                  Team meet at Starbucks
                                </a>
                              </h4>
                              <p className="wrap-cal">
                                <i
                                  data-feather="calendar"
                                  className="feather-calendar"
                                ></i>{" "}
                                13 Aug 2023
                              </p>
                              <p>
                                Meeting all teamets at Starbucks for identifying
                                them all.
                              </p>
                            </div>
                            <div className="notes-slider-widget notes-widget-profile">
                              <div className="notes-logo">
                                <a href="javascript:void(0);">
                                  <span>
                                    <img
                                      src="/img/users/user-30.jpg"
                                      alt="Profile"
                                      className="img-fluid"
                                    />
                                  </span>
                                </a>
                                <div className="d-flex">
                                  <span className="low-square">
                                    <i className="fas fa-square"></i>
                                  </span>
                                  <p className="low"> Personal</p>
                                </div>
                              </div>
                              <div className="notes-star-delete">
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                </a>
                                <a href="javascript:void(0);">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row custom-pagination">
                  <div className="col-md-12">
                    <div className="paginations d-flex justify-content-end">
                      <span>
                        <i className="fas fa-chevron-left"></i>
                      </span>
                      <ul className="d-flex align-items-center page-wrap">
                        <li>
                          <a href="javascript:void(0);" className="active">
                            1
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">2</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">3</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">4</a>
                        </li>
                      </ul>
                      <span>
                        <i className="fas fa-chevron-right"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Note Unit --> */}
        <div className="modal custom-modal fade" id="note-units">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header custom-modal-header">
                    <div className="page-title">
                      <h4>Add New Note</h4>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <form action="notes.html">
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">Note Title</label>
                            <input type="text" className="form-control" />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">User</label>
                            <select className="select">
                              <option>Choose</option>
                              <option>Recent1</option>
                              <option>Recent2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">Tag</label>
                            <select className="select">
                              <option>Select</option>
                              <option>Recent1</option>
                              <option>Recent2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">Priority</label>
                            <select className="select">
                              <option>Select</option>
                              <option>Recent1</option>
                              <option>Recent2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3 summer-description-box notes-summernote">
                            <label className="form-label">Descriptions</label>
                            <div id="summernote"></div>
                            <p>Maximum 60 Characters</p>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Note Unit --> */}

        {/* <!-- Note Unit --> */}
        <div className="modal fade" id="edit-note-units">
          <div className="modal-dialog modal-dialog-centered custom-modal">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header custom-modal-header">
                    <div className="page-title">
                      <h4>Edit Note</h4>
                    </div>
                    <div className=" edit-note-head d-flex align-items-center">
                      <a href="#" className="me-2">
                        <span>
                          <i data-feather="trash-2"></i>
                        </span>
                      </a>
                      <a href="#" className="me-2">
                        <span>
                          <i data-feather="star"></i>
                        </span>
                      </a>
                      <a href="#" className="me-2">
                        <span>
                          <i data-feather="eye"></i>
                        </span>
                      </a>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <form action="notes.html">
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3">
                            <label>Note Title</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Meet Lisa to discuss project details"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">Assignee</label>
                            <select className="select">
                              <option>Choose</option>
                              <option>Recent1</option>
                              <option>Recent2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">Tag</label>
                            <select className="select">
                              <option>Onhold</option>
                              <option>Onhold</option>
                              <option>Onhold</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">Priority</label>
                            <select className="select">
                              <option>High</option>
                              <option>Medium</option>
                              <option>Low</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3 summer-description-box notes-summernote">
                            <label className="form-label">Descriptions</label>
                            <div id="summernote2"></div>
                            <p>Maximum 60 Characters</p>
                          </div>
                        </div>
                      </div>

                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-submit">
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Note Unit --> */}

        {/* <!-- Delete Unit --> */}
        <div className="modal fade" id="delete-note-units">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="delete-popup">
                    <div className="delete-image text-center mx-auto">
                      <img
                        src="/img/icons/close-circle.png"
                        alt="Img"
                        className="img-fluid"
                      />
                    </div>
                    <div className="delete-heads">
                      <h4>Are You Sure?</h4>
                      <p>
                        Do you really want to delete this item, This process
                        cannot be undone.
                      </p>
                    </div>
                    <div className="modal-footer-btn delete-footer mt-3">
                      <a
                        href=""
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </a>
                      <a href="" className="btn btn-submit">
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Delete Unit --> */}

        {/* <!-- View Unit --> */}
        <div className="modal fade" id="view-note-units">
          <div className="modal-dialog modal-dialog-centered custom-modal">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header custom-modal-header">
                    <div className="page-title edit-page-title">
                      <h4>Notes</h4>
                      <p>Personal</p>
                    </div>
                    <div className=" edit-noted-head d-flex align-items-center">
                      <a href="javascript:void(0);">
                        <span>
                          <i data-feather="trash-2"></i>
                        </span>
                      </a>
                      <a href="#" className="me-2">
                        <span>
                          <i data-feather="star"></i>
                        </span>
                      </a>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <form action="notes.html">
                      <div className="row">
                        <div className="col-12">
                          <div className="edit-head-view">
                            <h6>Take a hike at a local park</h6>
                            <p>
                              Hiking is a long, vigorous walk, usually on trails
                              or footpaths in the countryside. Walking for
                              pleasure developed in Europe during the eighteenth
                              century. Religious pilgrimages have existed much
                              longer but they involve walking long distances for
                              a spiritual purpose associated with specific
                              religions and also we achieve inner peace while we
                              hike at a local park.
                            </p>

                            <p className="badged low">
                              <i className="fas fa-circle"></i> Low
                            </p>
                          </div>
                          <div className="modal-footer-btn edit-footer-menu">
                            <button
                              type="button"
                              className="btn btn-cancel me-2"
                              data-bs-dismiss="modal"
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /View Unit --> */}
      </div>
    </>
  )
}

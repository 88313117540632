import React, {useState} from 'react'
import VerticalMenuComponent from '../../components/VerticalMenuComponent'
import TopMenuComponent from '../../components/TopMenuComponent'
export default function BanIPAddress() {

	const [isOpen, setIsOpen] = useState(false)
	const openSideNav = () => {
	  setIsOpen(!isOpen)
	}

  return (
    <>
    
    <div className={`main-wrapper ${isOpen && "slide-nav" }`}>
                    {/* header component  */}
                    <VerticalMenuComponent/>
                    <TopMenuComponent openSideNav={openSideNav} />

                    {/* page wrapper  */}
                    <div className='page-wrapper'>
                            <div className='content'>
                                 

             <div className="row">
					<div className="col-md-12">

						{/* <!-- Page Header --> */}
						<div className="page-header">
							<div className="row align-items-center">
								<div className="col-8">
									<h4 className="page-title">Settings</h4>
								</div>
								<div className="col-4 text-end">
									<div className="head-icons">
										<a href="ban-ip-address.html" data-bs-toggle="tooltip" data-bs-placement="top"
											data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
										<a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top"
											data-bs-original-title="Collapse" id="collapse-header"><i
												className="ti ti-chevrons-up"></i></a>
									</div>
								</div>
							</div>
						</div>
						{/* <!-- /Page Header --> */}

						{/* <!-- Settings Menu --> */}
						<div className="card">
							<div className="card-body pb-0 pt-2">
								<ul className="nav nav-tabs nav-tabs-bottom">
									<li className="nav-item me-3">
										<a href="profile.html" className="nav-link px-0">
											<i className="ti ti-settings-cog me-2"></i>General Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="company-settings.html" className="nav-link px-0">
											<i className="ti ti-world-cog me-2"></i>Website Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="invoice-settings.html" className="nav-link px-0">
											<i className="ti ti-apps me-2"></i>App Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="email-settings.html" className="nav-link px-0">
											<i className="ti ti-device-laptop me-2"></i>System Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="payment-gateways.html" className="nav-link px-0">
											<i className="ti ti-moneybag me-2"></i>Financial Settings
										</a>
									</li>
									<li className="nav-item">
										<a href="storage.html" className="nav-link px-0 active">
											<i className="ti ti-flag-cog me-2"></i>Other Settings
										</a>
									</li>
								</ul>
							</div>
						</div>
						{/* <!-- /Settings Menu --> */}

						<div className="row">
							<div className="col-xl-3 col-lg-12 theiaStickySidebar">

								{/* <!-- Settings Sidebar --> */}
								<div className="card">
									<div className="card-body">
										<div className="settings-sidebar">
											<h4 className="fw-semibold mb-3">Other Settings</h4>
											<div className="list-group list-group-flush settings-sidebar">
												<a href="storage.html" className="fw-medium">Storage</a>
												<a href="ban-ip-address.html" className="fw-medium active">Ban IP
													Address</a>
											</div>
										</div>
									</div>
								</div>
								{/* <!-- /Settings Sidebar --> */}

							</div>

							<div className="col-xl-9 col-lg-12">

								{/* <!-- Settings Info --> */}
								<div className="card">
									<div className="card-body">
										<div className="d-flex align-items-center justify-content-between mb-4">
											<h4 className="fs-20">Ban IP Address</h4>
											<a href="javascript:void(0)" className="btn btn-icon btn-sm border"
												data-bs-toggle="modal" data-bs-target="#add_ip"><i
													className="ti ti-plus"></i></a>
										</div>
										<div className="row">
											{/* <!-- Ban Ip Box --> */}
											<div className="col-xxl-4 col-sm-6">
												<div
													className="border rounded d-flex align-items-center justify-content-between mb-4 p-3">
													<div className="d-flex align-items-center">
														<a href="javascript:void(0);" className="me-2 text-default"
															data-bs-toggle="tooltip" data-bs-placement="top"
															data-bs-original-title="Temporarily block to protect user accounts from internet fraudsters."><i
																className="ti ti-info-circle-filled"></i></a>
														<p>198.120.16.01</p>
													</div>
													<div className="dropdown table-action">
														<a href="#" className="action-icon bg-white"
															data-bs-toggle="dropdown" aria-expanded="false"><i
																className="fa fa-ellipsis-v"></i></a>
														<div className="dropdown-menu dropdown-menu-right">
															<a className="dropdown-item" href="#" data-bs-toggle="modal"
																data-bs-target="#edit_ip"><i
																	className="fa-solid fa-pencil text-blue"></i> Edit</a>
															<a className="dropdown-item" href="#" data-bs-toggle="modal"
																data-bs-target="#delete_ip"><i
																	className="fa-regular fa-trash-can text-danger"></i>
																Delete</a>
														</div>
													</div>
												</div>
											</div>
											{/* <!-- /Ban Ip Box --> */}
											{/* <!-- Ban Ip Box --> */}
											<div className="col-xxl-4 col-sm-6">
												<div
													className="border rounded d-flex align-items-center justify-content-between mb-4 p-3">
													<div className="d-flex align-items-center">
														<a href="javascript:void(0);" className="me-2 text-default"
															data-bs-toggle="tooltip" data-bs-placement="top"
															data-bs-original-title="Temporarily block to protect user accounts from internet fraudsters."><i
																className="ti ti-info-circle-filled"></i></a>
														<p>198.120.23.56</p>
													</div>
													<div className="dropdown table-action">
														<a href="#" className="action-icon bg-white"
															data-bs-toggle="dropdown" aria-expanded="false"><i
																className="fa fa-ellipsis-v"></i></a>
														<div className="dropdown-menu dropdown-menu-right">
															<a className="dropdown-item" href="#" data-bs-toggle="modal"
																data-bs-target="#edit_ip"><i
																	className="fa-solid fa-pencil text-blue"></i> Edit</a>
															<a className="dropdown-item" href="#" data-bs-toggle="modal"
																data-bs-target="#delete_ip"><i
																	className="fa-regular fa-trash-can text-danger"></i>
																Delete</a>
														</div>
													</div>
												</div>
											</div>
											{/* <!-- /Ban Ip Box --> */}
											{/* <!-- Ban Ip Box --> */}
											<div className="col-xxl-4 col-sm-6">
												<div
													className="border rounded d-flex align-items-center justify-content-between mb-4 p-3">
													<div className="d-flex align-items-center">
														<a href="javascript:void(0);" className="me-2 text-default"
															data-bs-toggle="tooltip" data-bs-placement="top"
															data-bs-original-title="Temporarily block to protect user accounts from internet fraudsters."><i
																className="ti ti-info-circle-filled"></i></a>
														<p>198.132.57.12</p>
													</div>
													<div className="dropdown table-action">
														<a href="#" className="action-icon bg-white"
															data-bs-toggle="dropdown" aria-expanded="false"><i
																className="fa fa-ellipsis-v"></i></a>
														<div className="dropdown-menu dropdown-menu-right">
															<a className="dropdown-item" href="#" data-bs-toggle="modal"
																data-bs-target="#edit_ip"><i
																	className="fa-solid fa-pencil text-blue"></i> Edit</a>
															<a className="dropdown-item" href="#" data-bs-toggle="modal"
																data-bs-target="#delete_ip"><i
																	className="fa-regular fa-trash-can text-danger"></i>
																Delete</a>
														</div>
													</div>
												</div>
											</div>
											{/* <!-- /Ban Ip Box --> */}
											{/* <!-- Ban Ip Box --> */}
											<div className="col-xxl-4 col-sm-6">
												<div
													className="border rounded d-flex align-items-center justify-content-between mb-4 p-3">
													<div className="d-flex align-items-center">
														<a href="javascript:void(0);" className="me-2 text-default"
															data-bs-toggle="tooltip" data-bs-placement="top"
															data-bs-original-title="Temporarily block to protect user accounts from internet fraudsters."><i
																className="ti ti-info-circle-filled"></i></a>
														<p>198.120.32.01</p>
													</div>
													<div className="dropdown table-action">
														<a href="#" className="action-icon bg-white"
															data-bs-toggle="dropdown" aria-expanded="false"><i
																className="fa fa-ellipsis-v"></i></a>
														<div className="dropdown-menu dropdown-menu-right">
															<a className="dropdown-item" href="#" data-bs-toggle="modal"
																data-bs-target="#edit_ip"><i
																	className="fa-solid fa-pencil text-blue"></i> Edit</a>
															<a className="dropdown-item" href="#" data-bs-toggle="modal"
																data-bs-target="#delete_ip"><i
																	className="fa-regular fa-trash-can text-danger"></i>
																Delete</a>
														</div>
													</div>
												</div>
											</div>
											{/* <!-- /Ban Ip Box --> */}
											{/* <!-- Ban Ip Box --> */}
											<div className="col-xxl-4 col-sm-6">
												<div
													className="border rounded d-flex align-items-center justify-content-between mb-4 p-3">
													<div className="d-flex align-items-center">
														<a href="javascript:void(0);" className="me-2 text-default"
															data-bs-toggle="tooltip" data-bs-placement="top"
															data-bs-original-title="Temporarily block to protect user accounts from internet fraudsters."><i
																className="ti ti-info-circle-filled"></i></a>
														<p>198.120.32.25</p>
													</div>
													<div className="dropdown table-action">
														<a href="#" className="action-icon bg-white"
															data-bs-toggle="dropdown" aria-expanded="false"><i
																className="fa fa-ellipsis-v"></i></a>
														<div className="dropdown-menu dropdown-menu-right">
															<a className="dropdown-item" href="#" data-bs-toggle="modal"
																data-bs-target="#edit_ip"><i
																	className="fa-solid fa-pencil text-blue"></i> Edit</a>
															<a className="dropdown-item" href="#" data-bs-toggle="modal"
																data-bs-target="#delete_ip"><i
																	className="fa-regular fa-trash-can text-danger"></i>
																Delete</a>
														</div>
													</div>
												</div>
											</div>
											{/* <!-- /Ban Ip Box --> */}
										</div>
										<div className="d-flex align-items-center">
											<a href="#" className="btn btn-light me-2">Cancel</a>
											<button type="submit" className="btn btn-primary">Save Changes</button>
										</div>
									</div>
								</div>
								{/* <!-- /Settings Info --> */}

							</div>
						</div>

					</div>
				</div>


                            </div>
                    </div>


                    {/* <!-- Add Ban IP Address --> */}
		<div className="modal custom-modal fade" id="add_ip" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Add Ban IP Address</h5>
						<div className="d-flex align-items-center mod-toggle">
							<div className="status-toggle">
								<input type="checkbox" id="toggle" className="check" checked=""/>
								<label for="toggle" className="checktoggle"></label>
							</div>
							<button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="ti ti-x"></i>
							</button>
						</div>
					</div>
					<div className="modal-body">
						<form action="ban-ip-address.html">
							<div className="mb-3">
								<label className="col-form-label">IP Address <span className="text-danger">*</span></label>
								<input type="text" className="form-control"/>
							</div>
							<div className="mb-3">
								<label className="col-form-label">Reason For Ban</label>
								<textarea className="form-control" rows="4"></textarea>
							</div>
							<div className="modal-btn">
								<a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Save</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Add Ban IP Address --> */}

		{/* <!-- Edit Ban IP Address --> */}
		<div className="modal custom-modal fade" id="edit_ip" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Edit Ban IP Address</h5>
						<div className="d-flex align-items-center mod-toggle">
							<div className="status-toggle">
								<input type="checkbox" id="toggle1" className="check" checked=""/>
								<label for="toggle1" className="checktoggle"></label>
							</div>
							<button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
								<i className="ti ti-x"></i>
							</button>
						</div>
					</div>
					<div className="modal-body">
						<form action="ban-ip-address.html">
							<div className="mb-3">
								<label className="col-form-label">IP Address <span className="text-danger">*</span></label>
								<input type="text" className="form-control"/>
							</div>
							<div className="mb-3">
								<label className="col-form-label">Reason For Ban</label>
								<textarea className="form-control" rows="4"></textarea>
							</div>
							<div className="modal-btn">
								<a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Save</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Edit Ban IP Address --> */}

		{/* <!-- Delete IP Address --> */}
		<div className="modal custom-modal fade" id="delete_ip" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header border-0 m-0 justify-content-end">
						<button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<div className="modal-body">
						<form action="ban-ip-address.html">
							<div className="success-message text-center">
								<div className="success-popup-icon">
									<i className="ti ti-trash-x"></i>
								</div>
								<h3>Remove IP Address?</h3>
								<p>Are you sure you want to remove it.</p>
								<div className="col-lg-12 text-center modal-btn">
									<a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
									<button type="submit" className="btn btn-danger">Yes, Delete it</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Delete IP Address --> */}



        </div>

    
    
    </>
  )
}

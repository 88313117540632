import React, { useState } from "react"
import "react-perfect-scrollbar/dist/css/styles.css"
import { useNavigate } from "react-router"
import API from "../../apis"
import { urls } from "../../apis/urls"
import { Link } from "react-router-dom"
import "./styles.css"
import { Common, Constant, Preferences } from "../../utils"
import CustomLoader from "../../components/custom-loader/CustomLoader.jsx"
import CustomAlert from "../../components/custom-alerts/CustomAlert.jsx"
import IconTint from 'react-icon-tint'

export default function Login() {
  
  const navigate = useNavigate()
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")
  const [loginSesstionId, setLoginSesstionId] = useState("")
  const [showOrganizationSelection, setShowOrganizationSelection] = useState(false)
  const [multipleOrganizationList, setMultipleOrganizationList] = useState([])
  const [borderCss, setBorderCSS] = useState("")

  const [alertTitle, setAlertTitle] = useState("")
  const [alertDesc, setAlertDesc] = useState("")
  const [isSweetAlert, SetIsSweetAlert] = useState(false)
  const [customAlertType, setCustomAlertType] = useState("")
  const [loading, setLoading] = useState()

  const handleUsername = (e) => {
    setUserName(e.target.value)
  }

  const handlePassword = (e) => {
    setPassword(e.target.value)
  }

  const confirmAlert = () => {
    SetIsSweetAlert(false)
  }

  const checkFieldValidations = async () => {
    if (userName == "" && password == "") {
      setAlertTitle("Error")
      setAlertDesc("User ID / Email Address OR Password is Required")
      SetIsSweetAlert(true)
    } else if (userName == "") {
      setAlertTitle("Error")
      setAlertDesc("User ID / Email Address is required")
      SetIsSweetAlert(true)
    } else if (password == "") {
      setAlertTitle("Error")
      setAlertDesc("Password is required")
      SetIsSweetAlert(true)
    } else {
      const loginParams = {
        username: userName,
        password: password,
        publicIpAddress: await Common.getPublicIpAddress(),
        privateIpAddress: await Common.getPrivateIpAddress(),
        macAddress: "",
      }
      await callLoginApi(loginParams)
    }
  }

  const callLoginApi = async (loginParams) => {
    setLoading(true)
    API.postRequestWithJsonForOnline(urls.memberLogin, loginParams)
      .then((data) => {
        console.log("Response:: ", data)
        if (data?.result?.response_Status === "success") {
          setLoginSesstionId(
            data?.result?.returnClass?.customerDir_forTocken.loginSesstionId
          )
          console.log(
            "loginSesstionId:: ",
            data?.result?.response?.result?.returnClass?.customerDir_forTocken
              .loginSesstionId
          )

          if (
            data?.result?.returnClass?.usr_OrgUsrAccounts.length != null &&
            data?.result?.returnClass?.usr_OrgUsrAccounts.length == 1
          ) {
            setShowOrganizationSelection(false)
            handleLoginSuccess()
          } else {
            setMultipleOrganizationList(
              data?.result?.returnClass?.usr_OrgUsrAccounts
            )
            setShowOrganizationSelection(true)
          }
        } else if (data?.result?.response_Status == "error") {
          console.log(data?.result?.response_Status)
          console.log(
            "data?.result?.response_Message",
            data?.result?.response_Message
          )
          setAlertTitle(data?.result?.response_Status)
          setAlertDesc(data?.result?.response_Message)
          // setLoading(false)
          SetIsSweetAlert(true)
          setCustomAlertType("error")
        } else {
          alert("Something went wrong with login")
        }
      })
      .catch((err) => {
        setLoading(false)
        console.log("API Error :: ", err)
      })
  }

  const enterInAccountApi = async (pkUsrOrgDirId) => {
    const params = `?loginSesstionId=${loginSesstionId}&pk_UsrOrgDirId=${pkUsrOrgDirId}`
    console.log("API | URL | JSON| POST:: ", urls.enterInAccount, params)
    API.postRequestWithParamsForOnline(urls.enterInAccount, params)
      .then((data) => {
        console.log("Response:: ", data)
        if (data?.result?.response_Status === "success") {
          setShowOrganizationSelection(false)
          handleLoginSuccess(data)
          setCustomAlertType(data?.result?.response_Status)
        } else if (data?.result?.response_Status == "error") {
          alert(data?.result?.response_Message)
          setCustomAlertType(data?.result?.response_Status)
        } else {
          alert("Something went wrong with enterInAccount")
        }
      })
      .catch((err) => {
        console.log("API Error :: ", err)
      })
    setBorderCSS("border: 2px solid #5281ff")
  }

  const handleLoginSuccess = (resData) => {
    createSession(resData)
    navigate("/dashboard")
  }

  const createSession = (resData) => {
    Preferences.saveSessionStorageData(
      Preferences.LOGIN_DATA,
      JSON.stringify(resData.result.returnClass.customerDir_forTocken)
    )
    Preferences.saveSessionStorageData(
      Preferences.COMPANY_DATA,
      JSON.stringify(resData.result.returnClass.usr_OrgUsrAccounts[0])
    )
    Preferences.saveSessionStorageData(
      Preferences.TOKEN_KEY,
      resData.tockenKey
    )
    Preferences.saveSessionStorageData(Preferences.IS_LOGIN_SUCCESS, "true")
  }

  const [selectOrg, setSelectOrg] = useState(null)

  const handleSuccess = () =>{
    if(!selectOrg){
      setAlertTitle("Error")
      setAlertDesc("Please select an organization.")
      SetIsSweetAlert(true)
      setCustomAlertType("error")
    }
    else{
      enterInAccountApi(selectOrg)
    }
  }


  return (
    <>
      {isSweetAlert && (
        <CustomAlert
          alertTitle={alertTitle}
          alertDesc={alertDesc}
          onCloseClick={confirmAlert}
          customAlertType={customAlertType}
        />
      )}

      {loading && <CustomLoader loaderAlignmensCss="" loaderStyle="loader" />}

      <div className="account-page">
        <div className="main-wrapper">
          <div className="account-content">
            <div className="d-flex flex-wrap w-100 vh-100 overflow-hidden account-bg-01">
              <div className="d-flex align-items-center justify-content-center flex-wrap vh-100 overflow-auto p-4 w-50 bg-backdrop">
                <form className="flex-fill">
                  <div className="mx-auto mw-450">
                    <div className="text-center mb-4">
                      <img
                        src={"/img/logo.svg"}
                        className="img-fluid"
                        alt="Logo"
                        style={{ width: "200px" }}
                      />
                    </div>
                    <div className="mb-4">
                      <h4 className="mb-2 fs-20">Sign In</h4>
                      <p>Access the CRM panel using your email and passcode.</p>
                    </div>
                    <div className="mb-3">
                      <label className="col-form-label">
                        User ID / Email Address
                      </label>
                      <div className="position-relative">
                        <span className="input-icon-addon">
                          <i className="ti ti-mail"></i>
                        </span>
                        <input
                          type="text"
                          value={userName}
                          className="form-control"
                          onChange={handleUsername}
                          required
                        />
                      </div>
                    </div>
                    <div className="mb-3">
                      <label className="col-form-label">Password</label>
                      <div className="pass-group">
                        <input
                          type="password"
                          value={password}
                          className="pass-input form-control"
                          onChange={handlePassword}
                          required
                        />
                        <span className="ti toggle-password ti-eye-off"></span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mb-3">
                      <div className="form-check form-check-md d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="checkebox-md"
                        />
                        <label className="form-check-label" for="checkebox-md">
                          Remember Me
                        </label>
                      </div>
                      <div className="text-end">
                        <Link className="text-primary fw-medium link-hover" to={Constant.forgotAccountURL} target="_blank">                            
                              Forgot Password?                        
                        </Link>
                      </div>
                    </div>
                    <div className="mb-3">
                      <button
                        className="btn btn-primary w-100"
                        type="button"
                        onClick={() => {
                          checkFieldValidations()
                          return false
                        }}
                      >
                        Sign In
                      </button>
                    </div>
                    <div className="mb-3">
                      <h6>
                          New on our platform? <Link to={Constant.createAccountURL} className="text-purple link-hover" target="_blank">Create an account</Link>
                      </h6>
                    </div>
                    <div className="text-center">
                      <p className="fw-medium text-gray">
                        Copyright &copy; 2024 - WorkX CRM
                      </p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

     {/* <Modal open={showOrganizationSelection}> */}
     {
          showOrganizationSelection && (
            <div
            className={`modal-overlay inline-flex`}
            style={{ border: borderCss }}
          >
            <div className="modal-contents">
              <p className="org-title">Select Organisation</p>
  
              <div className="organisation-box-outer">
                {multipleOrganizationList.map((account) => (
                  <div className="organisation-box" key={account.srNumber}>
                    <div className="inner-org-box">                      
                      <div className="org-img">                        
                        <IconTint src={account.logo ? account.logo : "/img/user-org-icon.png"} color="#6c7070" />                        
                      </div>
                      <div className="org-text">
                        <h4>{account.orgAccountName}</h4>
                        <p>( {account.orgAccountURL} )</p>
                      </div>
                      <div className="form-check form-check-lg">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="Radio"
                          id="Radio-md"
                          onChange={() => setSelectOrg(account.pk_UsrOrgDirId)}
                          value={selectOrg}
                          />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="outer-confirmbox">
                <button className="confirm-button" onClick={handleSuccess}>
                  Confirm
                </button>
              </div>
            </div>
          </div>    
          )
        }        
      {/* </Modal> */}
    </>
  )
}

import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"

export const countryListAdapter = createEntityAdapter()

export const fetchCountryList = createAsyncThunk(
    Preferences.COUNTRY_LIST,
    async (thunkApi) => {
        const params = `?fk_UsrOrgDirId=${
                JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
              }&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getCountryList, params)
        if (response?.result?.response_Status === 'success') {
            Preferences.saveSessionStorageData('CountryList', JSON.stringify(response?.result?.returnClass))
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status == 'error') {
            alert(response?.result?.response_Message) 
        } else {
            alert('Something went wrong with getCountryData')
        }
    }
)

export const initialAppState = countryListAdapter.getInitialState({
    loading: 'idel',
    countryListData: [],
    filterData: [],
    error: []
})

export const countryListSlice = createSlice({
    name: Preferences.COUNTRY_LIST,
    initialState: initialAppState,
    reducers: {
        resetCountryListFilter: state => {
            state.filterData = state.countryListData
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchCountryList.pending, state => {
            state.loading = 'loading'
        })
        builder.addCase(fetchCountryList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.countryListData = action.payload ?? []
            state.error = null
        })
        builder.addCase(fetchCountryList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    }
})

export const countryListReducer = countryListSlice.reducer

export const getCountryListLoading = state => {
    return state.countryListReducer.loading === 'loading' ? true : false
}

export const getCountryListError = state => {
    return state.countryListReducer.error
}

export const getCountryListFilterData = state => {
    return state.countryListReducer.filterData
}

export const getCountryListData = state => {
    return state.countryListReducer.countryListData
}

export const getCountryListDataByID = (state, {countryID}) => {
    return state.countryListReducer.filterData.filter(e => e?.pk_countryId == countryID)
}
import React, { useState } from "react"

import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"

export default function TaxRates() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h4 className="page-title">Settings</h4>
                    </div>
                    <div className="col-4 text-end">
                      <div className="head-icons">
                        <a
                          href="tax-rates.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                {/* <!-- Settings Menu --> */}
                <div className="card">
                  <div className="card-body pb-0 pt-2">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item me-3">
                        <a href="profile.html" className="nav-link px-0">
                          <i className="ti ti-settings-cog me-2"></i>General
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="company-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-world-cog me-2"></i>Website
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="invoice-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-apps me-2"></i>App Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a href="email-settings.html" className="nav-link px-0">
                          <i className="ti ti-device-laptop me-2"></i>System
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="payment-gateways.html"
                          className="nav-link px-0 active"
                        >
                          <i className="ti ti-moneybag me-2"></i>Financial
                          Settings
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="storage.html" className="nav-link px-0">
                          <i className="ti ti-flag-cog me-2"></i>Other Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /Settings Menu --> */}

                <div className="row">
                  <div className="col-xl-3 col-lg-12 theiaStickySidebar">
                    {/* <!-- Settings Sidebar --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="settings-sidebar">
                          <h4 className="fw-semibold mb-3">
                            Financial Settings
                          </h4>
                          <div className="list-group list-group-flush settings-sidebar">
                            <a
                              href="payment-gateways.html"
                              className="fw-medium"
                            >
                              Payment Gateways
                            </a>
                            <a href="bank-accounts.html" className="fw-medium">
                              Bank Accounts
                            </a>
                            <a
                              href="tax-rates.html"
                              className="fw-medium active"
                            >
                              Tax Rates
                            </a>
                            <a href="currencies.html" className="fw-medium">
                              Currencies
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Sidebar --> */}
                  </div>

                  <div className="col-xl-9 col-lg-12">
                    {/* <!-- Settings Info --> */}
                    <div className="card">
                      <div className="card-body pb-0">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <h4 className="fs-20">Tax Rates</h4>
                          <a
                            href="javascript:void(0)"
                            className="btn btn-sm btn-icon border rounded"
                            data-bs-toggle="modal"
                            data-bs-target="#add_tax"
                          >
                            <i className="ti ti-plus"></i>
                          </a>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="col-form-label">VAT</label>
                              <div className="icon-form-end">
                                <a
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_tax"
                                >
                                  <span className="form-icon">
                                    <i className="ti ti-edit"></i>
                                  </span>
                                </a>
                                <input
                                  type="text"
                                  className="form-control"
                                  value="16%"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="col-form-label">GST</label>
                              <div className="icon-form-end">
                                <a
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_tax"
                                >
                                  <span className="form-icon">
                                    <i className="ti ti-edit"></i>
                                  </span>
                                </a>
                                <input
                                  type="text"
                                  className="form-control"
                                  value="14%"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-3">
                              <label className="col-form-label">HST</label>
                              <div className="icon-form-end">
                                <a
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_tax"
                                >
                                  <span className="form-icon">
                                    <i className="ti ti-edit"></i>
                                  </span>
                                </a>
                                <input
                                  type="text"
                                  className="form-control"
                                  value="12%"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Info --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add Tax Rate --> */}
        <div className="modal fade" id="add_tax" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Tax Rate</h5>
                <div className="d-flex align-items-center">
                  <div className="status-toggle me-2">
                    <input
                      type="checkbox"
                      id="toggle"
                      className="check"
                      checked=""
                    />
                    <label for="toggle" className="checktoggle"></label>
                  </div>
                  <button
                    className="btn-close custom-btn-close border p-1 me-0 text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>
              </div>
              <form action="tax-rates.html">
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-0">
                    <label className="col-form-label">
                      Tax Rate % <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex align-items-center justify-content-end m-0">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- /Add Tax Rate --> */}

        {/* <!-- Edit Tax Rate --> */}
        <div className="modal fade" id="edit_tax" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Tax Rate</h5>
                <div className="d-flex align-items-center">
                  <div className="status-toggle me-2">
                    <input
                      type="checkbox"
                      id="toggle1"
                      className="check"
                      checked=""
                    />
                    <label for="toggle1" className="checktoggle"></label>
                  </div>
                  <button
                    className="btn-close custom-btn-close border p-1 me-0 text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>
              </div>
              <form action="tax-rates.html">
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" value="VAT" />
                  </div>
                  <div className="mb-0">
                    <label className="col-form-label">
                      Tax Rate % <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" value="16" />
                  </div>
                </div>
                <div className="modal-footer">
                  <a href="#" className="btn btn-light" data-bs-dismiss="modal">
                    Cancel
                  </a>
                  <button type="submit" className="btn btn-primary">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- /Edit Tax Rate --> */}
      </div>
    </>
  )
}

import React from 'react'
import { NavLink } from 'react-router-dom'
const SettingsHeader = ({isActive}) =>{
    return(
        <>
             {/* <!-- Settings Menu --> */}
             <div className="card">
                  <div className="card-body pb-0 pt-2">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item me-3">
                        {
                          isActive ?(
                            <NavLink to={"/notifications"} className="nav-link px-0">
                          <i className="ti ti-settings-cog me-2"></i>CRM Settings
                      </NavLink>                        
                                   
                          ):(
                            <NavLink to={"/storage"} className="nav-link px-0">
                                <i className="ti ti-settings-cog me-2"></i>CRM Settings
                            </NavLink>                 
                          )                          
                        }
                      </li>
                      <li className="nav-item me-3">
                        <NavLink to="/email-settings" className="nav-link px-0">
                            <i className="ti ti-settings-cog me-2"></i>System Settings
                        </NavLink>                        
                      </li>
                      <li className="nav-item me-3">
                        <NavLink to="/payment-gateways" className="nav-link px-0">
                            <i className="ti ti-settings-cog me-2"></i>Financial Settings
                        </NavLink>                        
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /Settings Menu --> */}
        </>
    )
}
export default SettingsHeader
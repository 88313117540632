import moment from "moment"
import { Preferences } from "."
import * as XLSX from "xlsx"
import { getSessionStorageData } from "./preferences"
import { useEffect, useState } from "react"
import platform from "platform"


export const doLogout = (navigate) => {
  Preferences.clearAllStorage(true)
  navigate("/login")
  return
}

export const checkLogin = (navigate) => {
  if (Preferences.getSessionStorageData(Preferences.IS_LOGIN_SUCCESS) != "true") {
    doLogout(navigate)
  } else return true
}

export const getPrivateIpAddress = async () => {
  let privateIp = ""
  fetch("https://geolocation-db.com/json/4c201930-974e-11ef-9d14-352837e79363")
    .then((response) => response.json())
    .then((data) => {
      privateIp = data
    })
    .catch((err) => {
      console.log("private ip address error - catch:: ", err)
      privateIp = ""
    })
  return privateIp
}

export const getPublicIpAddress = async () => {
  let publicIp = ""
  try {
    const response = await fetch("https://api.ipify.org?format=json")
    if (!response.ok) {
      console.log("public ip address error:: ", response)
      return publicIp
    }
    const data = await response.json()
    return data.ip
  } catch (err) {
    console.log("public ip address error - catch:: ", err.message)
    return publicIp
  }
}

export const exportToExcel = (columns, Data) => {
  const filteredData = Data.map(item => {
    const filteredItem = {}
    columns.forEach((column) => {
      let colvalue = column.headerValue
      if (item.hasOwnProperty(colvalue)) {
        filteredItem[column.headerName] = item[colvalue]
      }
    })
    return filteredItem
  })

  const ws = XLSX.utils.json_to_sheet(filteredData)
  const wb = XLSX.utils.book_new()
  XLSX.utils.book_append_sheet(wb, ws, "Sheet1")
  XLSX.writeFile(wb, "data_export.xlsx")
}

export const exportToPrint = (columns, Data) => { 

  let content = '<html><head><style>'
  content += 'table {border-collapse: collapse; width: 100%;}'
  content += 'th, td {border: 1px solid black; padding: 8px; text-align: left;}'
  content += '</style></head><body>'
  content += '<table>'
  content += '<thead><tr>'
  columns.forEach((col) => {
    content += `<th>${col.headerName}</th>`
  })

  content += '</tr></thead>'
  content += '<tbody>'
  Data.forEach((row) => {
    content += '<tr>'
    columns.forEach((col) => {
      const colValue = col.headerValue
      content += `<td>${row[colValue] || ''}</td>`
    })
    content += '</tr>'
  })
  content += '</tbody>'
  content += '</table>'
  content += '</body></html>'
  const printWindow = window.open("", "", "height=800,width=1200")
  printWindow.document.write(content)
  printWindow.document.close()
  printWindow.print()
}

export const handleCopyList = (columns, Data) => {
  const filteredData = Data.map(item => {
    const filteredItem = {}
    columns.forEach((column) => {
      let colvalue = column.headerValue
      if (item.hasOwnProperty(colvalue)) {
        filteredItem[column.headerName] = item[colvalue]
      }
    })
    return filteredItem
  })

  const headers = columns.map(column => column.headerName).join("\t")
  const rows = filteredData.map(item => columns.map(column => item[column.headerName] || '').join("\t") ).join("\n")
  const tableData = `${headers}\n${rows}`
  navigator.clipboard.writeText(tableData)
}

export const changeDateFormat = (date, fromFormat, toFormat) => {
  return moment(date, fromFormat).format(toFormat)
}

export const getDateSubString = (date) => {
  return date.slice(0, date.lastIndexOf('T'))
}

export const getTodayDate = () => {
  return moment().format('DD-MMM-yyyy')
}

export const getOrganisationID = () => {
  return JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
}

export const getLoggedInUserID = () => {
  return JSON.parse(getSessionStorageData(Preferences.LOGIN_DATA)).pk_UsrId
}

export const getTokenKey = () => {
  return getSessionStorageData(Preferences.TOKEN_KEY)
}

export const uploadFile = () => {
  return null
}

export const getBowserName = () =>{
  const [browserName, setBrowserName] = useState('')
  useEffect(() =>{
    setBrowserName(platform.name)
  },[])
  return browserName
}

export const handleFileUpload = (e) => {
  const file = e.target.files[0]
  const reader = new FileReader()
  
  reader.onload = (event) => {
    const workbook = XLSX.read(event.target.result, {type: 'binary'})
    const sheetName = workbook.SheetNames[0]
    const sheet = workbook.Sheets[sheetName]
    const sheetData = XLSX.utils.sheet_to_json(sheet)

    console.log('sheetData:: ', sheetData)
    return (sheetData)
  } 
  reader.readAsBinaryString(file)
}

export const handleReloadPage = () =>{
  return location.reload()
}

export const refershPage = () => {
  window.location.reload()
}
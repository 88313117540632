import { useEffect, useRef, useState } from "react"
import { connect, useDispatch } from "react-redux"
import {
  fetchContactList,
  getContactListData,
} from "../../store/slice/contact-slice"
import {
  fetchProductList,
  getProductListData,
} from "../../store/slice/product-slice"
import {
  fetchBrandList,
  getBrandListData,
} from "../../store/slice/brand-slice"
import {
  fetchCountryList,
  getCountryListData,
} from "../../store/slice/country-slice"
import {
  fetchStateList,
  getStateListData,
} from "../../store/slice/state-slice"
import { fetchCityList, getCityListData } from "../../store/slice/city-slice"
import { fetchZipList, getZipListData } from "../../store/slice/zip-slice"
import {
  fetchLocationList,
  getLocationListData,
} from "../../store/slice/location-slice"
import {
  fetchStatusList,
  getStatusListData,
} from "../../store/slice/status-slice"
import Creatable from "react-select/creatable"
import API from "../../apis"
import { urls } from "../../apis/urls"
import { Common, Constant } from "../../utils"
import CustomAlert from "../custom-alerts/CustomAlert"
import CustomModal from "../custom-modal"
import AddContact from "./contacts/AddContact"
import CustomDropdown from "../custom-dropdown"
import AddProduct from "./AddProduct"
import AddBrand from '../page-components/AddBrand'
import { fetchSourceList, getSourceListData } from "../../store/slice/source-slice"

const AddEnquiry = ({
  navigate,
  formType,
  selectedData,
  contactListData,
  productListData,
  brandListData,
  countryListData,
  stateListData,
  cityListData,
  zipListData,
  locationListData,
  sourceListData,
  statusListData,
}) => {
  const [loading, setLoading] = useState(false)
  const [selectedContactData, setSelectedContactData] = useState(null)
  const [selectedProductData, setSelectedProductData] = useState(null)
  const [selectedBrandData, setSelectedBrandData] = useState(null)
  const [selectedCountryData, setSelectedCountryData] = useState(null)
  const [selectedStateData, setSelectedStateData] = useState(null)
  const [selectedCityData, setSelectedCityData] = useState(null)
  const [selectedZipData, setSelectedZipData] = useState(null)
  const [selectedLocationData, setSelectedLocationData] = useState(null)
  const [selectedStatusData, setSelectedStatusData] = useState(null)
  const [selectedSourceData, setSelectedSourceData] = useState(null)
  const [remarks, setRemarks] = useState("")
  const remarksRef = useRef(null)

  const [showAddContactForm, setShowAddContactForm] = useState(false)
  const [showAddProductForm, setShowAddProductForm] = useState(false)
  const [showAddBrandForm, setShowAddBrandForm] = useState(false)

  const [showCustomAlert, setShowCustomAlert] = useState(false)
  const [alertTitle, setAlertTitle] = useState("")
  const [alertDesc, setAlertDesc] = useState("")
  const [customAlertType, setCustomAlertType] = useState("")

  const hideContactForm = () => setShowAddContactForm(false)
  const hideProductForm = () => setShowAddProductForm(false)
  const hideBrandForm = () => setShowAddBrandForm(false)

  useEffect(() => {
    if (selectedData) {
      const selectedContact = contactListData?.find((e) => e.pk_ContactId == selectedData?.fk_ContactId)
      const selectedProduct = productListData?.find((e) => e.pk_ProductId == selectedData?.fk_ProductId)
      const selectedBrand = brandListData?.find((e) => e.pk_BrandId == selectedData?.fk_BrandId)
      const selectedCountry = countryListData?.find((e) => e.pk_countryId == selectedData?.fk_countryId)
      const selectedState = stateListData?.find((e) => e.pk_stateId == selectedData?.fk_stateId)
      const selectedCity = cityListData?.find((e) => e.pk_cityId == selectedData?.fk_cityId)
      const selectedZip = zipListData?.find((e) => e.pk_zipId == selectedData?.fk_zipId)
      const selectedLocation = locationListData?.filter(e => e.pk_LocationId == selectedData?.fk_ContactId)
      const selectedSource = sourceListData?.find((e) => e.pk_EnqSourceId == selectedData?.fk_EnqSourceId)
      const selectedStatus = statusListData?.find((e) => e.pk_statusId == selectedData?.statusId)

      setSelectedContactData(selectedContact || null)
      setSelectedProductData(selectedProduct || null)
      setSelectedBrandData(selectedBrand || null)
      setSelectedCountryData(selectedCountry || null)
      setSelectedStateData(selectedState || null)
      setSelectedCityData(selectedCity || null)
      setSelectedZipData(selectedZip || null)
      setSelectedLocationData(selectedLocation || null)
      setSelectedSourceData(selectedSource || null)
      setSelectedStatusData(selectedStatus || null)
      setRemarks(selectedData.remarks)
    }
  }, [selectedData, contactListData, productListData, brandListData, countryListData, stateListData, cityListData, zipListData, locationListData, sourceListData, statusListData])
  
  const [creatableLocation, setCreatableLocation] = useState()
  const locationCreatable = locationListData?.length > 0 ? [{value: creatableLocation, label: creatableLocation}] : []

  const onRemarksChange = (event) => setRemarks(event.target.value)

  useEffect(() =>{
    locationListData?.map((elm) =>{
      return setCreatableLocation(elm.locationName)
    })      
  },[selectedLocationData])

  const clearInputValues = () => {
    setSelectedContactData("")
    setSelectedProductData("")
    setSelectedBrandData("")
    setSelectedCountryData("")
    setSelectedStateData("")
    setSelectedCityData("")
    setSelectedZipData("")
    setSelectedLocationData("")
    setSelectedSourceData("")
    setSelectedStatusData("")
    setRemarks("")
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchContactList())
    dispatch(fetchProductList())
    dispatch(fetchBrandList())
    dispatch(fetchCountryList())
    dispatch(fetchStateList())
    dispatch(fetchCityList())
    dispatch(fetchZipList())
    dispatch(fetchLocationList())
    dispatch(fetchSourceList())
    dispatch(fetchStatusList())
  }, [])

  const customAlertTexts = (title, desc, alertType) => {
      setAlertTitle(title ?? "")
      setAlertDesc(desc ?? "")
      setCustomAlertType(alertType)
      setShowCustomAlert(true)
  }

  const checkValidations = () => {
    if (!selectedContactData) {      
      customAlertTexts("Error", "Contact is required", "error")      
      return false
    } else if (!selectedProductData) {      
      customAlertTexts("Error", "Product is required", "error")            
      return false
    } else if (!selectedBrandData) {      
      customAlertTexts("Error", "Brand is required", "error")                  
      return false
    } else if (!selectedStatusData) {      
      customAlertTexts("Error", "Status is required", "error")                  
      return false
    }
    submitData()
  }

  const submitData = async () => {
    setLoading(true)
    const params = {
      pk_EnquiryId: formType == Constant.FORM_TYPE_NEW ? 0 : 0,
      fk_ContactId: selectedContactData.pk_ContactId ?? 0,
      fullName: "test",
      fk_UsrId: Common.getLoggedInUserID(),
      fk_UsrOrgDirId: Common.getOrganisationID(),
      fk_ProductId: selectedProductData?.pk_ProductId ?? 0,
      fk_BrandId: selectedBrandData?.pk_BrandId ?? 0,
      fk_countryId: selectedCountryData?.pk_countryId ?? 0,
      fk_stateId: selectedStateData?.pk_stateId ?? 0,
      fk_cityId: selectedCityData?.pk_cityId ?? 0,
      fk_zipId: selectedZipData?.pk_zipId ?? 0,
      fk_LocationId: selectedLocationData?.pk_LocationId ?? 0,
      fk_EnqSourceId: selectedSourceData?.pk_EnqSourceId ?? 0,
      ipaddress: "",
      deviceName: "",
      browser: "",
      statusCode: selectedStatusData?.pk_statusId + "" ?? "0",
      emailId: [],
      phoneNumber: [],
      createdBy: Common.getLoggedInUserID(),
      isFlag: 0,
      remarks: remarks
    }
    console.log("params - ", params)
    API.postRequestWithJsonForOnline(urls.createEnquiry, params)
      .then(handleResponse)
      .catch(() => {
        setLoading(false)
      })
  }

  const handleResponse = (response) => {
    setLoading(false)
    if (response.result.response_Status === Constant.STATUS_SUCCESS) {
      clearInputValues()
      customAlertTexts(response.result.response_Status, response.result.response_Message, response.result.response_Status)
      setShowCustomAlert(true)
    } else if (response.result.response_Status === Constant.STATUS_ERROR) {
      customAlertTexts(response.result.response_Status, response.result.response_Message, response.result.response_Status)
      setShowCustomAlert(true)
    } else {
      Common.doLogout(navigate)
    }
  }

  return (
    <>   
      <div
        className="offcanvas offcanvas-end offcanvas-large"
        tabindex="-1"
        id="offcanvas_add"
      >
      {showCustomAlert && (
        <CustomAlert
          alertTitle={alertTitle}
          alertDesc={alertDesc}
          onOkClick={() => setShowCustomAlert(false)}
          customAlertType={customAlertType}
        />
      )}
        <div className="offcanvas-header border-bottom">
          <h5 className="fw-semibold">
            {formType === Constant.FORM_TYPE_EDIT
              ? "Update Lead"
              : formType === Constant.FORM_TYPE_NEW
              ? "Add Lead"
              : "Lead Details"}
          </h5>
          <button
            type="button"
            className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              clearInputValues()
              return false
            }}
          >
            <i className="ti ti-x"></i>
          </button>
        </div>
        <div className="offcanvas-body">
          <form>
            <div className="outerInquiryFormBox">
              <div className="">
                <div className="mb-3">
                  <label className="col-form-label">
                    Contact <span className="text-danger">*</span>
                  </label>
                  {formType != Constant.FORM_TYPE_VIEW && (
                    <span
                      className="add-options"
                      onClick={() => setShowAddContactForm(true)}
                    >
                      Add Contact
                    </span>
                  )}
                  <CustomDropdown
                    options={contactListData}
                    label="contactName"
                    id="pk_ContactId"
                    selectedValue={selectedContactData}
                    setSelectedValue={setSelectedContactData}
                    placeholder="Contact"
                    isDisabled={formType === Constant.FORM_TYPE_VIEW}
                  />
                </div>
              </div>
              {/* <AddContact /> */}
              {showAddContactForm && (
                <CustomModal
                  children={
                    <AddContact
                      formType={Constant.FORM_TYPE_NEW}
                      hideContactForm={hideContactForm}
                      showAddContactForm={showAddContactForm}
                    />
                  }
                />
              )}
              <div className="col-md-"></div>
              {/* Product   */}
              <div className="">
                <div className="mb-3">
                  <label className="col-form-label">
                    Product<span className="text-danger">*</span>
                  </label>
                  {formType != Constant.FORM_TYPE_VIEW && (
                    <span
                      className="add-options"
                      onClick={() => setShowAddProductForm(true)}
                    >
                      Add Products
                    </span>
                  )}
                  <CustomDropdown
                    options={productListData}
                    label="productName"
                    id="pk_ProductId"
                    selectedValue={selectedProductData}
                    setSelectedValue={setSelectedProductData}
                    placeholder="Product"
                    isDisabled={formType === Constant.FORM_TYPE_VIEW}
                  />
                </div>
              </div>
              {showAddProductForm && (
                <CustomModal
                  children={
                    <AddProduct 
                    navigate={navigate} 
                    formType={formType}
                    hideProductForm={hideProductForm}
                    showAddProductForm={showAddProductForm}               
                    />
                  }
                />
              )}
              <div className="col-md-"></div>
              <div className="">
                <div className="mb-3">
                  <label className="col-form-label">
                    Brand <span className="text-danger">*</span>
                  </label>
                  {formType != Constant.FORM_TYPE_VIEW && (
                    <span
                      className="add-options"
                      onClick={() => setShowAddBrandForm(true)}
                    >
                      Add Brand
                    </span>
                  )}
                  <CustomDropdown
                    options={brandListData}
                    label="brandName"
                    id="pk_BrandId"
                    selectedValue={selectedBrandData}
                    setSelectedValue={setSelectedBrandData}
                    placeholder="Brand"
                    isDisabled={formType === Constant.FORM_TYPE_VIEW}
                  />                  
                </div>
              </div>
              {showAddBrandForm && (
                <CustomModal
                  children={
                    <AddBrand 
                    navigate={navigate}
                    formType={formType}
                    hideBrandForm={hideBrandForm}
                    showAddBrandForm={showAddBrandForm}
                    />
                  }
                />
              )}
              {/* Country */}
              <div className="col-md-"></div>
              <div className="">
                <div className="mb-3">
                  <label className="col-form-label">
                    Country
                  </label>
                  <CustomDropdown
                    options={countryListData}
                    label="countryName"
                    id="pk_countryId"
                    selectedValue={selectedCountryData}
                    setSelectedValue={(data) => {
                      setSelectedStateData(null)
                      dispatch(fetchStateList({countryID:data.pk_countryId}))
                      setSelectedCountryData(data)
                    }}
                    placeholder="Country"
                    isDisabled={formType === Constant.FORM_TYPE_VIEW}
                    isSubmitData = {false}
                    clearInputStyle={true}
                  />
                </div>
              </div>
              {/* State */}
              <div className="">
                <div className="mb-3">
                  <label className="col-form-label">
                    State
                  </label>
                  <CustomDropdown
                    options={stateListData}
                    label="stateName"
                    id="pk_stateId"
                    selectedValue={selectedStateData}
                    setSelectedValue={(data) => {
                      setSelectedCityData(null)
                      dispatch(fetchCityList({countryID: data.fk_countryId, stateID:data.pk_stateId}))
                      setSelectedStateData(data)
                    }}
                    placeholder="State"
                    isDisabled={formType === Constant.FORM_TYPE_VIEW}
                    clearInputStyle={true}
                  />
                </div>
              </div>
              {/* City ID  */}
              <div className="">
                <div className="mb-3">
                  <label className="col-form-label">
                    City
                  </label>
                  <CustomDropdown
                    options={cityListData}
                    label="cityName"
                    id="pk_cityId"
                    selectedValue={selectedCityData}
                    setSelectedValue={(data) => {
                      if (data && data.fk_countryId && data.fk_stateId && data.pk_cityId) {
                        setSelectedZipData(null)
                        dispatch(fetchZipList({countryID: data.fk_countryId, stateID: data.fk_stateId, cityID: data.pk_cityId}))
                        setSelectedCityData(data)
                      }
                    }}
                    placeholder="City"
                    isDisabled={formType === Constant.FORM_TYPE_VIEW}
                    isSubmitData={true}
                    clearInputStyle={true}
                    />                
                </div>
              </div>

              {/* Zip Code  */}
              <div className="">
                <div className="mb-3">
                  <label className="col-form-label">
                    Zip 
                  </label>
                  <CustomDropdown
                    options={zipListData}
                    label="zipCode"
                    id="pk_zipId"
                    selectedValue={selectedZipData}
                    setSelectedValue={(data) => {
                      if (data && data.pk_zipId) {
                        setSelectedLocationData(null)
                        dispatch(fetchLocationList({zipID: data.pk_zipId}))
                        setSelectedZipData(data)
                      }
                    }}
                    placeholder="Zip"
                    isDisabled={formType === Constant.FORM_TYPE_VIEW}
                    isSubmitData={true}
                    clearInputStyle={true}
                    inputType={"number"}

                    />
                </div>
              </div>

              {/* Location*/}
              <div className="">
                <div className="mb-3">
                  <label className="col-form-label">
                    Location 
                  </label>
                  <Creatable
                    options={locationCreatable}                    
                    isSearchable
                    placeholder="Location"
                    isMulti                    
                    isDisabled={formType === Constant.FORM_TYPE_VIEW}
                    onChange={(data) => {
                      console.log("selectedLocation:: ", data)
                      setSelectedLocationData(data)
                    }}
                  />
                </div>
              </div>

              {/* Source */}
              <div className="">
                <div className="mb-3">
                  <label className="col-form-label">
                    Source 
                  </label>
                  <CustomDropdown
                    options={sourceListData}
                    label="enqSourceName"
                    id="pk_EnqSourceId"
                    selectedValue={selectedSourceData}
                    setSelectedValue={setSelectedSourceData}
                    placeholder="Source"
                    isDisabled={formType === Constant.FORM_TYPE_VIEW}
                    clearInputStyle={true}
                    />
                </div>
              </div>

              {/* Remarks */}
              <div className="mb-3">
                <label htmlFor="recipient-name" className="col-form-label">
                  Remarks
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Remarks"
                  onChange={onRemarksChange}
                  value={remarks}
                  disabled={formType === Constant.FORM_TYPE_VIEW}
                  ref={remarksRef}
                  maxLength={300}
                />
              </div>

              {/* Status Code  */}
              <div className="">
                <div className="mb-3">
                  <label className="col-form-label">
                    Status <span className="text-danger">*</span>
                  </label>
                  <CustomDropdown
                    options={statusListData}
                    label="statusName"
                    id="pk_statusId"
                    selectedValue={selectedStatusData}
                    setSelectedValue={setSelectedStatusData}
                    placeholder="State"
                    isDisabled={formType === Constant.FORM_TYPE_VIEW}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              {formType !== Constant.FORM_TYPE_VIEW && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={checkValidations}
                  disabled={formType === Constant.FORM_TYPE_VIEW}
                >
                  {formType === Constant.FORM_TYPE_EDIT ? "Update" : "Submit"}
                </button>
              )}                
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  contactListData: getContactListData(state),
  productListData: getProductListData(state),
  brandListData: getBrandListData(state),
  countryListData: getCountryListData(state),
  stateListData: getStateListData(state),
  cityListData: getCityListData(state),
  zipListData: getZipListData(state),
  locationListData: getLocationListData(state),
  sourceListData: getSourceListData(state),
  statusListData: getStatusListData(state),
})

export default connect(mapStateToProps)(AddEnquiry)

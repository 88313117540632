import React from "react"

import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"
import { useState } from "react"
import CustomDataTableComponent from "../../components/page-components/CustomDataTableComponent"
import Data from "../../utils/dummy-data"

export default function CallHistory() {

  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

    const columns = [
    {
      name: "Contact Name",
      selector: (row) => row.contacts_name,
      cell: (row) => (
        <div className="d-flex flex-column">
        <span className="text-default">{row.contacts_name}</span>
        </div>
      ),
      sortable: true,
      },
  
      {
        name: "Phone Number",
        selector: (row) => row.contacts_name,
        cell: (row) => (
          <div className="d-flex flex-column">
            <span className="text-default">{row.phone}</span>
          </div>
        ),
        sortable: true,
      },
      {
        name: "Call Type",
        selector: (row) => row.contacts_name,
        sortable: true,
      },
  
    {
      name: "Duration",
      selector: (row) => row.status,
      sortable: true,
      },
  
      {
        name: "Date & Time",
        selector: (row) => row.created_date,
        sortable: true,
      },
  
      {
        name: "Action",
        button: true,
        sortable: true,
        cell: (row) => (
          <div className="outer-action-box">  
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              data-bs-original-title="View"
            >
              <a
                className="dropdown-item"
                data-bs-toggle="modal"
                data-bs-target="#user-profile-new"
                href="#"
                // onClick={() => {
                //   setSelectedData({ ...row })
                //   setFormType(Constant.FORM_TYPE_VIEW)
                // }}
              >
                <i class="ti ti-eye text-blue-light"></i>
              </a>
            </div>
            
          </div>
        ),
      },
    ]

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav" }`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="page-header menu">
                <div className="page-title">
                  <h4>Call History</h4>
                  <h6>Manage your products</h6>
                </div>
              </div>
            </div>

            {/* <!-- /product list --> */}
            <div className="card">
              <div className="card-body">
                {/* <!-- /Filter --> */}
                <CustomDataTableComponent columns={columns} rowData={Data} />
                

              </div>
            </div>
            {/* <!-- /product list --> */}
          </div>
        </div>

        {/* <!-- details popup --> */}
        <div className="modal fade" id="user-profile-new">
          <div className="modal-dialog history-modal-profile">
            <div className="modal-content">
              <div className="page-wrapper details-blk">
                <div className="content">
                  <div className="text-center right-sidebar-profile mb-3">
                    <figure className="avatar">
                      <img src="/img/users/user-23.jpg" alt="image" />
                    </figure>
                    <div className="chat-options chat-option-profile">
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <a
                            href="audio-call.html"
                            className="btn btn-outline-light "
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title=""
                            data-bs-original-title="Voice Call"
                          >
                            <i className="bx bx-phone"></i>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            href="chat.html"
                            className="btn btn-outline-light"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title=""
                            data-bs-original-title="Chat"
                          >
                            <i className="bx bx-message-square-dots"></i>
                          </a>
                        </li>
                        <li className="list-inline-item ">
                          <a
                            href="video-call.html"
                            className="btn btn-outline-light profile-open"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            title=""
                            data-bs-original-title="Video Call"
                          >
                            <i className="bx bx-video"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="modal-profile-detail">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="modal-userlist">
                          <ul>
                            <li>
                              Name<span>Thomas</span>
                            </li>
                            <li>
                              Phone<span>+1 25182 94528</span>
                            </li>
                            <li>
                              Email<span>thomas@example.com</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="modal-userlist d-flex justify-content-center">
                          <ul>
                            <li>
                              Total Calls<span>20</span>
                            </li>
                            <li>
                              Average Call Timing<span>0.30</span>
                            </li>
                            <li>
                              Average Waiting Time<span>00.5</span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /details popup --> */}
      </div>
    </>
  )
}

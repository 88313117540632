import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"


const IframeSettings = () => {
    const [isOpen, setIsOpen] = useState(false)
    const openSideNav = () => {
        setIsOpen(!isOpen)
    }
    const [isShowWidgetBox, setIsShowWidget] = useState(false)
    const [isWidgetAppearenceShowing, setIsWidgetAppearenceShowing] = useState(false)


    const handleWidgetBox = () => {}

    const handleAddWidget = () => {
        setIsShowWidget(true)
    }

    const handleAdvancedAppearance = () => {
        setIsWidgetAppearenceShowing(true)
    }

    const handleCancelWidgetDetails = () => {
        setIsShowWidget(false)
    }

    const handleCancelWidgetAppearance = () => {
        setIsWidgetAppearenceShowing(false)
    }

    const widgetsDetails = () => {
        return (
            <div className="widgetsDetails">
                <div className="innerWidgets-details mt-5">
                    <h2 className="mb-4">Enter Widget Details</h2>
                    <div className="col-md-6 mb-4">
                        <div className="mb-3">
                            <label className="form-label">Widget Name</label>
                            <input type="text" className="form-control" autoFocus placeholder="Enter Widget Name" />
                        </div>
                    </div>

                    <div className="col-md-6 mt-4">
                        <div className="mb-3">
                            <label className="form-label">Widget Type</label>
                            <div className="d-flex align-items-center gap-4 flex-wrap" style={{ justifyContent: "space-between" }} >
                                <div className="d-flex align-items-center flex-column gap-4">
                                    <img src={"/img/inline-widget.svg"} />
                                    <div className="form-check form-check-md">
                                        <input className="form-check-input" type="radio" name="Radio" id="Radio-md" />
                                        <label className="form-check-label" for="Radio-md">
                                            inline
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center flex-column gap-4">
                                    <img src={"/img/embed-widget.svg"} />
                                    <div className="form-check form-check-md">
                                        <input className="form-check-input" type="radio" name="Radio" id="Radio-md" />
                                        <label className="form-check-label" for="Radio-md">
                                            Embed
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mt-4 d-flex align-items-center justify-content-between gap-5 mb-4">                        
                            <button className="btn btn-light" onClick={handleCancelWidgetDetails} >Cancel</button>
                            <button className="btn btn-success">Success</button>                        
                    </div>
                </div>
            </div>
        )
    }    

    const widgetAppearence = () => {
        return(
            <div className="card">
            <div className="card-body">
                <h4 className="mb-4">Widget Appearence</h4>
             <div className="row">
                    <div className="col-md-6">
                        <label className="form-label">Desktop Widget</label>
                        {/* border color and Background Color  */}
                        <div className="row mb-4">
                            <div className="col-md-6 mb-4">
                                <div className="cardFill">
                                    <div className="innerCardBox">
                                        <label className="form-label">Choose Border Color</label>
                                        <div className="d-flex align-items-center flex-wrap gap-5">
                                            <div class="widgetAppearenceBoxOner">
                                                <input type="color" id="favcolor" name="favcolor" value="#ff0000" style={{ height: "40px", width: "100px" }} />
                                            </div>
                                            <p>#ff0000</p>
                                            {/* <div className="widgetAppearenceBoxTwor">
                                                <button className="editBtnIframe">Save</button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="cardFill">
                                    <div className="innerCardBox">
                                        <label className="form-label">Choose Background Color</label>
                                        <div className="d-flex align-items-center flex-wrap gap-5">
                                            <div class="widgetAppearenceBoxOner">
                                                <input type="color" id="favcolor" name="favcolor" value="#ff0000" style={{ height: "40px", width: "100px" }} />
                                            </div>
                                            <p>#ff0000</p>
                                            {/* <div className="widgetAppearenceBoxTwor">
                                                <button className="editBtnIframe">Save</button>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* height Width  */}

                        <div className="row">
                            <div className="col-md-6">
                                <div className="cardFill">
                                    <div className="innerCardBox">
                                        <div className="row">
                                            <div className="col-md-6 mb-2">
                                                <label className="form-label">Height</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                            <div className="col-md-6 mb-2">
                                                <label className="form-label">Width</label>
                                                <input type="text" className="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="cardFill">
                                    <div className="innerCardBox mb-2">
                                        <label className="form-label">Desktop Widget Position</label>
                                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                                            <div class="topLeftBottom">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div style={{ backgroundColor: "green" }}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                    <div className="col-md-6">
                        <label className="form-label">Desktop Widget Preview</label>
                        <div className="desktopWidgetPreview"></div>
                    </div>
            </div>
            <div className="outerWidgetButtonsBox mt-4">
                <button className="btn btn-light" onClick={handleCancelWidgetAppearance} >Cancel</button>
                <button className="btn btn-success">Confirm</button>
            </div>
            </div>
        </div>
        )
    }


    return (
        <>
            <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
                {/* header component  */}
                <VerticalMenuComponent />
                <TopMenuComponent openSideNav={openSideNav} />
                {/* page wrapper  */}
                <div className="page-wrapper">
                    <div className="content">
                        <div class="page-header">
                            <div class="row">
                                <div class="col">
                                    <h3 class="page-title">IFrame Settings</h3>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">

                                    {
                                        !isShowWidgetBox && !isWidgetAppearenceShowing && (
                                            <div className="card-body">
                                                <div className="col-lg-12"><button className="btn btn-success my-2" id="addWidget" onClick={handleAddWidget} >Add Widget</button> </div>

                                                <form action="#">

                                                    <div className="mb-3">
                                                        <div class="col-md-12">
                                                            {/* <div className="formControlBox"> */}
                                                                <input type="text" className="form-control"/>                                                                
                                                                {/* <button className="btnlight">Save</button> */}
                                                            {/* </div> */}
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="mb-3 row">
                                                                <label className="form-label">Widget Name</label>
                                                                <div class="d-flex gap10">                                                                    
                                                                        <input type="text" className="form-control" />
                                                                        <button className="btn btn-light">Save</button>                                                                    
                                                                </div>
                                                            </div>


                                                            <div className="mb-3 row">
                                                                <label className="form-label">Widget Status</label>
                                                                <div class="d-flex align-items-center" style={{gap:"10px"}}>                                                                  
                                                                    <div className="formControlBox">
                                                                        <div className="form-switch" style={{marginLeft:"1rem"}}>
                                                                            <input type="checkbox" id="custom-switch" className="form-check-input"/>
                                                                        </div>
                                                                    </div>
                                                                    <button className="btn btn-light" disabled >Active</button>
                                                                </div>
                                                            </div>

                                                            <div className="mb-3 row">
                                                                <label className="form-label">Widget ID</label>
                                                                <div class="d-flex" style={{gap:"10px"}}>                                                                                                                                        
                                                                        <input type="text" className="form-control" />                                                                        
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="form-label">Widget Code</label>
                                                            <div className="mb-3 row">
                                                                <div className="col-md-12">
                                                                    <textarea rows={8} cols={10} className="form-control" onMouseOver={handleWidgetBox} ></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="overlay-clipboard">
                                                                <button className="btn btn-outline-dark">Copy To Clipboard</button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-12">
                                                        <div className="mb-3 row">
                                                            <label className="form-label">Direct Chat Link</label>
                                                            <div class="d-flex" style={{gap:"10px"}}>                                                                
                                                                    <input type="text" className="form-control" />
                                                                    <button className="btn btn-light">Save</button>                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="row mb-3">

                                                        <div className="col-md-6">
                                                            <div className="cardFill">
                                                                <div className="innerCardBox">
                                                                    <label className="form-label">Widget Appearence</label>
                                                                    <div className="widgetAppearenceBoxOuter">
                                                                        <div class="widgetAppearenceBoxOne">
                                                                            <input type="color" id="favcolor" name="favcolor" value="#ff0000" style={{ height: "40px", width: "100px" }} />
                                                                        </div>
                                                                        <div className="widgetAppearenceBoxTwo">
                                                                            <button className="editBtnIframe" onClick={handleAdvancedAppearance} >Advanced</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="col-md-6">
                                                            {/* <div className="cardFill">
                                                                <div className="innerCardBox">
                                                                    <label className="form-label">Widget Content</label>
                                                                    <div className="widgetAppearenceBoxOuter">
                                                                        <div className="widgetAppearenceBoxOne">
                                                                            <select className="form-select" aria-label="Default select example">
                                                                                <option selected="">Open this select menu</option>
                                                                                <option value="1">One</option>
                                                                                <option value="2">Two</option>
                                                                                <option value="3">Three</option>
                                                                            </select>
                                                                        </div>
                                                                        <div className="widgetAppearenceBoxTwo">
                                                                            <button className="editBtnIframe">Edit Content</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> */}
                                                        </div>

                                                    </div>
                                                </form>
                                            </div>
                                        )
                                    }                                  
                                </div>
                                {isShowWidgetBox  && widgetsDetails()}
                                {isWidgetAppearenceShowing && widgetAppearence()}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default IframeSettings

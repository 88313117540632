import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"

export const brandListAdapter = createEntityAdapter()

export const fetchBrandList = createAsyncThunk(
    Preferences.BRAND_LIST,
    async (thunkApi) => {
        const params = `?fk_UsrOrgDirId=${
                JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
            }&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getBrandList, params)
        if (response?.result?.response_Status == 'success') {
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status === 'error') {
            alert(response?.result?.response_Message)
        } else {
            alert('Something went wrong with getBrandData')
        }
    }
)

export const initialAppState = brandListAdapter.getInitialState({
    loading: 'idel',
    brandListData: [],
    filterData: [],
    error: []
})

export const brandListSlice = createSlice({
    name: Preferences.BRAND_LIST,
    initialState: initialAppState,
    reducers: {
        resetBrandListFilter: state => {
            state.filterData = state.brandListData
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchBrandList.pending, state => {
            state.loading = 'loading'
        })
        builder.addCase(fetchBrandList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.brandListData = action.payload ?? []
            state.error = null
        })
        builder.addCase(fetchBrandList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    }
})

export const brandListReducer = brandListSlice.reducer

export const getBrandListLoading = state => {
    return state.brandListReducer.loading === 'loading' ? true : false
}

export const getBrandListError = state => {
    return state.brandListReducer.error
}

export const getBrandListFilterData = state => {
    return state.brandListReducer.filterData
}

export const getBrandListData = state => {
    return state.brandListReducer.brandListData
}

export const getBrandListDataByID = (state, {brandID}) => {
    return state.brandListReducer.filterData.filter(e => e?.pk_BrandId == brandID)
}
import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"
export default function TaskReports() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-sm-8">
                      <h4 className="page-title">
                        Task Reports <span className="count-title">123</span>
                      </h4>
                    </div>
                    <div className="col-sm-4 text-sm-end">
                      <div className="head-icons">
                        <a
                          href="task-reports.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                <div className="card ">
                  <div className="card-header">
                    {/* <!-- Search --> */}
                    <div className="row">
                      <div className="col-md-5 col-sm-4">
                        <div className="icon-form mb-3 mb-sm-0">
                          <span className="form-icon">
                            <i className="ti ti-search"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Tasks"
                          />
                        </div>
                      </div>
                      <div className="col-md-7 col-sm-8">
                        <div className="text-sm-end">
                          <a
                            href="download_report"
                            className="btn btn-primary"
                            data-bs-toggle="modal"
                            data-bs-target="#download_report"
                          >
                            <i className="ti ti-file-download me-2"></i>Download
                            Report
                          </a>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Search --> */}
                  </div>

                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-7 d-flex">
                        <div className="card shadow flex-fill">
                          <div className="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-2">
                            <h4>Tasks by Year</h4>
                            <div className="icon-form">
                              <span className="form-icon">
                                <i className="ti ti-calendar"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control bookingrange"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="card-body">
                            <div id="task-year"></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-5 d-flex">
                        <div className="card shadow flex-fill">
                          <div className="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-2">
                            <h4>Tasks by Types</h4>
                            <div className="icon-form ">
                              <span className="form-icon">
                                <i className="ti ti-calendar"></i>
                              </span>
                              <input
                                type="text"
                                className="form-control bookingrange"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="card-body">
                            <div id="leads-analysis"></div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* <!-- Filter --> */}
                    <div className="d-flex align-items-center justify-content-between flex-wrap mb-4 row-gap-2">
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="dropdown me-2">
                          <a
                            href="javascript:void(0);"
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            <i className="ti ti-sort-ascending-2 me-2"></i>Sort{" "}
                          </a>
                          <div className="dropdown-menu  dropdown-menu-start">
                            <ul>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Ascending
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Descending
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Recently Viewed
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Recently Added
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="icon-form">
                          <span className="form-icon">
                            <i className="ti ti-calendar"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control bookingrange"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="dropdown me-2">
                          <a
                            href="javascript:void(0);"
                            className="btn bg-soft-purple text-purple"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                          >
                            <i className="ti ti-columns-3 me-2"></i>Manage
                            Columns
                          </a>
                          <div className="dropdown-menu  dropdown-menu-md-end dropdown-md p-3">
                            <h4 className="mb-2 fw-semibold">
                              Want to manage datatables?
                            </h4>
                            <p className="mb-3">
                              Please drag and drop your column to reorder your
                              table and enable see option as you want.
                            </p>
                            <div className="border-top pt-3">
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <p className="mb-0 d-flex align-items-center">
                                  <i className="ti ti-grip-vertical me-2"></i>
                                  Task Name
                                </p>
                                <div className="status-toggle">
                                  <input
                                    type="checkbox"
                                    id="col-name"
                                    className="check"
                                  />
                                  <label
                                    for="col-name"
                                    className="checktoggle"
                                  ></label>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <p className="mb-0 d-flex align-items-center">
                                  <i className="ti ti-grip-vertical me-2"></i>
                                  Assigned To
                                </p>
                                <div className="status-toggle">
                                  <input
                                    type="checkbox"
                                    id="col-phone"
                                    className="check"
                                  />
                                  <label
                                    for="col-phone"
                                    className="checktoggle"
                                  ></label>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <p className="mb-0 d-flex align-items-center">
                                  <i className="ti ti-grip-vertical me-2"></i>
                                  Priority
                                </p>
                                <div className="status-toggle">
                                  <input
                                    type="checkbox"
                                    id="col-email"
                                    className="check"
                                  />
                                  <label
                                    for="col-email"
                                    className="checktoggle"
                                  ></label>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <p className="mb-0 d-flex align-items-center">
                                  <i className="ti ti-grip-vertical me-2"></i>
                                  Due Date
                                </p>
                                <div className="status-toggle">
                                  <input
                                    type="checkbox"
                                    id="col-tag"
                                    className="check"
                                  />
                                  <label
                                    for="col-tag"
                                    className="checktoggle"
                                  ></label>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <p className="mb-0 d-flex align-items-center">
                                  <i className="ti ti-grip-vertical me-2"></i>
                                  Type
                                </p>
                                <div className="status-toggle">
                                  <input
                                    type="checkbox"
                                    id="col-loc"
                                    className="check"
                                  />
                                  <label
                                    for="col-loc"
                                    className="checktoggle"
                                  ></label>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <p className="mb-0 d-flex align-items-center">
                                  <i className="ti ti-grip-vertical me-2"></i>
                                  Status
                                </p>
                                <div className="status-toggle">
                                  <input
                                    type="checkbox"
                                    id="col-rate"
                                    className="check"
                                  />
                                  <label
                                    for="col-rate"
                                    className="checktoggle"
                                  ></label>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between mb-3">
                                <p className="mb-0 d-flex align-items-center">
                                  <i className="ti ti-grip-vertical me-2"></i>
                                  Created Date
                                </p>
                                <div className="status-toggle">
                                  <input
                                    type="checkbox"
                                    id="col-owner"
                                    className="check"
                                  />
                                  <label
                                    for="col-owner"
                                    className="checktoggle"
                                  ></label>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="form-sorts dropdown">
                          <a
                            href="javascript:void(0);"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                          >
                            <i className="ti ti-filter-share"></i>Filter
                          </a>
                          <div className="filter-dropdown-menu dropdown-menu  dropdown-menu-md-end p-3">
                            <div className="filter-set-view">
                              <div className="filter-set-head">
                                <h4>
                                  <i className="ti ti-filter-share"></i>Filter
                                </h4>
                              </div>
                              <div className="accordion" id="accordionExample">
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseTwo"
                                      aria-expanded="true"
                                      aria-controls="collapseTwo"
                                    >
                                      Subjects
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse show"
                                    id="collapseTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <div className="mb-2 icon-form">
                                        <span className="form-icon">
                                          <i className="ti ti-search"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Name"
                                        />
                                      </div>
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              SEO Proposals
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Web Design
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Logo & Branding
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Development
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Logo
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#owner"
                                      aria-expanded="false"
                                      aria-controls="owner"
                                    >
                                      Sent to
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="owner"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <div className="mb-2 icon-form">
                                        <span className="form-icon">
                                          <i className="ti ti-search"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Client"
                                        />
                                      </div>
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              NovaWave LLC
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Redwood Inc
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              HarborVie w
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              CoastalStar Co.
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              RiverStone Ventur
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseone"
                                      aria-expanded="false"
                                      aria-controls="collapseone"
                                    >
                                      Date of Proposals
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="collapseone"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              15 May 2024
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              16 Jan 2024
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapsetwo"
                                      aria-expanded="false"
                                      aria-controls="collapsetwo"
                                    >
                                      Open till
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="collapsetwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <div className="mb-2 icon-form">
                                        <span className="form-icon">
                                          <i className="ti ti-search"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Date"
                                        />
                                      </div>
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              15 Aug 2024
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              15 Sep 2024
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseThree"
                                      aria-expanded="false"
                                      aria-controls="collapseThree"
                                    >
                                      Project
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="collapseThree"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              Truelysell
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Dreamsports
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Best@laundry
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Doccure
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapsethree"
                                      aria-expanded="false"
                                      aria-controls="collapsethree"
                                    >
                                      Created Date
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="collapsethree"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              21 May 2024
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              15 Apr 2024
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="filter-reset-btns">
                                <div className="row">
                                  <div className="col-6">
                                    <a href="#" className="btn btn-light">
                                      Reset
                                    </a>
                                  </div>
                                  <div className="col-6">
                                    <a
                                      href="task-reports.html"
                                      className="btn btn-primary"
                                    >
                                      Filter
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Filter --> */}

                    {/* <!-- Report List --> */}
                    <div className="table-responsive custom-table">
                      <table className="table" id="task-reports">
                        <thead className="thead-light">
                          <tr>
                            <th className="no-sort">
                              <label className="checkboxs">
                                <input type="checkbox" id="select-all" />
                                <span className="checkmarks"></span>
                              </label>
                            </th>
                            <th className="no-sort"></th>
                            <th>Task Name</th>
                            <th>Assigned To</th>
                            <th>Priority</th>
                            <th>Due Date</th>
                            <th>Type</th>
                            <th>Status</th>
                            <th>Created Date </th>
                          </tr>
                        </thead>
                        <tbody></tbody>
                      </table>
                    </div>
                    <div className="row align-items-center">
                      <div className="col-md-6">
                        <div className="datatable-length"></div>
                      </div>
                      <div className="col-md-6">
                        <div className="datatable-paginate"></div>
                      </div>
                    </div>
                    {/* <!-- /Report List --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Download Report --> */}
        <div
          className="modal custom-modal fade"
          id="download_report"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Download Report</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form action="#">
                  <div className="mb-3">
                    <label className="form-label">
                      File Type <span className="text-danger">*</span>
                    </label>
                    <select className="select">
                      <option>Download as PDF</option>
                      <option>Download as Excel</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <h5>Filters</h5>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      File Type <span className="text-danger">*</span>
                    </label>
                    <select className="select">
                      <option>All Fields</option>
                      <option>Name</option>
                      <option>Assigned To</option>
                      <option>Priority</option>
                      <option>Status</option>
                      <option>Type</option>
                      <option>Date Created</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Select Year<span className="text-danger">*</span>
                    </label>
                    <select className="select">
                      <option>2023</option>
                      <option>2022</option>
                      <option>2021</option>
                    </select>
                  </div>
                  <div className="col-lg-12 text-end modal-btn">
                    <button
                      type="submit"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Download Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Download Report --> */}
      </div>
    </>
  )
}

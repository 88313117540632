import React, { useEffect, useState } from "react"


const customerGridBox = (gridData, idx,) => {
  // Table Details Page 
  
  let name = gridData?.contactName || gridData?.fullName || gridData?.productName || gridData?.brandName
  let emails = gridData?.emailIds
  let phones = gridData?.phoneNumbers

  let statusName = gridData?.statusName
  let productType = gridData?.productType
  let productCategory = gridData?.productCategory

  let zipCode = gridData?.zipCode // from lead page
  let country = gridData?.countryName // from lead page

  let email = emails?.split(",")  // Email Array
  let phone = phones?.split(",") // Phone Array
  

  return (
    <div className="col-xxl-3 col-xl-4 col-md-6" key={idx}>
      <div className="card border">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between mb-3">
            <div className="d-flex align-items-center">
              <a
                href="contact-details.html"
                className="avatar avatar-md flex-shrink-0 me-2"
              >
                <img src="/img/profiles/avatar-19.jpg" alt="img" />
              </a>
              <div>
                <h6>
                  <p className="fw-medium">{name}</p>
                </h6>
              </div>
            </div>
            
             {/* <div className="outer-action-box">
                      <div
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-original-title="Edit"
                      >
                        <a
                          className="dropdown-item"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvas_add"
                          href="#!"
                          onClick={() => {
                            setFormType(Constant.FORM_TYPE_EDIT)
                            setSelectedData({ ...row })
                          }}
                        >
                          <i className="ti ti-edit text-blue"></i>
                        </a>
                      </div>
            
                      <div
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-original-title="View"
                      >
                        <a
                          className="dropdown-item"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvas_add"
                          href="#"
                          onClick={() => {
                            setFormType(Constant.FORM_TYPE_VIEW)
                            setSelectedData({ ...row })
                          }}
                        >
                          <i class="ti ti-eye text-blue-light"></i>
                        </a>
                      </div>
              </div> */}
            
          </div>
          <div className="d-block">
            <div className="d-flex flex-column mb-3">

              {Array.isArray(email) &&(
                email?.map((email, index) => {
                  return (
                    <div key={index}>
                      <p className="text-default d-inline-flex align-items-center mb-2">
                        <i className="ti ti-mail text-dark me-1"></i> {email}
                      </p>
                    </div>
                  )
                }))
              }
              {Array.isArray(phone) && (
                phone?.map((phone, index) => {
                  return (
                    <div key={index}>
                      <p className="text-default d-inline-flex align-items-center mb-2">
                        <i className="ti ti-phone text-dark me-1"></i> {phone}
                      </p>
                    </div>
                  )
                })
              )}
              {
                productType &&(
                  <div className="d-flex mb-3 flex-column">
                      <p className="text-default d-inline-flex align-items-center mb-2">Product Type : {productType}</p>
                      <p className="text-default d-inline-flex align-items-center mb-2">Product Category : {productCategory}</p>
                  </div>
                )
              }
              {
                zipCode &&(
                  <div className="d-flex mb-3 flex-column">
                      <p className="text-default d-inline-flex align-items-center mb-2">Zip Code : {zipCode}</p>
                      <p className="text-default d-inline-flex align-items-center mb-2">Country: {country}</p>
                  </div>
                )
              }


            </div>
            <div className="d-flex align-items-center">
              <span className="badge badge-tag badge-success-light me-2">
                Collab
              </span>
              <span className="badge badge-tag badge-warning-light">Rated</span>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center mt-3 pt-3 border-top">
            <div className="d-flex align-items-center grid-social-links">
              <a
                href="#"
                className="avatar avatar-xs text-dark rounded-circle me-1"
              >
                <i className="ti ti-mail fs-14"></i>
              </a>
              <a
                href="#"
                className="avatar avatar-xs text-dark rounded-circle me-1"
              >
                <i className="ti ti-phone-check fs-14"></i>
              </a>
              <a
                href="#"
                className="avatar avatar-xs text-dark rounded-circle me-1"
              >
                <i className="ti ti-message-circle-share fs-14"></i>
              </a>
              <a
                href="#"
                className="avatar avatar-xs text-dark rounded-circle me-1"
              >
                <i className="ti ti-brand-skype fs-14"></i>
              </a>
              <a href="#" className="avatar avatar-xs text-dark rounded-circle">
                <i className="ti ti-brand-facebook fs-14"></i>
              </a>
            </div>
            <div className="d-flex align-items-center">
              <div className="set-star text-default me-2">
                <i className="fa fa-star filled me-1"></i>4.2
              </div>
              <a
                href="javascript:void(0);"
                className="avatar avatar-md"
                data-bs-toggle="tooltip"
                data-bs-original-title="Mervin"
                data-bs-placement="top"
              >
                <img src="/img/profiles/avatar-14.jpg" alt="img" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const CustomTableGrid = ({ data }) => {
  const loadmoredata = () => {
    console.log("grid Data", data)
  }
  return (
    <>
      <div className="outer-grid-box">
        <div className="row">
          {data?.map((gridData, idx) => {
            return customerGridBox(gridData, idx,)
          })}
        </div>

        <div className="load-btn text-center">
          <a
            href="javascript:void(0);"
            className="btn btn-primary"
            onClick={loadmoredata}>
            Load More Contacts<i className="ti ti-loader"></i>
          </a>
        </div>
      </div>
    </>
  )
}

export default CustomTableGrid

const Data = [

  {
    id: 1,
    contacts_name: "Collins",
    company_name: "NovaWave LLC",
    company_image: "assets/img/icons/company-icon-01.svg",
    company_address: "Newyork, USA",
    phone: "+1 875455453",
    email: "robertson@example.com",
    created_date: "25 Sep 2023, 01:22 pm",
    owner: ["Hendry", "Two", "Three"],
    source: "Paid Social",
    status: "0",
    teamMember:["Hendry", "Two", "Three"]
  },
  {
    id: 2,
    contacts_name: "Konopelski",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "asfTwo", "Three"]

  },

  {
    id: 3,
    contacts_name: "ravi one",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "asfTwo"]
  },

  {
    id: 4,
    contacts_name: "ravi two",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]
  },

  {
    id: 5,
    contacts_name: "ravi three",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 6,
    contacts_name: "ravi four",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 7,
    contacts_name: "ravi five",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 8,
    contacts_name: "ravi six",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 9,
    contacts_name: "ravi seven",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 10,
    contacts_name: "ravi eight",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 11,
    contacts_name: "ravi nine",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 12,
    contacts_name: "Twelve",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 13,
    contacts_name: "ravi Thirteen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 14,
    contacts_name: "ravi Fourteen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 114,
    contacts_name: "ravi Fifteen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 16,
    contacts_name: "ravi Sixteen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 17,
    contacts_name: "ravi Seventeen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
  },

  {
    id: 18,
    contacts_name: "ravi Eighteen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 19,
    contacts_name: "ravi Ninteen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 20,
    contacts_name: "ravi Twenty",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 21,
    contacts_name: "ravi Twenty",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 22,
    contacts_name: "Collins Two",
    company_name: "NovaWave LLC",
    company_image: "assets/img/icons/company-icon-01.svg",
    company_address: "Newyork, USA",
    phone: "+1 875455453",
    email: "robertson@example.com",
    created_date: "25 Sep 2023, 01:22 pm",
    owner: "Hendry",
    source: "Paid Social",
    status: "0",
    teamMember:["asdasHendry", "Team Member"]

  },
  {
    id: 23,
    contacts_name: "Konopelski",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 24,
    contacts_name: "ravi one",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
  },

  {
    id: 25,
    contacts_name: "ravi two",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
  },

  {
    id: 26,
    contacts_name: "ravi three",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 27,
    contacts_name: "ravi four",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 28,
    contacts_name: "ravi five",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
  },

  {
    id: 29,
    contacts_name: "ravi six",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 30,
    contacts_name: "ravi seven",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 31,
    contacts_name: "ravi eight",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 32,
    contacts_name: "ravi nine",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 33,
    contacts_name: "Twelve",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
  },

  {
    id: 34,
    contacts_name: "ravi Thirteen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 35,
    contacts_name: "ravi Fourteen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 36,
    contacts_name: "ravi Fifteen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 37,
    contacts_name: "ravi Sixteen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 38,
    contacts_name: "ravi Seventeen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
  },

  {
    id: 39,
    contacts_name: "ravi Eighteen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 40,
    contacts_name: "ravi Ninteen",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 41,
    contacts_name: "ravi Twenty",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry", "Team Member"]

  },

  {
    id: 42,
    contacts_name: "ravi Twenty",
    company_name: "BlueSky Industries",
    company_image: "assets/img/icons/company-icon-02.svg",
    company_address: "Winchester, KY",
    phone: "+1 989757485",
    email: "sharon@example.com",
    created_date: "29 Sep 2023, 04:15 pm",
    owner: "Guillory",
    source: "Referrals",
    status: "2",
    teamMember:["asdasHendry"]

  },
]

export default Data
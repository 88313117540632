import React, { useEffect, useRef, useState } from "react"
import "react-perfect-scrollbar/dist/css/styles.css"
import VerticalMenuComponent from "../../../components/VerticalMenuComponent"
import TopMenuComponent from "../../../components/TopMenuComponent"
import { Common, Constant } from "../../../utils"
import { useNavigate } from "react-router"
import CustomTableGrid from "../../../components/custom-table-grid"
import { connect, useDispatch } from "react-redux"
import { useEffectOnce } from "react-use"
import {
  fetchEnquiryList,
  filterEnquiryListData,
  getEnquiryListData,
  getEnquiryListFilterData,
  getEnquiryListLoading,
  resetEnquiryListData,
} from "../../../store/slice/enquiry-slice"
import CustomDataTableComponent from "../../../components/page-components/CustomDataTableComponent"
import AddEnquiry from "../../../components/page-components/AddEnquiry"
import { handleFileUpload } from "../../../utils/common-functions"
import "react-datepicker/dist/react-datepicker.css"
import TopPageHeader from "../../../components/TopPageHeader"
import CustomAlert from "../../../components/custom-alerts/CustomAlert"
import LeadListFilter from "../../../store/filters/lead-list-filter"
import { fetchProductTypeList, getProductTypeListData } from "../../../store/slice/product-type-slice"
import { fetchProductCategoryList, getProductCategoryListData } from "../../../store/slice/product-category-slice"
import { fetchProductList, getProductListData } from "../../../store/slice/product-slice"
import { fetchBrandList, getBrandListData } from "../../../store/slice/brand-slice"
import { fetchCountryList, getCountryListData } from "../../../store/slice/country-slice"
import { fetchStateList, getStateListData } from "../../../store/slice/state-slice"
import { fetchCityList, getCityListData } from "../../../store/slice/city-slice"
import { fetchZipList, getZipListData } from "../../../store/slice/zip-slice"
import { fetchLocationList, getLocationListData } from "../../../store/slice/location-slice"
import { fetchStatusList, getStatusListData } from "../../../store/slice/status-slice"
import { fetchSourceList, getSourceListData } from "../../../store/slice/source-slice"
import DatePicker from "react-datepicker"

const Leads = ({
  isLoading,
  leadListData,
  productTypeListData,
  productCategoryListData,
  productListData,
  brandListData,
  countryListData,
  stateListData,
  cityListData,
  zipListData,
  locationListData,
  sourceListData,
  statusListData,
  filterListData
}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffectOnce(() => {
    Common.checkLogin(navigate)
  })

  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [formType, setFormType] = useState(Constant.FORM_TYPE_NEW)
  const [selectedData, setSelectedData] = useState(null)
  const [isGridView, setIsGridView] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const [alertDetails, setAlertDetails] = useState('')
  const [filterData, setFilterData] = useState({})
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const selectedDateTypeRef = useRef(null)

  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  const filterTable = (event) => {
    setSearchValue(event.target.value)
  }

  useEffect(() => {
    getApiData()
  }, [])

  const getApiData = () => {
    dispatch(fetchEnquiryList())
    dispatch(fetchProductTypeList())
    dispatch(fetchProductCategoryList())
    dispatch(fetchProductList())
    dispatch(fetchBrandList())
    dispatch(fetchCountryList())
    dispatch(fetchStateList())
    dispatch(fetchCityList())
    dispatch(fetchZipList())
    dispatch(fetchLocationList())
    dispatch(fetchStatusList())
    dispatch(fetchSourceList())
  }

  filterListData = filterListData?.filter((item) => {
    const searchLower = searchValue.toLowerCase()
    let name = item?.fullName.toLocaleLowerCase().includes(searchLower)
    let productName = item?.productName.toLocaleLowerCase().includes(searchLower)
    let brandID = item?.brandName.toString().toLocaleLowerCase().includes(searchLower)
    let countryName = item?.countryName.toLocaleLowerCase().includes(searchLower)
    let statusName = item?.statusName.toLocaleLowerCase().includes(searchLower)
    let sourceName = item?.enqSourceName.toLocaleLowerCase().includes(searchLower)
    let createdName = item?.createdByName.toLocaleLowerCase().includes(searchLower)
    let createdDate = item?.createdDate.toLocaleLowerCase().includes(searchLower)

    let filterFromDate = false
    if (selectedDateTypeRef != null && selectedDateTypeRef?.current?.value === 'createdDate') filterFromDate = (fromDate && new Date(item.createdDate) >= new Date(fromDate))
    else if (selectedDateTypeRef != null && selectedDateTypeRef?.current?.value === 'modifiedDate') filterFromDate = (fromDate && new Date(item.modifiedDate) >= new Date(fromDate))

    let filterToDate = false
    if (selectedDateTypeRef != null && selectedDateTypeRef?.current?.value === 'createdDate') filterToDate = (toDate && new Date(item.createdDate) <= new Date(toDate).setDate(toDate.getDate() + 1))
    else if (selectedDateTypeRef != null && selectedDateTypeRef?.current?.value === 'modifiedDate') filterToDate = (toDate && new Date(item.modifiedDate) <= new Date(toDate).setDate(toDate.getDate() + 1))

    return (name || productName || brandID || countryName || statusName || sourceName || createdName || createdDate) && (filterFromDate || !fromDate) && (filterToDate || !toDate)
  })


  const columns = [
    {
      name: "Source",
      sortable: true,
      selector: (row) => row.enqSourceName,
      cell: (row) => (
        <a href="#!" className="text-default">
          {row.enqSourceName}
        </a>
      ),
    },
    {
      name: "Full Name",
      sortable: true,
      selector: (row) => row.fullName,
      cell: (row) => (
        <a href="#!" className="text-default">
          {row.fullName}
        </a>
      ),
    },
    {
      name: "Product",
      sortable: true,
      selector: (row) => row.productName,
      cell: (row) => (
        <a href="#!" className="text-default">
          {row.productName}
        </a>
      ),
    },
    {
      name: "Brand",
      sortable: true,
      selector: (row) => row.brandName,
      cell: (row) => (
        <a href="#!" className="text-default">
          {row.brandName}
        </a>
      ),
    },
    {
      name: "Country",
      sortable: true,
      selector: (row) => row.countryName,
      cell: (row) => (
        <a href="#!" className="text-default">
          {row.countryName}
        </a>
      ),
    },
    {
      name: "Location",
      sortable: true,
      selector: (row) => row.lead_name,
      cell: (row) => (
        <a href="#!" className="text-default">
          {row.lead_name}
        </a>
      ),
    },
    {
      name: "Lead Status",
      selector: (row) => row.statusName,
      sortable: true,
      cell: (row) => {
        let statusClass = ""
        if (row.status === "0") {
          statusClass = "badge-success-light"
        } else if (row.status === "1") {
          statusClass = "badge-purple-light"
        } else if (row.status === "2") {
          statusClass = "badge-danger-light"
        } else {
          statusClass = "bg-warning-light"
        }
        return (
          <span className={`badge badge-tag ${statusClass}`}>
            {row.statusName}
          </span>
        )
      },
    },
    {
      name: "Created By",
      selector: (row) => row.createdByName,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => row.createdDate,
      sortable: true,
      cell: (row) => {
        const formattedDate = Common.getDateSubString(row.createdDate, "yyyy-mm-dd", "DD-MMM-yyyy")
        return (
          <div className="d-flex flex-column">
            <span className="text-default">
              {formattedDate}
            </span>
          </div>
        )
      },
    },
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <div className="outer-action-box">
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-original-title="Edit"
          >
            <a
              className="dropdown-item"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas_add"
              href="#!"
              onClick={() => {
                setFormType(Constant.FORM_TYPE_EDIT)
                setSelectedData({ ...row })
              }}
            >
              <i className="ti ti-edit text-blue"></i>
            </a>
          </div>

          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-original-title="View"
          >
            <a
              className="dropdown-item"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas_add"
              href="#"
              onClick={() => {
                setFormType(Constant.FORM_TYPE_VIEW)
                setSelectedData({ ...row })
              }}
            >
              <i class="ti ti-eye text-blue-light"></i>
            </a>
          </div>
        </div>
      ),
    },
  ]

  const handleExportExcel = () =>{
    Common.exportToExcel(Constant.selectedLeadListColumns, filterListData)
  }

  const handlePrintdata = () =>{
  Common.exportToPrint(Constant.selectedLeadListColumns, filterListData)
  }

  const handleCopyData = () =>{
    Common.handleCopyList(Constant.selectedLeadListColumns, filterListData)
    setShowAlert(true)
    setAlertDetails({
      title:"Success",
      desc:"Table data copied to clipboard!",
      type:"success",
      onOkClick: () =>{
        setShowAlert(false)
      }      
    })
  }
  
  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
      {
        showAlert && (
          <CustomAlert
          alertTitle={alertDetails.title}
          alertDesc={alertDetails.desc}
          onCloseClick={alertDetails.onOkClick}
          customAlertType={alertDetails.type}
          />
        )
      }

      {/* header component  */}
      <VerticalMenuComponent />
      <TopMenuComponent openSideNav={openSideNav} />
        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <TopPageHeader pageTitle={"Lead"} countTitle={filterListData?.length === 0 ? "0" :filterListData?.length} />
                </div>
                {/* <!-- /Page Header --> */}

                <div className="card">
                  <div className="card-header">
                    {/* <!-- Search --> */}
                    <div className="row align-items-center">
                      <div className="col-sm-4">
                        <div className="icon-form mb-3 mb-sm-0">
                          <span className="form-icon">
                            <i className="ti ti-search"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={filterTable}
                          />
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="d-flex align-items-center flex-wrap row-gap-2 justify-content-sm-end">
                          <div className="me-2 bulk-uploads">
                            <div className="outer-upload-box">
                              <i className="ti ti-upload me-2"></i>
                              <input
                                type="file"
                                id="fileUpload"
                                className="file-upload"
                                name="fileUpload"
                                onChange={handleFileUpload}
                                accept=".xls, .xlsx"
                              />
                              <label for="fileUpload">Upload Bulks</label>
                            </div>
                          </div>
                          <div className="dropdown me-2">
                            <a
                              href="javascript:void(0)"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                            >
                              <i className="ti ti-package-export me-2"></i>
                              Export
                            </a>
                            <div className="dropdown-menu  dropdown-menu-end">
                              <ul>
                                {
                                  filterListData?.length >0 ? (
                                    <li onClick={handleExportExcel}>
                                      <a href="javascript:void(0)" className="dropdown-item">
                                        <i className="ti ti-file-type-xls text-green me-1"></i>
                                          Export as Excel{" "}
                                      </a>
                                    </li>
                                  ):(
                                    <li>No Record Founds</li>
                                  )
                                }
                                  {
                                    filterListData?.length >0 ? (
                                    <li onClick={handlePrintdata}>
                                      <a href="javascript:void(0)" className="dropdown-item">
                                      <i class="ti ti-printer text-green me-1"></i>
                                        Print{" "}
                                      </a>
                                    </li>
                                    ):(
                                      <li>No Record Founds</li>
                                    )
                                  }
                                  {
                                    filterListData?.length >0 ? (
                                      <li onClick={handleCopyData}>
                                        <a href="javascript:void(0)" className="dropdown-item">
                                        <i class="ti ti-clipboard-check text-green me-1"></i>
                                          Copy
                                        </a>
                                      </li>
                                    ):(
                                      <li>No Record Found</li>
                                    )
                                  }
                              </ul>
                            </div>
                          </div>
                          <a
                            className="btn btn-primary"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvas_add"
                            onClick={() => setFormType(Constant.FORM_TYPE_NEW)}
                          >
                            <i className="ti ti-square-rounded-plus me-2"></i>
                            Add Lead
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Search --> */}
                  </div>
                  <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2 mb-4">
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="dropdown me-2">
                          <select className="form-select" disabled="" ref={selectedDateTypeRef} onChange={() => {
                            setFromDate('')
                            setToDate('')
                          }}>
                            <option value="createdDate">Created Date</option>
                            <option value="modifiedDate">Modified Date</option>
                          </select>
                        </div>
                        <div className="dropdown me-2">
                          <a href="javascript:void(0);" className="d-flex align-items-center gap-2" style={{color:"#262A2A"}} >
                            From
                            <DatePicker
                                selected={fromDate}
                                onChange={(date) => {
                                    setFromDate(date)
                                    setToDate('')
                                }}
                                maxDate={Common.getTodayDate()}
                                dateFormat="dd-MMM-yyyy" 
                                placeholderText="dd-MMM-yyyy"
                                className="calenderInput"
                            />
                            <span className="form-icon">
                                <i className="ti ti-calendar"></i>
                            </span>
                          </a>
                        </div>
                        <div className="dropdown me-2">
                          <a href="javascript:void(0);" className="d-flex align-items-center gap-2" style={{color:"#262A2A"}}>
                            To
                            <DatePicker
                                selected={toDate}
                                onChange={(date) => {
                                    setToDate(date)
                                }}
                                minDate={fromDate}
                                maxDate={Common.getTodayDate()}
                                dateFormat="dd-MMM-yyyy" 
                                placeholderText="dd-MMM-yyyy"
                                className="calenderInput"
                            />
                            <span className="form-icon">
                                <i className="ti ti-calendar"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    <LeadListFilter
                      isGridView={isGridView}
                      setIsGridView={setIsGridView}
                      filterData={filterData}
                      leadListData={leadListData}
                      productTypeListData={productTypeListData}
                      productCategoryListData={productCategoryListData}
                      productListData={productListData}
                      brandListData={brandListData}
                      countryListData={countryListData}
                      stateListData={stateListData}
                      cityListData={cityListData}
                      zipListData={zipListData}
                      locationListData={locationListData}
                      sourceListData={sourceListData}
                      statusListData={statusListData}
                      onFilterApply={filters => {
                        dispatch(resetEnquiryListData())
                        dispatch(filterEnquiryListData(filters))
                      }}
                      onResetApply={() => window.location.reload()}
                    />
                    </div>

                    {/* <!-- Lead List --> */}
                    {isGridView === 0 ? (
                      <CustomDataTableComponent
                        rowData={filterListData}
                        columns={columns}
                      />
                    ) : (
                      <CustomTableGrid data={filterListData} />
                    )}

                    {/* <!-- /Lead List --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Add Lead --> */}
        <AddEnquiry
          navigate={navigate}
          formType={formType}
          selectedData={selectedData}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  isLoading: getEnquiryListLoading(state),
  leadListData: getEnquiryListData(state),
  productTypeListData: getProductTypeListData(state),
  productCategoryListData: getProductCategoryListData(state),
  productListData: getProductListData(state),
  brandListData: getBrandListData(state),
  countryListData: getCountryListData(state),
  stateListData: getStateListData(state),
  cityListData: getCityListData(state),
  zipListData: getZipListData(state),
  locationListData: getLocationListData(state),
  sourceListData: getSourceListData(state),
  statusListData: getStatusListData(state),
  filterListData: getEnquiryListFilterData(state)
})

export default connect(mapStateToProps)(Leads)

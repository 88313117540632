import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"

export default function Calendar() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav}  />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="row align-items-center w-100">
                <div className="col-lg-10 col-sm-12">
                  <h3 className="page-title">Calendar</h3>
                </div>
                <div className="col-lg-2 col-sm-12 d-flex justify-content-end p-0">
                  <a
                    href="javascript:void(0);"
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#add_event"
                  >
                    Create Event
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-4">
                <h4 className="card-title">Drag & Drop Event</h4>
                <div id="calendar-events" className="mb-3">
                  <div className="calendar-events" data-className="bg-info">
                    <i className="fas fa-circle text-info"></i> My Event One
                  </div>
                  <div className="calendar-events" data-className="bg-success">
                    <i className="fas fa-circle text-success"></i> My Event Two
                  </div>
                  <div className="calendar-events" data-className="bg-danger">
                    <i className="fas fa-circle text-danger"></i> My Event Three
                  </div>
                  <div className="calendar-events" data-className="bg-warning">
                    <i className="fas fa-circle text-warning"></i> My Event Four
                  </div>
                </div>
                <div className="input-block add-lists todo-inbox-check mb-3">
                  <label className="checkboxs">
                    <input type="checkbox" id="drop-remove" />
                    <span className="checkmarks"></span> Remove after drop
                  </label>
                </div>
                <a
                  href="javascript:void(0);"
                  data-bs-toggle="modal"
                  data-bs-target="#add_new_event"
                  className="btn mb-3 btn-primary btn-block w-100"
                >
                  <i className="fas fa-plus"></i> Add Category
                </a>
              </div>
              <div className="col-lg-9 col-md-8">
                <div className="card">
                  <div className="card-body">
                    <div id="calendar"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add Event Modal --> */}
        <div id="add_event" className="modal fade" role="dialog">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Event</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form action="calendar.html">
                  <div className="input-blocks">
                    <label>
                      Event Name <span className="text-danger">*</span>
                    </label>
                    <input className="form-control" type="text" />
                  </div>
                  <div className="input-blocks">
                    <label>
                      Event Date <span className="text-danger">*</span>
                    </label>
                    <div className="cal-icon">
                      <input className="form-control " type="text" />
                    </div>
                  </div>
                  <div className="submit-section">
                    <button
                      type="submit"
                      className="btn btn-primary submit-btn"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Add Event Modal --> */}

        {/* <!-- Add Event Modal --> */}
        <div className="modal custom-modal fade none-border" id="my_event">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Event</h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
              </div>
              <div className="modal-body">
                <form action="calendar.html">
                  <div className="modal-footer justify-content-center">
                    <button
                      type="button"
                      className="btn btn-success save-event submit-btn"
                    >
                      Create event
                    </button>
                    <button
                      type="submit"
                      className="btn btn-danger delete-event submit-btn"
                      data-dismiss="modal"
                    >
                      Delete
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Add Event Modal --> */}

        {/* <!-- Add Category Modal --> */}
        <div className="modal custom-modal fade" id="add_new_event">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title">Add Category</h4>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-hidden="true"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form action="calendar.html">
                  <div className="mb-3">
                    <label className="form-label">Category Name</label>
                    <input
                      className="form-control form-white"
                      placeholder="Enter name"
                      type="text"
                      name="category-name"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Choose Category Color</label>
                    <select
                      className="form-control form-white"
                      data-placeholder="Choose a color..."
                      name="category-color"
                    >
                      <option value="success">Success</option>
                      <option value="danger">Danger</option>
                      <option value="info">Info</option>
                      <option value="primary">Primary</option>
                      <option value="warning">Warning</option>
                      <option value="inverse">Inverse</option>
                    </select>
                  </div>
                  <div className="submit-section">
                    <button
                      type="submit"
                      className="btn btn-primary save-category submit-btn"
                      data-dismiss="modal"
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Add Category Modal --> */}
      </div>
    </>
  )
}

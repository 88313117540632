import React, { useState, useEffect } from "react"
import { Common } from "../utils"

const TopPageHeader = ({pageTitle, countTitle}) => {

        const [isCollapse, setIsCollapse] = useState(false)
    
        const handleCollapse = () =>{
          setIsCollapse(!isCollapse)
        }
    
        useEffect(() =>{
          isCollapse === true ? document.body.classList.add("header-collapse") : document.body.classList.remove("header-collapse")      
        },[isCollapse])

  return (
        
          <div className="row align-items-center ">
            <div className="col-md-4">
              <h3 className="page-title">
                {pageTitle ?? ""} { countTitle && <span className="count-title"> {countTitle ?? ""} </span>} 
              </h3>
            </div>
            <div className="col-md-8 float-end ms-auto">
              <div className="d-flex title-head">
                <div className="daterange-picker d-flex align-items-center justify-content-center">
                  {/* <div className="form-sort me-2">
                    <i className="ti ti-calendar"></i>
                    <input
                      type="text"
                      className="form-control  date-range bookingrange"
                    />
                  </div> */}
                  <div className="head-icons mb-0">
                    <a
                      href="javascript:void(0)"
                      // data-bs-toggle="tooltip"
                      // data-bs-placement="top"
                      // data-bs-original-title="Refresh"
                      onClick={() => Common.handleReloadPage()}
                    >
                      <i className="ti ti-refresh-dot"></i>
                    </a>
                    <a
                      href="javascript:void(0);"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      data-bs-original-title="Collapse"
                      onClick={handleCollapse}
                      className={isCollapse ? "active" : ""}
                    >
                      <i className="ti ti-chevrons-up"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>        
  )
}

export default TopPageHeader

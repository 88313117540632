import React, { useState } from "react"
import "react-perfect-scrollbar/dist/css/styles.css"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"
import TopPageHeader from "../../components/TopPageHeader"

export default function Tasks() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">

                  <TopPageHeader 
                    pageTitle={"task"}
                    countTitle={110}
                  />

                </div>
                {/* <!-- /Page Header --> */}

                <div className="card ">
                  <div className="card-header">
                    {/* <!-- Search --> */}
                    <div className="row align-items-center">
                      <div className="col-sm-4">
                        <div className="icon-form mb-sm-0">
                          <span className="form-icon">
                            <i className="ti ti-search"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Task"
                          />
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="d-flex align-items-center flex-wrap row-gap-2 justify-content-sm-end mb-3 mb-sm-0">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvas_add"
                          >
                            <i className="ti ti-square-rounded-plus me-2"></i>
                            Add New Tasks
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Search --> */}
                  </div>
                  <div className="card-body">
                    {/* <!-- Filter --> */}
                    <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2 mb-4">
                      <div className="sort-dropdown drop-down task-drops">
                        <a
                          href="javascript:void(0);"
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          All Tasks{" "}
                        </a>
                        <div className="dropdown-menu  dropdown-menu-start">
                          <ul>
                            <li>
                              <a href="tasks">
                                <i className="ti ti-dots-vertical"></i>All Tasks
                              </a>
                            </li>
                            <li>
                              <a href="tasks-important">
                                <i className="ti ti-dots-vertical"></i>Important
                              </a>
                            </li>
                            <li>
                              <a href="tasks-completed">
                                <i className="ti ti-dots-vertical"></i>Completed
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="all-read me-2 ">
                          <label className="checkboxs">
                            <input type="checkbox" checked />
                            <span className="checkmarks"></span>
                            Mark all as read
                          </label>
                        </div>
                        <div className="dropdown me-2">
                          <a
                            href="javascript:void(0);"
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            <i className="ti ti-sort-ascending-2 me-2"></i>Sort{" "}
                          </a>
                          <div className="dropdown-menu  dropdown-menu-start">
                            <ul>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Ascending
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Descending
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Recently Viewed
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Recently Added
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="icon-form me-2">
                          <span className="form-icon">
                            <i className="ti ti-calendar"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control bookingrange"
                            placeholder=""
                          />
                        </div>
                        <div className="form-sorts dropdown">
                          <a
                            href="javascript:void(0);"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                          >
                            <i className="ti ti-filter-share"></i>Filter
                          </a>
                          <div className="filter-dropdown-menu dropdown-menu  dropdown-menu-md-end p-3">
                            <div className="filter-set-view">
                              <div className="filter-set-head">
                                <h4>
                                  <i className="ti ti-filter-share"></i>Filter
                                </h4>
                              </div>
                              <div className="accordion" id="accordionExample">
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseTwo"
                                      aria-expanded="true"
                                      aria-controls="collapseTwo"
                                    >
                                      Task Name
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse show"
                                    id="collapseTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <div className="mb-2 icon-form">
                                        <span className="form-icon">
                                          <i className="ti ti-search"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Task"
                                        />
                                      </div>
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              Add a form to Update Task
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Add a form to Update Task
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Update orginal content
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Use only component colours
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#owner"
                                      aria-expanded="false"
                                      aria-controls="owner"
                                    >
                                      Task Type
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="owner"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <div className="mb-2 icon-form">
                                        <span className="form-icon">
                                          <i className="ti ti-search"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Client"
                                        />
                                      </div>
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              Meeting
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Calls
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Task
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Email
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseone"
                                      aria-expanded="false"
                                      aria-controls="collapseone"
                                    >
                                      Tags
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="collapseone"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              Collab
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Rated
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Promotion
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseThree"
                                      aria-expanded="false"
                                      aria-controls="collapseThree"
                                    >
                                      Created By
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="collapseThree"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              Hendry
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Monty Beer
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Bradtke
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapsethree"
                                      aria-expanded="false"
                                      aria-controls="collapsethree"
                                    >
                                      Created Date
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="collapsethree"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              23 Oct 2023
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              29 Sep 2023
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              24 Oct 2023
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              25 Oct 2023
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="filter-reset-btns">
                                <div className="row">
                                  <div className="col-6">
                                    <a href="#" className="btn btn-light">
                                      Reset
                                    </a>
                                  </div>
                                  <div className="col-6">
                                    <a
                                      href="tasks"
                                      className="btn btn-primary"
                                    >
                                      Filter
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Filter --> */}

                    {/* <!-- Recent Task List --> */}
                    <div className="task-wrapper">
                      <a
                        href="#"
                        className="task-accordion"
                        data-bs-toggle="collapse"
                        data-bs-target="#recent"
                      >
                        <h4>
                          Recent<span>24</span>
                        </h4>
                      </a>
                      <div
                        className="tasks-activity tasks collapse show"
                        id="recent"
                      >
                        <ul>
                          <li className="task-wrap pending">
                            <div className="task-info">
                              <span className="task-icon">
                                <i className="ti ti-grip-vertical"></i>
                              </span>
                              <div className="task-checkbox">
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </div>
                              <div className="set-star rating-select">
                                <i className="fa fa-star"></i>
                              </div>
                              <p>Add a form to Update Task</p>
                              <span className="badge badge-pill badge-status bg-green">
                                <i className="ti ti-phone"></i>Calls
                              </span>
                              <span className="badge badge-tag bg-pending">
                                Pending
                              </span>
                            </div>
                            <div className="task-actions">
                              <ul>
                                <li className="task-time">
                                  <span className="badge badge-tag badge-purple-light">
                                    Promotion
                                  </span>
                                </li>
                                <li className="task-date">
                                  <i className="ti ti-calendar-exclamation"></i>
                                  25 Oct 2023
                                </li>
                                <li className="task-owner">
                                  <div className="task-user">
                                    <img
                                      src="/img/profiles/avatar-14.jpg"
                                      alt="img"
                                    />
                                  </div>
                                  <div className="dropdown table-action">
                                    <a
                                      href="#"
                                      className="action-icon "
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fa fa-ellipsis-v"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        data-bs-toggle="offcanvas"
                                        href="#"
                                        data-bs-target="#offcanvas_edit"
                                      >
                                        <i className="ti ti-edit text-blue"></i>{" "}
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete_activity"
                                      >
                                        <i className="ti ti-trash text-danger"></i>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li className="task-wrap pending">
                            <div className="task-info">
                              <span className="task-icon">
                                <i className="ti ti-grip-vertical"></i>
                              </span>
                              <div className="task-checkbox">
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </div>
                              <div className="set-star rating-select">
                                <i className="fa fa-star"></i>
                              </div>
                              <p>Make all strokes thinner</p>
                              <span className="badge activity-badge bg-warning">
                                <i className="ti ti-mail"></i> Email
                              </span>
                              <span className="badge badge-tag bg-pending">
                                Pending
                              </span>
                            </div>
                            <div className="task-actions">
                              <ul>
                                <li className="task-time">
                                  <span className="badge badge-tag badge-danger-light">
                                    Rejected
                                  </span>
                                  <span className="badge badge-tag badge-success-light">
                                    Collab
                                  </span>
                                </li>
                                <li className="task-date">
                                  <i className="ti ti-calendar-exclamation"></i>
                                  25 Oct 2023
                                </li>
                                <li className="task-owner">
                                  <div className="task-user">
                                    <img
                                      src="/img/profiles/avatar-14.jpg"
                                      alt="img"
                                    />
                                  </div>
                                  <div className="dropdown table-action">
                                    <a
                                      href="#"
                                      className="action-icon "
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fa fa-ellipsis-v"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        data-bs-toggle="offcanvas"
                                        href="#"
                                        data-bs-target="#offcanvas_edit"
                                      >
                                        <i className="ti ti-edit text-blue"></i>{" "}
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete_activity"
                                      >
                                        <i className="ti ti-trash text-danger"></i>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li className="task-wrap  warning">
                            <div className="task-info">
                              <span className="task-icon">
                                <i className="ti ti-grip-vertical"></i>
                              </span>
                              <div className="task-checkbox">
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </div>
                              <div className="set-star rating-select">
                                <i className="fa fa-star"></i>
                              </div>
                              <p>Update orginal content</p>
                              <span className="badge badge-pill badge-status bg-green">
                                <i className="ti ti-phone"></i>Calls
                              </span>
                              <span className="badge badge-tag bg-warning">
                                Inprogress
                              </span>
                            </div>
                            <div className="task-actions">
                              <ul>
                                <li className="task-time">
                                  <span className="badge badge-tag badge-purple-light">
                                    Promotion
                                  </span>
                                </li>
                                <li className="task-date">
                                  <i className="ti ti-calendar-exclamation"></i>
                                  25 Oct 2023
                                </li>
                                <li className="task-owner">
                                  <div className="task-user">
                                    <img
                                      src="/img/profiles/avatar-14.jpg"
                                      alt="img"
                                    />
                                  </div>
                                  <div className="dropdown table-action">
                                    <a
                                      href="#"
                                      className="action-icon "
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fa fa-ellipsis-v"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        data-bs-toggle="offcanvas"
                                        href="#"
                                        data-bs-target="#offcanvas_edit"
                                      >
                                        <i className="ti ti-edit text-blue"></i>{" "}
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete_activity"
                                      >
                                        <i className="ti ti-trash text-danger"></i>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li className="task-wrap warning">
                            <div className="task-info">
                              <span className="task-icon">
                                <i className="ti ti-grip-vertical"></i>
                              </span>
                              <div className="task-checkbox">
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </div>
                              <div className="set-star rating-select">
                                <i className="fa fa-star"></i>
                              </div>
                              <p>Use only component colours</p>
                              <span className="badge activity-badge bg-blue">
                                <i className="ti ti-subtask"></i> Task
                              </span>
                              <span className="badge badge-tag bg-warning">
                                Inprogress
                              </span>
                            </div>
                            <div className="task-actions">
                              <ul>
                                <li className="task-time">
                                  <span className="badge badge-tag badge-success-light">
                                    Collab
                                  </span>
                                  <span className="badge badge-tag badge-warning-light">
                                    Rated
                                  </span>
                                </li>
                                <li className="task-date">
                                  <i className="ti ti-calendar-exclamation"></i>
                                  25 Oct 2023
                                </li>
                                <li className="task-owner">
                                  <div className="task-user">
                                    <img
                                      src="/img/profiles/avatar-14.jpg"
                                      alt="img"
                                    />
                                  </div>
                                  <div className="dropdown table-action">
                                    <a
                                      href="#"
                                      className="action-icon "
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fa fa-ellipsis-v"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        data-bs-toggle="offcanvas"
                                        href="#"
                                        data-bs-target="#offcanvas_edit"
                                      >
                                        <i className="ti ti-edit text-blue"></i>{" "}
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete_activity"
                                      >
                                        <i className="ti ti-trash text-danger"></i>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <!-- /Recent Task List --> */}

                    {/* <!-- Task List --> */}
                    <div className="task-wrapper">
                      <a
                        href="#"
                        className="task-accordion"
                        data-bs-toggle="collapse"
                        data-bs-target="#yesterday"
                      >
                        <h4>Yesterday</h4>
                      </a>
                      <div
                        className="tasks-activity tasks collapse show"
                        id="yesterday"
                      >
                        <ul>
                          <li className="task-wrap  warning">
                            <div className="task-info">
                              <span className="task-icon">
                                <i className="ti ti-grip-vertical"></i>
                              </span>
                              <div className="task-checkbox">
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </div>
                              <div className="set-star rating-select">
                                <i className="fa fa-star"></i>
                              </div>
                              <p>Add images to the cards section</p>
                              <span className="badge badge-pill badge-status bg-green">
                                <i className="ti ti-phone"></i>Calls
                              </span>
                              <span className="badge badge-tag bg-warning">
                                Inprogress
                              </span>
                            </div>
                            <div className="task-actions">
                              <ul>
                                <li className="task-time">
                                  <span className="badge badge-tag badge-success-light">
                                    Collab
                                  </span>
                                  <span className="badge badge-tag badge-warning-light">
                                    Rated
                                  </span>
                                </li>
                                <li className="task-date">
                                  <i className="ti ti-calendar-exclamation"></i>
                                  24 Oct 2023
                                </li>
                                <li className="task-owner">
                                  <div className="task-user">
                                    <img
                                      src="/img/profiles/avatar-14.jpg"
                                      alt="img"
                                    />
                                  </div>
                                  <div className="dropdown table-action">
                                    <a
                                      href="#"
                                      className="action-icon "
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fa fa-ellipsis-v"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        data-bs-toggle="offcanvas"
                                        href="#"
                                        data-bs-target="#offcanvas_edit"
                                      >
                                        <i className="ti ti-edit text-blue"></i>{" "}
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete_activity"
                                      >
                                        <i className="ti ti-trash text-danger"></i>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <!-- /Task List --> */}

                    {/* <!-- Task List --> */}
                    <div className="task-wrapper">
                      <a
                        href="#"
                        className="task-accordion"
                        data-bs-toggle="collapse"
                        data-bs-target="#date-01"
                      >
                        <h4>23 Oct 2023</h4>
                      </a>
                      <div
                        className="tasks-activity tasks collapse show"
                        id="date-01"
                      >
                        <ul>
                          <li className="task-wrap warning">
                            <div className="task-info">
                              <span className="task-icon">
                                <i className="ti ti-grip-vertical"></i>
                              </span>
                              <div className="task-checkbox">
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </div>
                              <div className="set-star rating-select">
                                <i className="fa fa-star"></i>
                              </div>
                              <p>Design description banner & landing page</p>
                              <span className="badge activity-badge bg-blue">
                                <i className="ti ti-subtask"></i> Task
                              </span>
                              <span className="badge badge-tag bg-warning">
                                Inprogress
                              </span>
                            </div>
                            <div className="task-actions">
                              <ul>
                                <li className="task-time">
                                  <span className="badge badge-tag badge-success-light">
                                    Collab
                                  </span>
                                  <span className="badge badge-tag badge-warning-light">
                                    Rated
                                  </span>
                                </li>
                                <li className="task-date">
                                  <i className="ti ti-calendar-exclamation"></i>
                                  23 Oct 2023
                                </li>
                                <li className="task-owner">
                                  <div className="task-user">
                                    <img
                                      src="/img/profiles/avatar-14.jpg"
                                      alt="img"
                                    />
                                  </div>
                                  <div className="dropdown table-action">
                                    <a
                                      href="#"
                                      className="action-icon "
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fa fa-ellipsis-v"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        data-bs-toggle="offcanvas"
                                        href="#"
                                        data-bs-target="#offcanvas_edit"
                                      >
                                        <i className="ti ti-edit text-blue"></i>{" "}
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete_activity"
                                      >
                                        <i className="ti ti-trash text-danger"></i>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                          <li className="task-wrap  success">
                            <div className="task-info">
                              <span className="task-icon">
                                <i className="ti ti-grip-vertical"></i>
                              </span>
                              <div className="task-checkbox">
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </div>
                              <div className="set-star rating-select">
                                <i className="fa fa-star"></i>
                              </div>
                              <p>
                                <del>
                                  Make sure all the padding should be 24px
                                </del>
                              </p>
                              <span className="badge badge-pill badge-status bg-green">
                                <i className="ti ti-phone"></i>Calls
                              </span>
                              <span className="badge badge-tag bg-success">
                                Completed
                              </span>
                            </div>
                            <div className="task-actions">
                              <ul>
                                <li className="task-time">
                                  <span className="badge badge-tag badge-purple-light">
                                    Promotion
                                  </span>
                                </li>
                                <li className="task-date">
                                  <i className="ti ti-calendar-exclamation"></i>
                                  23 Oct 2023
                                </li>
                                <li className="task-owner">
                                  <div className="task-user">
                                    <img
                                      src="/img/profiles/avatar-14.jpg"
                                      alt="img"
                                    />
                                  </div>
                                  <div className="dropdown table-action">
                                    <a
                                      href="#"
                                      className="action-icon "
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fa fa-ellipsis-v"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        data-bs-toggle="offcanvas"
                                        href="#"
                                        data-bs-target="#offcanvas_edit"
                                      >
                                        <i className="ti ti-edit text-blue"></i>{" "}
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete_activity"
                                      >
                                        <i className="ti ti-trash text-danger"></i>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <!-- /Task List --> */}

                    {/* <!-- Task List --> */}
                    <div className="task-wrapper">
                      <a
                        href="#"
                        className="task-accordion"
                        data-bs-toggle="collapse"
                        data-bs-target="#date-02"
                      >
                        <h4>22 Oct 2023</h4>
                      </a>
                      <div
                        className="tasks-activity tasks collapse show"
                        id="date-02"
                      >
                        <ul>
                          <li className="task-wrap  success">
                            <div className="task-info">
                              <span className="task-icon">
                                <i className="ti ti-grip-vertical"></i>
                              </span>
                              <div className="task-checkbox">
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </div>
                              <div className="set-star rating-select">
                                <i className="fa fa-star"></i>
                              </div>
                              <p>
                                <del>Use border radius as 5px or 10 px</del>
                              </p>
                              <span className="badge badge-pill badge-status bg-purple">
                                <i className="ti ti-user-share"></i>Meeting
                              </span>
                              <span className="badge badge-tag bg-success">
                                Completed
                              </span>
                            </div>
                            <div className="task-actions">
                              <ul>
                                <li className="task-time">
                                  <span className="badge badge-tag badge-danger-light">
                                    Rejected
                                  </span>
                                  <span className="badge badge-tag badge-success-light">
                                    Collab
                                  </span>
                                </li>
                                <li className="task-date">
                                  <i className="ti ti-calendar-exclamation"></i>
                                  22 Oct 2023
                                </li>
                                <li className="task-owner">
                                  <div className="task-user">
                                    <img
                                      src="/img/profiles/avatar-14.jpg"
                                      alt="img"
                                    />
                                  </div>
                                  <div className="dropdown table-action">
                                    <a
                                      href="#"
                                      className="action-icon "
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="fa fa-ellipsis-v"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        data-bs-toggle="offcanvas"
                                        href="#"
                                        data-bs-target="#offcanvas_edit"
                                      >
                                        <i className="ti ti-edit text-blue"></i>{" "}
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="#"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete_activity"
                                      >
                                        <i className="ti ti-trash text-danger"></i>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <!-- /Task List --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add New Task --> */}
        <div
          className="offcanvas offcanvas-end offcanvas-large"
          tabindex="-1"
          id="offcanvas_add"
        >
          <div className="offcanvas-header border-bottom">
            <h4>Add New Task</h4>
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <i className="ti ti-x"></i>
            </button>
          </div>
          <div className="offcanvas-body">
            <form action="tasks">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="col-form-label">
                        Title <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" />
                    </div>
                    <div className="mb-3">
                      <label className="col-form-label">Category</label>
                      <select className="select">
                        <option>Choose</option>
                        <option>Calls</option>
                        <option>Email</option>
                        <option>Email</option>
                        <option>Meeting</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="col-form-label">
                        Responsible Persons{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <select className="multiple-img" multiple="multiple">
                        <option
                          data-image="/img/profiles/avatar-19.jpg"
                          selected
                        >
                          Darlee Robertson
                        </option>
                        <option data-image="/img/profiles/avatar-20.jpg">
                          Sharon Roy
                        </option>
                        <option data-image="/img/profiles/avatar-21.jpg">
                          Vaughan
                        </option>
                        <option data-image="/img/profiles/avatar-23.jpg">
                          Jessica
                        </option>
                        <option data-image="/img/profiles/avatar-16.jpg">
                          Carol Thomas
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Start Date <span className="text-danger">*</span>
                    </label>
                    <div className="mb-3 icon-form">
                      <span className="form-icon">
                        <i className="ti ti-calendar-check"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control datetimepicker"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Due Date <span className="text-danger">*</span>
                    </label>
                    <div className="mb-3 icon-form">
                      <span className="form-icon">
                        <i className="ti ti-calendar-check"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control datetimepicker"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="col-form-label">
                        Tags <span className="text-danger">*</span>
                      </label>
                      <input
                        className="input-tags form-control"
                        type="text"
                        data-role="tagsinput"
                        name="Label"
                        value="Promotion, Collab"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="col-form-label">Priority</label>
                      <div className="select-priority">
                        <span className="select-icon">
                          <i className="ti ti-square-rounded-filled"></i>
                        </span>
                        <select className="select">
                          <option>Select</option>
                          <option>High</option>
                          <option>Low</option>
                          <option>Medium</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="col-form-label">Status</label>
                      <select className="select">
                        <option selected>Active</option>
                        <option>Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="col-form-label">
                        Description <span className="text-danger">*</span>
                      </label>
                      <div className="summernote"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  data-bs-dismiss="offcanvas"
                  className="btn btn-light me-2"
                >
                  Cancel
                </button>
                <button type="button" className="btn btn-primary">
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* <!-- /Add New Task --> */}

        {/* <!-- Edit Task --> */}
        <div
          className="offcanvas offcanvas-end offcanvas-large"
          tabindex="-1"
          id="offcanvas_edit"
        >
          <div className="offcanvas-header border-bottom">
            <h4>Edit Task</h4>
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <i className="ti ti-x"></i>
            </button>
          </div>
          <div className="offcanvas-body">
            <form action="tasks">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="col-form-label">
                        Title <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value="Add a form to Update Task"
                      />
                    </div>
                    <div className="mb-3">
                      <label className="col-form-label">Category</label>
                      <select className="select">
                        <option>Choose</option>
                        <option>Calls</option>
                        <option>Email</option>
                        <option>Email</option>
                        <option>Meeting</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="col-form-label">
                        Responsible Persons{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <select className="multiple-img" multiple="multiple">
                        <option
                          data-image="/img/profiles/avatar-19.jpg"
                          selected
                        >
                          Darlee Robertson
                        </option>
                        <option data-image="/img/profiles/avatar-20.jpg">
                          Sharon Roy
                        </option>
                        <option data-image="/img/profiles/avatar-21.jpg">
                          Vaughan
                        </option>
                        <option data-image="/img/profiles/avatar-23.jpg">
                          Jessica
                        </option>
                        <option data-image="/img/profiles/avatar-16.jpg">
                          Carol Thomas
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Start Date <span className="text-danger">*</span>
                    </label>
                    <div className="mb-3 icon-form">
                      <span className="form-icon">
                        <i className="ti ti-calendar-check"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control datetimepicker"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Due Date <span className="text-danger">*</span>
                    </label>
                    <div className="mb-3 icon-form">
                      <span className="form-icon">
                        <i className="ti ti-calendar-check"></i>
                      </span>
                      <input
                        type="text"
                        className="form-control datetimepicker"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="col-form-label">
                        Tags <span className="text-danger">*</span>
                      </label>
                      <input
                        className="input-tags form-control"
                        type="text"
                        data-role="tagsinput"
                        name="Label"
                        value="Promotion, Collab"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="col-form-label">Priority</label>
                      <div className="select-priority">
                        <span className="select-icon">
                          <i className="ti ti-square-rounded-filled"></i>
                        </span>
                        <select className="select">
                          <option>Select</option>
                          <option>High</option>
                          <option>Low</option>
                          <option>Medium</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="mb-3">
                      <label className="col-form-label">Status</label>
                      <select className="select">
                        <option selected>Active</option>
                        <option>Inactive</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="col-form-label">
                        Description <span className="text-danger">*</span>
                      </label>
                      <div className="summernote"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  data-bs-dismiss="offcanvas"
                  className="btn btn-light me-2"
                >
                  Cancel
                </button>
                <button type="button" className="btn btn-primary">
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
        {/* <!-- /Edit Task --> */}

        {/* <!-- Delete Task --> */}
        <div className="modal fade" id="delete_activity" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  <div className="avatar avatar-xl bg-danger-light rounded-circle mb-3">
                    <i className="ti ti-trash-x fs-36 text-danger"></i>
                  </div>
                  <h4 className="mb-2">Remove Task?</h4>
                  <p className="mb-0">
                    Are you sure you want to remove <br /> task you selected.
                  </p>
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <a href="tasks" className="btn btn-danger">
                      Yes, Delete it
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Delete Task --> */}

        {/* <!-- Add New View --> */}
        <div className="modal custom-modal fade" id="save_view" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add New View</h5>
                <button
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x"></i>
                </button>
              </div>
              <div className="modal-body">
                <form action="campaign">
                  <div className="mb-3">
                    <label className="col-form-label">View Name</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="modal-btn text-end">
                    <a
                      href="#"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-danger">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Add New View --> */}
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react"
import "react-perfect-scrollbar/dist/css/styles.css"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"
import { Common } from "../../utils"
import { useNavigate } from "react-router"
import CustomDataTableComponent from "../../components/page-components/CustomDataTableComponent"
import { AgCharts } from "ag-charts-react"
import YearChart from "../../components/page-components/charts/YearChart"
import HorizontalStageChart from "../../components/page-components/charts/HorizontalStageChart"
import TopPageHeader from "../../components/TopPageHeader"


export default function Dashboard() {

  const navigate = useNavigate()

  useEffect(() => {
    Common.checkLogin(navigate)
  })

  const Data = [

    {
      id: 1,
      contacts_name: "Collins",
      company_name: "NovaWave LLC",
      company_image: "assets/img/icons/company-icon-01.svg",
      company_address: "Newyork, USA",
      phone: "+1 875455453",
      email: "robertson@example.com",
      created_date: "25 Sep 2023, 01:22 pm",
      owner: ["Hendry", "Two", "Three"],
      source: "Paid Social",
      status: "0",
      teamMember:["Hendry", "Two", "Three"]
    },
    {
      id: 2,
      contacts_name: "Konopelski",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "asfTwo", "Three"]
  
    },
  
    {
      id: 3,
      contacts_name: "ravi one",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "asfTwo"]
    },
  
    {
      id: 4,
      contacts_name: "ravi two",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
    },
  
    {
      id: 5,
      contacts_name: "ravi three",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 6,
      contacts_name: "ravi four",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 7,
      contacts_name: "ravi five",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 8,
      contacts_name: "ravi six",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 9,
      contacts_name: "ravi seven",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 10,
      contacts_name: "ravi eight",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 11,
      contacts_name: "ravi nine",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 12,
      contacts_name: "Twelve",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 13,
      contacts_name: "ravi Thirteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 14,
      contacts_name: "ravi Fourteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 114,
      contacts_name: "ravi Fifteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 16,
      contacts_name: "ravi Sixteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 17,
      contacts_name: "ravi Seventeen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },
  
    {
      id: 18,
      contacts_name: "ravi Eighteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 19,
      contacts_name: "ravi Ninteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 20,
      contacts_name: "ravi Twenty",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 21,
      contacts_name: "ravi Twenty",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 22,
      contacts_name: "Collins Two",
      company_name: "NovaWave LLC",
      company_image: "assets/img/icons/company-icon-01.svg",
      company_address: "Newyork, USA",
      phone: "+1 875455453",
      email: "robertson@example.com",
      created_date: "25 Sep 2023, 01:22 pm",
      owner: "Hendry",
      source: "Paid Social",
      status: "0",
      teamMember:["asdasHendry", "Team Member"]
  
    },
    {
      id: 23,
      contacts_name: "Konopelski",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 24,
      contacts_name: "ravi one",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },
  
    {
      id: 25,
      contacts_name: "ravi two",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },
  
    {
      id: 26,
      contacts_name: "ravi three",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 27,
      contacts_name: "ravi four",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 28,
      contacts_name: "ravi five",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },
  
    {
      id: 29,
      contacts_name: "ravi six",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 30,
      contacts_name: "ravi seven",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 31,
      contacts_name: "ravi eight",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 32,
      contacts_name: "ravi nine",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 33,
      contacts_name: "Twelve",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },
  
    {
      id: 34,
      contacts_name: "ravi Thirteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 35,
      contacts_name: "ravi Fourteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 36,
      contacts_name: "ravi Fifteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 37,
      contacts_name: "ravi Sixteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 38,
      contacts_name: "ravi Seventeen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },
  
    {
      id: 39,
      contacts_name: "ravi Eighteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 40,
      contacts_name: "ravi Ninteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 41,
      contacts_name: "ravi Twenty",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry", "Team Member"]
  
    },
  
    {
      id: 42,
      contacts_name: "ravi Twenty",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
      teamMember:["asdasHendry"]
  
    },
  ]

  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }


   const columns = [    
      {
        name: "Deal Name",
        selector: (row) => row.contacts_name,
        cell: (row) => (
          <div className="d-flex flex-column">
            <span className="text-default">{row.contacts_name}</span>
          </div>
        ),
        sortable: true,
      },
  
      {
        name: "Stage",
        selector: (row) => row.company_name,
        cell: (row) => (
          <div className="d-flex flex-column">
            <span className="text-default">{row.company_name}</span>            
          </div>
        ),
        sortable: true,
      },
  
      {
        name: "Deal Value",
        selector: (row) => row.phone,
        sortable: true,
      },
  
      {
        name: "Probability",
        selector: (row) => row.phone,
        sortable: true,
      },
  
      {
        name: "Project Status",
        selector: (row) => row.status,
        sortable: true,
        cell: (row) => {
          let statusClass = ""
          let statusName = ""
          if (row.status === "0") {
            statusClass = "badge-success-light"
            statusName = "Closed"
          } else if (row.status === "1") {
            statusClass = "badge-purple-light"
            statusName = "Lost"
          } else if (row.status === "2") {
            statusClass = "badge-danger-light"
            statusName = "Not Contacted"
          } else {
            statusClass = ["badge-tag", "bg-warning-light"]
            statusName = "Contacted"
          }
          return (
            <span className={`badge badge-tag ${statusClass}`}>{statusName}</span>
          )
        },
      },     
    ]

    // Bar Chart Options 
    const [options, setOptions] = useState({      
      data: [
        { month: "In Pipeline", avgTemp: 5.3, iceCreamSales:50},
        { month: "Follow Up", avgTemp: 6.3, iceCreamSales:100},
        { month: "Schedule", avgTemp: 16.2, iceCreamSales:200},
        { month: "Conversation", avgTemp: 22.8, iceCreamSales:300},
        { month: "Won", avgTemp: 14.5, iceCreamSales:400},
        { month: "Lost", avgTemp: 8.9, iceCreamSales:500},
      ],      
      series: [{ type: "bar", xKey: "month", yKey: "iceCreamSales" }],
    })
    
  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>      
      {/* header component  */}
        <VerticalMenuComponent/>
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper" style={{ minHeight: "451px" }}>
          <div className="content">
            <div className="row">
                <div className="col-md-12">
                    <div className="page-header">
                        <TopPageHeader pageTitle="Deals Dashboard"/>
                    </div>
                </div>
            </div>

            <div className="row">
              <div className="col-md-6 d-flex">
                <div className="card flex-fill">
                  <div className="card-header border-0 pb-0">
                    <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                      <h4>
                        <i className="ti ti-grip-vertical me-1"></i>Recently Created Deals
                      </h4>
                      <div className="dropdown">
                        <a
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          href="javascript:void(0);"
                        >
                          <i className="ti ti-calendar-check me-2"></i>Last 30
                          days
                        </a>
                        <div className="dropdown-menu dropdown-menu-end">
                          <a
                            href="javascript:void(0);"
                            className="dropdown-item"
                          >
                            Last 15 days
                          </a>
                          <a
                            href="javascript:void(0);"
                            className="dropdown-item"
                          >
                            Last 30 days
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                     <CustomDataTableComponent
                      rowData={Data.splice(0, 6)}
                      columns={columns}
                      rowsSix={6}
                     />
                    {/* <div className="table-responsive custom-table">
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex">
                <div className="card flex-fill">
                  <div className="card-header border-0 pb-0">
                    <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                      <h4>
                        <i className="ti ti-grip-vertical me-1"></i>Deals By
                        Stage
                      </h4>
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="dropdown me-2">
                          <a
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            href="javascript:void(0);"
                          >
                            Sales Pipeline
                          </a>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Marketing Pipeline
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Sales Pipeline
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Email
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Chats
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Operational
                            </a>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            href="javascript:void(0);"
                          >
                            Last 30 Days
                          </a>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Last 30 Days
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Last 15 Days
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Last 7 Days
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    {/* Bar chart  */}                    
                      <AgCharts options={options}/>                                          
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 d-flex">
                <div className="card flex-fill">
                  <div className="card-header border-0 pb-0">
                    <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                      <h4>
                        <i className="ti ti-grip-vertical me-1"></i>Leads By
                        Stage
                      </h4>
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="dropdown me-2">
                          <a
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            href="javascript:void(0);"
                          >
                            Marketing Pipeline
                          </a>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Marketing Pipeline
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Sales Pipeline
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Email
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Chats
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Operational
                            </a>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            href="javascript:void(0);"
                          >
                            Last 3 months
                          </a>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Last 3 months
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Last 6 months
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Last 12 months
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">                    
                    <HorizontalStageChart/>               
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex">
                <div className="card flex-fill">
                  <div className="card-header border-0 pb-0">
                    <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                      <h4>
                        <i className="ti ti-grip-vertical me-1"></i>Won Deals
                        Stage
                      </h4>
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="dropdown me-2">
                          <a
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            href="javascript:void(0);"
                          >
                            Marketing Pipeline
                          </a>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Marketing Pipeline
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Sales Pipeline
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Email
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Chats
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Operational
                            </a>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            href="javascript:void(0);"
                          >
                            Last 3 months
                          </a>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Last 3 months
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Last 6 months
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Last 12 months
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">                    
                    <HorizontalStageChart/>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 d-flex">
                <div className="card w-100">
                  <div className="card-header border-0 pb-0">
                    <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                      <h4>
                        <i className="ti ti-grip-vertical me-1"></i>Deals by
                        Year
                      </h4>
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="dropdown me-2">
                          <a
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            href="javascript:void(0);"
                          >
                            Sales Pipeline
                          </a>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Marketing Pipeline
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Sales Pipeline
                            </a>
                          </div>
                        </div>
                        <div className="dropdown">
                          <a
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                            href="javascript:void(0);"
                          >
                            Last 3 months
                          </a>
                          <div className="dropdown-menu dropdown-menu-end">
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Last 3 months
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Last 6 months
                            </a>
                            <a
                              href="javascript:void(0);"
                              className="dropdown-item"
                            >
                              Last 12 months
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <YearChart/>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  )
}

import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"

export const locationListAdapter = createEntityAdapter()

export const fetchLocationList = createAsyncThunk(
    Preferences.LOCATION_LIST,
    async ({zipID}, thunkApi) => {
        let zipParam = zipID ? `&fk_zipId=${zipID}` : ''
        const params = `?fk_UsrOrgDirId=${
                JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
              }${zipParam}&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getLocationList, params)
        if (response?.result?.response_Status === 'success') {
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status == 'error') {
            alert(response?.result?.response_Message) 
        } else {
            alert('Something went wrong with getLocationData')
        }
    }
)

export const initialAppState = locationListAdapter.getInitialState({
    loading: 'idel',
    locationListData: [],
    filterData: [],
    error: []
})

export const locationListSlice = createSlice({
    name: Preferences.LOCATION_LIST,
    initialState: initialAppState,
    reducers: {
        resetLocationListFilter: state => {
            state.filterData = state.locationListData
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchLocationList.pending, state => {
            state.loading = 'loading'
        })
        builder.addCase(fetchLocationList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.locationListData = action.payload ?? []
            state.error = null
        })
        builder.addCase(fetchLocationList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    }
})

export const locationListReducer = locationListSlice.reducer

export const getLocationListLoading = state => {
    return state.locationListReducer.loading === 'loading' ? true : false
}

export const getLocationListError = state => {
    return state.locationListReducer.error
}

export const getLocationListFilterData = state => {
    return state.locationListReducer.filterData
}

export const getLocationListData = state => {
    return state.locationListReducer.locationListData
}

export const getLocationListDataByID = (state, {locationID}) => {
    return state.locationListReducer.filterData.filter(e => e?.pk_locationId == locationID)
}
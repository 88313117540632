import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"

export const phoneListAdapter = createEntityAdapter()

export const fetchPhoneList = createAsyncThunk(
    Preferences.PHONE_LIST,
    async (thunkApi) => {
        const params = `?fk_UsrOrgDirId=${
                JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
            }&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getPhoneList, params)
        if (response?.result?.response_Status == 'success') {
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status === 'error') {
            alert(response?.result?.response_Message)
        } else {
            alert('Something went wrong with getPhoneData')
        }
    }
)

export const initialAppState = phoneListAdapter.getInitialState({
    loading: 'idel',
    phoneListData: [],
    filterData: [],
    error: []
})

export const phoneListSlice = createSlice({
    name: Preferences.PHONE_LIST,
    initialState: initialAppState,
    reducers: {
        resetPhoneListFilter: state => {
            state.filterData = state.phoneListData
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchPhoneList.pending, state => {
            state.loading = 'loading'
        })
        builder.addCase(fetchPhoneList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.phoneListData = action.payload ?? []
            state.error = null
        })
        builder.addCase(fetchPhoneList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    }
})

export const phoneListReducer = phoneListSlice.reducer

export const getPhoneListLoading = state => {
    return state.phoneListReducer.loading === 'loading' ? true : false
}

export const getPhoneListError = state => {
    return state.phoneListReducer.error
}

export const getPhoneListFilterData = state => {
    return state.phoneListReducer.filterData
}

export const getPhoneListUniqueData = state => {
    return [...new Set(state.phoneListReducer.phoneListData.map(item => item.phoneNumber))].map(phoneNumber => {
        return state.phoneListReducer.phoneListData.find(item => item.phoneNumber === phoneNumber)
    })
}

export const getPhoneListData = state => {
    return state.phoneListReducer.phoneListData
}

export const getPhoneListDataByID = (state, {phoneID}) => {
    return state.phoneListReducer.filterData.filter(e => e?.pk_PhoneId == phoneID)
}
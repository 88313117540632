import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"

export const enquiryListAdapter = createEntityAdapter()

export const fetchEnquiryList = createAsyncThunk(
    Preferences.ENQUIRY_LIST,
    async (thunkApi) => {
        const params = `?fk_UsrOrgDirId=${
            JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
          }&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getEnquiryList, params)
        if (response?.result?.response_Status === 'success') {
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status == 'error') {
            alert(response?.result?.response_Message) 
        } else {
            alert('Something went wrong with getContactData')
        }
    }
)

export const initialAppState = enquiryListAdapter.getInitialState({
    loading: 'idel',
    enquiryListData: [],
    filterData: [],
    error: []
})

export const enquiryListSlice = createSlice({
    name: Preferences.ENQUIRY_LIST,
    initialState: initialAppState,
    reducers: {
        resetEnquiryListData: state => {
            state.filterData = state.enquiryListData
        },
        filterEnquiryListData: (state, action) => {
            const {
                filterLeadName,
                filterProductTypeName,
                filterProductCategoryName,
                filterProductName,
                filterBrandName,
                filterCountryName,
                filterStateName,
                filterCityName,
                filterZipName,
                filterLocationName,
                filterSourceName,
                filterStatusName
            } = action.payload

            state.filterData = state.filterData.filter((item) => {
                return (
                    (filterLeadName && filterLeadName.some((filterItem) => filterItem.fullName === item.fullName)) ||
                    (filterProductTypeName && filterProductTypeName.some((filterItem) => filterItem.productType === item.productType)) ||
                    (filterProductCategoryName && filterProductCategoryName.some((filterItem) => filterItem.productCategory === item.productCategory)) ||
                    (filterProductName && filterProductName.some((filterItem) => filterItem.productName === item.productName)) ||
                    (filterBrandName && filterBrandName.some((filterItem) => filterItem.brandName === item.brandName)) ||
                    (filterCountryName && filterCountryName.some((filterItem) => filterItem.countryName === item.countryName)) ||
                    (filterStateName && filterStateName.some((filterItem) => filterItem.stateName === item.stateName)) ||
                    (filterCityName && filterCityName.some((filterItem) => filterItem.cityName === item.cityName)) ||
                    (filterZipName && filterZipName.some((filterItem) => filterItem.zipCode === item.zipCode)) ||
                    (filterLocationName && filterLocationName.some((filterItem) => filterItem._LocationMstList.some((locationData) => locationData === filterItem.locationName))) ||
                    (filterSourceName && filterSourceName.some((filterItem) => filterItem.sourceName === item.sourceName)) ||
                    (filterStatusName && filterStatusName.some((filterItem) => filterItem.statusName === item.statusName))
                )
            })
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchEnquiryList.pending, state => {
            state.loading = 'pending'
        })
        builder.addCase(fetchEnquiryList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.enquiryListData = action.payload ?? []
            state.filterData = action.payload ?? []
            state.error = null
        })
        builder.addCase(fetchEnquiryList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    }
})

export const enquiryListReducer = enquiryListSlice.reducer

export const {resetEnquiryListData, filterEnquiryListData} = enquiryListSlice.actions

export const getEnquiryListLoading = state => {
    return state.enquiryListReducer.loading === 'loading' ? true : false
}

export const getEnquiryListError = state => {
    return state.enquiryListReducer.error
}

export const getEnquiryListFilterData = state => {
    return state.enquiryListReducer.filterData
}

export const getEnquiryListData = (state) => {
    return state.enquiryListReducer.enquiryListData
}

export const getEnquiryListDataByID = (state, {enquiryID}) => {
    return state.enquiryListReducer.filterData.filter(e => e?.pk_EnquiryId == enquiryID)
}
import React from 'react'
import DataTable from 'react-data-table-component'
export default function CallLogDataTableComponent({columns, rowData}) {
    
    const customStyles = {
        rows:{
          style:{
            fontSize:"14px"
          }
        },
        cells:{
          style:{
            fontSize:"14px"
          }
        },
        headCells:{
          style:{
            fontSize:"14px",
            backgroundColor:"#FAFAFA"
          }
        }
      }      
  return (
    <div>
         <div className="table-responsive custom-table">
        <table className="table" >
            <DataTable  
              columns={columns} 
              data={rowData} 
              // selectableRows
              pagination customStyles={customStyles}              
              paginationRowsPerPageOptions={[10,20,30,100]}              
              sortIcon={<i class="fa-solid fa-arrow-down-a-z"  style={{fontSize:"14px", marginLeft:"4px"}} ></i>}                         
            />
            
        </table>
    </div>      
    </div>
  )
}

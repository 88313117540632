import { useNavigate } from "react-router"
import { useEffectOnce } from "react-use"
import { Common, Constant } from "../../../utils"
import { useEffect, useState } from "react"
import VerticalMenuComponent from "../../../components/VerticalMenuComponent"
import TopMenuComponent from "../../../components/TopMenuComponent"
import CustomDataTableComponent from "../../../components/page-components/CustomDataTableComponent"
import ProductForm from "../product-form"
import CustomTableGrid from "../../../components/custom-table-grid"
import { connect, useDispatch } from "react-redux"
import {fetchProductList,getProductListData,getProductListLoading,} from "../../../store/slice/product-slice"
import { Form } from "react-bootstrap"
import CustomAlert from "../../../components/custom-alerts/CustomAlert"
import TopPageHeader from "../../../components/TopPageHeader"

const Products = ({ productListData, isLoading }) => {
  
  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffectOnce(() => {
    Common.checkLogin(navigate)
  })

  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  const [formType, setFormType] = useState(Constant.FORM_TYPE_NEW)
  const [selectedData, setSelectedData] = useState(null)

  const [isGridView, setIsGridView] = useState(0)

  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  const filterTable = (event) => {
    setSearchValue(event.target.value)
  }

  const printData = () => {
    Common.exportToPrint(columns, productListData)
  }

  useEffect(() => {
    dispatch(fetchProductList())
  }, [])

  let rowData = productListData?.filter((field) => {
    const searchLower = searchValue.toLowerCase()
    let productName = field.productName
      .toLocaleLowerCase()
      .includes(searchLower)
    // let productTypeId =field?.fk_ProductTypeId.toLocaleLowerCase().includes(searchLower)
    // let productCategoryId = field?.fk_ProductCategoryId.toLocaleLowerCase().includes(searchLower)
    let createdName = field.createdByName
      .toLocaleLowerCase()
      .includes(searchLower)
    let createdDate = field.createdDate
      .toLocaleLowerCase()
      .includes(searchLower)

    return productName || createdName || createdDate
  })
  console.log(" Product List Data  Search Filter", rowData)

  const [alertTitle, setAlertTitle] = useState("")
  const [alertDesc, setAlertDesc] = useState("")
  const [radioToggle, setRadioToggle] = useState(true)
  const [customAlertType, setCustomAlertType] = useState("")

  const handleChange = () => {
    setAlertTitle("Warning")
    setAlertDesc("Are You Sure ?")
    setCustomAlertType("warning")
    setRadioToggle(false)
  }

  const columns = [
    {
      name: "Product Type",
      selector: (row) => row.fk_ProductTypeId,
      cell: (row) => (
        <div className="d-flex flex-column">
          <span className="text-default">{row.fk_ProductTypeId}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Product Category",
      selector: (row) => row.fk_ProductCategoryId,
      cell: (row) => (
        <div className="d-flex flex-column">
          <span className="text-default">{row.fk_ProductCategoryId}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Product Name",
      sortable: true,
      selector: (row) => row.productName,
      cell: (row) => (
        <a href="javascript:void(0)" className="title-name">
          {row.productName}
        </a>
      ),
    },
    {
      name: "Product Status",
      selector: (row) => row.statusName,
      sortable: true,
      cell: (row) => {
        let statusClass = ""
        if (row.status === "0") {
          statusClass = "bg-success"
        } else if (row.status === "1") {
          statusClass = "bg-danger"
        } else if (row.status === "2") {
          statusClass = "bg-pending"
        } else {
          statusClass = "bg-warning"
        }
        return (
          <span className={`badge badge-pill badge-status ${statusClass}`}>
            {row.statusName}
          </span>
        )
      },
    },
    {
      name: "Created By",
      selector: (row) => row.createdByName,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => row.createdDate,
      sortable: true,
      cell: (row) => {
        return Common.changeDateFormat(
          Common.getDateSubString(row.createdDate),
          "yyyy-mm-dd",
          "DD-MMM-yyyy"
        )
      },
    },
    {
      name: "Action",
      button: true,
      sortable: true,
      cell: (row) => (
        <div className="outer-action-box">
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-original-title="Edit"
          >
            <a
              className="dropdown-item"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas_add"
              href="#!"
              onClick={() => {
                setFormType(Constant.FORM_TYPE_EDIT)
                setSelectedData({ ...row })
              }}
            >
              <i className="ti ti-edit text-blue"></i>
            </a>
          </div>

          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-original-title="View"
          >
            <a
              className="dropdown-item"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas_add"
              href="#"
              onClick={() => {
                setFormType(Constant.FORM_TYPE_VIEW)
                setSelectedData({ ...row })
              }}
            >
              <i class="ti ti-eye text-blue-light"></i>
            </a>
          </div>
        </div>
      ),
    },

    {
      name: "Active / InActive",
      sortable: false,
      cell: (row) => (
        <div className="ms-4">
          <Form.Check
            type="switch"
            id="custom-switch"
            onChange={handleChange}
            className={`customRadio ${radioToggle === true ? "" : ""}`}
          />
        </div>
      ),
    },
  ]

 const handleExportExcel = () =>{
    Common.exportToExcel(Constant.selectedProductListColumns, rowData)
  }

  const handlePrintData = () =>{
    Common.exportToPrint(Constant.selectedProductListColumns, rowData)
  }

  const handleCopyData = () =>{
    Common.handleCopyList(Constant.selectedProductListColumns, rowData)
  }



  return (
    <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
      {radioToggle === false && (
        <CustomAlert
          alertTitle={alertTitle}
          alertDesc={alertDesc}
          onOkClick={() => setRadioToggle(true)}
          customAlertType={customAlertType}
        />
      )}
      {/* header component  */}
      <VerticalMenuComponent />
      <TopMenuComponent openSideNav={openSideNav} />

      {/* page wrapper  */}
      <div className="page-wrapper">
        <div className="content">
          <div className="row">
            <div className="col-md-12">
              {/* <!-- Page Header --> */}
              <div className="page-header">

                <TopPageHeader 
                  pageTitle={"Product"}
                  countTitle={110}
                />

              </div>
              {/* <!-- /Page Header --> */}

              <div className="card">
                <div className="card-header">
                  {/* <!-- Search --> */}
                  <div className="row align-items-center">
                    <div className="col-sm-4">
                      <div className="icon-form mb-3 mb-sm-0">
                        <span className="form-icon">
                          <i className="ti ti-search"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          onChange={filterTable}
                        />
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="d-flex align-items-center flex-wrap row-gap-2 justify-content-sm-end">
                        <div className="dropdown me-2">
                          <a
                            href="javascript:void(0);"
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown">                              
                            <i className="ti ti-package-export me-2"></i>
                            Export
                          </a>
                          <div className="dropdown-menu  dropdown-menu-end">
                            <ul>
                              {rowData?.length > 0 ? (
                                <li onClick={handleExportExcel}>                                  
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    <i className="ti ti-file-type-xls text-green me-1"></i>
                                    Export as Excel{" "}
                                  </a>
                                </li>
                              ) : (
                                <li>No Record Found.</li>
                              )}

                              {rowData?.length > 0 ? (
                                <li onClick={handlePrintData}>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    <i class="ti ti-printer text-green me-1"></i>
                                    Print{" "}
                                  </a>
                                </li>
                              ):(
                                <li>No Record Found.</li>
                              )}

                              {rowData?.length > 0 ? (
                                <li onClick={handleCopyData}>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                   <i class="ti ti-clipboard-check text-green me-1"></i>
                                    Print{" "}
                                  </a>
                                </li>
                              ):(
                                <li>No Record Found.</li>
                              )}

                            </ul>
                          </div>
                        </div>
                        <a
                          href="javascript:void(0);"
                          className="btn btn-primary"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvas_add"
                          onClick={() => setFormType(Constant.FORM_TYPE_NEW)}
                        >
                          <i className="ti ti-square-rounded-plus me-2"></i>
                          Add Product
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /Search --> */}
                </div>
                <div className="card-body">
                  {/* <!-- Filter --> */}
                  <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2 mb-4">
                    <div className="d-flex align-items-center flex-wrap row-gap-2">
                      <div className="dropdown me-2">
                        <a
                          href="javascript:void(0);"
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                        >
                          <i className="ti ti-sort-ascending-2 me-2"></i>Sort
                        </a>
                        <div className="dropdown-menu  dropdown-menu-start">
                          <ul>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                <i className="ti ti-circle-chevron-right me-1"></i>
                                Ascending
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                <i className="ti ti-circle-chevron-right me-1"></i>
                                Descending
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                <i className="ti ti-circle-chevron-right me-1"></i>
                                Recently Viewed
                              </a>
                            </li>
                            <li>
                              <a
                                href="javascript:void(0);"
                                className="dropdown-item"
                              >
                                <i className="ti ti-circle-chevron-right me-1"></i>
                                Recently Added
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="icon-form">
                        <span className="form-icon">
                          <i className="ti ti-calendar"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center flex-wrap row-gap-2">
                      <div className="form-sorts dropdown me-2">
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                        >
                          <i className="ti ti-filter-share"></i>Filter
                        </a>
                        <div className="filter-dropdown-menu dropdown-menu  dropdown-menu-md-end p-3">
                          <div className="filter-set-view">
                            <div className="filter-set-head">
                              <h4>
                                <i className="ti ti-filter-share"></i>Filter
                              </h4>
                            </div>
                            <div className="accordion" id="accordionExample">
                              <div className="filter-set-content">
                                <div className="filter-set-content-head">
                                  <a
                                    href="#"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseTwo"
                                    aria-expanded="true"
                                    aria-controls="collapseTwo"
                                  >
                                    contacts Name
                                  </a>
                                </div>
                                <div
                                  className="filter-set-contents accordion-collapse collapse show"
                                  id="collapseTwo"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="filter-content-list">
                                    <ul>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" checked />
                                            <span className="checkmarks"></span>
                                            Collins
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Konopelski
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Adams
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Schumm
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Wisozk
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Heller
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Gutkowski
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Walter
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="filter-set-content">
                                <div className="filter-set-content-head">
                                  <a
                                    href="#"
                                    className="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#company"
                                    aria-expanded="false"
                                    aria-controls="company"
                                  >
                                    Company Name
                                  </a>
                                </div>
                                <div
                                  className="filter-set-contents accordion-collapse collapse"
                                  id="company"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="filter-content-list">
                                    <div className="mb-2 icon-form">
                                      <span className="form-icon">
                                        <i className="ti ti-search"></i>
                                      </span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Company"
                                      />
                                    </div>
                                    <ul>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" checked />
                                            <span className="checkmarks"></span>
                                            NovaWave LLC
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            BlueSky Industries
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Silver Hawk
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Summit Peak
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            RiverStone Ventur
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="filter-set-content">
                                <div className="filter-set-content-head">
                                  <a
                                    href="#"
                                    className="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#Status"
                                    aria-expanded="false"
                                    aria-controls="Status"
                                  >
                                    contacts Status
                                  </a>
                                </div>
                                <div
                                  className="filter-set-contents accordion-collapse collapse"
                                  id="Status"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="filter-content-list">
                                    <ul>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" checked />
                                            <span className="checkmarks"></span>
                                            Closed
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Not Contacted
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Contacted
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="filter-set-content">
                                <div className="filter-set-content-head">
                                  <a
                                    href="#"
                                    className="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#collapseOne"
                                    aria-expanded="false"
                                    aria-controls="collapseOne"
                                  >
                                    Created Date
                                  </a>
                                </div>
                                <div
                                  className="filter-set-contents accordion-collapse collapse"
                                  id="collapseOne"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="filter-content-list">
                                    <ul>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" checked />
                                            <span className="checkmarks"></span>
                                            25 Sep 2023, 01:22 pm
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            25 Sep 2023, 01:22 pm
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            25 Sep 2023, 01:22 pm
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="filter-set-content">
                                <div className="filter-set-content-head">
                                  <a
                                    href="#"
                                    className="collapsed"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#owner"
                                    aria-expanded="false"
                                    aria-controls="owner"
                                  >
                                    contacts Owner
                                  </a>
                                </div>
                                <div
                                  className="filter-set-contents accordion-collapse collapse"
                                  id="owner"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div className="filter-content-list">
                                    <div className="mb-2 icon-form">
                                      <span className="form-icon">
                                        <i className="ti ti-search"></i>
                                      </span>
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search Owner"
                                      />
                                    </div>
                                    <ul>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" checked />
                                            <span className="checkmarks"></span>
                                            Hendry
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Guillory
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Jami
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Theresa
                                          </label>
                                        </div>
                                      </li>
                                      <li>
                                        <div className="filter-checks">
                                          <label className="checkboxs">
                                            <input type="checkbox" />
                                            <span className="checkmarks"></span>
                                            Espinosa
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="filter-reset-btns">
                              <div className="row">
                                <div className="col-6">
                                  <a href="#" className="btn btn-light">
                                    Reset
                                  </a>
                                </div>
                                <div className="col-6">
                                  <a
                                    href="contacts.html"
                                    className="btn btn-primary"
                                  >
                                    Filter
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="view-icons">
                        <a
                          href="#!"
                          className={`${isGridView === 0 ? "active" : ""}`}
                          onClick={() => setIsGridView(0)}
                        >
                          <i className="ti ti-list-tree"></i>
                        </a>
                        <a
                          href="#!"
                          className={`${isGridView === 1 ? "active" : ""}`}
                          onClick={() => setIsGridView(1)}
                        >
                          <i className="ti ti-grid-dots"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /Filter --> */}
                  {/* <!-- produc List --> */}

                  {isGridView === 0 ? (
                    <CustomDataTableComponent
                      rowData={rowData}
                      columns={columns}
                    />
                  ) : (
                    <CustomTableGrid data={rowData} />
                  )}

                  {/* <!-- /product List --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* add product */}
      <ProductForm
        navigate={navigate}
        formType={formType}
        selectedData={selectedData}
      />
      {/* add product */}
    </div>
  )
}

const mapStateToProps = (state) => ({
  productListData: getProductListData(state),
  isLoading: getProductListLoading(state),
})

export default connect(mapStateToProps)(Products)

import React, { useState } from "react"
import VerticalMenuComponent from "../../../components/VerticalMenuComponent"
import TopMenuComponent from "../../../components/TopMenuComponent"
import CallLogDataTableComponent from "../../../components/page-components/call-logs/CallLogDataTableComponent"
import { Link } from "react-router-dom"
import CustomTableGrid from "../../../components/custom-table-grid"
import TopPageHeader from "../../../components/TopPageHeader"

const CallLogs = () => {
  const [searchValue, setSearchValue] = useState("")
  const [isOpen, setIsOpen] = useState(false)
  const [isGridView, setISGridView] = useState(0)

  const OntableView = (num) => {
    setISGridView(num)
  }

  const OnGridViewTable = (num) => {
    setISGridView(num)
  }

  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  const [Data, setData] = useState([
    {
      id: 11,
      name: "Collins",
      company_name: "NovaWave LLC",
      company_image: "assets/img/icons/company-icon-01.svg",
      company_address: "Newyork, USA",
      phone: "+1 875455453",
      email: [
        "robertson@example.com",
        "one@gmail.com",
        "two@gmail.com",
        "three@gmail.com",
      ],
      created_date: "25 Sep 2023, 01:22 pm",
      owner: "Hendry",
      source: "Paid Social",
      status: "0",
    },
    {
      id: 22,
      name: "Konopelski",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 875455453",
      email: "robert@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 213,
      name: "ravi one",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 875455453",
      email: "second@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 1214,
      name: "ravi two",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 875455453",
      email: "robertson@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 5121,
      name: "ravi three",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 875455453",
      email: "second@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 1216,
      name: "ravi four",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 875455453",
      email: "second@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 7121,
      name: "ravi five",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 875455453",
      email: "second@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 83232,
      name: "ravi six",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 875455453",
      email: "second@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 123129,
      name: "ravi seven",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 875455453",
      email: "second@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 132131230,
      name: "ravi eight",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 12131,
      name: "ravi nine",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 112,
      name: "Twelve",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 10983,
      name: "ravi Thirteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 156564,
      name: "ravi Fourteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 115674,
      name: "ravi Fifteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 176,
      name: "ravi Sixteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 15677,
      name: "ravi Seventeen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 156758,
      name: "ravi Eighteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 15675659,
      name: "ravi Ninteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 2657560,
      name: "ravi Twenty",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 256756561,
      name: "ravi Twenty",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 2122,
      name: "Collins",
      company_name: "NovaWave LLC",
      company_image: "assets/img/icons/company-icon-01.svg",
      company_address: "Newyork, USA",
      phone: "+1 875455453",
      email: "robertson@example.com",
      created_date: "25 Sep 2023, 01:22 pm",
      owner: "Hendry",
      source: "Paid Social",
      status: "0",
    },
    {
      id: 2213,
      name: "Konopelski",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 2124,
      name: "ravi one",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 2215,
      name: "ravi two",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 212126,
      name: "ravi three",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 221217,
      name: "ravi four",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 28212,
      name: "ravi five",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 221321319,
      name: "ravi six",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 32130,
      name: "ravi seven",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 321311,
      name: "ravi eight",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 21332,
      name: "ravi nine",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 21333,
      name: "Twelve",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 31234,
      name: "ravi Thirteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 38905,
      name: "ravi Fourteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 36786,
      name: "ravi Fifteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 3677,
      name: "ravi Sixteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 3768,
      name: "ravi Seventeen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 3789,
      name: "ravi Eighteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 4098787670,
      name: "ravi Ninteen",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 411111111,
      name: "ravi Twenty",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },

    {
      id: 411111122222,
      name: "ravi Twenty",
      company_name: "BlueSky Industries",
      company_image: "assets/img/icons/company-icon-02.svg",
      company_address: "Winchester, KY",
      phone: "+1 989757485",
      email: "sharon@example.com",
      created_date: "29 Sep 2023, 04:15 pm",
      owner: "Guillory",
      source: "Referrals",
      status: "2",
    },
  ])

  const columns = [
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        let statusClass = ""
        let statusName = ""
        if (row.status === "0") {
          statusClass = "badge-success-light"
          statusName = "Closed"
        } else if (row.status === "1") {
          statusClass = "badge-purple-light"
          statusName = "Lost"
        } else if (row.status === "2") {
          statusClass = "badge-danger-light"
          statusName = "Not Contacted"
        } else {
          statusClass = "bg-warning-light"
          statusName = "Contacted"
        }
        return (
          <div className="call-log-status">
            <img
              src="/img/up-right-arrow.png"
              className="call-log-arrow"
              alt="Call Log"
            />
            <span className={`badge badge-tag ${statusClass}`}>
              {statusName}
            </span>
          </div>
        )
      },
    },
    {
      name: "Name",
      sortable: true,
      selector: (row) => row.name,
      cell: (row) => (
        <div className="d-flex align-items-center">
          <Link
            to={`/call-log-detail/${row.name}`}
            class="avatar avatar-sm border rounded p-1 me-2"
          >
            <img
              className="w-auto h-auto"
              src="/img/user-call-img.png"
              alt="User Image"
            />
          </Link>
          <Link
            to={`/call-log-detail/${row.name}`}
            className="d-flex flex-column title-name"
          >
            {row.name}
            <span class="text-default"> {row.phone} </span>
          </Link>
        </div>
      ),
    },

    {
      name: "Created By",
      selector: (row) => row.name,
      cell: (row) => (
        <a href="#!" className="title-name">
          {row.name}
        </a>
      ),
      sortable: true,
    },
    {
      name: "Called At",
      sortable: true,
      selector: (row) => row.created_date,
      cell: (row) => (
        <a href="#!" className="title-name">
          {row.created_date}
        </a>
      ),
    },

    {
      name: "Duration",
      sortable: true,
      selector: (row) => row.name,
      cell: (row) => (
        <a href="#!" className="title-name">
          {row.name}
        </a>
      ),
    },

    // {
    //   name: "Action",
    //   button: true,
    //   sortable: true,
    //   cell: (row) => (
    //     <div className="outer-action-box">
    //       <div
    //         data-bs-toggle="tooltip"
    //         data-bs-placement="top"
    //         data-bs-original-title="Edit"
    //       >
    //         <a
    //           className="dropdown-item"
    //           data-bs-toggle="offcanvas"
    //           data-bs-target="#offcanvas_add"
    //           href="#!"
    //           onClick={() => onEditLead(row.id)}
    //         >
    //           <i className="ti ti-edit text-blue"></i>
    //         </a>
    //       </div>

    //       <div
    //         data-bs-toggle="tooltip"
    //         data-bs-placement="top"
    //         data-bs-original-title="View"
    //       >
    //         <a
    //           className="dropdown-item"
    //           data-bs-toggle="offcanvas"
    //           data-bs-target="#offcanvas_add"
    //           href="#"
    //           onClick={() => onViewLead(row.id)}
    //         >
    //           <i class="ti ti-eye text-blue-light"></i>
    //         </a>
    //       </div>
    //     </div>
    //   ),
    // },
  ]

  const filterTable = (event) => {
    setSearchValue(event.target.value)
  }

  let rowData = Data.filter((input) => {
    const lowerSearchTerm = searchValue.toLowerCase()

    const leadName = input.name.toLocaleLowerCase().includes(lowerSearchTerm)
    const companyName = input.name
      .toLocaleLowerCase()
      .includes(lowerSearchTerm)
    const leadCreated = input.created_date
      .toLocaleLowerCase()
      .includes(lowerSearchTerm)

    const leadEmail = Array.isArray(input.email)
      ? input.email.some((email) =>
          email.toLowerCase().includes(lowerSearchTerm)
        )
      : input.email.toLowerCase().includes(lowerSearchTerm)
    const leadPhone = Array.isArray(input.name)
      ? input.phone.some((phone) =>
          phone.toLowerCase().includes(lowerSearchTerm)
        )
      : input.phone.toLowerCase().includes(lowerSearchTerm)

    return leadName || companyName || leadCreated || leadEmail || leadPhone
  })

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">

          <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">

                <TopPageHeader 
                    pageTitle={"Call Logs "}
                    countTitle={"111"}
                  />

                </div>
                {/* <!-- /Page Header --> */}

                <div className="card">
                  <div className="card-header">
                    {/* <!-- Search --> */}
                    <div className="row align-items-center">
                      <div className="col-sm-4">
                        <div className="icon-form mb-3 mb-sm-0">
                          <span className="form-icon">
                            <i className="ti ti-search"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            onChange={filterTable}
                          />
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="d-flex align-items-center flex-wrap row-gap-2 justify-content-sm-end">
                          <div className="dropdown me-2">
                            <a
                              href="javascript:void(0);"
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                            >
                              <i className="ti ti-package-export me-2"></i>
                              Export
                            </a>
                            <div className="dropdown-menu  dropdown-menu-end">
                              <ul>
                                {rowData?.length > 0 ? (
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <i className="ti ti-file-type-xls text-green me-1"></i>
                                      Export as Excel{" "}
                                    </a>
                                  </li>
                                ) : (
                                  <li>No Record Found</li>
                                )}

                                {rowData?.length > 0 ? (
                                  <li>
                                    <a
                                      href="javascript:void(0);"
                                      className="dropdown-item"
                                    >
                                      <i className="ti ti-file-type-xls text-green me-1"></i>
                                      Print{" "}
                                    </a>
                                  </li>
                                ) : (
                                  <li>No Record Found</li>
                                )}

                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >
                                    <i className="ti ti-file-type-xls text-green me-1"></i>
                                    Copy To Clipboard{" "}
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Search --> */}
                  </div>
                  <div className="card-body">
                    {/* <!-- Filter --> */}
                    <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2 mb-4">
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="dropdown me-2">
                          <a
                            href="javascript:void(0);"
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            <i className="ti ti-sort-ascending-2 me-2"></i>Sort
                          </a>
                          <div className="dropdown-menu  dropdown-menu-start">
                            <ul>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Ascending
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Descending
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Recently Viewed
                                </a>
                              </li>
                              <li>
                                <a
                                  href="javascript:void(0);"
                                  className="dropdown-item"
                                >
                                  <i className="ti ti-circle-chevron-right me-1"></i>
                                  Recently Added
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="icon-form">
                          <span className="form-icon">
                            <i className="ti ti-calendar"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center flex-wrap row-gap-2">
                        <div className="form-sorts dropdown me-2">
                          <a
                            href="javascript:void(0);"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                          >
                            <i className="ti ti-filter-share"></i>Filter
                          </a>
                          <div className="filter-dropdown-menu dropdown-menu  dropdown-menu-md-end p-3">
                            <div className="filter-set-view">
                              <div className="filter-set-head">
                                <h4>
                                  <i className="ti ti-filter-share"></i>Filter
                                </h4>
                              </div>
                              <div className="accordion" id="accordionExample">
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseTwo"
                                      aria-expanded="true"
                                      aria-controls="collapseTwo"
                                    >
                                      Call logs Name
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse show"
                                    id="collapseTwo"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              Collins
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Konopelski
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Adams
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Schumm
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Wisozk
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Heller
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Gutkowski
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Walter
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#company"
                                      aria-expanded="false"
                                      aria-controls="company"
                                    >
                                      Company Name
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="company"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <div className="mb-2 icon-form">
                                        <span className="form-icon">
                                          <i className="ti ti-search"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Company"
                                        />
                                      </div>
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              NovaWave LLC
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              BlueSky Industries
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Silver Hawk
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Summit Peak
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              RiverStone Ventur
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#Status"
                                      aria-expanded="false"
                                      aria-controls="Status"
                                    >
                                      Call logs Status
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="Status"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              Closed
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Not Contacted
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Contacted
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseOne"
                                      aria-expanded="false"
                                      aria-controls="collapseOne"
                                    >
                                      Created Date
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="collapseOne"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              25 Sep 2023, 01:22 pm
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              25 Sep 2023, 01:22 pm
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              25 Sep 2023, 01:22 pm
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                <div className="filter-set-content">
                                  <div className="filter-set-content-head">
                                    <a
                                      href="#"
                                      className="collapsed"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#owner"
                                      aria-expanded="false"
                                      aria-controls="owner"
                                    >
                                      Call logs Owner
                                    </a>
                                  </div>
                                  <div
                                    className="filter-set-contents accordion-collapse collapse"
                                    id="owner"
                                    data-bs-parent="#accordionExample"
                                  >
                                    <div className="filter-content-list">
                                      <div className="mb-2 icon-form">
                                        <span className="form-icon">
                                          <i className="ti ti-search"></i>
                                        </span>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Search Owner"
                                        />
                                      </div>
                                      <ul>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" checked />
                                              <span className="checkmarks"></span>
                                              Hendry
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Guillory
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Jami
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Theresa
                                            </label>
                                          </div>
                                        </li>
                                        <li>
                                          <div className="filter-checks">
                                            <label className="checkboxs">
                                              <input type="checkbox" />
                                              <span className="checkmarks"></span>
                                              Espinosa
                                            </label>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="filter-reset-btns">
                                <div className="row">
                                  <div className="col-6">
                                    <a href="#" className="btn btn-light">
                                      Reset
                                    </a>
                                  </div>
                                  <div className="col-6">
                                    <a
                                      href="Call logs.html"
                                      className="btn btn-primary"
                                    >
                                      Filter
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="view-icons">
                          <a
                            href="#!"
                            className={`${isGridView === 0 ? "active" : ""}`}
                            onClick={() => OntableView(0)}
                          >
                            <i className="ti ti-list-tree"></i>
                          </a>
                          <a
                            href="#!"
                            className={`${isGridView === 1 ? "active" : ""}`}
                            onClick={() => OnGridViewTable(1)}
                          >
                            <i className="ti ti-grid-dots"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Filter --> */}

                    {/* <!-- Call logs List --> */}
                    {isGridView === 0 ? (
                      <CallLogDataTableComponent
                        rowData={rowData}
                        columns={columns}
                      />
                    ) : (
                      <CustomTableGrid data={rowData} />
                    )}
                    {/* <!-- /Call logs List --> */}
                  </div>
                </div>
              </div>
          </div>

       

          </div>
        </div>

        {/* <!-- Add Call logs --> */}
        <div
          className="offcanvas offcanvas-end offcanvas-large"
          tabindex="-1"
          id="offcanvas_add"
        >
          <div className="offcanvas-header border-bottom">
            <h5 className="fw-semibold">Add New Call Logs</h5>
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <i className="ti ti-x"></i>
            </button>
          </div>
        </div>
        {/* <!-- /Add Call logs --> */}
      </div>
    </>
  )
}

export default CallLogs

import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"

export const zipListAdapter = createEntityAdapter()

export const fetchZipList = createAsyncThunk(
    Preferences.ZIP_LIST,
    async ({countryID, stateID, cityID}, thunkApi) => {
        let countryParam = countryID ? `&fk_countryId=${countryID}` : ''
        let stateParam = stateID ? `&fk_stateId=${stateID}` : ''
        let cityParam = cityID ? `&fk_cityId=${cityID}` : ''
        const params = `?fk_UsrOrgDirId=${
                JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
              }${countryParam}${stateParam}${cityParam}&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getZipList, params)
        if (response?.result?.response_Status === 'success') {
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status == 'error') {
            alert(response?.result?.response_Message) 
        } else {
            alert('Something went wrong with getZipData')
        }
    }
)

export const initialAppState = zipListAdapter.getInitialState({
    loading: 'idel',
    zipListData: [],
    filterData: [],
    error: []
})

export const zipListSlice = createSlice({
    name: Preferences.ZIP_LIST,
    initialState: initialAppState,
    reducers: {
        resetZipListFilter: state => {
            state.filterData = state.zipListData
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchZipList.pending, state => {
            state.loading = 'loading'
        })
        builder.addCase(fetchZipList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.zipListData = action.payload ?? []
            state.error = null
        })
        builder.addCase(fetchZipList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    }
})

export const zipListReducer = zipListSlice.reducer

export const getZipListLoading = state => {
    return state.zipListReducer.loading === 'loading' ? true : false
}

export const getZipListError = state => {
    return state.zipListReducer.error
}

export const getZipListFilterData = state => {
    return state.zipListReducer.filterData
}

export const getZipListData = state => {
    return state.zipListReducer.zipListData
}

export const getZipListDataByID = (state, {zipID}) => {
    return state.zipListReducer.filterData.filter(e => e?.pk_zipId == zipID)
}
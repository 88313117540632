const BASE_URL = 'https://ssoapi.workxcrm.com/api/'
const API_URL = 'https://crmapi.workxcrm.com/api/'

export const urls = {
    memberLogin: BASE_URL + 'memberLogin/MemberSignIn',
    enterInAccount: BASE_URL + 'memberLogin/enterInAccount',
    roleMenuGetUserActionRight: BASE_URL + '​roleMenu​/getUserActionRight',
    createContact: API_URL + "CantactMst/contactMaster",
    getContactList: API_URL + "CantactMst/getContactLst",
    createProductType: API_URL + "ProductTypeMst/ProductTypeMaster",
    getProductTypeList: API_URL + "ProductTypeMst/getProductTypeMstLst",
    createProductCategory: API_URL + "ProductCategoryMst/ProductCategoryMaster",
    getProductCategoryList: API_URL + "ProductCategoryMst/getProductCategoryMstLst",
    createProduct: API_URL + "ProductMst/ProductMaster",
    getProductList: API_URL + "ProductMst/getProductLst",
    createBrand: API_URL + "BrandMst/brandMaster",
    getBrandList: API_URL + "BrandMst/getBrandLst",
    getCountryList: API_URL + "LocationMst/getCountryLst",
    getStateList: API_URL + "LocationMst/getStateLst",
    getCityList: API_URL + "LocationMst/getCityLst",
    getZipList: API_URL + "LocationMst/getZipCodeLst",
    getLocationList: API_URL + "LocationMst/getLocationLst",
    getStatusList: API_URL + "StatusMst/getStatusMstLst",
    createEnquiry: API_URL + "EnquiryMst/enquiryForm",
    getEnquiryList: API_URL + "EnquiryMst/getEnquiryLst",
    getEmailList: API_URL + "CantactMst/emailIdList",
    getPhoneList: API_URL + "CantactMst/phoneList",
    getSourceList: API_URL + "EnqSourceMst/getEnqSourceMstLst",
}
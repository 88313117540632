import React, { createContext, useContext, useState } from "react"

const AccountContext = createContext()

export const AccountProvider = ({ children }) => {
  const [orgAccount, setOrgAccount] = useState(null)
  const [orgToken, setOrgToken] = useState()
  const [getResponse, setResponse] = useState([])
  const [singleUserLogin, setSingleUserLogin] = useState()
  const [sweetAlert, setSweetAlert] = useState("none")

  const countryOptions = [
    { value: "India", label: "India" },
    { value: "Pakistan", label: "Pakistan" },
    { value: "Delhi", label: "Delhi" },
    { value: "Badarpur", label: "Badarpur" },
    { value: "Noida", label: "Noida" },
    { value: "Haryana", label: "Haryana" },
    { value: "Gurugram", label:"Gurguram"},
    { value: "Saket", label:"Saket"},
    { value: "Add Location", label:"Add Location"}
  ]

  const locationOptions = [
    { value: "Delhi", label: "Delhi" },
    { value: "Badarpur", label: "Badarpur" },
    { value: "Noida", label: "Noida" },
    { value: "Haryana", label: "Haryana" },
    { value: "Gurugram", label:"Gurguram"},
    { value: "Saket", label:"Saket"},
    { value: "Add Location", label:"Add Location"}
  ]

  const value = {
    orgAccount,
    setOrgAccount,
    setOrgToken,
    orgToken,
    setResponse,
    getResponse,
    setSingleUserLogin,
    singleUserLogin,
    sweetAlert,
    setSweetAlert,
    countryOptions,
    locationOptions,
  }

  return (
    <AccountContext.Provider value={value}>{children}</AccountContext.Provider>
  )
}

export const useMainContext = () => {
  return useContext(AccountContext)
}

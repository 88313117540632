const getRequestWithJsonForOnline = async (apiUrl, params) => {
    return new Promise(async(resolve, reject) => {
        try {
            console.log('GET | URL | JSON | ONLINE :: ', apiUrl, params)
            await fetch(apiUrl+params, {
                method: 'GET'
            })
            .then(response => response.json())
            .then(response => {
                console.log('response - ', response)
                resolve(response)
            })
            .catch(error => {
                console.log('Error in API Response - ', error)
                reject(error)
            })
        } catch (error) {
            console.log('Error in API Response - ', error)
            reject(error)
        }
    })
}

const postRequestWithJsonForOnline = async (apiUrl, params) => {
    return new Promise(async(resolve, reject) => {
        try {
            console.log('POST | URL | JSON | ONLINE :: -> ', apiUrl, params)
            await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    Accept: 'applictaion/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(params)
            })
            .then(response => response.json())
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                console.log('Error in API Request -> ', error)
                reject(error)
            })
        } catch(error) {
            console.log('Error in API Request -> ', error)
            reject(error)
        }
    })
}

const postRequestWithParamsForOnline = async (apiUrl, params) => {
    return new Promise(async(resolve, reject) => {
        try {
            // console.log('POST | URL | JSON | ONLINE :: -> ', apiUrl, params)
            await fetch(apiUrl+params, {
                method: 'POST',
                headers: {
                    Accept: 'applictaion/json',
                    'Content-Type': 'application/json'
                },
            })
            .then(response => response.json())
            .then(response => {
                resolve(response)
            })
            .catch(error => {
                console.log('Error in API Request -> ', error)
                reject(error)
            })
        } catch(error) {
            console.log('Error in API Request -> ', error)
            reject(error)
        }
    })
}

const API = {
    postRequestWithJsonForOnline,
    postRequestWithParamsForOnline,
    getRequestWithJsonForOnline
}

export default API
import React, {useState} from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"

export default function AudioCall() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav" }`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav}/>
        {/* page wrapper  */}
        <div className="page-wrapper" style={{transform: "none",minHeight: "586px"}} >
          <div className="content">
            {/* <!-- /product list --> */}
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-xl-12"> 
                    <div className="conference-meet-group">
                      <div className="meeting-list">
                        {/* <!-- Horizontal View --> */}
                        <div className="join-contents horizontal-view fade-whiteboard">
                          <div className="join-video audio-calls user-active">
                            <div className="audio-call-group">
                              <ul>
                                <li className="active">
                                  <div className="avatar ">
                                    <img
                                      src="/img/profiles/avatar-02.jpg"
                                      className="rounded-circle"
                                      alt="image"
                                    />
                                    <div className="more-icon">
                                      <a href="javascript:void(0);">
                                        <i className="feather feather-radio"></i>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="user-audio-call">
                                    <h5>Mark Villiams</h5>
                                  </div>
                                </li>
                                <li>
                                  <div className="avatar ">
                                    <img
                                      src="/img/users/user-16.jpg"
                                      className="rounded-circle"
                                      alt="image"
                                    />
                                    <div className="more-icon audio-more-icon">
                                      <a
                                        href="javascript:void(0);"
                                        className="other-mic-off"
                                      >
                                        <i className="bx bx-microphone"></i>
                                      </a>
                                    </div>
                                  </div>
                                  <div className="user-audio-call">
                                    <h5>Benjamin</h5>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="record-time">
                              <span>40:12</span>
                            </div>
                            <div className="meet-drop meet-mutes-bottom">
                              <ul>
                                <li>
                                  <a
                                    href="javascript:void(0);"
                                    id="show-message"
                                  >
                                    <i className="bx bx-message-alt-dots"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* <!-- /Horizontal View --> */}
                      </div>
                      <div
                        className="right-user-side chat-rooms theiaStickySidebar mb-2"
                        id="chat-room"
                      >
                        <div className=" slime-grp">
                          <div className="left-chat-title">
                            <div className="chat-title">
                              <h4>Message</h4>
                            </div>
                            <div className="contact-close_call">
                              <a
                                href="#"
                                className="close_profile close_profile4"
                              >
                                <i data-feather="x"></i>
                              </a>
                            </div>
                          </div>
                          <div className="card-body-blk slimscroll  p-0">
                            <div className="chat-msg-blk ">
                              <div className="chats">
                                <div className="chat-avatar">
                                  <img
                                    src="/img/users/user-01.jpg"
                                    className="dreams_chat"
                                    alt="image"
                                  />
                                </div>
                                <div className="chat-content">
                                  <div className="message-content">
                                    <h4>Hi Everyone.!</h4>
                                  </div>
                                  <div className="chat-profile-name d-flex justify-content-end">
                                    <h6>10:00 AM</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="chats chats-right">
                                <div className="chat-content">
                                  <div className="message-content">
                                    <h4>
                                      Good Morning..! Today we have meeting
                                      about the new product.
                                    </h4>
                                  </div>
                                  <div className="chat-profile-name text-end">
                                    <h6>
                                      <i className="bx bx-check-double"></i>{" "}
                                      10:00
                                    </h6>
                                  </div>
                                </div>
                                <div className="chat-avatar">
                                  <img
                                    src="/img/users/user-02.jpg"
                                    className=" dreams_chat"
                                    alt="image"
                                  />
                                </div>
                              </div>
                              <div className="chats">
                                <div className="chat-avatar">
                                  <img
                                    src="/img/users/user-01.jpg"
                                    className=" dreams_chat"
                                    alt="image"
                                  />
                                </div>
                                <div className="chat-content">
                                  <div className="message-content">
                                    <h4>Hi.! Good Morning all.</h4>
                                  </div>
                                  <div className="chat-profile-name d-flex justify-content-end">
                                    <h6>10:00 AM</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="chats">
                                <div className="chat-avatar">
                                  <img
                                    src="/img/users/user-01.jpg"
                                    className=" dreams_chat"
                                    alt="image"
                                  />
                                </div>
                                <div className="chat-content">
                                  <div className="message-content">
                                    <h4>Nice..which category it belongs to?</h4>
                                  </div>
                                  <div className="chat-profile-name d-flex justify-content-end">
                                    <h6>10:00 AM</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="chats">
                                <div className="chat-avatar">
                                  <img
                                    src="/img/users/user-01.jpg"
                                    className=" dreams_chat"
                                    alt="image"
                                  />
                                </div>
                                <div className="chat-content">
                                  <div className="message-content">
                                    <h4>
                                      Great.! This is the second new product
                                      that comes in this week.
                                    </h4>
                                  </div>
                                  <div className="chat-profile-name d-flex justify-content-end">
                                    <h6>10:00 AM</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="chats">
                                <div className="chat-avatar">
                                  <img
                                    src="/img/users/user-01.jpg"
                                    className=" dreams_chat"
                                    alt="image"
                                  />
                                </div>
                                <div className="chat-content">
                                  <div className="message-content">
                                    <h4>Hi.! Good Morning all.</h4>
                                  </div>
                                  <div className="chat-profile-name d-flex justify-content-end">
                                    <h6>10:00 AM</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="chats">
                                <div className="chat-avatar">
                                  <img
                                    src="/img/users/user-01.jpg"
                                    className=" dreams_chat"
                                    alt="image"
                                  />
                                </div>
                                <div className="chat-content">
                                  <div className="message-content">
                                    <h4>Nice..which category it belongs to?</h4>
                                  </div>
                                  <div className="chat-profile-name d-flex justify-content-end">
                                    <h6>10:00 AM</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="chats chats-right">
                                <div className="chat-content">
                                  <div className="message-content">
                                    <h4>
                                      Good Morning..! Today we have meeting
                                      about the new product.
                                    </h4>
                                  </div>
                                  <div className="chat-profile-name text-end">
                                    <h6>
                                      <i className="bx bx-check-double"></i>{" "}
                                      10:00
                                    </h6>
                                  </div>
                                </div>
                                <div className="chat-avatar">
                                  <img
                                    src="/img/users/user-02.jpg"
                                    className="dreams_chat"
                                    alt="image"
                                  />
                                </div>
                              </div>
                              <div className="chats">
                                <div className="chat-avatar">
                                  <img
                                    src="/img/users/user-01.jpg"
                                    className=" dreams_chat"
                                    alt="image"
                                  />
                                </div>
                                <div className="chat-content">
                                  <div className="message-content">
                                    <h4>
                                      Great.! This is the second new product
                                      that comes in this week.
                                    </h4>
                                  </div>
                                  <div className="chat-profile-name d-flex justify-content-end">
                                    <h6>10:00 AM</h6>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="chat-footer">
                              <form>
                                <div className="smile-col comman-icon">
                                  <a href="#">
                                    <i className="far fa-smile"></i>
                                  </a>
                                </div>
                                <div className="attach-col comman-icon">
                                  <a href="#">
                                    <i className="fas fa-paperclip"></i>
                                  </a>
                                </div>
                                <div className="micro-col comman-icon">
                                  <a href="#">
                                    <i className="bx bx-microphone"></i>
                                  </a>
                                </div>
                                <input
                                  type="text"
                                  className="form-control chat_form"
                                  placeholder="Enter Message....."
                                />
                                <div className="send-chat comman-icon">
                                  <a href="#">
                                    <i data-feather="send"></i>
                                  </a>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="meet-call-menu-blk">
                      <div className="video-call-action">
                        <ul className="nav">
                          <li>
                            <a href="javascript:void(0);" className="mute-bt ">
                              <i className="bx bx-microphone"></i>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);" className="call-end">
                              <i data-feather="phone"></i>
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0);">
                              <i className="bx bx-video-off"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- /product list --> */}
          </div>
        </div>
      </div>
    </>
  )
}

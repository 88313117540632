import { useRef, useState } from "react"
import DatePicker from "react-datepicker"
import { Common } from "../../utils"
import { filterEnquiryListData, resetEnquiryListData } from "../slice/enquiry-slice"
import { useDispatch } from "react-redux"

const LeadListFilter = ({
    filterData,
    isGridView,
    setIsGridView,
    leadListData,
    productTypeListData,
    productCategoryListData,
    productListData,
    brandListData,
    countryListData,
    stateListData,
    cityListData,
    zipListData,
    locationListData,
    sourceListData,
    statusListData
}) => {

    const dispatch = useDispatch()
    const handleLeadNameFilter = (leadData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterLeadName) filterData.filterLeadName = []
            filterData?.filterLeadName.push(leadData)
        }
        else {
            if (filterData?.filterLeadName) {
                const index = filterData.filterLeadName.indexOf(leadData)
                if (index !== -1) {
                    filterData.filterLeadName.splice(index, 1)
                }
            }
        }
    }

    const handleProductTypeNameFilter = (productTypeData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterProductTypeName) filterData.filterProductTypeName = []
            filterData?.filterProductTypeName.push(productTypeData)
        }
        else {
            if (filterData?.filterProductTypeName) {
                const index = filterData.filterProductTypeName.indexOf(productTypeData)
                if (index !== -1) {
                    filterData.filterProductTypeName.splice(index, 1)
                }
            }
        }
    }

    const handleProductCategoryNameFilter = (productCategoryData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterProductCategoryName) filterData.filterProductCategoryName = []
            filterData?.filterProductCategoryName.push(productCategoryData)
        }
        else {
            if (filterData?.filterProductCategoryName) {
                const index = filterData.filterProductCategoryName.indexOf(productCategoryData)
                if (index !== -1) {
                    filterData.filterProductCategoryName.splice(index, 1)
                }
            }
        }
    }

    const handleProductNameFilter = (productData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterProductName) filterData.filterProductName = []
            filterData?.filterProductName.push(productData)
        }
        else {
            if (filterData?.filterProductName) {
                const index = filterData.filterProductName.indexOf(productData)
                if (index !== -1) {
                    filterData.filterProductName.splice(index, 1)
                }
            }
        }
    }

    const handleBrandNameFilter = (brandData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterBrandName) filterData.filterBrandName = []
            filterData?.filterBrandName.push(brandData)
        }
        else {
            if (filterData?.filterBrandName) {
                const index = filterData.filterBrandName.indexOf(brandData)
                if (index !== -1) {
                    filterData.filterBrandName.splice(index, 1)
                }
            }
        }
    }

    const handleCountryNameFilter = (countryData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterCountryName) filterData.filterCountryName = []
            filterData?.filterCountryName.push(countryData)
        }
        else {
            if (filterData?.filterCountryName) {
                const index = filterData.filterCountryName.indexOf(countryData)
                if (index !== -1) {
                    filterData.filterCountryName.splice(index, 1)
                }
            }
        }
    }

    const handleStateNameFilter = (stateData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterStateName) filterData.filterStateName = []
            filterData?.filterStateName.push(stateData)
        }
        else {
            if (filterData?.filterStateName) {
                const index = filterData.filterStateName.indexOf(stateData)
                if (index !== -1) {
                    filterData.filterStateName.splice(index, 1)
                }
            }
        }
    }

    const handleCityNameFilter = (cityData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterCityName) filterData.filterCityName = []
            filterData?.filterCityName.push(cityData)
        }
        else {
            if (filterData?.filterCityName) {
                const index = filterData.filterCityName.indexOf(cityData)
                if (index !== -1) {
                    filterData.filterCityName.splice(index, 1)
                }
            }
        }
    }

    const handleZipNameFilter = (zipData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterZipName) filterData.filterZipName = []
            filterData?.filterZipName.push(zipData)
        }
        else {
            if (filterData?.filterZipName) {
                const index = filterData.filterZipName.indexOf(zipData)
                if (index !== -1) {
                    filterData.filterZipName.splice(index, 1)
                }
            }
        }
    }

    const handleLocationNameFilter = (locationData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterLocationName) filterData.filterLocationName = []
            filterData?.filterLocationName.push(locationData)
        }
        else {
            if (filterData?.filterLocationName) {
                const index = filterData.filterLocationName.indexOf(locationData)
                if (index !== -1) {
                    filterData.filterLocationName.splice(index, 1)
                }
            }
        }
    }

    const handleSourceNameFilter = (sourceData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterSourceName) filterData.filterSourceName = []
            filterData?.filterSourceName.push(sourceData)
        }
        else {
            if (filterData?.filterSourceName) {
                const index = filterData.filterSourceName.indexOf(sourceData)
                if (index !== -1) {
                    filterData.filterSourceName.splice(index, 1)
                }
            }
        }
    }

    const handleStatusNameFilter = (statusData, isChecked) => {
        if (isChecked.target.checked) {
            if (!filterData?.filterStatusName) filterData.filterStatusName = []
            filterData?.filterStatusName.push(statusData)
        }
        else {
            if (filterData?.filterStatusName) {
                const index = filterData.filterStatusName.indexOf(statusData)
                if (index !== -1) {
                    filterData.filterStatusName.splice(index, 1)
                }
            }
        }
    }

    return (
        <>
            <div className="d-flex align-items-center flex-wrap row-gap-2">
                <div className="form-sorts dropdown me-2">
                    <a
                        href="#!;"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="outside"
                    >
                        <i className="ti ti-filter-share"></i>Filter
                    </a>
                    <div className="filter-dropdown-menu dropdown-menu  dropdown-menu-md-end p-3">
                        <div className="filter-set-view">
                            <div className="filter-set-head">
                                <h4>
                                    <i className="ti ti-filter-share"></i>Filter
                                </h4>
                            </div>
                            <div className="accordion" id="accordionExample">
                                <div className="filter-set-content">
                                    <div className="filter-set-content-head">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#leadName"
                                            aria-expanded="true"
                                            aria-controls="leadName"
                                        >
                                            Lead Name
                                        </a>
                                    </div>
                                    <div
                                        className="filter-set-contents accordion-collapse collapse show"
                                        id="leadName"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="filter-content-list">
                                            <ul>
                                                {leadListData?.map((leadData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="filter-checks">
                                                                <label className="checkboxs">
                                                                    <input 
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        onChange={(isChecked) => handleLeadNameFilter(leadData, isChecked)}
                                                                    />
                                                                    <span className="checkmarks"></span>
                                                                    {leadData?.fullName}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-set-content">
                                    <div className="filter-set-content-head">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#productType"
                                            aria-expanded="true"
                                            aria-controls="productType"
                                        >
                                            Product Type
                                        </a>
                                    </div>
                                    <div
                                        className="filter-set-contents accordion-collapse collapse"
                                        id="productType"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="filter-content-list">
                                            <ul>
                                                {productTypeListData?.map((productTypeData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="filter-checks">
                                                                <label className="checkboxs">
                                                                    <input 
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        onChange={(isChecked) => handleProductTypeNameFilter(productTypeData, isChecked)}
                                                                    />
                                                                    <span className="checkmarks"></span>
                                                                    {productTypeData?.productType}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-set-content">
                                    <div className="filter-set-content-head">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#productCategory"
                                            aria-expanded="true"
                                            aria-controls="productCategory"
                                        >
                                            Product Category
                                        </a>
                                    </div>
                                    <div
                                        className="filter-set-contents accordion-collapse collapse"
                                        id="productCategory"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="filter-content-list">
                                            <ul>
                                                {productCategoryListData?.map((productCategoryData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="filter-checks">
                                                                <label className="checkboxs">
                                                                    <input 
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        onChange={(isChecked) => handleProductCategoryNameFilter(productCategoryData, isChecked)}
                                                                    />
                                                                    <span className="checkmarks"></span>
                                                                    {productCategoryData?.productCategory}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-set-content">
                                    <div className="filter-set-content-head">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#productName"
                                            aria-expanded="true"
                                            aria-controls="productName"
                                        >
                                            Product
                                        </a>
                                    </div>
                                    <div
                                        className="filter-set-contents accordion-collapse collapse"
                                        id="productName"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="filter-content-list">
                                            <ul>
                                                {productListData?.map((productData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="filter-checks">
                                                                <label className="checkboxs">
                                                                    <input 
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        onChange={(isChecked) => handleProductNameFilter(productData, isChecked)}
                                                                    />
                                                                    <span className="checkmarks"></span>
                                                                    {productData?.productName}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-set-content">
                                    <div className="filter-set-content-head">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#brandName"
                                            aria-expanded="true"
                                            aria-controls="brandName"
                                        >
                                            Brand
                                        </a>
                                    </div>
                                    <div
                                        className="filter-set-contents accordion-collapse collapse"
                                        id="brandName"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="filter-content-list">
                                            <ul>
                                                {brandListData?.map((brandData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="filter-checks">
                                                                <label className="checkboxs">
                                                                    <input 
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        onChange={(isChecked) => handleBrandNameFilter(brandData, isChecked)}
                                                                    />
                                                                    <span className="checkmarks"></span>
                                                                    {brandData?.brandName}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-set-content">
                                    <div className="filter-set-content-head">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#countryName"
                                            aria-expanded="true"
                                            aria-controls="countryName"
                                        >
                                            Country
                                        </a>
                                    </div>
                                    <div
                                        className="filter-set-contents accordion-collapse collapse"
                                        id="countryName"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="filter-content-list">
                                            <ul>
                                                {countryListData?.map((countryData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="filter-checks">
                                                                <label className="checkboxs">
                                                                    <input 
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        onChange={(isChecked) => handleCountryNameFilter(countryData, isChecked)}
                                                                    />
                                                                    <span className="checkmarks"></span>
                                                                    {countryData?.countryName}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-set-content">
                                    <div className="filter-set-content-head">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#stateName"
                                            aria-expanded="true"
                                            aria-controls="stateName"
                                        >
                                            State
                                        </a>
                                    </div>
                                    <div
                                        className="filter-set-contents accordion-collapse collapse"
                                        id="stateName"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="filter-content-list">
                                            <ul>
                                                {stateListData?.map((stateData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="filter-checks">
                                                                <label className="checkboxs">
                                                                    <input 
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        onChange={(isChecked) => handleStateNameFilter(stateData, isChecked)}
                                                                    />
                                                                    <span className="checkmarks"></span>
                                                                    {stateData?.stateName}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-set-content">
                                    <div className="filter-set-content-head">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#cityName"
                                            aria-expanded="true"
                                            aria-controls="cityName"
                                        >
                                            City
                                        </a>
                                    </div>
                                    <div
                                        className="filter-set-contents accordion-collapse collapse"
                                        id="cityName"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="filter-content-list">
                                            <ul>
                                                {cityListData?.map((cityData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="filter-checks">
                                                                <label className="checkboxs">
                                                                    <input 
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        onChange={(isChecked) => handleCityNameFilter(cityData, isChecked)}
                                                                    />
                                                                    <span className="checkmarks"></span>
                                                                    {cityData?.cityName}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-set-content">
                                    <div className="filter-set-content-head">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#zipName"
                                            aria-expanded="true"
                                            aria-controls="zipName"
                                        >
                                            Zip
                                        </a>
                                    </div>
                                    <div
                                        className="filter-set-contents accordion-collapse collapse"
                                        id="zipName"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="filter-content-list">
                                            <ul>
                                                {zipListData?.map((zipData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="filter-checks">
                                                                <label className="checkboxs">
                                                                    <input 
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        onChange={(isChecked) => handleZipNameFilter(zipData, isChecked)}
                                                                    />
                                                                    <span className="checkmarks"></span>
                                                                    {zipData?.zipCode}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-set-content">
                                    <div className="filter-set-content-head">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#locationName"
                                            aria-expanded="true"
                                            aria-controls="locationName"
                                        >
                                            Location
                                        </a>
                                    </div>
                                    <div
                                        className="filter-set-contents accordion-collapse collapse"
                                        id="locationName"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="filter-content-list">
                                            <ul>
                                                {locationListData?.map((locationData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="filter-checks">
                                                                <label className="checkboxs">
                                                                    <input 
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        onChange={(isChecked) => handleLocationNameFilter(locationData, isChecked)}
                                                                    />
                                                                    <span className="checkmarks"></span>
                                                                    {locationData?.locationName}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-set-content">
                                    <div className="filter-set-content-head">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#sourceName"
                                            aria-expanded="true"
                                            aria-controls="sourceName"
                                        >
                                            Source
                                        </a>
                                    </div>
                                    <div
                                        className="filter-set-contents accordion-collapse collapse"
                                        id="sourceName"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="filter-content-list">
                                            <ul>
                                                {sourceListData?.map((sourceData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="filter-checks">
                                                                <label className="checkboxs">
                                                                    <input 
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        onChange={(isChecked) => handleSourceNameFilter(sourceData, isChecked)}
                                                                    />
                                                                    <span className="checkmarks"></span>
                                                                    {sourceData?.brandName}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="filter-set-content">
                                    <div className="filter-set-content-head">
                                        <a
                                            href="#"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#statusName"
                                            aria-expanded="true"
                                            aria-controls="statusName"
                                        >
                                            Status
                                        </a>
                                    </div>
                                    <div
                                        className="filter-set-contents accordion-collapse collapse"
                                        id="statusName"
                                        data-bs-parent="#accordionExample"
                                    >
                                        <div className="filter-content-list">
                                            <ul>
                                                {statusListData?.map((statusData, index) => {
                                                    return (
                                                        <li key={index}>
                                                            <div className="filter-checks">
                                                                <label className="checkboxs">
                                                                    <input 
                                                                        type="checkbox"
                                                                        value="checkbox"
                                                                        onChange={(isChecked) => handleStatusNameFilter(statusData, isChecked)}
                                                                    />
                                                                    <span className="checkmarks"></span>
                                                                    {statusData?.statusName}
                                                                </label>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </div>
                                </div>  
                            </div>
                            <div className="filter-reset-btns">
                                <div className="row">
                                    <div className="col-6" onClick={() => window.location.reload()}>
                                        <a href="#" className="btn btn-light">
                                            Reset
                                        </a>
                                    </div>
                                    <div className="col-6" onClick={() => {
                                        dispatch(resetEnquiryListData())
                                        dispatch(filterEnquiryListData(filterData))
                                    }}>
                                        <a
                                            href="javascript:void(0)"
                                            className="btn btn-primary"
                                        >
                                            Filter
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="view-icons">
                    <a
                        href="#!"
                        className={`${isGridView === 0 ? "active" : ""}`}
                        onClick={() => setIsGridView(0)}
                    >
                        <i className="ti ti-list-tree"></i>
                    </a>
                    <a
                        href="#!"
                        className={`${isGridView === 1 ? "active" : ""}`}
                        onClick={() => setIsGridView(1)}
                    >
                        <i className="ti ti-grid-dots"></i>
                    </a>
                </div>
            </div>
        </>
    )
}

export default LeadListFilter
import React,{useState} from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"

export default function ConnectedApps() {

  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav" }`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav}  />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h4 className="page-title">Settings</h4>
                    </div>
                    <div className="col-4 text-end">
                      <div className="head-icons">
                        <a
                          href="connected-apps.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                {/* <!-- Settings Menu --> */}
                <div className="card">
                  <div className="card-body pb-0 pt-2">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item me-3">
                        <a href="profile.html" className="nav-link px-0 active">
                          <i className="ti ti-settings-cog me-2"></i>General
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="company-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-world-cog me-2"></i>Website
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="invoice-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-apps me-2"></i>App Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a href="email-settings.html" className="nav-link px-0">
                          <i className="ti ti-device-laptop me-2"></i>System
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="payment-gateways.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-moneybag me-2"></i>Financial
                          Settings
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="storage.html" className="nav-link px-0">
                          <i className="ti ti-flag-cog me-2"></i>Other Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /Settings Menu --> */}

                <div className="row">
                  <div className="col-xl-3 col-lg-12 theiaStickySidebar">
                    {/* <!-- Settings Sidebar --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="settings-sidebar">
                          <h4 className="fw-semibold mb-3">General Settings</h4>
                          <div className="list-group list-group-flush settings-sidebar">
                            <a href="profile.html" className="fw-medium">
                              Profile
                            </a>
                            <a href="security.html" className="fw-medium">
                              Security
                            </a>
                            <a href="notifications.html" className="fw-medium">
                              Notifications
                            </a>
                            <a
                              href="connected-apps.html"
                              className="fw-medium active"
                            >
                              Connected Apps
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Sidebar --> */}
                  </div>

                  <div className="col-xl-9 col-lg-12">
                    {/* <!-- Settings Info --> */}
                    <div className="card">
                      <div className="card-body pb-0">
                        <h4 className="fw-semibold mb-3">Connected Apps</h4>
                        <div className="row">
                          {/* <!-- App --> */}
                          <div className="col-md-4 col-sm-6">
                            <div className="card border mb-3">
                              <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                  <img
                                    src="/img/icons/integration-01.svg"
                                    alt="Icon"
                                  />
                                  <div className="connect-btn">
                                    <a
                                      href="javascript:void(0);"
                                      className="badge badge-soft-success"
                                    >
                                      Connected
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p className="mb-0">Google Calendar</p>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /App --> */}

                          {/* <!-- App --> */}
                          <div className="col-md-4 col-sm-6">
                            <div className="card border mb-3">
                              <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                  <img
                                    src="/img/icons/integration-02.svg"
                                    alt="Icon"
                                  />
                                  <div className="connect-btn">
                                    <a
                                      href="javascript:void(0);"
                                      className="badge border bg-white text-default"
                                    >
                                      Connect
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p className="mb-0">Figma</p>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /App --> */}

                          {/* <!-- App --> */}
                          <div className="col-md-4 col-sm-6">
                            <div className="card border mb-3">
                              <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                  <img
                                    src="/img/icons/integration-03.svg"
                                    alt="Icon"
                                  />
                                  <div className="connect-btn">
                                    <a
                                      href="javascript:void(0);"
                                      className="badge badge-soft-success"
                                    >
                                      Connected
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p className="mb-0">Dropbox</p>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /App --> */}

                          {/* <!-- App --> */}
                          <div className="col-md-4 col-sm-6">
                            <div className="card border mb-3">
                              <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                  <img
                                    src="/img/icons/integration-04.svg"
                                    alt="Icon"
                                  />
                                  <div className="connect-btn">
                                    <a
                                      href="javascript:void(0);"
                                      className="badge border bg-white text-default"
                                    >
                                      Connect
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p className="mb-0">Slack</p>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /App --> */}

                          {/* <!-- App --> */}
                          <div className="col-md-4 col-sm-6">
                            <div className="card border mb-3">
                              <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                  <img
                                    src="/img/icons/integration-05.svg"
                                    alt="Icon"
                                  />
                                  <div className="connect-btn">
                                    <a
                                      href="javascript:void(0);"
                                      className="badge badge-soft-success"
                                    >
                                      Connected
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p className="mb-0">Gmail</p>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /App --> */}

                          {/* <!-- App --> */}
                          <div className="col-md-4 col-sm-6">
                            <div className="card border mb-3">
                              <div className="card-body">
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                  <img
                                    src="/img/icons/integration-06.svg"
                                    alt="Icon"
                                  />
                                  <div className="connect-btn">
                                    <a
                                      href="javascript:void(0);"
                                      className="badge border bg-white text-default"
                                    >
                                      Connect
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p className="mb-0">Github</p>
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /App --> */}
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Info --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

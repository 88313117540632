import { useNavigate } from "react-router"
import { useEffectOnce } from "react-use"
import { Common, Constant, Preferences } from "../../../utils"
import React, { useEffect, useRef, useState } from "react"
import VerticalMenuComponent from "../../../components/VerticalMenuComponent"
import TopMenuComponent from "../../../components/TopMenuComponent"
import ContactForm from "../contact-form"
import {
  fetchContactList,
  getContactListData,
  getContactListFilterData,
  getContactListLoading,
  getContactListUniqueData,
} from "../../../store/slice/contact-slice"
import { connect, useDispatch } from "react-redux"
import CustomTableGrid from "../../../components/custom-table-grid"
import CustomDataTableComponent from "../../../components/page-components/CustomDataTableComponent"
import { Form } from "react-bootstrap"
import CustomAlert from "../../../components/custom-alerts/CustomAlert"
import CustomLoader from "../../../components/custom-loader/CustomLoader"
import { fetchEmailList, getEmailListUniqueData } from "../../../store/slice/email-slice"
import { fetchPhoneList, getPhoneListUniqueData } from "../../../store/slice/phone-slice"
import ContactListFilter from "../../../store/filters/contact-list-filter"
import { fetchStatusList, getStatusListUniqueData } from "../../../store/slice/status-slice"
import { fetchCountryList, getCountryListData } from "../../../store/slice/country-slice"
import TopPageHeader from "../../../components/TopPageHeader"
import DatePicker from "react-datepicker"
import API from "../../../apis"
import { urls } from "../../../apis/urls"
import { getSessionStorageData } from "../../../utils/preferences"

const Contacts = ({
  isLoading, 
  contactListData,
  contactListUniqueData,
  emailListUniqueData,
  phoneListUniqueData,
  statusListUniqueData,
  filterListData,
  countryListData
}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  useEffectOnce(() => {
    Common.checkLogin(navigate)
  })

  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isGridView, setIsGridView] = useState(0)
  const [showAlert, setShowAlert] = useState(false)
  const [alertDetails, setAlertDetails] = useState('')

  const [searchValue, setSearchValue] = useState("")

  const [formType, setFormType] = useState(Constant.FORM_TYPE_NEW)
  const [selectedData, setSelectedData] = useState(null)
  const [filterData, setFilterData] = useState({})
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const selectedDateTypeRef = useRef(null)

  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  const handleSearchValue = (event) => {
    setSearchValue(event.target.value)
  }

  useEffect(() => {
    getApiData()
  }, [])

  const getApiData = () => {
    dispatch(fetchContactList())
    dispatch(fetchEmailList())
    dispatch(fetchPhoneList())
    dispatch(fetchStatusList())
    dispatch(fetchCountryList())
  }

  filterListData = filterListData?.filter((item) => {
    const searchLower = searchValue.toLowerCase()
    let name = item?.contactName.toLocaleLowerCase().includes(searchLower)
    let multiNumbers = item?.phoneNumbers.split(",")
    let multiEmails = item?.emailIds.split(",")
    let phone = Array.isArray(multiNumbers) ? multiNumbers.some((phone) => phone.toLocaleLowerCase().includes(searchLower)) : item.phoneNumbers.toLocaleLowerCase().includes(searchLower)
    let email = Array.isArray(multiEmails) ? multiEmails.some((email) => email.toLocaleLowerCase().includes(searchLower)) : item.emailIds.toLocaleLowerCase().includes(searchLower)
    let createdDate = item?.createdDate.toLocaleLowerCase().includes(searchLower)

    let filterFromDate = false
    if (selectedDateTypeRef != null && selectedDateTypeRef?.current?.value === 'createdDate') filterFromDate = (fromDate && new Date(item.createdDate) >= new Date(fromDate))
    else if (selectedDateTypeRef != null && selectedDateTypeRef?.current?.value === 'modifiedDate') filterFromDate = (fromDate && new Date(item.modifiedDate) >= new Date(fromDate))

    let filterToDate = false
    if (selectedDateTypeRef != null && selectedDateTypeRef?.current?.value === 'createdDate') filterToDate = (toDate && new Date(item.createdDate) <= new Date(toDate).setDate(toDate.getDate() + 1))
    else if (selectedDateTypeRef != null && selectedDateTypeRef?.current?.value === 'modifiedDate') filterToDate = (toDate && new Date(item.modifiedDate) <= new Date(toDate).setDate(toDate.getDate() + 1))

    return (name || phone || email || createdDate) && (filterFromDate || !fromDate) && (filterToDate || !toDate)
  })

  const [radioToggle, setRadioToggle] = useState(true)

  const columns = [
    
    {
      name: "Name",
      sortable: true,
      selector: (row) => row.contactName,
      cell: (row) => (
        <a href="javascript:void(0)" className="text-default">
          {row.contactName}
        </a>
      ),
    },
    {
      name: "Email",
      selector: (row) => row.emailIds,
      cell: (row) => {
        const emails = Array.isArray(row.emailIds)
          ? row.emailIds
          : row.emailIds.split(",")
        return (
          <>
            <div className="d-flex flex-column">
              {emails.map((email, index) => (
                <span key={index} className="text-default">
                  {email.trim()}
                  {index < emails.length - 1 && ", "}
                </span>
              ))}
            </div>
          </>
        )
      },
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phoneNumbers,
      cell: (row) => {
        const phones = Array.isArray(row.phoneNumbers)
          ? row.phoneNumbers
          : row.phoneNumbers.split(",")
        return (
          <>
            <div className="d-flex flex-column">
              {phones.map((phone, index) => (
                <span key={index} className="text-default">
                  {phone.trim()}
                  {index < phones.length - 1 && ", "}
                </span>
              ))}
            </div>
          </>
        )
      },
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.statusName,
      sortable: true,
      cell: (row) => {
        let statusClass = ""
        if (row.statusId === "0") {
          statusClass = "bg-success"
        } else if (row.statusId === "1") {
          statusClass = "bg-danger"
        } else if (row.statusId === "2") {
          statusClass = "bg-pending"
        } else {
          statusClass = "bg-warning"
        }
        return (
          <span className={`badge badge-pill badge-status ${statusClass}`}>
            {row.statusName}
          </span>
        )
      },
    },
    {
      name: "Created By",
      selector: (row) => row.createdByName,
      cell: (row) =>(
        <div className="d-flex flex-column">
            <span className="text-default">{row.createdByName}</span>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => row.createdDate,
      sortable: true,
      cell: (row) => {        
        const formattedDate = Common.getDateSubString(row.createdDate, "yyyy-mm-dd", "DD-MMM-yyyy")
        return (
          <div className="d-flex flex-column">
            <span className="text-default">
              {formattedDate}
            </span>
          </div>
        )
      },
    },
    
    {
      name: "Action",
      button: true,
      cell: (row) => (
        <div className="outer-action-box">
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-original-title="Edit"
          >
            <a
              className="dropdown-item"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas_add"
              href="#!"
              onClick={() => {
                setFormType(Constant.FORM_TYPE_EDIT)
                setSelectedData({ ...row })
              }}
            >
              <i className="ti ti-edit text-blue"></i>
            </a>
          </div>

          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-original-title="View"
          >
            <a
              className="dropdown-item"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas_add"
              href="#"
              onClick={() => {
                setFormType(Constant.FORM_TYPE_VIEW)
                setSelectedData({ ...row })
              }}
            >
              <i className="ti ti-eye text-blue-light"></i>
            </a>
          </div>
        </div>
      ),
    },
    {
      name: "Active / InActive",
      sortable: false,
      cell: (row) => (
        <div className="ms-4">
          <Form.Check
            type="switch"
            id="custom-switch"
            checked={row.isActive}
            onChange={(event) => handleActiveToggleButton(row, event.target.checked)}
            className={`customRadio ${radioToggle === true ? "" : ""}`}
          />
        </div>
      ),
    },
  ]
  
  const handleActiveToggleButton = (contactData, isActive) => {
    setShowAlert(true)
    setAlertDetails({
      title: 'Warning',
      desc: isActive === true ? 'Are you sure want to activate the record ?' : 'Are you sure want to in-active the record ?',
      type: Constant.STATUS_WARNING,
      showCloseIcon: false,
      rightButtonName: 'OK',
      onRightButtonClick: () => {
        setShowAlert(false)
        submitData(contactData, isActive)
      }
    })
  }

  const submitData = async (contactData, isActive) => {
    setLoading(true)

    const finalEmail = []
    contactData.emailIds.split(',').forEach(function (item) {
      finalEmail.push({
        emailId: item,
      })
    })

    const finalPhoneNumber = []
    contactData.phoneNumbers.split(',').forEach(function (item) {
      finalPhoneNumber.push({
        phoneNumber: item,
      })
    })

    const params = {
      pk_ContactId: contactData.pk_ContactId,
      contactName: contactData.contactName,
      fk_UsrOrgDirId: contactData.fk_UsrOrgDirId,
      isActive: isActive,
      createdBy: contactData.createdBy,
      isFlag: 1,
      emailId: finalEmail,
      phoneNumber: finalPhoneNumber,
    }
    console.log("params - ", params)
    API.postRequestWithJsonForOnline(
      urls.createContact +
        `?tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`,
      params
    )
      .then(apiResponse)
      .catch(() => {
        setLoading(false)
      })
  }

  const apiResponse = (response) => {
    setLoading(false)
    if (response.result.response_Status === "success") {
      setAlertDetails({
        title: response.result.response_Status,
        desc: response.result.response_Message,
        type: Constant.STATUS_SUCCESS,
        showCloseIcon: false,
        rightButtonName: 'OK',
        onRightButtonClick: () => {
          setShowAlert(false)
          Common.refershPage()
        }
      })
      setShowAlert(true)
    } 
    else if (response.result.response_Status === "error") {
      setAlertDetails({
        title: response.result.response_Status,
        desc: response.result.response_Message,
        type: Constant.STATUS_ERROR,
        showCloseIcon: false,
        rightButtonName: 'OK',
        onRightButtonClick: () => {
          setShowAlert(false)
        }
      })
      setShowAlert(true)
    }
  }

  const handleExportExcel = () =>{
    Common.exportToExcel(Constant.selectedContactListColumns, contactListData)
  }

  const handlePrintData = () =>{
    Common.exportToPrint(Constant.selectedContactListColumns, contactListData)
  }

  const handleCopyData = () =>{
    Common.handleCopyList(Constant.selectedContactListColumns, contactListData)
    setShowAlert(true)
    setAlertDetails({
      title:"Success",
      desc:"Table data copied to clipboard!",
      type:"success",
      onOkClick: () =>{
        setShowAlert(false)
      }      
    })
  }

  return (
    <div className={`main-wrapper ${isOpen && "slide-nav"}`}>

      {showAlert && (
        <CustomAlert
          alertTitle={alertDetails.title}
          alertDesc={alertDetails.desc}
          customAlertType={alertDetails.type}
          showCloseIcon={alertDetails.showCloseIcon}
          rightButtonName={alertDetails.rightButtonName}
          onRightButtonClick={alertDetails.onRightButtonClick}
        />
      )}
      {/* header component  */}
      <VerticalMenuComponent />
      <TopMenuComponent openSideNav={openSideNav} />
      {isLoading && (
        <CustomLoader/>
      )}

      {/* page wrapper  */}
      <div className="page-wrapper">

        <div className="content">
          <div className="row">
            <div className="col-md-12">            
              {/* <!-- Page Header --> */}
              <div className="page-header">                
                <TopPageHeader 
                  pageTitle={"Contact"}
                  countTitle={filterListData?.length === 0 ? "0" :filterListData?.length}
                />
              </div>
              {/* <!-- /Page Header --> */}

              <div className="card">
                <div className="card-header">
                  {/* <!-- Search --> */}
                  <div className="row align-items-center">
                    <div className="col-sm-4">
                      <div className="icon-form mb-3 mb-sm-0">
                        <span className="form-icon">
                          <i className="ti ti-search"></i>
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          onChange={handleSearchValue}
                        />
                      </div>
                    </div>
                    <div className="col-sm-8">
                      <div className="d-flex align-items-center flex-wrap row-gap-2 justify-content-sm-end">
                        <div className="me-2 bulk-uploads">
                          <div className="outer-upload-box">
                            <i className="ti ti-upload me-2"></i>
                            <input
                              type="file"
                              id="fileUpload"
                              className="file-upload"
                              name="fileUpload"
                            />
                            <label for="fileUpload">Upload Bulks</label>
                          </div>
                        </div>
                        <div className="dropdown me-2">
                          <a
                            href="javascript:void(0);"
                            className="dropdown-toggle"
                            data-bs-toggle="dropdown"
                          >
                            <i className="ti ti-package-export me-2"></i>
                            Export
                          </a>
                          <div className="dropdown-menu  dropdown-menu-end">
                            <ul>
                              {filterListData?.length > 0 ? (
                                <li
                                  onClick={handleExportExcel}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    className="dropdown-item"
                                  >
                                    <i className="ti ti-file-type-xls text-green me-1"></i>
                                    Export as Excel
                                  </a>
                                </li>
                              ) : (
                                <li>No Record Found</li>
                              )}

                              {filterListData?.length > 0 ? (
                                <li onClick={handlePrintData}>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >                                    
                                    <i class="ti ti-printer text-green me-1"></i>
                                    Print{" "}
                                  </a>
                                </li>
                              ) : (
                                <li>No Record Found</li>
                              )}
                              {filterListData?.length > 0 ? (
                                <li onClick={handleCopyData}>
                                  <a
                                    href="javascript:void(0);"
                                    className="dropdown-item"
                                  >                                    
                                    <i class="ti ti-clipboard-check text-green me-1"></i>
                                    Copy{" "}
                                  </a>
                                </li>
                              ) : (
                                <li>No Record Found</li>
                              )}
                            </ul>
                          </div>
                        </div>
                        <a
                          className="btn btn-primary"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvas_add"
                          onClick={() => setFormType(Constant.FORM_TYPE_NEW)}
                        >
                          <i className="ti ti-square-rounded-plus me-2"></i>
                          Add Contact
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /Search --> */}
                </div>
                <div className="card-body">
                <div className="d-flex align-items-center justify-content-between flex-wrap row-gap-2 mb-4">
                <div className="d-flex align-items-center flex-wrap row-gap-2">
                    <div className="dropdown me-2">
                        <select className="form-select" disabled="" ref={selectedDateTypeRef} onChange={() => {
                          setFromDate('')
                          setToDate('')
                        }}>
                          <option value="createdDate">Created Date</option>
                          <option value="modifiedDate">Modified Date</option>
                        </select>
                    </div>
                    <div className="dropdown me-2">
                        <a href="javascript:void(0);" className="d-flex align-items-center gap-2" style={{color:"#262A2A"}} >
                          From
                          <DatePicker
                            selected={fromDate}
                            onChange={(date) => {
                              setFromDate(date)
                              setToDate('')
                            }}
                            maxDate={Common.getTodayDate()}
                            dateFormat="dd-MMM-yyyy" 
                            placeholderText="dd-MMM-yyyy"
                            className="calenderInput"
                          />
                          <span className="form-icon">
                            <i className="ti ti-calendar"></i>
                          </span>
                        </a>
                    </div>
                    <div className="dropdown me-2">
                        <a href="javascript:void(0);" className="d-flex align-items-center gap-2" style={{color:"#262A2A"}}>
                          To
                          <DatePicker
                            selected={toDate}
                            onChange={(date) => {
                              setToDate(date)
                            }}
                            minDate={fromDate}
                            maxDate={Common.getTodayDate()}
                            dateFormat="dd-MMM-yyyy" 
                            placeholderText="dd-MMM-yyyy"
                            className="calenderInput"
                          />
                          <span className="form-icon">
                            <i className="ti ti-calendar"></i>
                          </span>
                        </a>
                    </div>
                  </div>
                  {/* <!-- Filter --> */}
                  <ContactListFilter
                    filterData={filterData}
                    isGridView={isGridView}
                    setIsGridView={setIsGridView}
                    contactListData={contactListUniqueData}
                    emailListData={emailListUniqueData}
                    phoneListData={phoneListUniqueData}
                    statusListData={statusListUniqueData}
                  />
                  {/* <!-- /Filter --> */}
                  </div>

                  {/* <!-- contacts List --> */}
                  {isGridView === 0 ? (
                    <CustomDataTableComponent
                      rowData={filterListData}
                      columns={columns}
                    />
                  ) : (
                    <CustomTableGrid data={filterListData} />
                  )}

                  {/* <!-- /contacts List --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Add contacts --> */}
      <ContactForm
        formType={formType}
        selectedData={selectedData}
        setFormType={setFormType}
        countryListData={countryListData}
      />
      {/* <!-- /Add contacts --> */}
    </div>
  )
}

const mapStateToProps = (state) => ({
  isLoading: getContactListLoading(state),
  contactListData: getContactListData(state),
  contactListUniqueData: getContactListUniqueData(state),
  emailListUniqueData: getEmailListUniqueData(state),
  phoneListUniqueData: getPhoneListUniqueData(state),
  statusListUniqueData: getStatusListUniqueData(state),
  filterListData: getContactListFilterData(state),
  countryListData: getCountryListData(state)
})

export default connect(mapStateToProps)(Contacts)

export const LOGIN_DATA='LOGIN_DATA'
export const COMPANY_DATA='COMPANY_DATA'
export const IS_LOGIN_SUCCESS='isLoginSuccess'
export const TOKEN_KEY='TOKENKEY'
export const CONTACT_LIST = 'CONTACTLIST'
export const PRODUCT_TYPE_LIST = 'PRODUCTTYPELIST'
export const PRODUCT_CATEGORY_LIST = 'PRODUCTCATEGORYLIST'
export const PRODUCT_LIST = 'PRODUCTLIST'
export const BRAND_LIST = 'BRANDLIST'
export const ENQUIRY_LIST = 'ENQUIRYLIST'
export const COUNTRY_LIST = 'COUNTRYLIST'
export const STATE_LIST = 'STATELIST'
export const CITY_LIST = 'CITYLIST'
export const ZIP_LIST = 'ZIPLIST'
export const LOCATION_LIST = 'LOCATIONLIST'
export const STATUS_LIST = "STATUSLIST"
export const EMAIL_LIST = "EMAILLIST"
export const PHONE_LIST = "PHONELIST"
export const SOURCE_LIST = "SOURCELIST"

export const saveLocalStorageData = (key, value) => {
    localStorage.setItem(key, value)
}

export const getLocalStorageData = (key) => {
    return localStorage.getItem(key)
}

export const removeLocalStorageData = (key) => {
    localStorage.removeItem(key)
}

export const clearAllLocalStorage = (isClear) => {
    if (isClear) localStorage.clear()
}

export const saveSessionStorageData = (key, value) => {
    localStorage.setItem(key, value)
}

export const getSessionStorageData = (key) => {
    return localStorage.getItem(key)
}

export const removeSessionStorageData = (key) => {
    localStorage.removeItem(key)
}

export const clearAllSessionStorage = (isClear) => {
    if (isClear) localStorage.clear()
}

export const saveCookieData = (key, value, expiryDateFull) => {
    const expiryYear = expiryDateFull
    const expiryMonth = expiryDateFull
    const expiryDate = expiryDateFull
    document.cookie = `${key}=${value}; expires=${new Date(expiryYear, expiryMonth, expiryDate).toUTCString()}`
}

export const getCookieData = (key) => {
    return document.cookie()
}

export const removeCookieData = (key) => {

}

export const clearAllCookieStorage = () => {

}

export const clearAllStorage = (isClear) => {
    clearAllLocalStorage(isClear)
    clearAllSessionStorage(isClear)
    clearAllCookieStorage(isClear)
}
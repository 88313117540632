import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import React from 'react'
import Activities from './pages/activity-module/activity-list'
import Analytics from './pages/analytics'
import CompanyList from './pages/company-module/company-list'
import Deals from './pages/deal-module/deal-list'
import Estimations from './pages/estimation-module/estimation-list'
import Invoices from './pages/invoice-module/invoice-list'
import Payments from './pages/payment-module/payment-list'
import Pipeline from './pages/pipeline-module/pipeline-list'
import Projects from './pages/project-module/project-list'
import Proposals from './pages/proposal-module/proposal-list'
import Tasks from './pages/tasks'
import Dashboard from './pages/dashboard'
import Login from './pages/login'
import Faq from './pages/faq'
import Email from './pages/email'
import Profile from './pages/profile'
import LeadsDashboard from './pages/leads-dashboard'
import ProjectDashboard from './pages/project-dashboard'
import AudioCall from './pages/audio-call'
import CallHistory  from './pages/call-history'
import Calendar from './pages/calendar'
import Todo from './pages/todo'
import Notes from './pages/notes'
import FileManager from './pages/file-manager'
import LostReason from './pages/lost-reason'
import ContactStage from './pages/contact-stage'
import Industry from './pages/industry'
import Calls from './pages/calls'
import Security from './pages/security'
import Notifications from './pages/notifications'
import ConnectedApps from './pages/connected-apps'
import CompanySettings from './pages/company-settings'
import Localization from './pages/localization'
import Prefixes from './pages/prefixes'
import Language from './pages/language'
import Preference from './pages/preference'
import Appearance from './pages/appearance'
import InvoiceSettings from './pages/invoice-settings'
import Printers from './pages/printers'
import CustomFields from './pages/custom-fields'
import EmailSettings from './pages/email-settings'
import SmsGateway from './pages/sms-gateway'
import PaymentGateways from './pages/payment-gateways'
import BankAccounts from './pages/bank-accounts'
import TaxRates from './pages/tax-rates'
import Currencies from './pages/currencies'
import Storage from './pages/storage'
import BanIPAddress from './pages/ban-ip-address'
import DealReports from './pages/deal-reports'
import CompanyReports from './pages/company-reports'
import ProjectReports from './pages/project-reports'
import TaskReports from './pages/task-reports'
import Register from './pages/register'
import ForgotPassword from './pages/forgot-password'
import Leads from './pages/lead-module/lead-list'
import TeamList from './pages/team-module/team-list'
import CallLogs from './pages/call-log-module/call-logs'
import CallLogDetail from './pages/call-log-module/call-log-detail'
import Contacts from './pages/contact-module/contact-list'
import Products from './pages/product-module/product-list'
import Brand from './pages/brand-module/brand-list'

import ProductCategoryList from './pages/product-module/product-category/product-category-list'

import ProductTypeList from './pages/product-module/product-type/product-type-list'
import IframeSettings from './pages/iframe-settings'
import SourcesList from './pages/source-module/source-list'
 
function App() {
    return (
        <>
            <React.StrictMode>
                    <Router>
                        <Routes>
                            <Route path="/" element={<Login/>} />
                            <Route path='/login' element={<Login/>} />
                            <Route path='/dashboard' element={<Dashboard/>} />
                            <Route path='/activities' element={<Activities/>} />
                            <Route path='/analytics' element={<Analytics/>} />                    
                            <Route path='/companies' element={<CompanyList/>} />
                            <Route path='/contact' element={<Contacts/>} />
                            <Route path='/contracts' element={<Contacts/>} />
                            <Route path='/deals' element={<Deals/>} />
                            <Route path='/estimations' element={<Estimations/>} />
                            <Route path='/invoices' element={<Invoices/>} />
                            <Route path='/leads' element={<Leads/>} />
                            <Route path='/payments' element={<Payments/>} />
                            <Route path='/pipeline' element={<Pipeline/>} />
                            <Route path='/projects' element={<Projects/>} />
                            <Route path='/proposals' element={<Proposals/>} />
                            <Route path='/tasks' element={<Tasks/>} />
                            <Route path='/faq' element={<Faq/>} />
                            <Route path='/email' element={<Email/>} />
                            <Route path='lead-reports'  element={<Leads/>} />
                            <Route path='/profile' element={<Profile/>} />
                            <Route path='/leads-dashboard' element={<LeadsDashboard/>} />
                            <Route path='/project-dashboard'  element={<ProjectDashboard/>}  />
                            <Route path='/audio-call' element={<AudioCall/>} />
                            <Route path='/call-history' element={<CallHistory/>} />
                            <Route path='/calendar' element={<Calendar/>} />
                            <Route path='/todo' element={<Todo/>}  />
                            <Route path='/notes' element={<Notes/>} />
                            <Route path='/file-manager' element={<FileManager/>} />
                            <Route path='/source' element={<SourcesList/>}/>
                            <Route path='/lost-reason' element={<LostReason/>} />
                            <Route path='/contact-stage' element={<ContactStage/>} />
                            <Route path='/industry' element={<Industry/>} />
                            <Route path='/calls' element={<Calls/>} />
                            <Route path='/security' element={<Security/>} />
                            <Route path='/notifications' element={<Notifications/>} />
                            <Route path='/connected-apps' element={<ConnectedApps/>} />
                            <Route path='/company-settings' element={<CompanySettings/>} />
                            <Route path='/localization' element={<Localization/>} />
                            <Route path='/prefixes' element={<Prefixes/>} />
                            <Route path='/language' element={<Language/>} />
                            <Route path='/preference' element={<Preference/>} />
                            <Route path='/appearance' element={<Appearance/>} />
                            <Route path='/invoice-settings' element={<InvoiceSettings/>} />
                            <Route path='/printers' element={<Printers/>} />
                            <Route path='/custom-fields' element={<CustomFields/>} />
                            <Route path='/email-settings' element={<EmailSettings/>} />
                            <Route path='/sms-gateways' element={<SmsGateway/>} />
                            <Route path='/payment-gateways' element={<PaymentGateways/>} />
                            <Route path="/bank-accounts" element={<BankAccounts/>} />
                            <Route path='/tax-rates' element={<TaxRates/>} />
                            <Route path='/currencies' element={<Currencies/>} />
                            <Route path='/storage' element={<Storage/>} />
                            <Route path='/ban-ip-address' element={<BanIPAddress/>} />
                            <Route path='/deal-reports' element={<DealReports/>} />
                            <Route path='/contact-reports' element={<Contacts/>} />
                            <Route path='/company-reports' element={<CompanyReports/>} />
                            <Route path='/project-reports' element={<ProjectReports/>} />
                            <Route path='/task-reports' element={<TaskReports/>} />
                            <Route path='/register' element={<Register/>}/>
                            <Route path='/forgot-password' element={<ForgotPassword/>}  />
                            <Route path='/product' element={<Products/>}  />  
                            <Route path='/brand' element={<Brand/>}/>                                      
                            <Route path='/team' element={<TeamList/>} /> 
                            <Route path='/call-logs' element={<CallLogs/>} />
                            <Route path='/call-log-detail/:id' element={<CallLogDetail/>}/>     
                            <Route path='/product-type' element={<ProductTypeList/>} />
                            <Route path='/product-category' element={<ProductCategoryList/>} />
                            <Route path='/enquiry-widget' element={<IframeSettings/>}/>
                        </Routes>
                    </Router>
            </React.StrictMode>
        </>
    )
}

export default App
import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"

export const productListAdapter = createEntityAdapter()

export const fetchProductList = createAsyncThunk(
    Preferences.PRODUCT_LIST,
    async (thunkApi) => {
        const params = `?fk_UsrOrgDirId=${
            JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
        }&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getProductList, params)
        if (response?.result?.response_Status === 'success') {
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status == 'error') {
            alert(response?.result?.response_Message)
        } else {
            alert('Something went wrong with getProductData')
        }
    }
)

export const initialAppState = productListAdapter.getInitialState({
    loading: 'idel',
    productListData: [],
    filterData: [],
    error: []
})

export const productListSlice = createSlice({
    name: Preferences.PRODUCT_LIST,
    initialState: initialAppState,
    reducers: {
        resetProductListFilter: state => {
            state.filterData = state.productListData
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchProductList.pending, state => {
            state.loading = 'loading'
        })
        builder.addCase(fetchProductList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.productListData = action.payload ?? []
            state.error
        })
        builder.addCase(fetchProductList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    }
})

export const productListReducer = productListSlice.reducer

export const getProductListLoading = state => {
    return state.productListReducer.loading === 'loading' ? true : false
}

export const getProductListError = state => {
    return state.productListReducer.error
}

export const getProductListFilterData = state => {
    return state.productListReducer.filterData
}

export const getProductListData = state => {
    return state.productListReducer.productListData
}

export const getProductListDataByID = (state, {productID}) => {
    return state.productListReducer.filterData.filter(e => e?.pk_productId == productID)
}
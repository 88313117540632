import { combineReducers } from "redux"
import { sessionReducer } from "redux-react-session"
import { contactListReducer } from "./contact-slice"
import { productListReducer } from "./product-slice"
import { brandListReducer } from "./brand-slice"
import { enquiryListReducer } from "./enquiry-slice"
import { countryListReducer } from "./country-slice"
import { stateListReducer } from "./state-slice"
import { cityListReducer } from "./city-slice"
import { zipListReducer } from "./zip-slice"
import { locationListReducer } from "./location-slice"
import { productTypeListReducer } from "./product-type-slice"
import { productCategoryListReducer } from "./product-category-slice"
import { statusListReducer } from "./status-slice"
import { emailListReducer } from "./email-slice"
import { phoneListReducer } from "./phone-slice"
import { sourceListReducer } from "./source-slice"

export const rootReducer = combineReducers({
    session: sessionReducer,
    contactListReducer,
    productTypeListReducer,
    productCategoryListReducer,
    productListReducer,
    brandListReducer,
    enquiryListReducer,
    countryListReducer,
    stateListReducer,
    cityListReducer,
    zipListReducer,
    locationListReducer,
    statusListReducer,
    emailListReducer,
    phoneListReducer,
    sourceListReducer
})

import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"

export default function Localization() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h4 className="page-title">Settings</h4>
                    </div>
                    <div className="col-4 text-end">
                      <div className="head-icons">
                        <a
                          href="localization.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                {/* <!-- Settings Menu --> */}
                <div className="card">
                  <div className="card-body pb-0 pt-2">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item me-3">
                        <a href="profile.html" className="nav-link px-0">
                          <i className="ti ti-settings-cog me-2"></i>General
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="company-settings.html"
                          className="nav-link px-0 active"
                        >
                          <i className="ti ti-world-cog me-2"></i>Website
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="invoice-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-apps me-2"></i>App Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a href="email-settings.html" className="nav-link px-0">
                          <i className="ti ti-device-laptop me-2"></i>System
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="payment-gateways.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-moneybag me-2"></i>Financial
                          Settings
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="storage.html" className="nav-link px-0">
                          <i className="ti ti-flag-cog me-2"></i>Other Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /Settings Menu --> */}

                <div className="row">
                  <div className="col-xl-3 col-lg-12 theiaStickySidebar">
                    {/* <!-- Settings Sidebar --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="settings-sidebar">
                          <h4 className="fw-semibold mb-3">Website Settings</h4>
                          <div className="list-group list-group-flush settings-sidebar">
                            <a
                              href="company-settings.html"
                              className="fw-medium"
                            >
                              Company Settings
                            </a>
                            <a
                              href="localization.html"
                              className="fw-medium active"
                            >
                              Localization
                            </a>
                            <a href="prefixes.html" className="fw-medium">
                              Prefixes
                            </a>
                            <a href="preference.html" className="fw-medium">
                              Preference
                            </a>
                            <a href="appearance.html" className="fw-medium">
                              Appearance
                            </a>
                            <a href="language.html" className="fw-medium">
                              Language
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Sidebar --> */}
                  </div>

                  <div className="col-xl-9 col-lg-12">
                    {/* <!-- Prefixes --> */}
                    <div className="card">
                      <div className="card-body">
                        <h4 className="fw-semibold mb-3">Localization</h4>
                        <form action="localization.html">
                          <div className="border-bottom mb-3 pb-3">
                            <h5 className="fw-semibold mb-1">
                              Basic Information
                            </h5>
                            <p>Provide the basic information below</p>
                          </div>
                          <div className="border-bottom mb-3">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Language</h6>
                                  <p>Select Language of the website</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>English</option>
                                    <option>Chinese</option>
                                    <option>Hindi</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Language Switcher</h6>
                                  <p>To display in all the pages</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <div className="status-toggle">
                                    <input
                                      type="checkbox"
                                      id="prefer3"
                                      className="check"
                                      checked=""
                                    />
                                    <label
                                      for="prefer3"
                                      className="checktoggle"
                                    >
                                      {" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Timezone</h6>
                                  <p>
                                    Select date format to display in website
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>UTC 4:30</option>
                                    <option>(UTC+11:00) INR</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Date Format</h6>
                                  <p>Select Language of the website</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>01 Jan 2023</option>
                                    <option>01-Jan-2023</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Time Format</h6>
                                  <p>
                                    Select time format to display in website
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>English</option>
                                    <option>12 Hours</option>
                                    <option>24 Hours</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Financial Year</h6>
                                  <p>Select year for finance</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>2023</option>
                                    <option>2024</option>
                                    <option>2022</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Starting Month</h6>
                                  <p>Select starting month to display</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>January</option>
                                    <option>February</option>
                                    <option>March</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-bottom mb-3 pb-3">
                            <h5 className="fw-semibold mb-1">
                              Currency Settings
                            </h5>
                            <p>Provide the currency information below</p>
                          </div>
                          <div className="border-bottom mb-3">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Currency</h6>
                                  <p>Select currency</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>
                                      United States Of America
                                    </option>
                                    <option>India</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Currency Symbol</h6>
                                  <p>Select currency symbol</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>$</option>
                                    <option>€</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Currency Position</h6>
                                  <p>Select currency position</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>$100</option>
                                    <option>$400</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Decimal Seperator</h6>
                                  <p>Select decimal seperator</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>,</option>
                                    <option>,</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Thousand Seperator</h6>
                                  <p>Select thousand seperator</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>,</option>
                                    <option>.</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-bottom mb-3 pb-3">
                            <h5 className="fw-semibold mb-1">
                              Country Settings
                            </h5>
                            <p>Provide the country information below</p>
                          </div>
                          <div className="border-bottom mb-3">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Countries Restriction</h6>
                                  <p>Select restricted countries</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>
                                      Allow All Countries
                                    </option>
                                    <option>Deny All Countries</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-bottom mb-3 pb-3">
                            <h5 className="fw-semibold mb-1">File Settings</h5>
                            <p>Provide the files information below</p>
                          </div>
                          <div className="border-bottom mb-3 border-0 mb-0">
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Allowed Files</h6>
                                  <p>Select allowed files</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <input
                                    className="input-tags form-control"
                                    type="text"
                                    data-role="tagsinput"
                                    name="Label"
                                    value="JPG, PNG, GIF"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h6>Max File Size</h6>
                                  <p>Select size of the files</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="5000MB"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <a href="#" className="btn btn-light me-2">
                              Cancel
                            </a>
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!-- /Prefixes --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

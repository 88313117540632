import React,{useState} from 'react'
import VerticalMenuComponent from '../../components/VerticalMenuComponent'
import TopMenuComponent from '../../components/TopMenuComponent'
import CustomDataTableComponent from '../../components/page-components/CustomDataTableComponent'
import { AgCharts } from 'ag-charts-react'
import YearChart from '../../components/page-components/charts/YearChart'
import HorizontalStageChart from '../../components/page-components/charts/HorizontalStageChart'
import TopPageHeader from '../../components/TopPageHeader'

export default function LeadsDashboard() {

	const [isOpen, setIsOpen] = useState(false)
	const openSideNav = () => {
	  setIsOpen(!isOpen)
	}
 
	const Data = [

		{
		  id: 1,
		  contacts_name: "Collins",
		  company_name: "NovaWave LLC",
		  company_image: "assets/img/icons/company-icon-01.svg",
		  company_address: "Newyork, USA",
		  phone: "+1 875455453",
		  email: "robertson@example.com",
		  created_date: "25 Sep 2023, 01:22 pm",
		  owner: ["Hendry", "Two", "Three"],
		  source: "Paid Social",
		  status: "0",
		  teamMember:["Hendry", "Two", "Three"]
		},
		{
		  id: 2,
		  contacts_name: "Konopelski",
		  company_name: "BlueSky Industries",
		  company_image: "assets/img/icons/company-icon-02.svg",
		  company_address: "Winchester, KY",
		  phone: "+1 989757485",
		  email: "sharon@example.com",
		  created_date: "29 Sep 2023, 04:15 pm",
		  owner: "Guillory",
		  source: "Referrals",
		  status: "2",
		  teamMember:["asdasHendry", "asfTwo", "Three"]
	  
		},
	  
		{
		  id: 3,
		  contacts_name: "ravi one",
		  company_name: "BlueSky Industries",
		  company_image: "assets/img/icons/company-icon-02.svg",
		  company_address: "Winchester, KY",
		  phone: "+1 989757485",
		  email: "sharon@example.com",
		  created_date: "29 Sep 2023, 04:15 pm",
		  owner: "Guillory",
		  source: "Referrals",
		  status: "2",
		  teamMember:["asdasHendry", "asfTwo"]
		},
	  
		{
		  id: 4,
		  contacts_name: "ravi two",
		  company_name: "BlueSky Industries",
		  company_image: "assets/img/icons/company-icon-02.svg",
		  company_address: "Winchester, KY",
		  phone: "+1 989757485",
		  email: "sharon@example.com",
		  created_date: "29 Sep 2023, 04:15 pm",
		  owner: "Guillory",
		  source: "Referrals",
		  status: "2",
		  teamMember:["asdasHendry", "Team Member"]
		},
	  
		{
		  id: 5,
		  contacts_name: "ravi three",
		  company_name: "BlueSky Industries",
		  company_image: "assets/img/icons/company-icon-02.svg",
		  company_address: "Winchester, KY",
		  phone: "+1 989757485",
		  email: "sharon@example.com",
		  created_date: "29 Sep 2023, 04:15 pm",
		  owner: "Guillory",
		  source: "Referrals",
		  status: "2",
		  teamMember:["asdasHendry", "Team Member"]
	  
		},
	  
		{
		  id: 6,
		  contacts_name: "ravi four",
		  company_name: "BlueSky Industries",
		  company_image: "assets/img/icons/company-icon-02.svg",
		  company_address: "Winchester, KY",
		  phone: "+1 989757485",
		  email: "sharon@example.com",
		  created_date: "29 Sep 2023, 04:15 pm",
		  owner: "Guillory",
		  source: "Referrals",
		  status: "2",
		  teamMember:["asdasHendry", "Team Member"]
	  
		},
	  ]

	  const columns = [    
		{
		  name: "Lead Name",
		  selector: (row) => row.contacts_name,
		  cell: (row) => (
			<div className="d-flex flex-column">
			  <span className="text-default">{row.contacts_name}</span>
			</div>
		  ),
		  sortable: true,
		},
	
		{
		  name: "Company Name",
		  selector: (row) => row.company_name,
		  cell: (row) => (
			<div className="d-flex flex-column">
			  <span className="text-default">{row.company_name}</span>            
			</div>
		  ),
		  sortable: true,
		},
	
		{
		  name: "Phone",
		  selector: (row) => row.phone,
		  sortable: true,
		},	
	
		{
		  name: "Lead Status",
		  selector: (row) => row.status,
		  sortable: true,
		  cell: (row) => {
			let statusClass = ""
			let statusName = ""
			if (row.status === "0") {
			  statusClass = "badge-success-light"
			  statusName = "Closed"
			} else if (row.status === "1") {
			  statusClass = "badge-purple-light"
			  statusName = "Lost"
			} else if (row.status === "2") {
			  statusClass = "badge-danger-light"
			  statusName = "Not Contacted"
			} else {
			  statusClass = ["badge-tag", "bg-warning-light"]
			  statusName = "Contacted"
			}
			return (
			  <span className={`badge badge-tag ${statusClass}`}>{statusName}</span>
			)
		  },
		},     
	  ]

	//   Pie Chart for Project By Stage
	  const projectStageData = [ 
	{ asset: "Inpipeline", amount: 27.7 },
    { asset: "Follow Up", amount: 400 },
    { asset: "Schedule Service", amount: 700 },
    { asset: "Conversation", amount: 500 },
	]
		const [options, setOptions] = useState({
			data: projectStageData,
			title: {
			  text: "",
			},
			series: [
			  {
				type: "pie",
				angleKey: "amount",
				legendItemKey: "asset",
			  },
			],
		  })


  return (
    <>        
    	<div className={`main-wrapper ${isOpen && "slide-nav"}`}>
                    {/* header component  */}
                    <VerticalMenuComponent/>
                    <TopMenuComponent openSideNav={openSideNav}/>
                    {/* page wrapper  */}
                    <div className='page-wrapper'>
            <div className='content'>          
					<div className="row">
						<div className="col-md-12">
							<div className="page-header">
								<TopPageHeader pageTitle="Leads Dashboard"/>
							</div>
						</div>
					</div>                                        
				<div className="row">
					<div className="col-md-7">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Recently Created Leads</h4>
									<div className="dropdown">
										<a className="dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0);">
											<i className="ti ti-calendar-check me-2"></i>Last 30 days
										</a>
										<div className="dropdown-menu dropdown-menu-end">
											<a href="javascript:void(0);" className="dropdown-item">
												Last 15 days
											</a>
											<a href="javascript:void(0);" className="dropdown-item">
												Last 30 days
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
								<CustomDataTableComponent rowData={Data} columns={columns} />
							</div>
						</div>
					</div>
					<div className="col-md-5 d-flex">
						<div className="card w-100">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Projects By Stage</h4>
									<div className="dropdown">
										<a className="dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0);">
											Last 30 Days
										</a>
										<div className="dropdown-menu dropdown-menu-end">
											<a href="javascript:void(0);" className="dropdown-item">
												Last 30 Days
											</a>
											<a href="javascript:void(0);" className="dropdown-item">
												Last 15 Days
											</a>
											<a href="javascript:void(0);" className="dropdown-item">
												Last 7 Days
											</a>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
								<AgCharts options={options}/>								
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12 d-flex">
						<div className="card w-100">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Projects By Stage</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Sales Pipeline
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Marketing Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Sales Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Email
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Chats
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Operational
												</a>
											</div>
										</div>
										<div className="dropdown">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 30 Days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
									<YearChart/>							
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Leads By Stage</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Marketing Pipeline
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Marketing Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Sales Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Email
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Chats
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Operational
												</a>
											</div>
										</div>
										<div className="dropdown">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 3 months
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 3 months
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 6 months
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 12 months
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
								<HorizontalStageChart/>
							</div>
						</div>
					</div>
					<div className="col-md-6">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Won Deals Stage</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Marketing Pipeline
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Marketing Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Sales Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Email
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Chats
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Operational
												</a>
											</div>
										</div>
										<div className="dropdown">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 3 months
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 3 months
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 6 months
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 12 months
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body ">
								<HorizontalStageChart/>
							</div>
						</div>
					</div>
				</div>


                            </div>
                    </div>

        </div>

    
    
    </>
  )
}

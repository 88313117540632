import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { Provider } from "react-redux"
import { sessionService } from "redux-react-session"
import { AccountProvider } from "./context/MainContext"
import { store } from "./store/store"

const sessionConfig = {
  driver: "localStorage",
  expires: 3600000,
}
sessionService.initSessionService(store, sessionConfig)

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <AccountProvider>
    <Provider store={store}>
      <App />
    </Provider>
  </AccountProvider>
)

reportWebVitals()

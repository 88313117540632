import React, { useEffect, useRef, useState } from "react"
import { Common, Constant, Preferences } from "../../../utils"
import "./style.css"
import CustomAlerts from "../../custom-alerts/CustomAlert"
import API from "../../../apis"
import { urls } from "../../../apis/urls"
import { getSessionStorageData } from "../../../utils/preferences"
import emailValidator from "../../../helpers/emailValidator"

const AddContact = ({
  formType,
  selectedData,
  setFormType,
  hideContactForm,
  showAddContactForm,
  countryListData
}) => {
  const [loading, setLoading] = useState("")
  const [contactName, setContactName] = useState("")
  const [contactEmail, setContactEmail] = useState([])
  const [contactNumber, setContactNumber] = useState([])
  const [addMoreEmailValues, setAddMoreEmailValues] = useState([])
  const [addMoreNumberValues, setAddMoreNumberValues] = useState([])
  const [moreEmailInputs, setMoreEmailInputs] = useState([])
  const [moreNumberInputs, setMoreNumberInputs] = useState([])
  const [selectedCountryCodeMaxLength, setSelectedCountryCodeMaxLength] = useState({})

  const contactNameRef = useRef(null)
  const contactEmailRef = useRef(null)
  const contactPhoneRef = useRef(null)
  
  const maxEmailInputs = 4
  const maxNumberInputs = 4

  const [showAlert, setShowAlert] = useState(false)
  const [alertDetails, setAlertDetails] = useState('')

  const [isEmailEditable, setIsEmailEditable] = useState(true)
  const [isNumberEditable, setIsNumberEditable] = useState(true)

  const [countryCodeValue, setCountryCodeValue] = useState("91")

  
  useEffect(() => {
    if (selectedData) {
      setContactName(selectedData?.contactName || "")
      setContactEmail(selectedData?.emailIds?.split(",") || [])
      setContactNumber(selectedData?.phoneNumbers?.split(",") || [])
      setIsEmailEditable(false)
      setIsNumberEditable(false)      
    }
    if(contactEmail.length >= maxEmailInputs){
      setContactEmail(contactEmail)
      console.log("Selected :: ", contactEmail.length)
    }
    if(contactNumber.length >= maxNumberInputs){
      setContactName(contactNumber)
      console.log("Selected :: ", contactNumber.length)
    }
  }, [selectedData])

  const onContactName = (event) => setContactName(event.target.value)

  const onContactEmail = (index, event) => {
    const updatedEmails = [...contactEmail]
    updatedEmails[index] = event.target.value
    setContactEmail(updatedEmails)
  }

  const onContactNumber = (index, event) => {
    const updatedNumbers = [...contactNumber]
    updatedNumbers[index] = event.target.value
    setContactNumber(updatedNumbers)        
    const countryData = (JSON.parse(Preferences.getSessionStorageData('CountryList'))).find((country) => country.phoneCode === parseInt(countryCodeValue.toString().trim()))
    const maxLength = countryData.numcode
    contactNumber.map((num) =>{
      if(num.length > maxLength){
        updatedNumbers[index] = num.substring(0,9)          
      }
    })    
    setSelectedCountryCodeMaxLength((prevMaxLength) => ({ ...prevMaxLength, [index]: maxLength }))
  }

  const clearInputValues = () => {
    setContactName("")
    setContactEmail([])
    setContactNumber([])
    setAddMoreEmailValues([])
    setAddMoreNumberValues([])
    setMoreEmailInputs([])
    setMoreNumberInputs([])
    setFormType(Constant.FORM_TYPE_EDIT)
    setIsEmailEditable(true)
    setIsNumberEditable(true)
  }

  const checkValidations = () => {
    if (!contactName) {
      setAlertDetails({
        title: 'Validation Error',
        desc: 'Name cannot be empty',
        type: 'error',
        showCloseIcon: false,
        rightButtonName: 'OK',
        onRightButtonClick: () => {
          contactNameRef.current.focus()
          setShowAlert(false)
        }
      })
      setShowAlert(true)
      return false
    }

    if (!contactNumber[0]) {
      setAlertDetails({
        title: 'Validation Error',
        desc: 'Phone number cannot be empty',
        type: 'error',
        showCloseIcon: false,
        rightButtonName: 'OK',
        onRightButtonClick: () => {
          contactPhoneRef.current.focus()
          setShowAlert(false)
        }
      })
      setShowAlert(true)
      return false
    }

    submitData()
  }

  const checkEmailValidation = (emailList) => {
    let invalidEmails = ''
    console.log("Email List ::", emailList)
    emailList.forEach((email) => {
        if (email.emailId && !emailValidator(email.emailId)) {
            invalidEmails = invalidEmails + email.emailId + ', \n'
        }
    })
    if (invalidEmails) {
        setAlertDetails({
          title: 'Validation Error',
          desc: `Please enter a valid email address for \n\n${invalidEmails}`,
          type: Constant.STATUS_ERROR,
          showCloseIcon: false,
          rightButtonName: 'OK',
          onRightButtonClick: () => {
            setShowAlert(false)
          }
        })
        setShowAlert(true)
        return false
    }
    return true
  }

  const submitData = async () => {
    const finalEmail = []
    const emailRef = [...contactEmail, ...addMoreEmailValues]
    emailRef.forEach(function (item) {
      finalEmail.push({
        emailId: item.trim(),
      })
    })

    if (!checkEmailValidation(finalEmail)) return false

    const finalNumber = []
    const phoneRef = [...contactNumber, ...addMoreNumberValues]
    phoneRef.forEach(function (item) {
      finalNumber.push({
        phoneNumber: item,
      })
    })

    setLoading(true)
    const params = {
      pk_ContactId: formType == Constant.FORM_TYPE_NEW ? 0 : selectedData.pk_ContactId,
      contactName: contactName,
      fk_UsrOrgDirId: JSON.parse(
        getSessionStorageData(Preferences.COMPANY_DATA)
      ).pk_UsrOrgDirId,
      isActive: formType == Constant.FORM_TYPE_NEW ? true : true,
      createdBy: JSON.parse(getSessionStorageData(Preferences.LOGIN_DATA))
        .pk_UsrId,
      isFlag: 0,
      emailId: finalEmail,
      phoneNumber: finalNumber,
    }
    console.log("params - ", params)

    API.postRequestWithJsonForOnline(
      urls.createContact +
        `?tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`,
      params
    )
      .then(apiResponse)
      .catch(() => {
        setLoading(false)
      })
  }

  const apiResponse = (response) => {
    setLoading(false)
    if (response.result.response_Status === "success") {
      clearInputValues()
      setAlertDetails({
        title: response.result.response_Status,
        desc: response.result.response_Message,
        type: Constant.STATUS_SUCCESS,
        showCloseIcon: false,
        rightButtonName: 'OK',
        onRightButtonClick: () => {
          setShowAlert(false)
          Common.refershPage()
        }
      })
      setShowAlert(true)
    } 
    else if (response.result.response_Status === "error") {
      setAlertDetails({
        title: response.result.response_Status,
        desc: response.result.response_Message,
        type: Constant.STATUS_ERROR,
        showCloseIcon: false,
        rightButtonName: 'OK',
        onRightButtonClick: () => {
          setShowAlert(false)
        }
      })
      setShowAlert(true)
    }
  }

  const addMoreEmail = () => {
    if (contactEmail?.length === 0 || !contactEmail) {
      setAlertDetails({
        title: 'Validation Error',
        desc: 'Add Contact Email',
        type: 'error',
        showCloseIcon: false,
        rightButtonName: 'OK',
        onRightButtonClick: () => {
          contactEmailRef.current.focus()
          setShowAlert(false)
        }
      })
      setShowAlert(true)
      return
    }
    else if(moreEmailInputs.length >= maxEmailInputs){
      return setMoreEmailInputs(moreEmailInputs)
    }
    else{
      setMoreEmailInputs([...moreEmailInputs, { id: Date.now() }])
    }
    setMoreEmailInputs([...moreEmailInputs, { id: Date.now() }])
  }

  const deleteMoreEmail = (id) => {
    const filteredEmail = moreEmailInputs.filter((email) => email.id !== id)
    setMoreEmailInputs(filteredEmail)
  }

  const deleteContactEmail = (mail) => {
    const filteredContactEmail = contactEmail.filter((email) => email !== mail)
    setContactEmail(filteredContactEmail)

    if(filteredContactEmail.length ===0){
      setIsEmailEditable(true)
    }
  }

  const deleteContactNumber = (num) => {
    const filteredNumber = contactNumber.filter((number) => number !== num)
    setContactNumber(filteredNumber)

    if(filteredNumber.length === 0){
      setIsNumberEditable(true)
    }
  }

  const addMoreNumber = () => {
    if (contactNumber?.length === 0 || !contactNumber) {
      setAlertDetails({
        title: 'Validation Error',
        desc: 'Add Contact Number',
        type: 'error',
        showCloseIcon: false,
        rightButtonName: 'OK',
        onRightButtonClick: () => {
          contactPhoneRef.current.focus()
          setShowAlert(false)
        }
      })
      setShowAlert(true)
      return
    }
    else if(moreNumberInputs.length >= maxNumberInputs){
      return setMoreNumberInputs(moreNumberInputs)
    }
    else{
      setMoreNumberInputs([...moreNumberInputs, { id: Date.now() }])
    }
    setMoreNumberInputs([...moreNumberInputs, { id: Date.now() }])
  }

  const deleteNumber = (id) => {
    const filteredNumber = moreNumberInputs.filter((num) => num.id !== id)
    setMoreNumberInputs(filteredNumber)
  }

  const onAddMoreEmailChange = (idx, e) => {
    const updatedEmails = [...addMoreEmailValues]
    updatedEmails[idx] = e.target.value
    setAddMoreEmailValues(updatedEmails)
  }

  const onAddMoreNumberChange = (idx, e) => {
    const updatedNumbers = [...addMoreNumberValues]
    updatedNumbers[idx] = e.target.value
    setAddMoreNumberValues(updatedNumbers)
  }

  const [isActive, setIsActive] = useState("active")
  const onActiveStatus = (e) => { 
    setIsActive(e.target.value)
  }


  const handleCountryCodeChange = (e, idx) => {
    setCountryCodeValue(e.target.value)      
    const countryData = (JSON.parse(Preferences.getSessionStorageData('CountryList'))).find((country) => country.phoneCode === parseInt(countryCodeValue.toString().trim()))    
    const maxLength = countryData.numcode
    setSelectedCountryCodeMaxLength((prevMaxLength) => ({ ...prevMaxLength, [idx]: maxLength }))
    console.log("max length", maxLength)
  }

  return (
    <>
      {showAlert && (
        <CustomAlerts 
          alertTitle={alertDetails.title}
          alertDesc={alertDetails.desc}
          showCloseIcon={alertDetails.showCloseIcon}
          onCloseClick={() => setShowAlert(false)}
          leftButtonName={alertDetails.leftButtonName ?? ''}
          onLeftButtonClick={alertDetails.onLeftButtonClick}
          rightButtonName={alertDetails.rightButtonName ?? ''}
          onRightButtonClick={alertDetails.onRightButtonClick}
          customAlertType={alertDetails.type}
        />
      )}

      <div className="outer-modal-contentbox">
        
        <div className="offcanvas-header border-bottom">
          <h5 className="fw-semibold">            
            {formType === Constant.FORM_TYPE_EDIT ? "Update Contact" : (formType === Constant.FORM_TYPE_NEW ? "Add Contact" : (formType === Constant.FORM_TYPE_VIEW ? "Contact Details" : ""))}
          </h5>
          {showAddContactForm === true ? (
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              onClick={hideContactForm}
            >
              <i className="ti ti-x"></i>
            </button>
          ) : (
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              data-bs-dismiss="offcanvas"
              onClick={() => clearInputValues()}
              aria-label="Close"
            >
              <i className="ti ti-x"></i>
            </button>
          )}
        </div>         
        <div className="modal-content">
          <div className={`modal-body ${ !showAddContactForm ? "customModal" :""}`}>
            <form>
              <div className="mb-3">
                <label htmlFor="recipient-name" className="col-form-label">
                  Full Name <span style={{ color: 'red' }}>*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Name"
                  onChange={onContactName}
                  value={contactName}
                  disabled={formType === Constant.FORM_TYPE_VIEW}
                  ref={contactNameRef}
                />
              </div>

              <div className="mb-3">
                <label className="col-form-label">Email</label>
                {
                  formType !=Constant.FORM_TYPE_VIEW &&(
                    moreEmailInputs.length >=maxEmailInputs ?(
                      <span hidden></span>
                    ):<span className="add-more-email" onClick={addMoreEmail}>Add More Email</span>
                  )
                }
                {/* Default Email  */}

                {  isEmailEditable === true && (
                  <input
                    type="email"
                    className="form-control"
                    placeholder="Enter Email"
                    value={contactEmail}
                    onChange={(e) => onContactEmail(0, e)}
                    disabled={formType === Constant.FORM_TYPE_VIEW}
                    ref={contactEmailRef}
                    autoFocus
                  /> 
                  )}

                {/* Edit Emails  */}

                {
                  isEmailEditable === false && (
                    contactEmail.map((email, idx) => (
                      <div
                        key={idx}
                        className={
                          contactEmail.length > 0 ? "moreEmailOuterBox" : ""
                        }
                      >
                        <input 
                          type="email"
                          className="form-control"
                          placeholder="Enter Email"
                          value={email}
                          onChange={(e) => onContactEmail(idx, e)}
                          disabled={formType === Constant.FORM_TYPE_VIEW}
                          autoFocus
                        />
                        {formType !== Constant.FORM_TYPE_VIEW && (
                          <span
                            className="delete-email"
                            onClick={() => deleteContactEmail(email)}
                          >
                            <img src="/img/delete.png" alt="Delete" />
                          </span>
                        )}
                      </div>
                    ))
                  )
                }


                {moreEmailInputs.map((email, idx) => (
                  <div className="moreEmailOuterBox" key={email.id}>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Email"
                      disabled={formType === Constant.FORM_TYPE_VIEW}
                      onChange={(e) => onAddMoreEmailChange(idx, e)}
                      value={addMoreEmailValues[idx] || ""}
                    />
                    <span
                      className="delete-email"
                      onClick={() => deleteMoreEmail(email.id, email)}
                    >
                      <img src="/img/delete.png" alt="Delete" />
                    </span>
                  </div>
                ))}
              </div>

              <div className="mb-3">
                <label className="col-form-label">Phone Number <span style={{ color: 'red' }}>*</span></label>
                {formType !== Constant.FORM_TYPE_VIEW && (
                  moreNumberInputs.length >=maxNumberInputs ?(
                    <span hidden></span>
                  ):<span className="add-more-email" onClick={addMoreNumber}>Add Phone Number</span>
                )}

                {/* Default Contact Number  */}
                
                {isNumberEditable === true &&(
                  <div className="outer-number-box">                        
                    <select  className="country-numbers-field" onChange={(e) => handleCountryCodeChange(e, 0)} disabled={formType === Constant.FORM_TYPE_VIEW}>
                      {(JSON.parse(Preferences.getSessionStorageData('CountryList')))?.map((countryData, index) =>
                        <option value={countryData.phoneCode} >{countryData.phoneCode}</option>
                      )}
                    </select>
                    <input
                      type="tel"
                      className="form-control"
                      placeholder="Enter Phone Number"
                      onChange={(e) => onContactNumber(0, e)}
                      value={contactNumber}
                      disabled={formType === Constant.FORM_TYPE_VIEW}
                      ref={contactPhoneRef}
                      maxLength={selectedCountryCodeMaxLength[0]}
                      autoFocus
                    />
                  </div>
                )}

                {/* Edit Contact Numbers  */}
                {
                  isNumberEditable === false && (
                    contactNumber.map((phone, idx) => (
                      <div key={idx} className={`${contactNumber.length > 0 ? "moreEmailOuterBox" :"" } outer-number-box"`} >
                        <select  
                          className="country-numbers-field" 
                          onChange={(e) => handleCountryCodeChange(e, idx)}
                          disabled={formType === Constant.FORM_TYPE_VIEW}>
                            {(JSON.parse(Preferences.getSessionStorageData('CountryList')))?.map((countryData, index) =>
                              <option value={countryData.phoneCode} key={index}>{countryData.phoneCode}</option>
                            )}
                        </select>
  
                        <input
                          type="tel"
                          className="form-control"
                          placeholder="Enter Phone Number"
                          onChange={(e) => onContactNumber(idx, e)}
                          value={phone}
                          disabled={formType === Constant.FORM_TYPE_VIEW}
                          maxLength={selectedCountryCodeMaxLength[idx]}
                          autoFocus
                        />

                        {formType !== Constant.FORM_TYPE_VIEW && (
                          <span
                            className="delete-number"
                            onClick={() => deleteContactNumber(phone)}
                          >
                            <img src="/img/delete.png" alt="Delete" />
                          </span>
                        )}
                      </div>
                    ))
                  )
                }

                {moreNumberInputs.map((number, idx) => (
                  <div key={number.id} className="moreNumberOuterBox outer-number-box">
                    <select  className="country-numbers-field" onChange={(e) => handleCountryCodeChange(e, idx)} disabled={formType === Constant.FORM_TYPE_VIEW}>
                      {(JSON.parse(Preferences.getSessionStorageData('CountryList')))?.map((countryData, index) =>
                        <option>{countryData.phoneCode}</option>
                      )}
                    </select>
                    <input                    
                      type="tel"
                      className="form-control"
                      placeholder="Enter Number"
                      disabled={formType === Constant.FORM_TYPE_VIEW}
                      onChange={(e) => onAddMoreNumberChange(idx, e)}
                      maxLength={selectedCountryCodeMaxLength[idx]}
                      value={addMoreNumberValues[idx] || ""}
                      />                                            
                      <span className="delete-number"
                        onClick={() => deleteNumber(number.id)}>
                        <img src="/img/delete.png" alt="Delete" />
                      </span>
                  </div>
                ))}

              </div>

              {formType != Constant.FORM_TYPE_EDIT && (
                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Status <span style={{ color: 'red' }}>*</span>
                  </label>
                  <select class="form-select" onChange={onActiveStatus} value={isActive} disabled={formType === Constant.FORM_TYPE_VIEW}>
										<option value="active" >Active</option>
                    <option value="inactive" >In Active</option>
								  </select>                
                </div>
              )}
              

            </form>
          </div>
          <div className="modal-footer">
            {
              formType !== Constant.FORM_TYPE_VIEW  &&(
              <button
                type="button"
                className="btn btn-primary"
                onClick={checkValidations}
                disabled={formType === Constant.FORM_TYPE_VIEW}>
                {formType === Constant.FORM_TYPE_EDIT ? "Update" : "Submit"}

              </button>
              )
            }           
          </div>
        </div>

      </div>
    </>
  )
}

export default AddContact

import React,{useState} from 'react'
import VerticalMenuComponent from '../../components/VerticalMenuComponent'
import TopMenuComponent from '../../components/TopMenuComponent'
export default function Industry() {

	const [isOpen, setIsOpen] = useState(false)
	const openSideNav = () => {
	  setIsOpen(!isOpen)
	}

  return (
    <>
    
    <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
                    {/* header component  */}
                    <VerticalMenuComponent/>
                    <TopMenuComponent  openSideNav={openSideNav} />

                    {/* page wrapper  */}
                    <div className='page-wrapper'>
                            <div className='content'>
                                  
                            <div className="row">
					<div className="col-md-12">

						{/* <!-- Page Header --> */}
						<div className="page-header">
							<div className="row align-items-center">
								<div className="col-sm-8">
									<h4 className="page-title">Industry <span className="count-title">123</span></h4>
								</div>
								<div className="col-sm-4 text-sm-end">
									<div className="head-icons">
										<a href="industry.html" data-bs-toggle="tooltip" data-bs-placement="top"
											data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
										<a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top"
											data-bs-original-title="Collapse" id="collapse-header"><i
												className="ti ti-chevrons-up"></i></a>
									</div>
								</div>
							</div>
						</div>
						{/* <!-- /Page Header --> */}

						<div className="card">
							<div className="card-header">
								{/* <!-- Search --> */}
								<div className="row align-items-center">
									<div className="col-sm-4">
										<div className="icon-form mb-3 mb-sm-0">
											<span className="form-icon"><i className="ti ti-search"></i></span>
											<input type="text" className="form-control" placeholder="Search Industry"/>
										</div>
									</div>
									<div className="col-sm-8">
										<div className="text-sm-end">
											<a href="javascript:void(0);" className="btn btn-primary" data-bs-toggle="modal"
												data-bs-target="#add_industry"><i
													className="ti ti-square-rounded-plus me-2"></i>Add New Industry</a>
										</div>
									</div>
								</div>
								{/* <!-- /Search --> */}
							</div>
							<div className="card-body">
								{/* <!-- Industry List --> */}
								<div className="table-responsive custom-table">
									<table className="table" id="industry-list">
										<thead className="thead-light">
											<tr>
												<th className="no-sort">
													<label className="checkboxs">
														<input type="checkbox" id="select-all"/><span
															className="checkmarks"></span>
													</label>
												</th>
												<th>Title</th>
												<th>Created at</th>
												<th>Status</th>
												<th>Action</th>
											</tr>
										</thead>
										<tbody>

										</tbody>
									</table>
								</div>
								<div className="row align-items-center">
									<div className="col-md-6">
										<div className="datatable-length"></div>
									</div>
									<div className="col-md-6">
										<div className="datatable-paginate"></div>
									</div>
								</div>
								{/* <!-- /Industry List --> */}

							</div>
						</div>

					</div>
				</div>


                            </div>
                    </div>


                    {/* <!-- Add New Industry --> */}
		<div className="modal fade" id="add_industry" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Add New Industry</h5>
						<button className="btn-close custom-btn-close border p-1 me-0 text-dark" data-bs-dismiss="modal"
							aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<form action="industry.html">
						<div className="modal-body">
							<div className="mb-3">
								<label className="col-form-label">Industry Name <span className="text-danger">*</span></label>
								<input type="text" className="form-control"/>
							</div>
							<div className="mb-0">
								<label className="col-form-label">Status</label>
								<div className="d-flex align-items-center">
									<div className="me-2">
										<input type="radio" className="status-radio" id="add-active" name="status"
											checked=""/>
										<label for="add-active">Active</label>
									</div>
									<div>
										<input type="radio" className="status-radio" id="add-inactive" name="status"/>
										<label for="add-inactive">Inactive</label>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<div className="d-flex align-items-center justify-content-end m-0">
								<a href="#" className="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Create</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		{/* <!-- /Add New Industry --> */}

		{/* <!-- Edit Industry --> */}
		<div className="modal fade" id="edit_industry" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Edit Industry</h5>
						<button className="btn-close custom-btn-close border p-1 me-0 text-dark" data-bs-dismiss="modal"
							aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<form action="industry.html">
						<div className="modal-body">
							<div className="mb-3">
								<label className="col-form-label">Industry Name <span className="text-danger">*</span></label>
								<input type="text" className="form-control" value="Retail Industry"/>
							</div>
							<div className="mb-0">
								<label className="col-form-label">Status</label>
								<div className="d-flex align-items-center">
									<div className="me-2">
										<input type="radio" className="status-radio" id="edit-active" name="status"
											checked=""/>
										<label for="edit-active">Active</label>
									</div>
									<div>
										<input type="radio" className="status-radio" id="edit-inactive" name="status"/>
										<label for="edit-inactive">Inactive</label>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<div className="d-flex align-items-center justify-content-end m-0">
								<a href="#" className="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Save Changes</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		{/* <!-- /Edit Industry --> */}

		{/* <!-- Delete Industry --> */}
		<div className="modal fade" id="delete_industry" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-body">
						<div className="text-center">
							<div className="avatar avatar-xl bg-danger-light rounded-circle mb-3">
								<i className="ti ti-trash-x fs-36 text-danger"></i>
							</div>
							<h4 className="mb-2">Remove Industry?</h4>
							<p className="mb-0">AAre you sure you want to remove it.</p>
							<div className="d-flex align-items-center justify-content-center mt-4">
								<a href="#" className="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
								<a href="industry.html" className="btn btn-danger">Yes, Delete it</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Delete Industry --> */}




        </div>

    
    </>
  )
}

import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"

export default function InvoiceSettings() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h4 className="page-title">Settings</h4>
                    </div>
                    <div className="col-4 text-end">
                      <div className="head-icons">
                        <a
                          href="invoice-settings.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                {/* <!-- Settings Menu --> */}
                <div className="card">
                  <div className="card-body pb-0 pt-2">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item me-3">
                        <a href="profile.html" className="nav-link px-0">
                          <i className="ti ti-settings-cog me-2"></i>General
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="company-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-world-cog me-2"></i>Website
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="invoice-settings.html"
                          className="nav-link px-0 active"
                        >
                          <i className="ti ti-apps me-2"></i>App Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a href="email-settings.html" className="nav-link px-0">
                          <i className="ti ti-device-laptop me-2"></i>System
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="payment-gateways.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-moneybag me-2"></i>Financial
                          Settings
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="storage.html" className="nav-link px-0">
                          <i className="ti ti-flag-cog me-2"></i>Other Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /Settings Menu --> */}

                <div className="row">
                  <div className="col-xl-3 col-lg-12 theiaStickySidebar">
                    {/* <!-- Settings Sidebar --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="settings-sidebar">
                          <h4 className="fw-semibold mb-3">App Settings</h4>
                          <div className="list-group list-group-flush settings-sidebar">
                            <a
                              href="invoice-settings.html"
                              className="fw-medium active"
                            >
                              Invoice Settings
                            </a>
                            <a href="printers.html" className="fw-medium">
                              Printer
                            </a>
                            <a href="custom-fields.html" className="fw-medium">
                              Custom Fields
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Sidebar --> */}
                  </div>

                  <div className="col-xl-9 col-lg-12">
                    {/* <!-- Invoice Settings --> */}
                    <div className="card">
                      <div className="card-body">
                        <h4 className="fw-semibold mb-3">Invoice Settings</h4>
                        <form action="invoice-settings.html">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <h6 className="fw-medium">Invoice Logo</h6>
                                <p>
                                  Upload logo of your company to display in
                                  invoice
                                </p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <div className="profile-upload">
                                  <div className="profile-upload-img">
                                    <span>
                                      <i className="ti ti-photo"></i>
                                    </span>
                                    <img
                                      id="ImgPreview"
                                      src="assets/img/logo.svg"
                                      alt="img"
                                      className="preview1"
                                    />
                                    <button
                                      type="button"
                                      id="removeImage1"
                                      className="profile-remove"
                                    >
                                      <i className="feather-x"></i>
                                    </button>
                                  </div>
                                  <div className="profile-upload-content">
                                    <label className="profile-upload-btn">
                                      <i className="ti ti-file-broken"></i>{" "}
                                      Upload File
                                      <input
                                        type="file"
                                        id="imag"
                                        className="input-img"
                                      />
                                    </label>
                                    <p>
                                      Upload Logo of your company to display in
                                      website(JPG or PNG). Max size of 800K
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <h6 className="fw-medium">Invoice Prefix</h6>
                                <p>Add prefix to your invoice</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  value="INV-"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <h6 className="fw-medium">Invoice Due</h6>
                                <p>Select due date to display in invoice</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <div className="d-flex align-items-center inv-days">
                                  <div className="select-drop">
                                    <select className="select">
                                      <option selected>5</option>
                                      <option>7</option>
                                    </select>
                                  </div>
                                  <p>Days</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <h6 className="fw-medium">Invoice Round Off</h6>
                                <p>Value roundoff in invoice</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="mb-3">
                                <div className="d-flex align-items-center">
                                  <div className="form-check form-switch me-2">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      role="switch"
                                      checked
                                    />
                                  </div>
                                  <div className="select-drop w-100">
                                    <select className="select">
                                      <option selected>Roundoff Up</option>
                                      <option>Roundoff Down</option>
                                    </select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row align-items-center">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <h6 className="fw-medium">
                                  Show Company Details
                                </h6>
                                <p>Show/hide company details in invoice</p>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    checked
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <h6 className="fw-medium">
                                  Invoice Header Terms
                                </h6>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <div className="summernote"></div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <h6 className="fw-medium">
                                  Invoice Footer Terms
                                </h6>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <div className="summernote"></div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <a href="#" className="btn btn-light me-2">
                              Cancel
                            </a>
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!-- /Invoice Settings --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React, {useState} from 'react'
import 'react-perfect-scrollbar/dist/css/styles.css'
import VerticalMenuComponent from '../../components/VerticalMenuComponent'
import TopMenuComponent from '../../components/TopMenuComponent'
import TopPageHeader from '../../components/TopPageHeader'

export default function Analytics() {

	const [isOpen, setIsOpen] = useState(false)
	const openSideNav = () => {
	  setIsOpen(!isOpen)
	}


    return (
        <>
                  <div className={`main-wrapper ${isOpen && "slide-nav" }`}>
                    {/* header component  */}
                    <VerticalMenuComponent/>
                    <TopMenuComponent openSideNav={openSideNav}  />

                    {/* page wrapper  */}
                    <div className='page-wrapper'>
                            <div className='content'>
                            <div className="row">
					<div className="col-md-12">

						{/* <!-- Page Header --> */}
						<div className="page-header">
							<TopPageHeader pageTitle={"Analytics"}/>							
						</div>
						{/* <!-- /Page Header --> */}

					</div>
				</div>

				<div className="row">
					<div className="col-xl-6">
						<div className="card ">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Recently Created Contacts</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 30 Days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div className="table-responsive">
									<table className="table dataTable custom-table mb-0" id="analytic-contact">
										<thead>
											<tr>
												<th>Contact</th>
												<th>Email</th>
												<th>Phone</th>
												<th>Created at</th>
											</tr>
										</thead>
										<tbody>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className="card ">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Won Deals Stage</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Marketing Pipeline
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Marketing Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Sales Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Email
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Chats
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Operational
												</a>
											</div>
										</div>
										<div className="dropdown">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 30 Days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body pt-0">
								<div id="won-chart"></div>
							</div>
						</div>
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Recently Created Deals</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 30 Days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div className="table-responsive">
									<table className="table table-nowrap custom-table mb-0" id="analytic-deal">
										<thead>
											<tr>
												<th>Deal Name</th>
												<th>Stage</th>
												<th>Deal Value</th>
												<th>Probability</th>
												<th>Status</th>
											</tr>
										</thead>
										<tbody>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Lost Leads Stage</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Marketing Pipeline
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Marketing Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Sales Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Email
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Chats
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Operational
												</a>
											</div>
										</div>
										<div className="dropdown">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 30 Days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body pt-0">
								<div id="last-chart-2"></div>
							</div>
						</div>
						<div className="card ">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Leads By Stage</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Sales Pipeline
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Marketing Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Sales Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Email
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Chats
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Operational
												</a>
											</div>
										</div>
										<div className="dropdown">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 30 Days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div id="leads-chart"></div>
							</div>
						</div>
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Recently Added Companies</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 30 Days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
										<a href="#" className="btn btn-primary" data-bs-toggle="offcanvas"
											data-bs-target="#offcanvas_add_2"><i
												className="ti ti-square-rounded-plus me-2"></i> Add Company</a>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div className="table-responsive">
									<table className="table custom-table mb-0" id="analytic-company">
										<thead>
											<tr>
												<th>Company Name</th>
												<th>Email</th>
												<th>Phone</th>
												<th>Created at</th>
											</tr>
										</thead>
										<tbody>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
					<div className="col-xl-6">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Deals By Stage</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Sales Pipeline
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Marketing Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Sales Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Email
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Chats
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Operational
												</a>
											</div>
										</div>
										<div className="dropdown">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 30 Days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div id="deals-chart"></div>
							</div>
						</div>
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Activities</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 30 Days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
										<a href="#" className="btn btn-primary" data-bs-toggle="offcanvas"
											data-bs-target="#offcanvas_add"><i
												className="ti ti-square-rounded-plus me-2"></i>Add New Activity</a>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div className="activities-list">
									<ul>
										<li>
											<div className="row align-items-center">
												<div className="col-md-5">
													<div className="activity-name">
														<h5>We scheduled a meeting for next week</h5>
														<p>Due Date : 10 Feb 2024, 09:00 am </p>
														<span className="badge activity-badge bg-purple"><i
																className="ti ti-user-share"></i>Meeting</span>
													</div>
												</div>
												<div className="col-md-3">
													<div className="user-activity">
														<span>
															<img src="/img/profiles/avatar-19.jpg" alt="img" />
														</span>
														<h6>Darlee Robertson</h6>
													</div>
												</div>
												<div className="col-md-4">
													<div className="mb-0">
														<select className="select">
															<option>Inprogress</option>
															<option>Completed</option>
															<option>Cancelled</option>
														</select>
													</div>
												</div>
											</div>
										</li>
										<li>
											<div className="row align-items-center">
												<div className="col-md-5">
													<div className="activity-name">
														<h5>Regarding latest updates in project </h5>
														<p>Due date : 29 Sep 2023, 08:20 am</p>
														<span className="badge activity-badge bg-warning"><i
																className="ti ti-mail"></i>Email</span>
													</div>
												</div>
												<div className="col-md-3">
													<div className="user-activity">
														<span>
															<img src="/img/profiles/avatar-22.jpg" alt="img" />
														</span>
														<h6>Dawn Mercha</h6>
													</div>
												</div>
												<div className="col-md-4">
													<div className="mb-0">
														<select className="select">
															<option>Inprogress</option>
															<option>Completed</option>
															<option>Cancelled</option>
														</select>
													</div>
												</div>
											</div>
										</li>
										<li>
											<div className="row align-items-center">
												<div className="col-md-5">
													<div className="activity-name">
														<h5>Call John and discuss about project</h5>
														<p>Due date : 05 Oct 2023, 10:35 am</p>
														<span className="badge activity-badge bg-blue"><i
																className="ti ti-subtask"></i>Task</span>
													</div>
												</div>
												<div className="col-md-3">
													<div className="user-activity">
														<span>
															<img src={'/img/avatar16'} alt="img" />
														</span>
														<h6>Carol Thomas</h6>
													</div>
												</div>
												<div className="col-md-4">
													<div className="mb-0">
														<select className="select">
															<option>Inprogress</option>
															<option>Completed</option>
															<option>Cancelled</option>
														</select>
													</div>
												</div>
											</div>
										</li>
										<li>
											<div className="row align-items-center">
												<div className="col-md-5">
													<div className="activity-name">
														<h5>Discussed budget proposal with Edwin</h5>
														<p>Due date : 16 Oct 2023, 04:40 pm</p>
														<span className="badge activity-badge bg-green"><i
																className="ti ti-phone"></i>Calls</span>
													</div>
												</div>
												<div className="col-md-3">
													<div className="user-activity">
														<span>
															<img src="/img/profiles/avatar-20.jpg" alt="img" />
														</span>
														<h6>Sharon Roy</h6>
													</div>
												</div>
												<div className="col-md-4">
													<div className="mb-0">
														<select className="select">
															<option>Inprogress</option>
															<option>Completed</option>
															<option>Cancelled</option>
														</select>
													</div>
												</div>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Lost Leads Stage</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 30 Days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
										<a href="#" className="btn btn-primary" data-bs-toggle="offcanvas"
											data-bs-target="#offcanvas_add_2"><i
												className="ti ti-square-rounded-plus me-2"></i> Add Company</a>
									</div>
								</div>
							</div>
							<div className="card-body pt-0">
								<div id="last-chart"></div>
							</div>
						</div>
						<div className="card ">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Recently Created Leads</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 30 Days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div className="table-responsive">
									<table className="table custom-table mb-0" id="analytic-lead">
										<thead>
											<tr>
												<th>Lead Name</th>
												<th>Company Name</th>
												<th>Phone</th>
												<th>Lead Status</th>
											</tr>
										</thead>
										<tbody>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Recently Created Campaign</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 30 Days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
										<a href="#" className="btn btn-primary" data-bs-toggle="offcanvas"
											data-bs-target="#offcanvas_pipeline"><i
												className="ti ti-square-rounded-plus me-2"></i> Add Pipeline</a>
									</div>
								</div>
							</div>
							<div className="card-body">
								<div className="campaign-card">
									<ul>
										<li className="campaign-wrap">
											<div className="campaign-info">
												<div className="campaign-name">
													<h5>Distribution</h5>
													<p>Public Relations</p>
												</div>
												<ul className="list-progress">
													<li>
														<h6>30.5%</h6>
														<p>Opened</p>
													</li>
													<li>
														<h6>20.5%</h6>
														<p>Closed</p>
													</li>
													<li>
														<h6>30.5%</h6>
														<p>Unsubscribe</p>
													</li>
													<li>
														<h6>70.5%</h6>
														<p>Delivered</p>
													</li>
													<li>
														<h6>35.0%</h6>
														<p>Conversation</p>
													</li>
												</ul>
											</div>
											<div className="campaign-action">
												<div className="campaign-date">
													<span className="badge badge-pill badge-status bg-danger">Bounced</span>
													<p>Due Date : 25 Sep 2023</p>
												</div>
												<ul className="project-mem">
													<li>
														<a href="#"><img src="/img/profiles/avatar-14.jpg"
																alt="img" /></a>
													</li>
													<li>
														<a href="#"><img src="/img/profiles/avatar-15.jpg"
																alt="img" /></a>
													</li>
													<li>
														<a href="#"><img src={'/img/avatar16'}
																alt="img" /></a>
													</li>
													<li className="more-set"><a href="#">+03</a></li>
												</ul>
											</div>
										</li>
										<li className="campaign-wrap">
											<div className="campaign-info">
												<div className="campaign-name">
													<h5>Pricing</h5>
													<p>Social Marketing</p>
												</div>
												<ul className="list-progress">
													<li>
														<h6>42.5%</h6>
														<p>Opened</p>
													</li>
													<li>
														<h6>38.2%</h6>
														<p>Closed</p>
													</li>
													<li>
														<h6>48.8%</h6>
														<p>Unsubscribe</p>
													</li>
													<li>
														<h6>62.7%</h6>
														<p>Delivered</p>
													</li>
													<li>
														<h6>22.5%</h6>
														<p>Conversation</p>
													</li>
												</ul>
											</div>
											<div className="campaign-action">
												<div className="campaign-date">
													<span className="badge badge-pill badge-status bg-green">Running</span>
													<p>Due Date : 14 Oct 2023</p>
												</div>
												<ul className="project-mem">
													<li>
														<a href="#"><img src="/img/profiles/avatar-14.jpg"
																alt="img" /></a>
													</li>
													<li>
														<a href="#"><img src="/img/profiles/avatar-15.jpg"
																alt="img" /></a>
													</li>
													<li>
														<a href="#"><img src={'/img/avatar16'}
																alt="img" /></a>
													</li>
													<li className="more-set"><a href="#">+04</a></li>
												</ul>
											</div>
										</li>
										<li className="campaign-wrap">
											<div className="campaign-info">
												<div className="campaign-name">
													<h5>Merchandising</h5>
													<p>Content Marketing</p>
												</div>
												<ul className="list-progress">
													<li>
														<h6>52.5%</h6>
														<p>Opened</p>
													</li>
													<li>
														<h6>29.3%</h6>
														<p>Closed</p>
													</li>
													<li>
														<h6>62.8%</h6>
														<p>Unsubscribe</p>
													</li>
													<li>
														<h6>71.3%</h6>
														<p>Delivered</p>
													</li>
													<li>
														<h6>39.5%</h6>
														<p>Conversation</p>
													</li>
												</ul>
											</div>
											<div className="campaign-action">
												<div className="campaign-date">
													<span className="badge badge-pill badge-status bg-info">Paused</span>
													<p>Due Date : 14 Oct 2023</p>
												</div>
												<ul className="project-mem">
													<li>
														<a href="#"><img src="/img/profiles/avatar-14.jpg"
																alt="img" /></a>
													</li>
													<li>
														<a href="#"><img src="/img/profiles/avatar-15.jpg"
																alt="img" /></a>
													</li>
													<li>
														<a href="#"><img src={'/img/avatar16'}
																alt="img" /></a>
													</li>
													<li className="more-set"><a href="#">+06</a></li>
												</ul>
											</div>
										</li>
										<li className="campaign-wrap">
											<div className="campaign-info">
												<div className="campaign-name">
													<h5>Repeat Customers</h5>
													<p>Rebranding</p>
												</div>
												<ul className="list-progress">
													<li>
														<h6>17.5%</h6>
														<p>Opened</p>
													</li>
													<li>
														<h6>49.5%</h6>
														<p>Closed</p>
													</li>
													<li>
														<h6>35.2%</h6>
														<p>Unsubscribe</p>
													</li>
													<li>
														<h6>54.8%</h6>
														<p>Delivered</p>
													</li>
													<li>
														<h6>60.5%</h6>
														<p>Conversation</p>
													</li>
												</ul>
											</div>
											<div className="campaign-action">
												<div className="campaign-date">
													<span
														className="badge badge-pill badge-status bg-success">Success</span>
													<p>Due Date : 29 Oct 2023</p>
												</div>
												<ul className="project-mem">
													<li>
														<a href="#"><img src="/img/profiles/avatar-14.jpg"
																alt="img"/></a>
													</li>
													<li>
														<a href="#"><img src="/img/profiles/avatar-15.jpg"
																alt="img"/></a>
													</li>
													<li>
														<a href="#"><img src={'/img/avatar16'}
																alt="img"/></a>
													</li>
													<li className="more-set"><a href="#">+02</a></li>
												</ul>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
                            </div>
                    </div>

                    {/* <!-- Add Company --> */}
		<div className="offcanvas offcanvas-end offcanvas-large" tabindex="-1" id="offcanvas_add_2">
			<div className="offcanvas-header border-bottom">
				<h5 className="fw-semibold">Add New Company</h5>
				<button type="button"
					className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
					data-bs-dismiss="offcanvas" aria-label="Close">
					<i className="ti ti-x"></i>
				</button>
			</div>
			<div className="offcanvas-body">
				<form action="companies.html">
					<div className="accordion" id="main_accordion">
						{/* <!-- Basic Info --> */}
						<div className="accordion-item rounded mb-3">
							<div className="accordion-header">
								<a href="#"
									className="accordion-button accordion-custom-button bg-white rounded fw-medium text-dark"
									data-bs-toggle="collapse" data-bs-target="#basic">
									<span className="avatar avatar-md rounded text-dark border me-2"><i
											className="ti ti-user-plus fs-20"></i></span>
									Basic Info
								</a>
							</div>
							<div className="accordion-collapse collapse show" id="basic" data-bs-parent="#main_accordion">
								<div className="accordion-body border-top">
									<div className="row">
										<div className="col-md-12">
											<div className="mb-3">
												<div className="profile-upload">
													<div className="profile-upload-img">
														<span><i className="ti ti-photo"></i></span>
														<img src="/img/profiles/avatar-20.jpg" alt="img"
															className="preview1" />
														<button type="button" className="profile-remove">
															<i className="ti ti-x"></i>
														</button>
													</div>
													<div className="profile-upload-content">
														<label className="profile-upload-btn">
															<i className="ti ti-file-broken"></i> Upload File
															<input type="file" className="input-img" />
														</label>
														<p>JPG, GIF or PNG. Max size of 800K</p>
													</div>
												</div>
											</div>
										</div>
										<div className="col-md-12">
											<div className="mb-3">
												<label className="col-form-label">Company Name</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-12">
											<div className="mb-3">
												<div className="d-flex justify-content-between align-items-center">
													<label className="col-form-label">Email <span
															className="text-danger">*</span></label>
													<div
														className="status-toggle small-toggle-btn d-flex align-items-center">
														<span className="me-2 label-text">Email Opt Out</span>
														<input type="checkbox" id="user" className="check" checked="" />
														<label for="user" className="checktoggle"></label>
													</div>
												</div>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Phone 1 <span
														className="text-danger">*</span></label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Phone 2</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Fax <span
														className="text-danger">*</span></label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Website <span
														className="text-danger">*</span></label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Ratings</label>
												<div className="icon-form-end">
													<span className="form-icon"><i className="ti ti-star"></i></span>
													<input type="text" className="form-control" placeholder="4.2" />
												</div>
											</div>
										</div>
										<div className="col-md-6">
											<div className="fmb-3">
												<label className="col-form-label">Owner</label>
												<select className="select-img">
													<option data-image="/img/profiles/avatar-14.jpg" selected>
														Jerald</option>
													<option data-image="/img/profiles/avatar-15.jpg">Guillory
													</option>
													<option data-image={'/img/avatar16'}>Jami</option>
													<option data-image="/img/profiles/avatar-13.jpg">Theresa
													</option>
													<option data-image="/img/profiles/avatar-24.jpg">Espinosa
													</option>
												</select>
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Tags </label>
												<input className="input-tags form-control" type="text" data-role="tagsinput"
													name="Label" value="Collab" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<div className="d-flex align-items-center justify-content-between">
													<label className="col-form-label">Deals</label>
													<a href="#" className="label-add" data-bs-toggle="offcanvas"
														data-bs-target="#offcanvas_add_3"><i
															className="ti ti-square-rounded-plus"></i>Add New</a>
												</div>
												<select className="select2">
													<option>Choose</option>
													<option>Collins</option>
													<option>Konopelski</option>
													<option>Adams</option>
													<option>Schumm</option>
													<option>Wisozk</option>
												</select>
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Source <span
														className="text-danger">*</span></label>
												<select className="select2">
													<option>Choose</option>
													<option>Phone Calls</option>
													<option>Social Media</option>
													<option>Referral Sites</option>
													<option>Web Analytics</option>
													<option>Previous Purchases</option>
												</select>
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Industry <span
														className="text-danger">*</span></label>
												<select className="select">
													<option>Choose</option>
													<option>Retail Industry</option>
													<option>Banking</option>
													<option>Hotels</option>
													<option>Financial Services</option>
													<option>Insurance</option>
												</select>
											</div>
										</div>
										<div className="col-md-12">
											<div className="mb-3">
												<label className="col-form-label">Contacts</label>
												<select className="multiple-img" multiple="multiple">
													<option data-image="/img/profiles/avatar-19.jpg">Darlee
														Robertson</option>
													<option data-image="/img/profiles/avatar-20.jpg" selected>
														Sharon Roy</option>
													<option data-image="/img/profiles/avatar-21.jpg">Vaughan
													</option>
													<option data-image="/img/profiles/avatar-23.jpg">Jessica
													</option>
													<option data-image={'/img/avatar16'}>Carol Thomas
													</option>
												</select>
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Currency <span
														className="text-danger">*</span></label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Language <span
														className="text-danger">*</span></label>
												<select className="select">
													<option>Choose</option>
													<option>English</option>
													<option>Arabic</option>
													<option>Chinese</option>
													<option>Hindi</option>
												</select>
											</div>
										</div>
										<div className="col-md-12">
											<div className="mb-0">
												<label className="col-form-label">Description <span
														className="text-danger">*</span></label>
												<textarea className="form-control" rows="5"></textarea>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* <!-- /Basic Info --> */}

						{/* <!-- Address Info --> */}
						<div className="accordion-item border-top rounded mb-3">
							<div className="accordion-header">
								<a href="#"
									className="accordion-button accordion-custom-button rounded bg-white fw-medium text-dark"
									data-bs-toggle="collapse" data-bs-target="#address">
									<span className="avatar avatar-md rounded text-dark border me-2"><i
											className="ti ti-map-pin-cog fs-20"></i></span>
									Address Info
								</a>
							</div>
							<div className="accordion-collapse collapse" id="address" data-bs-parent="#main_accordion">
								<div className="accordion-body border-top">
									<div className="row">
										<div className="col-md-12">
											<div className="mb-3">
												<label className="col-form-label">Street Address </label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">City </label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">State / Province </label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3 mb-md-0">
												<label className="col-form-label">Country</label>
												<select className="select">
													<option>Choose</option>
													<option>India</option>
													<option>USA</option>
													<option>France</option>
													<option>UK</option>
													<option>UAE</option>
												</select>
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-0">
												<label className="col-form-label">Zipcode </label>
												<input type="text" className="form-control" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* <!-- /Address Info --> */}

						{/* <!-- Social Profile --> */}
						<div className="accordion-item border-top rounded mb-3">
							<div className="accordion-header">
								<a href="#"
									className="accordion-button accordion-custom-button rounded bg-white fw-medium text-dark"
									data-bs-toggle="collapse" data-bs-target="#social">
									<span className="avatar avatar-md rounded text-dark border me-2"><i
											className="ti ti-social fs-20"></i></span>
									Social Profile
								</a>
							</div>
							<div className="accordion-collapse collapse" id="social" data-bs-parent="#main_accordion">
								<div className="accordion-body border-top">
									<div className="row">
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Facebook</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Skype </label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Linkedin </label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3">
												<label className="col-form-label">Twitter</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-3 mb-md-0">
												<label className="col-form-label">Whatsapp</label>
												<input type="text" className="form-control" />
											</div>
										</div>
										<div className="col-md-6">
											<div className="mb-0">
												<label className="col-form-label">Instagram</label>
												<input type="text" className="form-control" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* <!-- /Social Profile --> */}

						{/* <!-- Access --> */}
						<div className="accordion-item border-top rounded mb-3">
							<div className="accordion-header">
								<a href="#"
									className="accordion-button accordion-custom-button rounded bg-white fw-medium text-dark"
									data-bs-toggle="collapse" data-bs-target="#access-info">
									<span className="avatar avatar-md rounded text-dark border me-2"><i
											className="ti ti-accessible fs-20"></i></span>
									Access
								</a>
							</div>
							<div className="accordion-collapse collapse" id="access-info" data-bs-parent="#main_accordion">
								<div className="accordion-body border-top">
									<div className="row">
										<div className="col-md-12">
											<div className="mb-3">
												<label className="col-form-label">Visibility</label>
												<div className="d-flex flex-wrap">
													<div className="me-2">
														<input type="radio" className="status-radio" id="public"
															name="visible" />
														<label for="public">Public</label>
													</div>
													<div className="me-2">
														<input type="radio" className="status-radio" id="private"
															name="visible" />
														<label for="private">Private</label>
													</div>
													<div data-bs-toggle="modal" data-bs-target="#access_view">
														<input type="radio" className="status-radio" id="people"
															name="visible" />
														<label for="people">Select People</label>
													</div>
												</div>
											</div>
											<div className="mb-0">
												<label className="col-form-label">Status</label>
												<div className="d-flex flex-wrap">
													<div className="me-2">
														<input type="radio" className="status-radio" id="active"
															name="status" checked="" />
														<label for="active">Active</label>
													</div>
													<div>
														<input type="radio" className="status-radio" id="inactive"
															name="status" />
														<label for="inactive">Inactive</label>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						{/* <!-- /Access --> */}
					</div>
					<div className="d-flex align-items-center justify-content-end">
						<button type="button" data-bs-dismiss="offcanvas" className="btn btn-light me-2">Cancel</button>
						<button type="button" className="btn btn-primary">Create</button>
					</div>
				</form>
			</div>
		</div>
		{/* <!-- /Add Company --> */}

		{/* <!-- Add New Pipeline --> */}
		<div className="offcanvas offcanvas-end offcanvas-large" tabindex="-1" id="offcanvas_pipeline">
			<div className="offcanvas-header border-bottom">
				<h4>Add New Pipeline</h4>
				<button type="button"
					className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
					data-bs-dismiss="offcanvas" aria-label="Close">
					<i className="ti ti-x"></i>
				</button>
			</div>
			<div className="offcanvas-body">
				<form action="pipeline.html">
					<div>
						<div className="mb-3">
							<label className="col-form-label">Pipeline Name <span className="text-danger">*</span></label>
							<input className="form-control" type="text" />
						</div>
						<div className="mb-3">
							<div className="pipe-title d-flex align-items-center justify-content-between">
								<h5 className="form-title">Pipeline Stages</h5>
								<a href="#" className="add-stage" data-bs-toggle="modal" data-bs-target="#add_stage"><i
										className="ti ti-square-rounded-plus"></i>Add New</a>
							</div>
							<div className="pipeline-listing">
								<div className="pipeline-item">
									<p><i className="ti ti-grip-vertical"></i> Inpipeline</p>
									<div className="action-pipeline">
										<a href="#" data-bs-toggle="modal" data-bs-target="#edit_stage"><i
												className="ti ti-edit text-blue"></i>Edit</a>
										<a href="#" data-bs-toggle="modal" data-bs-target="#delete_stage"><i
												className="ti ti-trash text-danger"></i>Delete</a>
									</div>
								</div>
								<div className="pipeline-item">
									<p><i className="ti ti-grip-vertical"></i> Follow Up</p>
									<div className="action-pipeline">
										<a href="#" data-bs-toggle="modal" data-bs-target="#edit_stage"><i
												className="ti ti-edit text-blue"></i>Edit</a>
										<a href="#" data-bs-toggle="modal" data-bs-target="#delete_stage"><i
												className="ti ti-trash text-danger"></i>Delete</a>
									</div>
								</div>
								<div className="pipeline-item">
									<p><i className="ti ti-grip-vertical"></i> Schedule Service</p>
									<div className="action-pipeline">
										<a href="#" data-bs-toggle="modal" data-bs-target="#edit_stage"><i
												className="ti ti-edit text-blue"></i>Edit</a>
										<a href="#" data-bs-toggle="modal" data-bs-target="#delete_stage"><i
												className="ti ti-trash text-danger"></i>Delete</a>
									</div>
								</div>
							</div>
						</div>
						<div className="mb-3">
							<h5 className="form-title">Access</h5>
							<div className="d-flex flex-wrap access-item nav">
								<div className="radio-btn" data-bs-toggle="tab" data-bs-target="#all">
									<input type="radio" className="status-radio" id="all" name="status" checked="" />
									<label for="all">All</label>
								</div>
								<div className="radio-btn" data-bs-toggle="tab" data-bs-target="#select-person">
									<input type="radio" className="status-radio" id="select" name="status" />
									<label for="select">Select Person</label>
								</div>
							</div>
							<div className="tab-content">
								<div className="tab-pane fade" id="select-person">
									<div className="access-wrapper">
										<div className="access-view">
											<div className="access-img">
												<img src="/img/profiles/avatar-21.jpg" alt="Image" />Vaughan
											</div>
											<a href="#">Remove</a>
										</div>
										<div className="access-view">
											<div className="access-img">
												<img src="/img/profiles/avatar-01.jpg" alt="Image" />Jessica
											</div>
											<a href="#">Remove</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-end">
						<button type="button" data-bs-dismiss="offcanvas" className="btn btn-light me-2">Cancel</button>
						<button type="button" className="btn btn-primary">Create</button>
					</div>
				</form>
			</div>

		</div>
		{/* <!-- /Add New Pipeline --> */}

		{/* <!-- Add New Activity --> */}
		<div className="offcanvas offcanvas-end offcanvas-large" tabindex="-1" id="offcanvas_add">
			<div className="offcanvas-header border-bottom">
				<h4>Add New Activity </h4>
				<button type="button"
					className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
					data-bs-dismiss="offcanvas" aria-label="Close">
					<i className="ti ti-x"></i>
				</button>
			</div>
			<div className="offcanvas-body">
				<form action="invoices.html">
					<div>
						<div className="row">
							<div className="col-md-12">
								<div className="mb-3">
									<label className="col-form-label">Title <span className="text-danger">*</span></label>
									<input type="text" className="form-control" />
								</div>
							</div>
							<div className="col-md-12">
								<div className="mb-3">
									<label className="col-form-label">Activity Type <span
											className="text-danger">*</span></label>
									<ul className="radio-activity">
										<li>
											<div className="active-type">
												<input type="radio" id="call" name="status" checked="" />
												<label for="call"><i className="ti ti-phone"></i>Calls</label>
											</div>
										</li>
										<li>
											<div className="active-type">
												<input type="radio" id="mail" name="status" />
												<label for="mail"><i className="ti ti-mail"></i>Email</label>
											</div>
										</li>
										<li>
											<div className="active-type">
												<input type="radio" id="task" name="status" />
												<label for="task"><i className="ti ti-subtask"></i>Task</label>
											</div>
										</li>
										<li>
											<div className="active-type">
												<input type="radio" id="shares" name="status" />
												<label for="shares"><i className="ti ti-user-share"></i>Meeting</label>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-md-6">
								<label className="col-form-label">Due Date <span className="text-danger">*</span></label>
								<div className="mb-3 icon-form">
									<span className="form-icon"><i className="ti ti-calendar-check"></i></span>
									<input type="text" className="form-control datetimepicker" />
								</div>
							</div>
							<div className="col-md-6">
								<label className="col-form-label">Time <span className="text-danger">*</span></label>
								<div className="mb-3 icon-form">
									<span className="form-icon"><i className="ti ti-clock-hour-10"></i></span>
									<input type="text" className="form-control datetimepicker-time" />
								</div>
							</div>
							<div className="col-md-6">
								<label className="col-form-label">Reminder <span className="text-danger">*</span></label>
								<div className="mb-3 icon-form">
									<span className="form-icon"><i className="ti ti-bell-ringing"></i></span>
									<input type="text" className="form-control" />
								</div>
							</div>
							<div className="col-md-6">
								<div className="d-flex align-items-center">
									<div className="mb-3 w-100">
										<label className="col-form-label">&nbsp;</label>
										<select className="select">
											<option>Select</option>
											<option>Minutes</option>
											<option>Hours</option>
										</select>
									</div>
									<div className="mb-3 time-text">
										<label className="col-form-label">&nbsp;</label>
										<p>Before Due</p>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Owner <span className="text-danger">*</span></label>
									<select className="select">
										<option>Select</option>
										<option>Hendry</option>
										<option>Bradtke</option>
										<option>Sally</option>
									</select>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Guests <span className="text-danger">*</span></label>
									<select className="multiple-img" multiple="multiple">
										<option data-image="/img/profiles/avatar-19.jpg" selected>Darlee Robertson
										</option>
										<option data-image="/img/profiles/avatar-20.jpg">Sharon Roy</option>
										<option data-image="/img/profiles/avatar-21.jpg">Vaughan</option>
										<option data-image="/img/profiles/avatar-23.jpg">Jessica</option>
										<option data-image={'/img/avatar16'}>Carol Thomas</option>
									</select>
								</div>
							</div>
							<div className="col-md-12">
								<div className="mb-3">
									<label className="col-form-label">Description <span className="text-danger">*</span></label>
									<div className="summernote"></div>
								</div>
								<div className="mb-3">
									<div className="d-flex align-items-center justify-content-between">
										<label className="col-form-label">Deals</label>
										<a href="#" className="label-add" data-bs-toggle="modal"
											data-bs-target="#add_deal"><i className="ti ti-square-rounded-plus"></i>Add
											New</a>
									</div>
									<select className="select">
										<option>Select</option>
										<option>Collins</option>
										<option>Konopelski </option>
										<option>Adams</option>
									</select>
								</div>
								<div className="mb-3">
									<div className="d-flex align-items-center justify-content-between">
										<label className="col-form-label">Contacts</label>
										<a href="#" className="label-add" data-bs-toggle="modal"
											data-bs-target="#add_contacts"><i className="ti ti-square-rounded-plus"></i>Add
											New</a>
									</div>
									<select className="select">
										<option>Select</option>
										<option>Collins</option>
										<option>Konopelski </option>
										<option>Adams</option>
									</select>
								</div>
								<div className="mb-3">
									<div className="d-flex align-items-center justify-content-between">
										<label className="col-form-label">Companies</label>
										<a href="#" className="label-add" data-bs-toggle="modal"
											data-bs-target="#add_company"><i className="ti ti-square-rounded-plus"></i>Add
											New</a>
									</div>
									<select className="select">
										<option>Select</option>
										<option>NovaWave LLC</option>
										<option>Silver Hawk</option>
										<option>Harbor View</option>
									</select>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-end">
						<button type="button" data-bs-dismiss="offcanvas" className="btn btn-light me-2">Cancel</button>
						<button type="button" className="btn btn-primary">Create</button>
					</div>
				</form>
			</div>
		</div>
		{/* <!-- /Add New Activity --> */}

		{/* <!-- Add Deals --> */}
		<div className="modal custom-modal fade" id="add_deal" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Add Deals</h5>
						<button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<div className="modal-body">
						<form action="activity-calls.html">
							<div className="mb-2 icon-form">
								<span className="form-icon"><i className="ti ti-search"></i></span>
								<input type="text" className="form-control" placeholder="Search" />
							</div>
							<div className="access-wrap">
								<ul>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<a href="#">Collins</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<a href="#">Konopelski</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<a href="#">Adams</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<a href="#">Schumm</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<a href="#">Wisozk</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<a href="#">Dawn Mercha</a>
											</span>
										</label>
									</li>
								</ul>
							</div>
							<div className="modal-btn text-end">
								<a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Confirm</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Add Deals --> */}

		{/* <!-- Add Contacts --> */}
		<div className="modal custom-modal fade" id="add_contacts" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Add Contact</h5>
						<button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<div className="modal-body">
						<form action="activities.html">
							<div className="mb-2 icon-form">
								<span className="form-icon"><i className="ti ti-search"></i></span>
								<input type="text" className="form-control" placeholder="Search" />
							</div>
							<div className="access-wrap">
								<ul>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<img src="/img/profiles/avatar-19.jpg" alt="img" />
												<a href="#">Darlee Robertson</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<img src="/img/profiles/avatar-20.jpg" alt="img" />
												<a href="#">Sharon Roy</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<img src="/img/profiles/avatar-21.jpg" alt="img" />
												<a href="#">Vaughan</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<img src="/img/profiles/avatar-01.jpg" alt="img" / > 
												<a href="#">Jessica</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<img src={'/img/avatar16'} alt="img" />
												<a href="#">Carol Thomas</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<img src="/img/profiles/avatar-22.jpg" alt="img" />
												<a href="#">Dawn Mercha</a>
											</span>
										</label>
									</li>
								</ul>
							</div>
							<div className="modal-btn text-end">
								<a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Confirm</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Add Contacts --> */}

		{/* <!-- Add Company --> */}
		<div className="modal custom-modal fade" id="add_company" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Add Company</h5>
						<button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<div className="modal-body">
						<form action="activities.html">
							<div className="mb-2 icon-form">
								<span className="form-icon"><i className="ti ti-search"></i></span>
								<input type="text" className="form-control" placeholder="Search" />
							</div>
							<div className="access-wrap">
								<ul>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<img src="/img/icons/company-icon-01.svg" alt="img" />
												<a href="#">NovaWave LLC</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<img src="/img/icons/company-icon-02.svg" alt="img" />
												<a href="#">BlueSky Industries</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<img src="/img/icons/company-icon-03.svg" alt="img" />
												<a href="#">Silver Hawk</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<img src="/img/icons/company-icon-04.svg" alt="img" />
												<a href="#">Summit Peak</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<img src="/img/icons/company-icon-05.svg" alt="img" />
												<a href="#">RiverStone Ventur</a>
											</span>
										</label>
									</li>
									<li className="select-people-checkbox">
										<label className="checkboxs">
											<input type="checkbox" />
											<span className="checkmarks"></span>
											<span className="people-profile">
												<img src="/img/icons/company-icon-06.svg" alt="img"/>
												<a href="#">Bright Bridge Grp</a>
											</span>
										</label>
									</li>
								</ul>
							</div>
							<div className="modal-btn text-end">
								<a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Confirm</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Add Company --> */}

		{/* <!-- Add New Deals --> */}
		<div className="offcanvas offcanvas-end offcanvas-large" tabindex="-1" id="offcanvas_add_3">
			<div className="offcanvas-header border-bottom">
				<h5 className="fw-semibold">Add New Deals</h5>
				<button type="button"
					className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
					data-bs-dismiss="offcanvas" aria-label="Close">
					<i className="ti ti-x"></i>
				</button>
			</div>
			<div className="offcanvas-body">
				<form action="contacts.html">
					<div className="row">
						<div className="col-md-12">
							<div className="mb-3">
								<label className="col-form-label">Deal Name <span className="text-danger">*</span></label>
								<input type="text" className="form-control"/>
							</div>
						</div>
						<div className="col-md-6">
							<div className="mb-3">
								<div className="d-flex align-items-center justify-content-between">
									<label className="col-form-label">Pipeine <span className="text-danger">*</span></label>
								</div>
								<select className="select2">
									<option>Choose</option>
									<option>Sales</option>
									<option>Marketing</option>
									<option>Calls</option>
								</select>
							</div>
						</div>
						<div className="col-md-6">
							<div className="mb-3">
								<label className="col-form-label">Status <span className="text-danger">*</span></label>
								<select className="select2">
									<option>Choose</option>
									<option>Open</option>
									<option>Lost</option>
									<option>Won</option>
								</select>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="mb-3">
								<label className="col-form-label">Deal Value<span className="text-danger"> *</span></label>
								<input className="form-control" type="text"/>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="mb-3">
								<label className="col-form-label">Currency <span className="text-danger">*</span></label>
								<select className="select">
									<option>Select</option>
									<option>$</option>
									<option>€</option>
								</select>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="mb-3">
								<label className="col-form-label">Period <span className="text-danger">*</span></label>
								<select className="select">
									<option>Choose</option>
									<option>Days</option>
									<option>Month</option>
								</select>
							</div>
						</div>
						<div className="col-lg-3 col-md-6">
							<div className="mb-3">
								<label className="col-form-label">Period Value <span className="text-danger">*</span></label>
								<input className="form-control" type="text" />
							</div>
						</div>
						<div className="col-md-12">
							<div className="mb-3">
								<label className="col-form-label">Contact <span className="text-danger">*</span></label>
								<select className="multiple-img" multiple="multiple">
									<option data-image="/img/profiles/avatar-19.jpg" selected>Darlee Robertson
									</option>
									<option data-image="/img/profiles/avatar-20.jpg">Sharon Roy</option>
									<option data-image="/img/profiles/avatar-21.jpg">Vaughan</option>
									<option data-image="/img/profiles/avatar-23.jpg">Jessica</option>
									<option data-image={'/img/avatar16'}>Carol Thomas</option>
								</select>
							</div>
							<div className="mb-3">
								<label className="col-form-label">Project <span className="text-danger">*</span></label>
								<select className="select" multiple="multiple">
									<option selected>Devops Design</option>
									<option selected>MargrateDesign</option>
									<option selected>UI for Chat</option>
									<option>Web Chat</option>
								</select>
							</div>
						</div>
						<div className="col-md-6">
							<div className="mb-3">
								<label className="col-form-label">Due Date <span className="text-danger">*</span></label>
								<div className="icon-form">
									<span className="form-icon"><i className="ti ti-calendar-check"></i></span>
									<input type="text" className="form-control datetimepicker" placeholder="" />
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="mb-3">
								<label className="col-form-label">Expected Closing Date <span
										className="text-danger">*</span></label>
								<div className="icon-form">
									<span className="form-icon"><i className="ti ti-calendar-check"></i></span>
									<input type="text" className="form-control datetimepicker" placeholder="" />
								</div>
							</div>
						</div>
						<div className="col-md-12">
							<div className="mb-3">
								<label className="col-form-label">Assignee <span className="text-danger">*</span></label>
								<select className="multiple-img" multiple="multiple">
									<option data-image="/img/profiles/avatar-19.jpg">Darlee Robertson</option>
									<option data-image="/img/profiles/avatar-20.jpg" selected>Sharon Roy</option>
									<option data-image="/img/profiles/avatar-21.jpg">Vaughan</option>
									<option data-image="/img/profiles/avatar-23.jpg">Jessica</option>
									<option data-image={'/img/avatar16'}>Carol Thomas</option>
								</select>
							</div>
						</div>
						<div className="col-md-6">
							<div className="mb-3">
								<label className="col-form-label">Follow Up Date <span className="text-danger">*</span></label>
								<div className="icon-form">
									<span className="form-icon"><i className="ti ti-calendar-check"></i></span>
									<input type="text" className="form-control datetimepicker" placeholder="" />
								</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className="mb-3">
								<label className="col-form-label">Source <span className="text-danger">*</span></label>
								<select className="select">
									<option>Select</option>
									<option>Google</option>
									<option>Social Media</option>
								</select>
							</div>
						</div>
						<div className="col-md-6">
							<div className="mb-3">
								<label className="col-form-label">Tags <span className="text-danger">*</span></label>
								<input className="input-tags form-control" type="text" data-role="tagsinput" name="Label"
									value="Collab, Rated" />
							</div>
						</div>
						<div className="col-md-6">
							<div className="mb-3">
								<label className="col-form-label">Priority <span className="text-danger">*</span></label>
								<select className="select">
									<option>Select</option>
									<option>Highy</option>
									<option>Low</option>
									<option>Medium</option>
								</select>
							</div>
						</div>
						<div className="col-lg-12">
							<div className="mb-3">
								<label className="col-form-label">Description <span className="text-danger">*</span></label>
								<div className="summernote"></div>
							</div>
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-end">
						<button type="button" data-bs-dismiss="offcanvas" className="btn btn-light me-2">Cancel</button>
						<button type="button" className="btn btn-primary" data-bs-toggle="modal"
							data-bs-target="#create_success">Create</button>
					</div>
				</form>
			</div>

		</div>
		{/* <!-- /Add New Deals --> */}

		{/* <!-- Add New Stage --> */}
		<div className="modal custom-modal fade" id="add_stage" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Add New Stage</h5>
						<button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<div className="modal-body">
						<form action="deals-details.html">
							<div className="mb-3">
								<label className="col-form-label">Stage Name *</label>
								<input type="text" className="form-control" />
							</div>
							<div className="modal-btn text-end">
								<a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-danger">Save Changes</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Add New Stage --> */}

		{/* <!-- Edit Stage --> */}
		<div className="modal custom-modal fade" id="edit_stage" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Edit Stage</h5>
						<button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<div className="modal-body">
						<form action="deals-details.html">
							<div className="mb-3">
								<label className="col-form-label">Stage Name *</label>
								<input type="text" className="form-control" value="Inpipeline" />
							</div>
							<div className="modal-btn text-end">
								<a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-danger">Save Changes</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Edit Stage --> */}

		{/* <!-- Delete Stage --> */}
		<div className="modal fade" id="delete_stage" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-body">
						<div className="text-center">
							<div className="avatar avatar-xl bg-danger-light rounded-circle mb-3">
								<i className="ti ti-trash-x fs-36 text-danger"></i>
							</div>
							<h4 className="mb-2">Remove Stage?</h4>
							<p className="mb-0">Are you sure you want to remove <br/> stage you selected.</p>
							<div className="d-flex align-items-center justify-content-center mt-4">
								<a href="#" className="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
								<a href="contacts.html" className="btn btn-danger">Yes, Delete it</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Delete Stage --> */}
        </div>
        </>
    )
}

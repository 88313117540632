import React , {useState} from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"
export default function Email() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }
  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="page-header">
              <div className="row">
                <div className="col">
                  <h3 className="page-title">Inbox</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className="compose-btn">
                  <a
                    href="javascript:void(0);"
                    className="btn btn-primary btn-block w-100"
                  >
                    Compose
                  </a>
                </div>
                <ul className="inbox-menu">
                  <li className="active">
                    <a href="javascript:void(0);">
                      <i className="fas fa-download"></i> Inbox{" "}
                      <span className="mail-count">(5)</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i className="far fa-star"></i> Important
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i className="far fa-paper-plane"></i> Sent Mail
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i className="far fa-file-alt"></i> Drafts{" "}
                      <span className="mail-count">(13)</span>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      <i className="far fa-trash-alt"></i> Trash
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-9 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="email-header">
                      <div className="row">
                        <div className="col-lg-9 top-action-left col-sm-12">
                          <div className="float-left">
                            <div className="btn-group dropdown-action me-1">
                              <button
                                type="button"
                                className="btn btn-white dropdown-toggle"
                                data-bs-toggle="dropdown"
                              >
                                Select <i className="fas fa-angle-down"></i>
                              </button>
                              <div className="dropdown-menu">
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  All
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  None
                                </a>
                                <div className="dropdown-divider"></div>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Read
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Unread
                                </a>
                              </div>
                            </div>
                            <div className="btn-group dropdown-action me-1">
                              <button
                                type="button"
                                className="btn btn-white dropdown-toggle"
                                data-bs-toggle="dropdown"
                              >
                                Actions <i className="fas fa-angle-down"></i>
                              </button>
                              <div className="dropdown-menu">
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Reply
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Forward
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Archive
                                </a>
                                <div className="dropdown-divider"></div>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Mark As Read
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Mark As Unread
                                </a>
                                <div className="dropdown-divider"></div>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Delete
                                </a>
                              </div>
                            </div>
                            <div className="btn-group dropdown-action me-1">
                              <button
                                type="button"
                                className="btn btn-white dropdown-toggle"
                                data-bs-toggle="dropdown"
                              >
                                <i className="fas fa-folder"></i>{" "}
                                <i className="fas fa-angle-down"></i>
                              </button>
                              <div role="menu" className="dropdown-menu">
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Social
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Forums
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Updates
                                </a>
                                <div className="dropdown-divider"></div>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Spam
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Trash
                                </a>
                                <div className="dropdown-divider"></div>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  New
                                </a>
                              </div>
                            </div>
                            <div className="btn-group dropdown-action me-1">
                              <button
                                type="button"
                                data-bs-toggle="dropdown"
                                className="btn btn-white dropdown-toggle"
                              >
                                <i className="fas fa-tags"></i>{" "}
                                <i className="fas fa-angle-down"></i>
                              </button>
                              <div role="menu" className="dropdown-menu">
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Work
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Family
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Social
                                </a>
                                <div className="dropdown-divider"></div>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Primary
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Promotions
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="javascript:void(0);"
                                >
                                  Forums
                                </a>
                              </div>
                            </div>
                            <div className="btn-group dropdown-action mail-search">
                              <input
                                type="text"
                                placeholder="Search Messages"
                                className="form-control search-message"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 top-action-right col-sm-12">
                          <div className="text-end ">
                            <button
                              type="button"
                              title="Refresh"
                              data-toggle="tooltip"
                              className="btn btn-white d-none d-md-inline-block me-1"
                            >
                              <i className="fas fa-sync-alt"></i>
                            </button>
                            <div className="btn-group">
                              <a className="btn btn-white">
                                <i className="fas fa-angle-left"></i>
                              </a>
                              <a className="btn btn-white">
                                <i className="fas fa-angle-right"></i>
                              </a>
                            </div>
                          </div>
                          <div className="text-end">
                            <span className="text-muted d-none d-md-inline-block">
                              Showing 10 of 112
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="email-content">
                      <div className="table-responsive">
                        <table className="table table-inbox table-hover">
                          <thead>
                            <tr>
                              <th colspan="6">
                                <label className="checkboxs">
                                  <input type="checkbox" id="select-all" />
                                  <span className="checkmarks"></span>
                                </label>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="unread clickable-row">
                              <td>
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </td>
                              <td>
                                <span className="mail-important">
                                  <i className="fas fa-star starred"></i>
                                </span>
                              </td>
                              <td className="name">John Doe</td>
                              <td className="subject">
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit
                              </td>
                              <td>
                                <i className="fas fa-paperclip"></i>
                              </td>
                              <td className="mail-date">13:14</td>
                            </tr>
                            <tr className="unread clickable-row">
                              <td>
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </td>
                              <td>
                                <span className="mail-important">
                                  <i className="far fa-star"></i>
                                </span>
                              </td>
                              <td className="name">Envato Account</td>
                              <td className="subject">
                                Important account security update from Envato
                              </td>
                              <td></td>
                              <td className="mail-date">8:42</td>
                            </tr>
                            <tr className="clickable-row">
                              <td>
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </td>
                              <td>
                                <span className="mail-important">
                                  <i className="far fa-star"></i>
                                </span>
                              </td>
                              <td className="name">Twitter</td>
                              <td className="subject">
                                HRMS Bootstrap Admin Template
                              </td>
                              <td></td>
                              <td className="mail-date">30 Nov</td>
                            </tr>
                            <tr className="unread clickable-row">
                              <td>
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </td>
                              <td>
                                <span className="mail-important">
                                  <i className="far fa-star"></i>
                                </span>
                              </td>
                              <td className="name">Richard Parker</td>
                              <td className="subject">
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit
                              </td>
                              <td></td>
                              <td className="mail-date">18 Sep</td>
                            </tr>
                            <tr className="clickable-row">
                              <td>
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </td>
                              <td>
                                <span className="mail-important">
                                  <i className="far fa-star"></i>
                                </span>
                              </td>
                              <td className="name">John Smith</td>
                              <td className="subject">
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit
                              </td>
                              <td></td>
                              <td className="mail-date">21 Aug</td>
                            </tr>
                            <tr className="clickable-row">
                              <td>
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </td>
                              <td>
                                <span className="mail-important">
                                  <i className="far fa-star"></i>
                                </span>
                              </td>
                              <td className="name">me, Robert Smith (3)</td>
                              <td className="subject">
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit
                              </td>
                              <td></td>
                              <td className="mail-date">1 Aug</td>
                            </tr>
                            <tr className="unread clickable-row">
                              <td>
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </td>
                              <td>
                                <span className="mail-important">
                                  <i className="far fa-star"></i>
                                </span>
                              </td>
                              <td className="name">Codecanyon</td>
                              <td className="subject">Welcome To Codecanyon</td>
                              <td></td>
                              <td className="mail-date">Jul 13</td>
                            </tr>
                            <tr className="clickable-row">
                              <td>
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </td>
                              <td>
                                <span className="mail-important">
                                  <i className="far fa-star"></i>
                                </span>
                              </td>
                              <td className="name">Richard Miles</td>
                              <td className="subject">
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit
                              </td>
                              <td>
                                <i className="fas fa-paperclip"></i>
                              </td>
                              <td className="mail-date">May 14</td>
                            </tr>
                            <tr className="unread clickable-row">
                              <td>
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </td>
                              <td>
                                <span className="mail-important">
                                  <i className="far fa-star"></i>
                                </span>
                              </td>
                              <td className="name">John Smith</td>
                              <td className="subject">
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit
                              </td>
                              <td></td>
                              <td className="mail-date">11/11/16</td>
                            </tr>
                            <tr className="clickable-row">
                              <td>
                                <label className="checkboxs">
                                  <input type="checkbox" />
                                  <span className="checkmarks"></span>
                                </label>
                              </td>
                              <td>
                                <span className="mail-important">
                                  <i className="far fa-star starred"></i>
                                </span>
                              </td>
                              <td className="name">Mike Litorus</td>
                              <td className="subject">
                                Lorem ipsum dolor sit amet, consectetuer
                                adipiscing elit
                              </td>
                              <td></td>
                              <td className="mail-date">10/31/16</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

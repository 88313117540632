import { useEffect, useState } from "react"
import { Common, Constant } from "../../utils"
import API from "../../apis"
import { urls } from "../../apis/urls"
import CustomAlert from "../custom-alerts/CustomAlert"

const AddBrand = ({
  navigate,
  formType,
  selectedData,
  showAddBrandForm,
  hideBrandForm,
}) => {
  const [loading, setLoading] = useState(false)
  const [brandName, setBrandName] = useState("")
  const [convertedJsonData, setConvertedJsonData] = useState('')
  const [customAlertType, setCustomAlertType] = useState("")

  useEffect(() => {
    if (selectedData) {
      setBrandName(selectedData?.brandName)
    }
  }, [selectedData])

  const handleBrandName = (event) => setBrandName(event.target.value)

  const clearInputValues = () => {
    setBrandName("")
  }
  const [alertTitle, setAlertTitle] = useState("")
  const [alertDesc, setAlertDesc] = useState("")
  const [isALert, setIsAlert] = useState(false)

  const CustomAlertTexts = (title, desc, alertType) => {
    setAlertTitle(title ?? '')
    setAlertDesc(desc ?? '')
    setCustomAlertType(alertType)
    setIsAlert(true)
  }
  const checkValidations = async () => {
    if (!brandName) {
      CustomAlertTexts("Error", "Brand Name is required", "error")
      return false
    }
    submitData()
  }

  const submitData = async () => {
    setLoading(true)
    const params = {
      pk_BrandId: formType === Constant.FORM_TYPE_NEW ? 0 : 0,
      brandName: brandName,
      fk_UsrOrgDirId: Common.getOrganisationID(),
      isActive: true,
      createdBy: Common.getLoggedInUserID(),
      isFlag: 0,
    }
    console.log("params - ", params)
    API.postRequestWithJsonForOnline(
      urls.createBrand + `?tockenKey=${Common.getTokenKey()}`,
      params
    )
      .then(handleResponse)
      .catch(() => {
        setLoading(false)
      })
  }

  const handleResponse = (response) => {
    setLoading(false)
    if (response.result.response_Status === Constant.STATUS_SUCCESS) {
      clearInputValues()
      CustomAlertTexts(response.result.response_Status, response.result.response_Message, response.result.response_Status)
      location.reload()
    } else if (response.result.response_Status === Constant.STATUS_ERROR) {      
      CustomAlertTexts(response.result.response_Status, response.result.response_Message, response.result.response_Status)
    } else {
      Common.doLogout(navigate)
    }
  }

  const [isActive, setIsActive] = useState("active")
  const onActiveStatus = (e) => setIsActive(e.target.value)

  (convertedJsonData && console.log('convertedJsonData - ', convertedJsonData))

  return (
    <>
      {isALert && (
        <CustomAlert
          alertTitle={alertTitle}
          alertDesc={alertDesc}
          onOkClick={() => setIsAlert(false)}
          customAlertType={customAlertType}
        />
      )}

      <div className="outer-modal-contentbox">
          <div className="offcanvas-header border-bottom">
            <h5 className="fw-semibold">
              {formType === Constant.FORM_TYPE_EDIT
                ? "Update Brand"
                : formType === Constant.FORM_TYPE_NEW
                ? "Add Brand"
                : formType === Constant.FORM_TYPE_VIEW
                ? "Brand Details"
                : ""}
            </h5>

            {showAddBrandForm === true ? (
              <button
                type="button"
                className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
                onClick={hideBrandForm}
              />
            ) : (
              <button
                type="button"
                className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
                data-bs-dismiss="offcanvas"
                onClick={() => {
                  clearInputValues()
                  return false
                }}
                aria-label="Close"
              >
                <i className="ti ti-x"></i>
              </button>
            )}
          </div>

          <div className="modal-content">
            <div className={`modal-body ${!showAddBrandForm ? "customModal" :""}`}>
              <form>
                <div className="mb-3">
                  <label className="col-form-label">Brand Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Brand Name"
                    onChange={handleBrandName}
                    value={brandName}
                    disabled={formType == Constant.FORM_TYPE_VIEW}
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Status:
                  </label>
                  <select
                    class="form-select"
                    onChange={onActiveStatus}
                    value={isActive}
                    disabled
                  >
                    <option value="active">Active</option>
                    <option value="isactive">In Active</option>
                  </select>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              {formType !== Constant.FORM_TYPE_VIEW && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={checkValidations}
                  disabled={formType === Constant.FORM_TYPE_VIEW}
                >
                  {formType === Constant.FORM_TYPE_EDIT ? "Update" : "Submit"}
                </button>
              )}
            </div>
          </div>
          
      </div>
    </>
  )
}

export default AddBrand

import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"
import SettingsHeader from "../../components/page-components/settings-header"

export default function EmailSettings() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h4 className="page-title">Settings</h4>
                    </div>
                    <div className="col-4 text-end">
                      <div className="head-icons">
                        <a
                          href="email-settings.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                {/* <!-- Settings Menu --> */}
                <SettingsHeader/>
                {/* <!-- /Settings Menu --> */}

                <div className="row">
                  <div className="col-xl-3 col-lg-12 theiaStickySidebar">
                    {/* <!-- Settings Sidebar --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="settings-sidebar">
                          <h4 className="fw-semibold mb-3">Other Settings</h4>
                          <div className="list-group list-group-flush settings-sidebar">
                            <a
                              href="email-settings.html"
                              className="fw-medium active"
                            >
                              Email Settings
                            </a>
                            <a href="sms-gateways.html" className="fw-medium">
                              SMS Gateways
                            </a>
                            <a href="gdpr-cookies.html" className="fw-medium">
                              GDPR Cookies
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Sidebar --> */}
                  </div>

                  <div className="col-xl-9 col-lg-12">
                    {/* <!-- Settings Info --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <h4 className="fs-20">Email Settings</h4>
                          <a
                            href="javascript:void(0)"
                            className="btn-add"
                            data-bs-toggle="modal"
                            data-bs-target="#add_mail"
                          >
                            <i className="ti ti-mail"></i>
                          </a>
                        </div>
                        <div className="row">
                          {/* <!-- Email Wrap --> */}
                          <div className="col-md-12">
                            {/* <!-- PHP Mailer --> */}
                            <div className="border rounded p-3 mb-4">
                              <div className="row gy-3">
                                <div className="col-sm-5">
                                  <div className="d-flex align-items-center">
                                    <span className="avatar avtar-lg border me-2 flex-shrink-0">
                                      <img
                                        src="/img/icons/mail-01.svg"
                                        className="w-auto h-auto"
                                        alt="img"
                                      />
                                    </span>
                                    <div>
                                      <h6 className="fw-medium mb-1">
                                        PHP Mailer
                                      </h6>
                                      <a
                                        href="javascript:void(0);"
                                        className="badge bg-soft-success"
                                      >
                                        Connected
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-7">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                      <a
                                        href="javascript:void(0);"
                                        data-bs-toggle="collapse"
                                        data-bs-target="#php-mail"
                                        className="info-icon me-3 text-default"
                                      >
                                        <i className="ti ti-info-circle-filled me-1"></i>
                                      </a>
                                      <a
                                        href="#"
                                        className="btn btn-light"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add_phpmail"
                                      >
                                        <i className="ti ti-tool me-1"></i>View
                                        Integration
                                      </a>
                                    </div>
                                    <div className="status-toggle">
                                      <input
                                        type="checkbox"
                                        id="mail1"
                                        className="check"
                                        checked=""
                                      />
                                      <label
                                        for="mail1"
                                        className="checktoggle"
                                      >
                                        {" "}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="collapse" id="php-mail">
                                <div className="mail-collapse">
                                  <p>
                                    PHPMailer is a third-party PHP library that
                                    provides a simple way to send emails in PHP.
                                    It offers a range of features that make it a
                                    popular alternative to PHP's built-in mail()
                                    function, such as support for HTML emails,
                                    attachments, and SMTP authentication.
                                  </p>
                                </div>
                              </div>
                            </div>
                            {/* <!-- /PHP Mailer --> */}

                            {/* <!-- SMTP --> */}
                            <div className="border rounded p-3 mb-4">
                              <div className="row gy-3">
                                <div className="col-sm-5">
                                  <div className="d-flex align-items-center">
                                    <span className="avatar avtar-lg border me-2 flex-shrink-0">
                                      <img
                                        src="/img/icons/mail-02.svg"
                                        className="w-auto h-auto"
                                        alt="img"
                                      />
                                    </span>
                                    <div>
                                      <h6 className="fw-medium mb-1">SMTP</h6>
                                      <a
                                        href="javascript:void(0);"
                                        className="badge bg-soft-success"
                                      >
                                        Connected
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-7">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                      <a
                                        href="javascript:void(0);"
                                        className="me-3 text-default"
                                      >
                                        <i className="ti ti-info-circle-filled me-1"></i>
                                      </a>
                                      <a
                                        href="#"
                                        className="btn btn-light"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add_smtp"
                                      >
                                        <i className="ti ti-tool me-1"></i>View
                                        Integration
                                      </a>
                                    </div>
                                    <div className="status-toggle">
                                      <input
                                        type="checkbox"
                                        id="mail2"
                                        className="check"
                                        checked=""
                                      />
                                      <label
                                        for="mail2"
                                        className="checktoggle"
                                      >
                                        {" "}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <!-- /SMTP --> */}

                            {/* <!-- SendGrid --> */}
                            <div className="border rounded p-3">
                              <div className="row gy-3">
                                <div className="col-sm-5">
                                  <div className="d-flex align-items-center">
                                    <span className="avatar avtar-lg border me-2 flex-shrink-0">
                                      <img
                                        src="/img/icons/mail-03.svg"
                                        className="w-auto h-auto"
                                        alt="img"
                                      />
                                    </span>
                                    <div>
                                      <h6 className="fw-medium mb-1">
                                        SendGrid
                                      </h6>
                                      <a
                                        href="javascript:void(0);"
                                        className="badge bg-soft-purple text-purple"
                                      >
                                        Not Connected
                                      </a>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-7">
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div>
                                      <a
                                        href="javascript:void(0);"
                                        className="me-3 text-default"
                                      >
                                        <i className="ti ti-info-circle-filled me-1"></i>
                                      </a>
                                      <a href="#" className="btn btn-primary">
                                        <i className="ti ti-plug-connected me-1"></i>
                                        Connect Now
                                      </a>
                                    </div>
                                    <div className="status-toggle">
                                      <input
                                        type="checkbox"
                                        id="mail3"
                                        className="check"
                                        checked=""
                                      />
                                      <label
                                        for="mail3"
                                        className="checktoggle"
                                      >
                                        {" "}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <!-- /SendGrid --> */}
                          </div>
                          {/* <!-- /Email Wrap --> */}
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Info --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- PHP Mailer --> */}
        <div className="modal fade" id="add_phpmail" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">PHP Mailer</h5>
                <div className="d-flex align-items-center">
                  <div className="status-toggle me-2">
                    <input
                      type="checkbox"
                      id="toggle"
                      className="check"
                      checked=""
                    />
                    <label for="toggle" className="checktoggle"></label>
                  </div>
                  <button
                    className="btn-close custom-btn-close border p-1 me-0 text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>
              </div>
              <form action="email-settings.html">
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="col-form-label">
                      From Email Address <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Email Password <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-0">
                    <label className="col-form-label">
                      From Email Name <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex align-items-center justify-content-end m-0">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- /PHP Mailer --> */}

        {/* <!-- SMTP --> */}
        <div className="modal fade" id="add_smtp" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">SMTP</h5>
                <div className="d-flex align-items-center">
                  <div className="status-toggle me-2">
                    <input
                      type="checkbox"
                      id="toggle1"
                      className="check"
                      checked=""
                    />
                    <label for="toggle1" className="checktoggle"></label>
                  </div>
                  <button
                    className="btn-close custom-btn-close border p-1 me-0 text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>
              </div>
              <form action="email-settings.html">
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="col-form-label">
                      From Email Address <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Email Password <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Email Host <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-0">
                    <label className="col-form-label">
                      Port <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex align-items-center justify-content-end m-0">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- /SMTP --> */}

        {/* <!-- Test Mail --> */}
        <div className="modal fade" id="add_mail" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Test Mail</h5>
                <button
                  className="btn-close custom-btn-close border p-1 me-0 text-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x"></i>
                </button>
              </div>
              <form action="email-settings.html">
                <div className="modal-body">
                  <div className="mb-0">
                    <label className="col-form-label">
                      Enter Email Address <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex align-items-center justify-content-end m-0">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- /Test Mail --> */}
      </div>
    </>
  )
}

import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"

export const statusListAdapter = createEntityAdapter()

export const fetchStatusList = createAsyncThunk(
    Preferences.STATUS_LIST,
    async (thunkApi) => {
        const params = `?fk_UsrOrgDirId=${
                JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
              }&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getStatusList, params)
        if (response?.result?.response_Status === 'success') {
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status == 'error') {
            alert(response?.result?.response_Message) 
        } else {
            alert('Something went wrong with getStatusData')
        }
    }
)

export const initialAppState = statusListAdapter.getInitialState({
    loading: 'idel',
    statusListData: [],
    filterData: [],
    error: []
})

export const statusListSlice = createSlice({
    name: Preferences.STATUS_LIST,
    initialState: initialAppState,
    reducers: {
        resetStatusListFilter: state => {
            state.filterData = state.statusListData
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchStatusList.pending, state => {
            state.loading = 'loading'
        })
        builder.addCase(fetchStatusList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.statusListData = action.payload ?? []
            state.error = null
        })
        builder.addCase(fetchStatusList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    }
})

export const statusListReducer = statusListSlice.reducer

export const getStatusListLoading = state => {
    return state.statusListReducer.loading === 'loading' ? true : false
}

export const getStatusListError = state => {
    return state.statusListReducer.error
}

export const getStatusListFilterData = state => {
    return state.statusListReducer.filterData
}

export const getStatusListUniqueData = state => {
    return [...new Set(state.statusListReducer.statusListData.map(item => item.statusName))].map(statusName => {
        return state.statusListReducer.statusListData.find(item => item.statusName === statusName)
    })
}

export const getStatusListData = state => {
    return state.statusListReducer.statusListData
}

export const getStatusListDataByID = (state, {statusID}) => {
    return state.statusListReducer.filterData.filter(e => e?.pk_statusId == statusID)
}
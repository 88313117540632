import React, {useState} from 'react'
import VerticalMenuComponent from '../../components/VerticalMenuComponent'
import TopMenuComponent from '../../components/TopMenuComponent'
export default function Language() {

	const [isOpen, setIsOpen] = useState(false)
	const openSideNav = () => {
	  setIsOpen(!isOpen)
	}

  return (
    <>
    
    <div className={`main-wrapper ${isOpen && "slide-nav"}`} >
                    {/* header component  */}
                    <VerticalMenuComponent/>
                    <TopMenuComponent openSideNav={openSideNav} />

                    {/* page wrapper  */}
                    <div className='page-wrapper'>
                            <div className='content'>
                                   
                            <div className="row">
					<div className="col-md-12">

						{/* <!-- Page Header --> */}
						<div className="page-header">
							<div className="row align-items-center">
								<div className="col-8">
									<h4 className="page-title">Settings</h4>
								</div>
								<div className="col-4 text-end">
									<div className="head-icons">
										<a href="language.html" data-bs-toggle="tooltip" data-bs-placement="top"
											data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
										<a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top"
											data-bs-original-title="Collapse" id="collapse-header"><i
												className="ti ti-chevrons-up"></i></a>
									</div>
								</div>
							</div>
						</div>
						{/* <!-- /Page Header --> */}

						{/* <!-- Settings Menu --> */}
						<div className="card">
							<div className="card-body pb-0 pt-2">
								<ul className="nav nav-tabs nav-tabs-bottom">
									<li className="nav-item me-3">
										<a href="profile.html" className="nav-link px-0">
											<i className="ti ti-settings-cog me-2"></i>General Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="company-settings.html" className="nav-link px-0 active">
											<i className="ti ti-world-cog me-2"></i>Website Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="invoice-settings.html" className="nav-link px-0">
											<i className="ti ti-apps me-2"></i>App Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="email-settings.html" className="nav-link px-0">
											<i className="ti ti-device-laptop me-2"></i>System Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="payment-gateways.html" className="nav-link px-0">
											<i className="ti ti-moneybag me-2"></i>Financial Settings
										</a>
									</li>
									<li className="nav-item">
										<a href="storage.html" className="nav-link px-0">
											<i className="ti ti-flag-cog me-2"></i>Other Settings
										</a>
									</li>
								</ul>
							</div>
						</div>
						{/* <!-- /Settings Menu --> */}

						<div className="row">
							<div className="col-xl-3 col-lg-12 theiaStickySidebar">

								{/* <!-- Settings Sidebar --> */}
								<div className="card">
									<div className="card-body">
										<div className="settings-sidebar">
											<h4 className="fw-semibold mb-3">Website Settings</h4>
											<div className="list-group list-group-flush settings-sidebar">
												<a href="company-settings.html" className="fw-medium">Company Settings</a>
												<a href="localization.html" className="fw-medium">Localization</a>
												<a href="prefixes.html" className="fw-medium">Prefixes</a>
												<a href="preference.html" className="fw-medium">Preference</a>
												<a href="appearance.html" className="fw-medium">Appearance</a>
												<a href="language.html" className="fw-medium active">Language</a>
											</div>
										</div>
									</div>
								</div>
								{/* <!-- /Settings Sidebar --> */}

							</div>

							<div className="col-xl-9 col-lg-12">

								{/* <!-- Custom Fields --> */}
								<div className="card">
									<div className="card-body">
										<h4 className="fw-semibold mb-3">Language</h4>
										{/* <!-- Search --> */}
										<div className="row">
											<div className="col-xl-3 col-md-3 col-lg-5">
												<div className="mb-3 icon-form">
													<span className="form-icon"><i className="ti ti-search"></i></span>
													<input type="text" className="form-control"
														placeholder="Search Language"/>
												</div>
											</div>
											<div className="col-xl-9 col-md-9 col-lg-12">
												<div className="d-flex align-items-center flex-wrap justify-content-sm-end">
													<div className="dropdown me-2 mb-3">
														<a href="javascript:void(0);" className="dropdown-toggle"
															data-bs-toggle="dropdown"><i
																className="ti ti-package-export me-2"></i>Export</a>
														<div className="dropdown-menu  dropdown-menu-end">
															<ul>
																<li>
																	<a href="javascript:void(0);"
																		className="dropdown-item"><i
																			className="ti ti-file-type-pdf text-danger me-1"></i>Export
																		as PDF</a>
																</li>
																<li>
																	<a href="javascript:void(0);"
																		className="dropdown-item"><i
																			className="ti ti-file-type-xls text-green me-1"></i>Export
																		as Excel </a>
																</li>
															</ul>
														</div>
													</div>
													<a href="javascript:void(0);" className="btn btn-primary me-2 mb-3"
														data-bs-toggle="modal" data-bs-target="#import_sample"><i
															className="ti ti-download me-2"></i>Import Sample</a>
													<a href="javascript:void(0);" className="btn btn-primary mb-3"
														data-bs-toggle="modal" data-bs-target="#add_translation"><i
															className="ti ti-square-rounded-plus me-2"></i>Add
														Translation</a>
												</div>
											</div>
										</div>
										{/* <!-- /Search --> */}

										{/* <!-- Filter --> */}
										<div className="d-flex align-items-center justify-content-between flex-wrap mb-2">
											<div className="dropdown mb-3">
												<a href="javascript:void(0);" className="dropdown-toggle"
													data-bs-toggle="dropdown"><i
														className="ti ti-sort-ascending-2 me-2"></i>Sort </a>
												<div className="dropdown-menu  dropdown-menu-start">
													<ul>
														<li>
															<a href="javascript:void(0);" className="dropdown-item">
																<i className="ti ti-circle-chevron-right me-1"></i>Ascending
															</a>
														</li>
														<li>
															<a href="javascript:void(0);" className="dropdown-item">
																<i
																	className="ti ti-circle-chevron-right me-1"></i>Descending
															</a>
														</li>
														<li>
															<a href="javascript:void(0);" className="dropdown-item">
																<i className="ti ti-circle-chevron-right me-1"></i>Recently
																Viewed
															</a>
														</li>
														<li>
															<a href="javascript:void(0);" className="dropdown-item">
																<i className="ti ti-circle-chevron-right me-1"></i>Recently
																Added
															</a>
														</li>
													</ul>
												</div>
											</div>
											<div className="dropdown mb-3">
												<a href="javascript:void(0);" className="btn bg-soft-purple text-purple"
													data-bs-toggle="dropdown" data-bs-auto-close="outside"><i
														className="ti ti-columns-3 me-2"></i>Manage Columns</a>
												<div className="dropdown-menu  dropdown-menu-md-end dropdown-md p-3">
													<h4 className="mb-2 fw-semibold">Want to manage datatables?</h4>
													<p className="mb-3">Please drag and drop your column to reorder your
														table and enable see option as you want.</p>
													<div className="border-top pt-3">
														<div
															className="d-flex align-items-center justify-content-between mb-3">
															<p className="mb-0 d-flex align-items-center"><i
																	className="ti ti-grip-vertical me-2"></i>Language</p>
															<div className="status-toggle">
																<input type="checkbox" id="col-name" className="check" />
																<label for="col-name" className="checktoggle"></label>
															</div>
														</div>
														<div
															className="d-flex align-items-center justify-content-between mb-3">
															<p className="mb-0 d-flex align-items-center"><i
																	className="ti ti-grip-vertical me-2"></i>Code</p>
															<div className="status-toggle">
																<input type="checkbox" id="col-phone" className="check"/>
																<label for="col-phone" className="checktoggle"></label>
															</div>
														</div>
														<div
															className="d-flex align-items-center justify-content-between mb-3">
															<p className="mb-0 d-flex align-items-center"><i
																	className="ti ti-grip-vertical me-2"></i>RTL</p>
															<div className="status-toggle">
																<input type="checkbox" id="col-email" className="check"/>
																<label for="col-email" className="checktoggle"></label>
															</div>
														</div>
														<div
															className="d-flex align-items-center justify-content-between mb-3">
															<p className="mb-0 d-flex align-items-center"><i
																	className="ti ti-grip-vertical me-2"></i>Total</p>
															<div className="status-toggle">
																<input type="checkbox" id="col-tag" className="check"/>
																<label for="col-tag" className="checktoggle"></label>
															</div>
														</div>
														<div
															className="d-flex align-items-center justify-content-between mb-3">
															<p className="mb-0 d-flex align-items-center"><i
																	className="ti ti-grip-vertical me-2"></i>Done</p>
															<div className="status-toggle">
																<input type="checkbox" id="col-loc" className="check"/>
																<label for="col-loc" className="checktoggle"></label>
															</div>
														</div>
														<div
															className="d-flex align-items-center justify-content-between mb-3">
															<p className="mb-0 d-flex align-items-center"><i
																	className="ti ti-grip-vertical me-2"></i>Progress</p>
															<div className="status-toggle">
																<input type="checkbox" id="col-rate" className="check"/>
																<label for="col-rate" className="checktoggle"></label>
															</div>
														</div>
														<div
															className="d-flex align-items-center justify-content-between mb-3">
															<p className="mb-0 d-flex align-items-center"><i
																	className="ti ti-grip-vertical me-2"></i>Status</p>
															<div className="status-toggle">
																<input type="checkbox" id="col-owner" className="check"/>
																<label for="col-owner" className="checktoggle"></label>
															</div>
														</div>
														<div
															className="d-flex align-items-center justify-content-between mb-3">
															<p className="mb-0 d-flex align-items-center"><i
																	className="ti ti-grip-vertical me-2"></i>Contact</p>
															<div className="status-toggle">
																<input type="checkbox" id="col-contact" className="check"
																	checked=""/>
																<label for="col-contact" className="checktoggle"></label>
															</div>
														</div>
														<div
															className="d-flex align-items-center justify-content-between mb-3">
															<p className="mb-0 d-flex align-items-center"><i
																	className="ti ti-grip-vertical me-2"></i>Status</p>
															<div className="status-toggle">
																<input type="checkbox" id="col-status" className="check"/>
																<label for="col-status" className="checktoggle"></label>
															</div>
														</div>
														<div className="d-flex align-items-center justify-content-between">
															<p className="mb-0 d-flex align-items-center"><i
																	className="ti ti-grip-vertical me-2"></i>Action</p>
															<div className="status-toggle">
																<input type="checkbox" id="col-action" className="check"/>
																<label for="col-action" className="checktoggle"></label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										{/* <!-- /Filter --> */}

										{/* <!-- Contact List --> */}
										<div className="table-responsive custom-table">
											<table className="table" id="language-list">
												<thead className="thead-light">
													<tr>
														<th className="no-sort"></th>
														<th className="no-sort"></th>
														<th>Language</th>
														<th>Code</th>
														<th>RTL</th>
														<th>Total</th>
														<th>Done</th>
														<th>Progress</th>
														<th>Status</th>
														<th>Action</th>
													</tr>
												</thead>
												<tbody>

												</tbody>
											</table>
										</div>
										<div className="row align-items-center">
											<div className="col-md-6">
												<div className="datatable-length"></div>
											</div>
											<div className="col-md-6">
												<div className="datatable-paginate"></div>
											</div>
										</div>
										{/* <!-- /Contact List --> */}

									</div>
								</div>
								{/* <!-- /Custom Fields --> */}

							</div>
						</div>
					</div>
				</div> 



                            </div>
                    </div>


                    {/* <!-- Add Translation --> */}
		<div className="modal fade" id="add_translation" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Add Translation</h5>
						<button className="btn-close custom-btn-close border p-1 me-0 text-dark" data-bs-dismiss="modal"
							aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<form action="language.html">
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Language <span className="text-danger">*</span></label>
								<input type="text" className="form-control"/>
							</div>
							<div className="mb-3">
								<label className="form-label">Code <span className="text-danger">*</span></label>
								<input type="text" className="form-control"/>
							</div>
							<div className="mb-0">
								<label className="form-label">Status</label>
								<div className="radio-wrap">
									<div className="d-flex flex-wrap">
										<div className="radio-btn">
											<input type="radio" className="status-radio" id="add-active" name="status"
												checked=""/>
											<label for="add-active">Active</label>
										</div>
										<div className="radio-btn">
											<input type="radio" className="status-radio" id="add-inactive" name="status"/>
											<label for="add-inactive">Inactive</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<div className="d-flex align-items-center">
								<a href="#" className="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Save</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		{/* <!-- /Add Translation --> */}

		{/* <!-- Edit Translation --> */}
		<div className="modal fade" id="edit_translation" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Edit Translation</h5>
						<button className="btn-close custom-btn-close border p-1 me-0 text-dark" data-bs-dismiss="modal"
							aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<form action="language.html">
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">Language <span className="text-danger">*</span></label>
								<input type="text" className="form-control" value="English"/>
							</div>
							<div className="mb-3">
								<label className="form-label">Code <span className="text-danger">*</span></label>
								<input type="text" className="form-control" value="en" />
							</div>
							<div className="mb-0">
								<label className="form-label">Status</label>
								<div className="radio-wrap">
									<div className="d-flex flex-wrap">
										<div className="radio-btn">
											<input type="radio" className="status-radio" id="edit-active" name="status"
												checked="" />
											<label for="edit-active">Active</label>
										</div>
										<div className="radio-btn">
											<input type="radio" className="status-radio" id="edit-inactive" name="status" />
											<label for="edit-inactive">Inactive</label>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<div className="d-flex align-items-center">
								<a href="#" className="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Save</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		{/* <!-- /Edit Translation --> */}

		{/* <!-- Import Sample --> */}
		<div className="modal fade" id="import_sample" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Import Sample</h5>
						<button className="btn-close custom-btn-close border p-1 me-0 text-dark" data-bs-dismiss="modal"
							aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<form action="language.html">
						<div className="modal-body">
							<div className="mb-3">
								<label className="form-label">File <span className="text-danger">*</span></label>
								<select className="select">
									<option>Inventory</option>
									<option>Expense</option>
									<option>Product</option>
								</select>
							</div>
							<div className="mb-3">
								<label className="form-label">Language <span className="text-danger">*</span></label>
								<select className="select">
									<option>English</option>
									<option>Hindi</option>
									<option>Chinese</option>
								</select>
							</div>
							<div className="mb-3">
								<div className="drag-attach">
									<input type="file" />
									<div className="img-upload">
										<i className="ti ti-file-broken"></i>Upload File
									</div>
								</div>
							</div>
							<div className="mb-0">
								<label className="form-label">Uploaded Files</label>
								<div className="upload-file">
									<h6>Projectneonals teyys.xls</h6>
									<p>4.25 MB</p>
									<div className="progress">
										<div className="progress-bar bg-success" role="progressbar" style={{width:"25%"}}
											aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
									<p className="black-text">45%</p>
								</div>
								<div className="upload-file d-flex align-items-center">
									<div>
										<h6 className="fw-medium">tes.txt</h6>
										<p>4.25 MB</p>
									</div>
									<a href="javascript:void(0);" className="text-danger"><i className="ti ti-trash-x"></i></a>
								</div>
							</div>
						</div>
						<div className="modal-footer">
							<div className="d-flex align-items-center">
								<a href="#" className="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Save</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		{/* <!-- /Import Sample --> */}

		{/* <!-- Delete Translation --> */}
		<div className="modal fade" id="delete_translation" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-body">
						<form action="language.html">
							<div className="text-center">
								<div className="avatar avatar-xl bg-danger-light rounded-circle mb-3">
									<i className="ti ti-trash-x fs-36 text-danger"></i>
								</div>
								<h4 className="mb-2">Remove Translation?</h4>
								<p className="mb-0">Are you sure you want to remove it.</p>
								<div className="d-flex align-items-center justify-content-center mt-4">
									<a href="#" className="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
									<button type="submit" className="btn btn-danger">Yes, Delete it</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Delete Translation --> */}



        </div>

    
    
    </>
  )
}

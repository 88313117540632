import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import "./VerticalMenu.js";
import { Common, Constant } from "../utils/index.js";

export default function VerticalMenuComponent() {
  let orgAccName = "test";
  let orgAccUrl = "test";
  let orgLogo = "test";

  // let singleUserAccountName = singleUserLogin?.result?.returnClass
  // let singleUserAccountURL = singleUserLogin?.returnClass?.usr_OrgUsrAccounts?.orgAccountURL
  // let singleUserAccountLogo = singleUserLogin?.returnClass?.usr_OrgUsrAccounts?.logo

  let userRoleId = 5;
  let menuUrl = `https://ssoapi.workxcrm.com/api/roleMenu/getUserActionRight?pk_usrRoleId=${userRoleId}&tockenKey=${Common.getTokenKey()}`

  const [userMenu, setUserMenu] = useState([])

  useEffect(() => {
    fetch(menuUrl)
      .then((response) => response.json())
      .then((menuData) => {
        setUserMenu(menuData.result.returnClass)
      });
  }, []);

  const [subMenu, setSubMenu] = useState([])  
  const [isDynamicMenuActive, setDynamicMenuActive] = useState(null)

  let filterInterNavLink = userMenu.filter((menu) => menu.interNavLink === true)
  let filterSubMenuMaster = userMenu.filter((menu) => menu.isMaster === false)
  let filterMenuMaster = userMenu.filter((menu) => menu.isMaster === true)

  const subMenuclick = (idx ,pk_usrMenuId) => {
    let subMenuFilter = filterSubMenuMaster.filter((submenu) => submenu.mainMenuId === pk_usrMenuId)    
    setSubMenu(subMenuFilter)    
    setDynamicMenuActive((prev) => (prev === idx ? null : idx))
  };

  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const [innerMenu, setInnerMenu] = useState(null);
  const [dashActive, setdashActive] = useState(null);

  const DashboardMenu = (e, index) => {
    e.preventDefault();
    setdashActive((prev) => (prev === index ? null : index));
  };

  const ApplicationMenuClick = (e, index) => {
    e.preventDefault();
    setActiveSubmenu((prev) => (prev === index ? null : index));
  };

  const ApplicationInnerSubMenu = (index) => {
    setInnerMenu((prev) => (prev === index ? null : index));
  };

  return (
    <>
      <div className="sidebar" id="sidebar">
        <div className="sidebar-inner slimscroll">
          <div id="sidebar-menu" className="sidebar-menu">
            <ul>
              <li className="clinicdropdown">
                <a href="profile">
                  <img
                    src={"/img/profiles/avatar-20.jpg"}
                    className="img-fluid"
                    alt="Profile"
                  />
                  <div className="user-names">
                    <h5>{orgAccName ? orgAccName : "Not an User"}</h5>
                    <h6> {orgAccUrl ? orgAccUrl : "No User Found"} </h6>
                  </div>
                </a>
              </li>
            </ul>
            <ul>
              {/* Dynamic Menu Start  */}
              <li>
                <h6 className="submenu-hdr">Dynamic Main Menu</h6>
                <ul>
                  {
                    filterMenuMaster.map((menu, idx) =>{                      
                      return(
                        menu.urlLink === "" ? (
                          <li className="submenu" key={idx}>
                          <a
                            href="#!"
                            onClick={() => subMenuclick(idx, menu.pk_usrMenuId)}
                            className={`${isDynamicMenuActive === idx ? "subdrop" : ""}`}> 
                            <i className="ti ti-layout-2"></i>
                            <span>{menu.actualTitle}</span>
                            <span className="menu-arrow"></span>
                          </a>
                          <ul style={{display: isDynamicMenuActive === idx ? "block" : "none"}}>
                            {subMenu.map((submenu, idx) => (
                              <li key={idx}>
                                <NavLink to={submenu.urlLink}>
                                  {submenu.actualTitle}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li> 
                        ):(
                          <li key={idx}>
                            <NavLink to={menu.urlLink}>
                              <i className="ti ti-box"></i>
                              <span>{menu.displayTitle}</span>
                            </NavLink>
                          </li>
                        )
                      )
                    })

                  }
                  {/* <li>
                    {filterMenuMaster?.map((menu) => {
                      return (
                        <li key={menu.srNumber}>
                          {menu.urlNavLink === "" ? (
                            <li className="submenu">
                              <a
                                href="#!"
                                onClick={() => subMenuclick(menu.pk_usrMenuId)}>                                  
                                <i className="ti ti-layout-2"></i>
                                <span>{menu.actualTitle}</span>
                                <span className="menu-arrow"></span>
                              </a>
                              <ul>
                                {subMenu.map((submenu, idx) => (
                                  <li key={idx}>
                                    <NavLink to={submenu.urlNavLink}>
                                      {submenu.actualTitle}
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          ) : (
                            <Link to={menu.urlNavLink} className="">
                              <i className="ti ti-layout-2"></i>
                              <span> {menu.displayTitle} </span>
                            </Link>
                          )}
                        </li>
                      )
                    })}

                  </li> */}
                </ul>
              </li>

              {/* Dynamic Menu End  */}

              <li>
                <h6 className="submenu-hdr">Main Menu</h6>
                <ul>
                  {/* Dashboard Main Menu  */}
                  <li className="submenu">
                    <a
                      href="#!"
                      className={`${dashActive === 11 ? "subdrop" : " "}`}
                      onClick={(e) => DashboardMenu(e, 11)}
                    >
                      <i className="ti ti-layout-2"></i>
                      <span>Dashboard</span>
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={{ display: dashActive === 11 ? "block" : "none" }}
                    >
                      <li>
                        <NavLink to="/dashboard">Deals Dashboard</NavLink>
                      </li>
                      <li>
                        <NavLink to="/leads-dashboard">Leads Dashboard</NavLink>
                      </li>
                      <li>
                        <NavLink to="/project-dashboard">
                          Project Dashboard
                        </NavLink>
                      </li>
                    </ul>
                  </li>

                  {/* Task Menu  */}

                  <li className="submenu">
                    <a
                      href="#!"
                      className={`${activeSubmenu === 1 ? "subdrop" : ""}`}
                      onClick={(e) => ApplicationMenuClick(e, 1)}
                    >
                      <i className="ti ti-brand-airtable"></i>
                      <span>Task</span>
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={{
                        display: activeSubmenu === 1 ? "block" : "",
                      }}
                    >
                      {/* Submenu 1: Call */}
                      <li className="submenu submenu-two">
                        <a href="#!" onClick={() => ApplicationInnerSubMenu(2)}>
                          Call
                          <span className="menu-arrow inside-submenu"></span>
                        </a>
                        <ul
                          style={{
                            display: innerMenu === 2 ? "block" : "none",
                          }}
                        >
                          <li>
                            <NavLink to="/audio-call">Audio Call</NavLink>
                          </li>
                          <li>
                            <NavLink to="/call-history">Call History</NavLink>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <NavLink to="/calendar">Calendar</NavLink>
                      </li>
                      <li>
                        <NavLink to="/email">Email</NavLink>
                      </li>
                      <li>
                        <NavLink to="/todo">To Do</NavLink>
                      </li>
                      <li>
                        <NavLink to="/notes">Notes</NavLink>
                      </li>
                      <li>
                        <NavLink to="/file-manager">File Manager</NavLink>
                      </li>
                    </ul>

                  </li>

                </ul>
              </li>

              <li>
                <h6 className="submenu-hdr">CRM</h6>
                <ul>
                  <li>
                    <NavLink to="/call-logs">
                      {/* <i className="ti ti-user-up"></i> */}
                      <i className="ti ti-phone-calling"></i>
                      <span>Call Log</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">
                      <i className="ti ti-user-up"></i>
                      <span>Contact</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/product-type">
                      <i class="ti ti-box"></i>
                      <span>Product Type</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/product-category">
                      <i class="ti ti-category"></i>
                      <span>Product Category</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/product">
                      <i class="ti ti-box"></i>
                      <span>Product</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/brand">
                      <i class="ti ti-box"></i>
                      <span>Brand</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/team">
                      <i class="ti ti-users"></i>
                      <span>Team</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/companies">
                      <i className="ti ti-building-community"></i>
                      <span>Companies</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/deals">
                      <i className="ti ti-medal"></i>
                      <span>Deal</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/leads">
                      <i className="ti ti-chart-arcs"></i>
                      <span>Lead</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/pipeline">
                      <i className="ti ti-timeline-event-exclamation"></i>
                      <span>Pipeline</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/projects">
                      <i className="ti ti-atom-2"></i>
                      <span>Project</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/tasks">
                      <i className="ti ti-list-check"></i>
                      <span>Task</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/proposals">
                      <i className="ti ti-file-star"></i>
                      <span>Proposal</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/contracts">
                      <i className="ti ti-file-check"></i>
                      <span>Contract</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/estimations">
                      <i className="ti ti-file-report"></i>
                      <span>Estimations</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/invoices">
                      <i className="ti ti-file-invoice"></i>
                      <span>Invoice</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/payments">
                      <i className="ti ti-report-money"></i>
                      <span>Payment</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/analytics">
                      <i className="ti ti-chart-bar"></i>
                      <span>Analytic</span>
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="/activities">
                      <i className="ti ti-bounce-right"></i>
                      <span>Activity</span>
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <h6 className="submenu-hdr">Reports</h6>
                <ul>
                  <li className="submenu">
                    <a
                      href="#!"
                      onClick={() => ApplicationInnerSubMenu(3)}
                      className={`${innerMenu === 3 ? "subdrop" : ""}`}
                    >
                      <i className="ti ti-file-invoice"></i>
                      <span>Report</span>
                      <span className="menu-arrow"></span>
                    </a>
                    <ul style={{ display: innerMenu === 3 ? "block" : "none" }}>
                      <li>
                        <NavLink to="/lead-reports">Lead Reports</NavLink>
                      </li>
                      <li>
                        <NavLink to="/deal-reports">Deal Reports</NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact-reports">Contact Reports</NavLink>
                      </li>
                      <li>
                        <NavLink to="/company-reports">Company Reports</NavLink>
                      </li>
                      <li>
                        <NavLink to="/project-reports">Project Reports</NavLink>
                      </li>
                      <li>
                        <NavLink to="/task-reports">Task Reports</NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <h6 className="submenu-hdr">Settings</h6>
                <ul>
                  {/* crm settings  */}
                  <li className="submenu">
                    <a
                      href="#!"
                      className={`lorem ${
                        activeSubmenu === 4 ? "subdrop" : ""
                      }`}
                      onClick={(e) => ApplicationMenuClick(e, 4)}
                    >
                      <i className="ti ti-settings-cog"></i>
                      <span>CRM Settings</span>
                      <span className="menu-arrow"></span>
                    </a>

                    <ul
                      style={{
                        display: activeSubmenu === 4 ? "block" : "none",
                      }}
                    >
                      <li>
                        <NavLink to="/source">
                          <span>Source</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/lost-reason">
                          <span>Lost Reason</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/contact-stage">
                          <span>Contact Stage</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/industry">
                          <span>Industry</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/calls">
                          <span>Calls</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/enquiry-widget">
                          <span>Enquiry Widgets</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/storage">
                          <span>Storage</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to={Constant.banIpURL} target="_blank">
                          <span>Ban IP Address</span>
                        </NavLink>
                      </li>

                      <li>
                        <NavLink to="/notifications">
                          <span>Notification</span>
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* system settings  */}
                  <li className="submenu">
                    <a
                      href="#!"
                      className={`${activeSubmenu === 5 ? "subdrop" : ""}`}
                      onClick={(e) => ApplicationMenuClick(e, 5)}
                    >
                      <i className="ti ti-device-laptop"></i>
                      <span>System Settings</span>
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={{
                        display: activeSubmenu === 5 ? "block" : "none",
                      }}
                    >
                      <li>
                        <NavLink to="/email-settings">Email Settings</NavLink>
                      </li>
                      <li>
                        <NavLink to="/sms-gateways">SMS Gateways</NavLink>
                      </li>
                    </ul>
                  </li>
                  {/* financial Settings  */}
                  <li className="submenu">
                    <a
                      href="#!"
                      className={`${activeSubmenu === 6 ? "subdrop" : ""}`}
                      onClick={(e) => ApplicationMenuClick(e, 6)}
                    >
                      <i className="ti ti-moneybag"></i>
                      <span>Financial Settings</span>
                      <span className="menu-arrow"></span>
                    </a>

                    <ul
                      style={{
                        display: activeSubmenu === 6 ? "block" : "none",
                      }}
                    >
                      <li>
                        <NavLink to="/payment-gateways">
                          Payment Gateways
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/bank-accounts">Bank Accounts</NavLink>
                      </li>
                      <li>
                        <NavLink to="/tax-rates">Tax Rates</NavLink>
                      </li>
                      <li>
                        <NavLink to="/currencies">Currencies</NavLink>
                      </li>
                    </ul>
                  </li>

                  {/* <li className="submenu">
                    <a
                      href="#!"
                      className={`lorem ${
                        activeSubmenu === 5 ? "subdrop" : ""
                      }`}
                      onClick={(e) => ApplicationMenuClick(e, 5)}
                    >
                      <i className="ti ti-world-cog"></i>
                      <span>Website Settings</span>
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={{
                        display: activeSubmenu === 5 ? "block" : "none",
                      }}
                    >
                      <li>
                        <NavLink to="/company-settings">
                          Company Settings
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/localization">Localization</NavLink>
                      </li>
                      <li>
                        <NavLink to="/prefixes">Prefixes</NavLink>
                      </li>
                      <li>
                        <NavLink to="/preference">Preference</NavLink>
                      </li>
                      <li>
                        <NavLink to="/appearance">Appearance</NavLink>
                      </li>
                      <li>
                        <NavLink to="/language">language</NavLink>
                      </li>
                    </ul>
                  </li> */}

                  {/* <li className="submenu">
                    <a
                      href="#!"
                      className={`${activeSubmenu === 6 ? "subdrop" : ""}`}
                      onClick={(e) => ApplicationMenuClick(e, 6)}
                    >
                      <i className="ti ti-apps"></i>
                      <span>App Settings</span>
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={{
                        display: activeSubmenu === 6 ? "block" : "none",
                      }}
                    >
                      <li>
                        <NavLink to="/invoice-settings">
                          Invoice Settings
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/printers">Printers</NavLink>
                      </li>
                      <li>
                        <NavLink to="/custom-fields">Custom Fields</NavLink>
                      </li>
                    </ul>
                  </li> */}
                  {/* <li className="submenu">
                    <a
                      href="#!"
                      className={`${activeSubmenu === 9 ? "subdrop" : ""}`}
                      onClick={(e) => ApplicationMenuClick(e, 9)}
                    >
                      <i className="ti ti-flag-cog"></i>
                      <span>Other Settings</span>
                      <span className="menu-arrow"></span>
                    </a>
                    <ul
                      style={{
                        display: activeSubmenu === 9 ? "block" : "none",
                      }}
                    >
                      <li>
                        <NavLink to="/storage">Storage</NavLink>
                      </li>
                      <li>
                        <NavLink to="/ban-ip-address">Ban IP Address</NavLink>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </li>

              {/* <!-- user Interface  --> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"

export default function Todo() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }
  
  const [todotitle, setTodoTitle] = useState("")
  const [todoassignee, setTodoAssignee] = useState("")
  const [todotag, setTodoTag] = useState("")
  const [todopriority, setTodoPriority] = useState("")
  const [tododate, setTodoDate] = useState("")
  const [todostatus, setTodoStatus] = useState("")
  const [tododesc, setTodoDesc] = useState("")
  
  const todoTitle = (event)=>{setTodoTitle(event.target.value)}
  const todoAssignee = (event)=>{setTodoAssignee(event.target.value)}
  const todoTag = (event)=>{setTodoTag(event.target.value)}
  const todoPriority = (event)=>{setTodoPriority(event.target.value)}
  const todoDate = (event)=>{setTodoDate(event.target.value)}
  const todoStatus = (event)=>{setTodoStatus(event.target.value)}
  const todoDescription = (event)=>{setTodoDesc(event.target.value)}
  
  
  const [todos, setTodos] = useState([])
  
  const addNewTodo = (e)=>{
    e.preventDefault()
    let todoObj = {
      id:Date.now(),
      title: todotitle,
      assignee: todoassignee,
      tag: todotag,
      priority: todopriority,
      data: tododate,
      status:todostatus,
      desc: tododesc,
    }
    
    setTodos([...todos, todoObj])
    console.log("Assignee",todoassignee)
    alert("Todo Added")
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
                      
        <div className="page-wrapper notes-page-wrapper">
          <div className="content">
            <div className="page-header page-add-notes">
              <div className="add-item d-flex">
                <div className="page-title">
                  <h4>Todo</h4>
                  <h6>Manage your tasks</h6>
                </div>
                <a
                  id="toggle_btn2"
                  className="notes-tog"
                  href="javascript:void(0);"
                >
                  <i className="fas fa-chevron-left"></i>
                </a>
              </div>
              <div className="d-flex flex-sm-row flex-column align-items-sm-center align-items-start">
                <div className="input-block add-lists todo-inbox-check">
                  <label className="checkboxs">
                    <input type="checkbox" checked />
                    <span className="checkmarks"></span>Mark all as Complete
                  </label>
                </div>
                <div className="form-sort me-2 mb-sm-0 mb-3">
                  <i  className="info-img"></i>
                  <select className="form-select">
                    <option>Sort by Date</option>
                    <option>Ascending </option>
                    <option>Descending</option>
                    <option>Recently Viewed </option>
                    <option>Recently Added</option>
                    <option>Creation Date </option>
                  </select>
                </div>
                <div className="page-btn">
                  <a
                    href="#"
                    className="btn btn-added"
                    data-bs-toggle="modal"
                    data-bs-target="#note-units"
                  >
                    <i data-feather="plus-circle" className="me-2"></i> Add Task{" "}
                  </a>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-3 col-md-12 sidebars-right theiaStickySidebar section-bulk-widget">
                <div className="notes-dash">
                  <div className="notes-top-head">
                    <h5>
                      {" "}
                      <i
                        data-feather="file-text"
                        className="feather-file-text"
                      ></i>{" "}
                      Todo List
                    </h5>
                  </div>
                  <div className="notes-top-head-submenu">
                    <div
                      className="nav flex-column nav-pills todo-inbox"
                      id="v-pills-tab"
                      role="tablist"
                      aria-orientation="vertical"
                    >
                      <button
                        className="nav-link todo-tab todo-inbox active"
                        id="v-pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-profile"
                        aria-selected="true"
                      >
                        {" "}
                        <i data-feather="inbox" className="feather-inbox"></i>
                        Inbox <span className="ms-2">1</span>
                      </button>
                      <button
                        className="nav-link todo-tab todo-inbox"
                        id="v-pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-home"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="false"
                      >
                        {" "}
                        <i
                          data-feather="check-circle"
                          className="feather-check-circle"
                        ></i>
                        Done
                      </button>
                      <button
                        className="nav-link todo-tab-btn todo-inbox"
                        id="v-pills-messages-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-messages"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-messages"
                        aria-selected="false"
                      >
                        {" "}
                        <i
                          data-feather="star"
                          className="feather-star"
                        ></i>{" "}
                        Important
                      </button>
                      <button
                        className="nav-link todo-tab todo-inbox mb-0"
                        id="v-pills-settings-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#v-pills-settings"
                        type="button"
                        role="tab"
                        aria-controls="v-pills-settings"
                        aria-selected="false"
                      >
                        {" "}
                        <i
                          data-feather="trash-2"
                          className="feather-trash-2"
                        ></i>
                        Trash
                      </button>
                    </div>
                  </div>
                  <div className="content-submenu-tag">
                    <h6>Tags</h6>
                    <ul className="tags-list">
                      <li className="personal">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          Pending
                        </a>
                      </li>
                      <li className="social">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          Onhold
                        </a>
                      </li>
                      <li className="public">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          Inprogress
                        </a>
                      </li>

                      <li className="work">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          Done
                        </a>
                      </li>
                    </ul>

                    <h6>Priority</h6>
                    <ul className="priority-list">
                      <li className="medium">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          Medium
                        </a>
                      </li>
                      <li className="high">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          High
                        </a>
                      </li>
                      <li className="low">
                        <a href="javascript:void(0);">
                          <span>
                            <i className="fas fa-square"></i>
                          </span>
                          Low
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-xl-9 budget-role-notes">
                <div className="section-bulk-wrap">
                  <div className="bulk-action-type">
                    <div className="form-sort select-bluk">
                      <select className="form-select">
                        <option>Bulk Actions</option>
                        <option>Delete Marked</option>
                        <option>Unmark All</option>
                        <option>Mark All</option>
                      </select>
                    </div>
                    <a href="" className="btn btn-added ">
                      Apply
                    </a>
                    <div className="search-set">
                      <div className="search-input">
                        <a href="" className="btn btn-searchset">
                          <i
                            data-feather="search"
                            className="feather-search"
                          ></i>
                        </a>
                        <div
                          id="DataTables_Table_0_filter"
                          className="dataTables_filter"
                        >
                          <label>
                            {" "}
                            <input
                              type="search"
                              className="form-control form-control-sm"
                              placeholder="Search"
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                    {/* <i data-feather="filter" className="feather-filter"></i> */}
                  <div className="form-sort">
                    <select className="form-select">
                      <option>Modified</option>
                      <option>Last Modified</option>
                      <option>Last Modified by me</option>
                    </select>
                  </div>
                </div>
                <div className="tab-content" id="v-pills-tabContent">
                  <div
                    className="tab-pane fade active show"
                    id="v-pills-profile"
                    role="tabpanel"
                    aria-labelledby="v-pills-profile-tab"
                  >
                    <div className="sections-notes-slider">
                      <div className="row">
                        <div className="col-lg-12">
                            {/* today Todo  */}
                          <div className="accordion-card-one accordion todo-accordion" id="accordionExample">
                            <div className="accordion-item">
                              <div className="accordion-header" id="headingOne">
                                <div
                                  className="accordion-button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseOne"
                                  aria-controls="collapseOne"
                                >
                                  <div className="notes-content todo-today-content">
                                    <div className="notes-header todo-today-header">
                                      <span>
                                        <i
                                          data-feather="calendar"
                                          className="feather-calendar"
                                        ></i>
                                      </span>
                                      <h3>Today</h3>
                                      <h6>1</h6>
                                    </div>
                                  </div>
                                  <div className="todo-drop-down">
                                    <a href="javascript:void(0);">
                                      <span>
                                        <i className="fas fa-chevron-down"></i>
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingOne"
                                data-bs-parent="#accordionExample"
                              >
                                <div className="accordion-body">
                                  <div className="todo-widget">
                                    <div className="todo-wrapper-list">
                                      <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                                        <label className="checkboxs">
                                          <input type="checkbox" />
                                          <span className="checkmarks"></span>
                                        </label>
                                        <div className="todo-wrapper-list-content">
                                          <h4>
                                            Meeting with Shaun Park at 4:50pm
                                          </h4>
                                          <p>Discuss about new project</p>
                                        </div>
                                      </div>
                                      <div className="notes-card-body d-flex align-items-center">
                                        <p className="badge bg-outline-danger badge-lg me-2 mb-0">
                                          <i className="fas fa-circle"></i> High
                                        </p>
                                        <p className="badge bg-outline-secondary badge-lg me-2 mb-0">
                                          New
                                        </p>
                                      </div>
                                      <div className=" todo-profile d-flex align-items-center">
                                        <img
                                          src="/img/users/user-24.jpg"
                                          alt="Img"
                                          className="img-fluid"
                                        />
                                        <a
                                          href="#"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fas fa-ellipsis-v"></i>
                                        </a>
                                        <div className="dropdown-menu notes-menu dropdown-menu-end">
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#edit-note-units"
                                          >
                                            <span>
                                              <i data-feather="edit"></i>
                                            </span>
                                            Edit
                                          </a>
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#delete-note-units"
                                          >
                                            <span>
                                              <i data-feather="trash-2"></i>
                                            </span>
                                            Delete
                                          </a>
                                          <a href="#" className="dropdown-item">
                                            <span>
                                              <i data-feather="star"></i>
                                            </span>
                                            Not Important
                                          </a>
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#view-note-units"
                                          >
                                            <span>
                                              <i data-feather="eye"></i>
                                            </span>
                                            View
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            {/* Yesterday  */}
                          <div className="accordion-card-one accordion todo-accordion" id="accordionExample2">

                            <div className="accordion-item">
                              <div className="accordion-header" id="headingTwo">
                                <div
                                  className="accordion-button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseTwo"
                                  aria-controls="collapseTwo"
                                >
                                  <div className="notes-content todo-today-content">
                                    <div className="notes-header todo-today-header">
                                      <span>
                                        <i
                                          data-feather="calendar"
                                          className="feather-calendar"
                                        ></i>
                                      </span>
                                      <h3>Yesterday</h3>
                                    </div>
                                    <div className="todo-drop-down">
                                      <a href="javascript:void(0);">
                                        <span>
                                          <i className="fas fa-chevron-down"></i>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="collapseTwo"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingTwo"
                                data-bs-parent="#accordionExample2"
                              >
                                <div className="accordion-body">
                                  <div className="todo-widget">
                                    <div className="todo-wrapper-list">
                                      <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                                        <label className="checkboxs">
                                          <input type="checkbox" />
                                          <span className="checkmarks"></span>
                                        </label>
                                        <div className="todo-wrapper-list-content">
                                          <h4>Team meet at Starbucks</h4>
                                          <p>
                                            Identify the implementation team
                                          </p>
                                        </div>
                                      </div>
                                      <div className="notes-card-body d-flex align-items-center">
                                        <p className="badge bg-outline-danger badge-lg me-2 mb-0">
                                          <i className="fas fa-circle"></i> High
                                        </p>
                                        <p className="badge bg-outline-info badge-lg me-2 mb-0">
                                          Pending
                                        </p>
                                      </div>
                                      <div className=" todo-profile d-flex align-items-center">
                                        <a
                                          href="#"
                                          className="todo-star star-todo-inbox"
                                        >
                                          <span>
                                            <i
                                              data-feather="star"
                                              className="feather-star me-3"
                                            ></i>
                                          </span>
                                        </a>
                                        <img
                                          src="assets/img/profiles/avatar-02.jpg"
                                          alt="Img"
                                          className="img-fluid"
                                        />
                                        <a
                                          href="#"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fas fa-ellipsis-v"></i>
                                        </a>
                                        <div className="dropdown-menu notes-menu dropdown-menu-end">
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#edit-note-units"
                                          >
                                            <span>
                                              <i data-feather="edit"></i>
                                            </span>
                                            Edit
                                          </a>
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#delete-note-units"
                                          >
                                            <span>
                                              <i data-feather="trash-2"></i>
                                            </span>
                                            Delete
                                          </a>
                                          <a href="#" className="dropdown-item">
                                            <span>
                                              <i data-feather="star"></i>
                                            </span>
                                            Not Important
                                          </a>
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#view-note-units"
                                          >
                                            <span>
                                              <i data-feather="eye"></i>
                                            </span>
                                            View
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="todo-wrapper-list">
                                      <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                                        <label className="checkboxs">
                                          <input type="checkbox" />
                                          <span className="checkmarks"></span>
                                        </label>
                                        <div className="todo-wrapper-list-content">
                                          <h4>
                                            Meet Lisa to discuss project details
                                          </h4>
                                          <p>
                                            Discuss about additional features
                                          </p>
                                        </div>
                                      </div>
                                      <div className="notes-card-body d-flex align-items-center">
                                        <p className="badge bg-outline-secondary badge-lg me-2 mb-0">
                                          <i className="fas fa-circle"></i>{" "}
                                          Medium
                                        </p>
                                        <p className="badge bg-outline-warning badge-lg me-2 mb-0">
                                          InProgress
                                        </p>
                                      </div>
                                      <div className=" todo-profile d-flex align-items-center">
                                        <img
                                          src="/img/users/user-11.jpg"
                                          alt="Img"
                                          className="img-fluid"
                                        />
                                        <a
                                          href="#"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fas fa-ellipsis-v"></i>
                                        </a>
                                        <div className="dropdown-menu notes-menu dropdown-menu-end">
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#edit-note-units"
                                          >
                                            <span>
                                              <i data-feather="edit"></i>
                                            </span>
                                            Edit
                                          </a>
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#delete-note-units"
                                          >
                                            <span>
                                              <i data-feather="trash-2"></i>
                                            </span>
                                            Delete
                                          </a>
                                          <a href="#" className="dropdown-item">
                                            <span>
                                              <i data-feather="star"></i>
                                            </span>
                                            Not Important
                                          </a>
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#view-note-units"
                                          >
                                            <span>
                                              <i data-feather="eye"></i>
                                            </span>
                                            View
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="todo-wrapper-list">
                                      <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                                        <label className="checkboxs">
                                          <input type="checkbox" checked />
                                          <span className="checkmarks"></span>
                                        </label>
                                        <div className="todo-wrapper-list-content todo-strike-content">
                                          <h4>Download Complete</h4>
                                          <p>
                                            Install console machines and
                                            prerequiste softwares
                                          </p>
                                        </div>
                                      </div>
                                      <div className="notes-card-body d-flex align-items-center">
                                        <p className="badge bg-outline-warning badge-lg me-2 mb-0">
                                          <i className="fas fa-circle"></i> Low
                                        </p>
                                        <p className="badge bg-outline-success badge-lg me-2 mb-0">
                                          Completed
                                        </p>
                                      </div>
                                      <div className=" todo-profile d-flex align-items-center">
                                        <img
                                          src="/img/users/user-02.jpg"
                                          alt="Img"
                                          className="img-fluid"
                                        />
                                        <a
                                          href="#"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fas fa-ellipsis-v"></i>
                                        </a>
                                        <div className="dropdown-menu notes-menu dropdown-menu-end">
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#edit-note-units"
                                          >
                                            <span>
                                              <i data-feather="edit"></i>
                                            </span>
                                            Edit
                                          </a>
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#delete-note-units"
                                          >
                                            <span>
                                              <i data-feather="trash-2"></i>
                                            </span>
                                            Delete
                                          </a>
                                          <a href="#" className="dropdown-item">
                                            <span>
                                              <i data-feather="star"></i>
                                            </span>
                                            Not Important
                                          </a>
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#view-note-units"
                                          >
                                            <span>
                                              <i data-feather="eye"></i>
                                            </span>
                                            View
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                            {/* Current Date  */}
                          <div className="accordion-card-one accordion todo-accordion" id="accordionExample4" >
                            <div className="accordion-item">
                              <div
                                className="accordion-header"
                                id="headingFour"
                              >
                                <div
                                  className="accordion-button"
                                  data-bs-toggle="collapse"
                                  data-bs-target="#collapseFour"
                                  aria-controls="collapseFour"
                                >
                                  <div className="notes-content todo-today-content">
                                    <div className="notes-header todo-today-header">
                                      <span>
                                        <i
                                          data-feather="calendar"
                                          className="feather-calendar"
                                        ></i>
                                      </span>
                                      <h3>25/07/2023</h3>
                                    </div>
                                    <div className="todo-drop-down">
                                      <a href="javascript:void(0);">
                                        <span>
                                          <i className="fas fa-chevron-down"></i>
                                        </span>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                id="collapseFour"
                                className="accordion-collapse collapse show"
                                aria-labelledby="headingFour"
                                data-bs-parent="#accordionExample4"
                              >
                                <div className="accordion-body">
                                  <div className="todo-widget">
                                    <div className="todo-wrapper-list">
                                      <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                                        <label className="checkboxs">
                                          <input type="checkbox" />
                                          <span className="checkmarks"></span>
                                        </label>
                                        <div className="todo-wrapper-list-content">
                                          <h4>New User Registered</h4>
                                          <p>Add new user</p>
                                        </div>
                                      </div>
                                      <div className="notes-card-body d-flex align-items-center">
                                        <p className="badge bg-outline-danger badge-lg me-2 mb-0">
                                          <i className="fas fa-circle"></i> High
                                        </p>
                                        <p className="badge bg-outline-info badge-lg me-2 mb-0">
                                          Pending
                                        </p>
                                      </div>
                                      <div className=" todo-profile d-flex align-items-center">
                                        <img
                                          src="/img/users/user-25.jpg"
                                          alt="Img"
                                          className="img-fluid"
                                        />
                                        <a
                                          href="#"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fas fa-ellipsis-v"></i>
                                        </a>
                                        <div className="dropdown-menu notes-menu dropdown-menu-end">
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#edit-note-units"
                                          >
                                            <span>
                                              <i data-feather="edit"></i>
                                            </span>
                                            Edit
                                          </a>
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#delete-note-units"
                                          >
                                            <span>
                                              <i data-feather="trash-2"></i>
                                            </span>
                                            Delete
                                          </a>
                                          <a href="#" className="dropdown-item">
                                            <span>
                                              <i data-feather="star"></i>
                                            </span>
                                            Not Important
                                          </a>
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#view-note-units"
                                          >
                                            <span>
                                              <i data-feather="eye"></i>
                                            </span>
                                            View
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="todo-wrapper-list">
                                      <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                                        <label className="checkboxs">
                                          <input type="checkbox" />
                                          <span className="checkmarks"></span>
                                        </label>
                                        <div className="todo-wrapper-list-content">
                                          <h4>Fix issues in new project</h4>
                                          <p>
                                            Unit test had done and bug fixed
                                          </p>
                                        </div>
                                      </div>
                                      <div className="notes-card-body d-flex align-items-center">
                                        <p className="badge bg-outline-danger badge-lg me-2 mb-0">
                                          <i className="fas fa-circle"></i> High
                                        </p>
                                        <p className="badge bg-outline-warning badge-lg me-2 mb-0">
                                          InProgress
                                        </p>
                                      </div>
                                      <div className=" todo-profile d-flex align-items-center">
                                        <img
                                          src="/img/users/user-04.jpg"
                                          alt="Img"
                                          className="img-fluid"
                                        />
                                        <a
                                          href="#"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="fas fa-ellipsis-v"></i>
                                        </a>
                                        <div className="dropdown-menu notes-menu dropdown-menu-end">
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#edit-note-units"
                                          >
                                            <span>
                                              <i data-feather="edit"></i>
                                            </span>
                                            Edit
                                          </a>
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#delete-note-units"
                                          >
                                            <span>
                                              <i data-feather="trash-2"></i>
                                            </span>
                                            Delete
                                          </a>
                                          <a href="#" className="dropdown-item">
                                            <span>
                                              <i data-feather="star"></i>
                                            </span>
                                            Not Important
                                          </a>
                                          <a
                                            href="#"
                                            className="dropdown-item"
                                            data-bs-toggle="modal"
                                            data-bs-target="#view-note-units"
                                          >
                                            <span>
                                              <i data-feather="eye"></i>
                                            </span>
                                            View
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          
                        </div>

                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade "
                    id="v-pills-home"
                    role="tabpanel"
                    aria-labelledby="v-pills-home-tab"
                  >
                    <div className="sections-notes-slider">
                      <div className="row">
                        <div className="todo-widget">
                          <div className="todo-wrapper-list">
                            <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                              <label className="checkboxs">
                                <input type="checkbox" />
                                <span className="checkmarks"></span>
                              </label>
                              <div className="todo-wrapper-list-content">
                                <h4>Team meet at Starbucks</h4>
                                <p>Identify the implementation team</p>
                              </div>
                            </div>
                            <div className="notes-card-body d-flex align-items-center">
                              <p className="badge bg-outline-danger badge-lg me-2 mb-0">
                                <i className="fas fa-circle"></i> High
                              </p>
                              <p className="badge bg-outline-info badge-lg me-2 mb-0">
                                {" "}
                                Pending
                              </p>
                            </div>
                            <div className=" todo-profile d-flex align-items-center">
                              <img
                                src="/img/users/user-03.jpg"
                                alt="Img"
                                className="img-fluid"
                              />
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="todo-wrapper-list">
                            <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                              <label className="checkboxs">
                                <input type="checkbox" />
                                <span className="checkmarks"></span>
                              </label>
                              <div className="todo-wrapper-list-content">
                                <h4>Meet Lisa to discuss project details</h4>
                                <p>Discuss about additional features</p>
                              </div>
                            </div>
                            <div className="notes-card-body d-flex align-items-center">
                              <p className="badge bg-outline-secondary badge-lg me-2 mb-0">
                                <i className="fas fa-circle"></i> Medium
                              </p>
                              <p className="badge bg-outline-warning badge-lg me-2 mb-0">
                                {" "}
                                InProgress
                              </p>
                            </div>
                            <div className=" todo-profile d-flex align-items-center">
                              <img
                                src="/img/users/user-04.jpg"
                                alt="Img"
                                className="img-fluid"
                              />
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="todo-wrapper-list">
                            <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                              <label className="checkboxs active">
                                <input type="checkbox" />
                                <span className="checkmarks"></span>
                              </label>
                              <div className="todo-wrapper-list-content todo-strike-content">
                                <h4>Download Complete</h4>
                                <p>
                                  Install console machines and prerequiste
                                  softwares
                                </p>
                              </div>
                            </div>
                            <div className="notes-card-body d-flex align-items-center">
                              <p className="badge bg-outline-warning badge-lg me-2 mb-0">
                                <i className="fas fa-circle"></i> Low
                              </p>
                              <p className="badge bg-outline-success badge-lg me-2 mb-0">
                                {" "}
                                Completed
                              </p>
                            </div>
                            <div className=" todo-profile d-flex align-items-center">
                              <img
                                src="/img/users/user-05.jpg"
                                alt="Img"
                                className="img-fluid me-0"
                              />
                              <a href="#" className="inbox-call-profile">
                                Calls
                              </a>
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-messages"
                    role="tabpanel"
                    aria-labelledby="v-pills-messages-tab"
                  >
                    <div className="sections-notes-slider">
                      <div className="row">
                        <div className="todo-widget">
                          <div className="todo-wrapper-list">
                            <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                              <label className="checkboxs">
                                <input type="checkbox" />
                                <span className="checkmarks"></span>
                              </label>
                              <div className="todo-wrapper-list-content">
                                <h4>Team meet at Starbucks</h4>
                                <p>Identify the implementation team</p>
                              </div>
                            </div>
                            <div className="notes-card-body d-flex align-items-center">
                              <p className="badge bg-outline-danger badge-lg me-2 mb-0">
                                <i className="fas fa-circle"></i> High
                              </p>
                              <p className="badge bg-outline-info badge-lg me-2 mb-0">
                                {" "}
                                Pending
                              </p>
                            </div>
                            <div className=" todo-profile d-flex align-items-center">
                              <a href="#" className="todo-star">
                                <span>
                                  <i className="fas fa-star me-3"></i>
                                </span>
                              </a>
                              <img
                                src="/img/users/user-05.jpg"
                                alt="Img"
                                className="img-fluid"
                              />
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="todo-wrapper-list">
                            <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                              <label className="checkboxs">
                                <input type="checkbox" />
                                <span className="checkmarks"></span>
                              </label>
                              <div className="todo-wrapper-list-content">
                                <h4>Meet Lisa to discuss project details</h4>
                                <p>Discuss about additional features</p>
                              </div>
                            </div>
                            <div className="notes-card-body d-flex align-items-center">
                              <p className="badge bg-outline-secondary badge-lg me-2 mb-0">
                                <i className="fas fa-circle"></i> Medium
                              </p>
                              <p className="badge bg-outline-warning badge-lg me-2 mb-0">
                                {" "}
                                InProgress
                              </p>
                            </div>
                            <div className=" todo-profile d-flex align-items-center">
                              <img
                                src="/img/users/user-06.jpg"
                                alt="Img"
                                className="img-fluid"
                              />
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="todo-wrapper-list">
                            <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                              <label className="checkboxs active">
                                <input type="checkbox" />
                                <span className="checkmarks"></span>
                              </label>
                              <div className="todo-wrapper-list-content todo-strike-content">
                                <h4>Download Complete</h4>
                                <p>
                                  Install console machines and prerequiste
                                  softwares
                                </p>
                              </div>
                            </div>
                            <div className="notes-card-body d-flex align-items-center">
                              <p className="badge bg-outline-warning badge-lg me-2 mb-0">
                                <i className="fas fa-circle"></i> Low
                              </p>
                              <p className="badge bg-outline-success badge-lg me-2 mb-0">
                                {" "}
                                Completed
                              </p>
                            </div>
                            <div className=" todo-profile d-flex align-items-center">
                              <img
                                src="/img/users/user-07.jpg"
                                alt="Img"
                                className="img-fluid"
                              />
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit-note-units"
                                >
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete-note-units"
                                >
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="star"></i>
                                  </span>
                                  Not Important
                                </a>
                                <a
                                  href="#"
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#view-note-units"
                                >
                                  <span>
                                    <i data-feather="eye"></i>
                                  </span>
                                  View
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="v-pills-settings"
                    role="tabpanel"
                    aria-labelledby="v-pills-settings-tab"
                  >
                    <div className="sections-notes-slider">
                      <div className="row">
                        <div className="todo-widget">
                          <div className="todo-wrapper-list">
                            <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                              <label className="checkboxs">
                                <input type="checkbox" />
                                <span className="checkmarks"></span>
                              </label>
                              <div className="todo-wrapper-list-content">
                                <h4>Team meet at Starbucks</h4>
                                <p>Identify the implementation team</p>
                              </div>
                            </div>
                            <div className="notes-card-body d-flex align-items-center">
                              <p className="badge bg-outline-danger badge-lg me-2 mb-0">
                                <i className="fas fa-circle"></i> High
                              </p>
                              <p className="badge bg-outline-info badge-lg me-2 mb-0">
                                {" "}
                                Pending
                              </p>
                            </div>
                            <div className=" todo-profile d-flex align-items-center">
                              <img
                                src="/img/users/user-08.jpg"
                                alt="Img"
                                className="img-fluid"
                              />
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Permanent Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Restore Task
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="todo-wrapper-list">
                            <div className="input-block add-lists todo-inbox-check todo-inbox-check-list">
                              <label className="checkboxs">
                                <input type="checkbox" />
                                <span className="checkmarks"></span>
                              </label>
                              <div className="todo-wrapper-list-content">
                                <h4>Meet Lisa to discuss project details</h4>
                                <p>Discuss about additional features</p>
                              </div>
                            </div>
                            <div className="notes-card-body d-flex align-items-center">
                              <p className="badge bg-outline-secondary badge-lg me-2 mb-0">
                                <i className="fas fa-circle"></i> Medium
                              </p>
                              <p className="badge bg-outline-warning badge-lg me-2 mb-0">
                                {" "}
                                InProgress
                              </p>
                            </div>
                            <div className=" todo-profile d-flex align-items-center">
                              <img
                                src="/img/users/user-09.jpg"
                                alt="Img"
                                className="img-fluid"
                              />
                              <a
                                href="#"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fas fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu notes-menu dropdown-menu-end">
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="edit"></i>
                                  </span>
                                  Permanent Delete
                                </a>
                                <a href="#" className="dropdown-item">
                                  <span>
                                    <i data-feather="trash-2"></i>
                                  </span>
                                  Restore Task
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row custom-pagination">
                  <div className="col-md-12">
                    <div className="paginations d-flex justify-content-end">
                      <span>
                        <i className="fas fa-chevron-left"></i>
                      </span>
                      <ul className="d-flex align-items-center page-wrap">
                        <li>
                          <a href="javascript:void(0);" className="active">
                            1
                          </a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">2</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">3</a>
                        </li>
                        <li>
                          <a href="javascript:void(0);">4</a>
                        </li>
                      </ul>
                      <span>
                        <i className="fas fa-chevron-right"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
     
        {/* <!-- Add Note --> */}
        <div className="modal fade" id="note-units">
          <div className="modal-dialog modal-dialog-centered custom-modal">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header custom-modal-header">
                    <div className="page-title">
                      <h4>Add Todo</h4>
                    </div>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <form onSubmit={addNewTodo}>
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">Todo Title</label>
                            <input type="text" className="form-control" required onChange={todoTitle} />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">Assignee</label>
                            <select className="select" onChange={todoAssignee} >
                              <option>Choose</option>
                              <option>Recent1</option>
                              <option>Recent2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">Tag</label>
                            <select className="select"  onChange={todoTag}>
                              <option>Select</option>
                              <option>Recent1</option>
                              <option>Recent2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">Priority</label>
                            <select className="select"  onChange={todoPriority} >
                              <option>Select</option>
                              <option>Recent1</option>
                              <option>Recent2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="input-blocks todo-calendar">
                            <label className="form-label">Due Date</label>
                            <div className="input-groupicon calender-input">
                              <input
                                type="text"
                                className="form-control  date-range bookingrange"
                                placeholder="Select"
                                value={tododate}
                                onChange={todoDate}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">Status</label>
                            <select className="select" onChange={todoStatus}>
                              <option>Select</option>
                              <option>Recent1</option>
                              <option>Recent2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3 summer-description-box notes-summernote">
                            <label className="form-label">Descriptions</label>
                            <div id="summernote"></div>
                            <p>Maximum 60 Characters</p>
                            <textarea required onChange={todoDescription} ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-submit">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Add Note --> */}

        {/* <!-- Edit Note --> */}
        <div className="modal fade" id="edit-note-units">
          <div className="modal-dialog modal-dialog-centered custom-modal">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header custom-modal-header">
                    <div className="page-title">
                      <h4>Todo Title</h4>
                    </div>
                    <div className=" edit-note-head d-flex align-items-center">
                      <a href="javascript:void(0);" className="me-2">
                        <span>
                          <i data-feather="trash-2"></i>
                        </span>
                      </a>
                      <a href="javascript:void(0);" className="me-2">
                        <span>
                          <i data-feather="star"></i>
                        </span>
                      </a>
                      <a href="javascript:void(0);" className="me-2">
                        <span>
                          <i data-feather="eye"></i>
                        </span>
                      </a>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <form action="todo.html">
                      <div className="row">
                        <div className="col-12">
                          <div className="mb-3">
                            <label className="form-label">Note Title</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Meet Lisa to discuss project details"
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="input-blocks">
                            <label className="form-label">Assignee</label>
                            <select className="select">
                              <option>Choose</option>
                              <option>Recent1</option>
                              <option>Recent2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">Tag</label>
                            <select className="select">
                              <option>Onhold</option>
                              <option>Onhold</option>
                              <option>Onhold</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">Priority</label>
                            <select className="select">
                              <option>High</option>
                              <option>Medium</option>
                              <option>Low</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3 input-blocks todo-calendar">
                            <label className="form-label">Due Date</label>
                            <div className="input-groupicon calender-input">
                              <input
                                type="text"
                                className="form-control date-range bookingrange"
                                placeholder="Select"
                                value="13 Aug 1992"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="mb-3">
                            <label className="form-label">Status</label>
                            <select className="select">
                              <option>Select</option>
                              <option>Recent1</option>
                              <option>Recent2</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3 summer-description-box notes-summernote">
                            <label className="form-label">Descriptions</label>
                            <div id="summernote2"></div>
                            <p>Maximum 60 Characters</p>
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer-btn">
                        <button
                          type="button"
                          className="btn btn-cancel me-2"
                          data-bs-dismiss="modal"
                        >
                          Cancel
                        </button>
                        <button type="submit" className="btn btn-submit">
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Edit Note --> */}

        {/* <!-- Delete Note --> */}
        <div className="modal fade" id="delete-note-units">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="delete-popup">
                    <div className="delete-image text-center mx-auto">
                      <img
                        src="/img/icons/close-circle.png"
                        alt="Img"
                        className="img-fluid"
                      />
                    </div>
                    <div className="delete-heads">
                      <h4>Are You Sure?</h4>
                      <p>
                        Do you really want to delete this item, This process
                        cannot be undone.
                      </p>
                    </div>
                    <div className="modal-footer-btn delete-footer mt-3">
                      <a
                        href=""
                        className="btn btn-cancel me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </a>
                      <a href="" className="btn btn-submit">
                        Delete
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Delete Note --> */}

        {/* <!-- View Note --> */}
        <div className="modal fade" id="view-note-units">
          <div className="modal-dialog modal-dialog-centered custom-modal">
            <div className="modal-content">
              <div className="page-wrapper-new p-0">
                <div className="content">
                  <div className="modal-header custom-modal-header">
                    <div className="page-title edit-page-title">
                      <h4>Todo</h4>
                      <p>Personal</p>
                    </div>
                    <div className=" edit-noted-head d-flex align-items-center">
                      <a href="javascript:void(0);">
                        <span>
                          <i data-feather="trash-2"></i>
                        </span>
                      </a>
                      <a href="javascript:void(0);" className="me-2">
                        <span>
                          <i data-feather="star"></i>
                        </span>
                      </a>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body custom-modal-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="edit-head-view">
                          <h6>Meet Lisa to discuss project details</h6>
                          <p>
                            Hiking is a long, vigorous walk, usually on trails
                            or footpaths in the countryside. Walking for
                            pleasure developed in Europe during the eighteenth
                            century. Religious pilgrimages have existed much
                            longer but they involve walking long distances for a
                            spiritual purpose associated with specific religions
                            and also we achieve inner peace while we hike at a
                            local park.
                          </p>

                          <p className="badged high">
                            <i className="fas fa-circle"></i> High
                          </p>
                        </div>
                        <div className="modal-footer-btn edit-footer-menu">
                          <a
                            href=""
                            className="btn btn-cancel me-2"
                            data-bs-dismiss="modal"
                          >
                            Close
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /View Note --> */}
      </div>
    </>
  )
}

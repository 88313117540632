import {Constant } from "../../utils"

const crmLeftMenuData = () =>{
    return(
        [
            {
                "id":1,
                "menuName": "Source",
                "menuUrl" :"/source"
            },
            {
                "id":2,
                "menuName":"Lost Reason",
                "menuUrl" :"/lost-reason"
            },
            {
                "id":3,
                "menuName":"Contact Stage",
                "menuUrl" :"/contact-stage"
            },
            {
                "id":4,
                "menuName":"Industry",
                "menuUrl" :"/industry"
            },
            {
                "id":5,
                "menuName":"Calls",
                "menuUrl" :"/calls"
            },
            {
                "id":6,
                "menuName":"Enquiry Widgets",
                "menuUrl" :"/enquiry-widget"
            },
            {
                "id":7,
                "menuName":"Storage",
                "menuUrl" :"/storage"
            },
            {
                "id":8,
                "menuName":"Ban IP Address",
                "menuUrl": Constant.banIpURL
            },
            {
                "id":8,
                "menuName":"Notification",
                "menuUrl":"/notifications"
            }
        
        ]
    )
}
export default crmLeftMenuData
import AddBrand from "../../../components/page-components/AddBrand"

const BrandForm = ({
    navigate,
    formType,
    selectedData
}) => {
    return (
        <>
            <div
                className="offcanvas offcanvas-end offcanvas-large"
                tabindex="-1"
                id="offcanvas_add"
            >
                <AddBrand
                    navigate={navigate}
                    formType={formType}
                    selectedData={selectedData}
                />
            </div>
        </>
    )
}

export default BrandForm
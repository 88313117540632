import React, { useState } from "react"
import { useNavigate, useParams } from "react-router"
import VerticalMenuComponent from "../../../components/VerticalMenuComponent"
import TopMenuComponent from "../../../components/TopMenuComponent"
import { Link } from "react-router-dom"

const CallLogDetail = () => {
  
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }
  const { id } = useParams()


  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-sm-4">
                      <h4 className="page-title">
                        Contacts<span className="count-title">123</span>
                      </h4>
                    </div>
                    <div className="col-sm-8 text-sm-end">
                      <div className="head-icons">
                        <a
                          href="contact-details.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {/* <!-- Contact User --> */}
                <div className="contact-head">
                  <div className="row align-items-center">
                    <div className="col-sm-6">
                      <ul className="contact-breadcrumb">
                        <li>
                            <Link to="/call-logs">                            
                                <i className="ti ti-arrow-narrow-left"></i>Call Logs                            
                            </Link>
                        </li>
                        <li> {id} </li>
                      </ul>
                    </div>
                    <div className="col-sm-6 text-sm-end">
                      <div className="contact-pagination">
                        <p>1 of 40</p>
                        <ul>
                          <li>
                            <a href="contact-details.html">
                              <i className="ti ti-chevron-left"></i>
                            </a>
                          </li>
                          <li>
                            <a href="contact-details.html">
                              <i className="ti ti-chevron-right"></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card">
                  <div className="card-body pb-2">
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                      <div className="d-flex align-items-center mb-2">
                        <div className="avatar avatar-xxl online online-sm me-3 flex-shrink-0">
                          <img src="/img/profiles/avatar-14.jpg" alt="img" />
                          <span className="status online"></span>
                        </div>
                        <div>
                          <h5 className="mb-1">Jackson Daniel</h5>
                          <p className="mb-2">Facility Manager, Global INC</p>
                          <div className="d-flex align-items-center">
                            <span className="badge badge-light bg-light-600 text-default me-3">
                              <i className="ti ti-lock me-1"></i>Private
                            </span>
                            <p className="d-inline-flex align-items-center mb-0">
                              <i className="fa-solid fa-star text-warning me-1"></i>{" "}
                              5.0
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="contacts-action">
                        <a href="#" className="btn-icon rating">
                          <i className="fa-solid fa-star"></i>
                        </a>
                        <a
                          href="#"
                          className="btn btn-danger"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvas_add_2"
                        >
                          <i className="ti ti-circle-plus"></i>Add Deal
                        </a>
                        <a
                          href="#"
                          className="btn btn-primary"
                          data-bs-toggle="modal"
                          data-bs-target="#add_compose"
                        >
                          <i className="ti ti-mail"></i>Send Email
                        </a>
                        <a href="chat.html" className="btn-icon">
                          <i className="ti ti-brand-hipchat"></i>
                        </a>
                        <a
                          href="#"
                          className="btn-icon"
                          data-bs-toggle="offcanvas"
                          data-bs-target="#offcanvas_edit"
                        >
                          <i className="ti ti-edit-circle"></i>
                        </a>
                        <div className="act-dropdown">
                          <a
                            href="#"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="ti ti-dots-vertical"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <a
                              className="dropdown-item"
                              href="javascript:void(0);"
                              data-bs-toggle="modal"
                              data-bs-target="#delete_contact"
                            >
                              <i className="ti ti-trash text-danger"></i>Delete
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Contact User --> */}
              </div>

              {/* <!-- Contact Sidebar --> */}
              <div className="col-xl-3 theiaStickySidebar">
                <div className="card">
                  <div className="card-body p-3">
                    <h6 className="mb-3 fw-semibold">Basic Information</h6>
                    <div className="mb-3">
                      <div className="d-flex align-items-center mb-3">
                        <span className="avatar avatar-xs bg-light-300 p-0 flex-shrink-0 rounded-circle text-dark me-2">
                          <i className="ti ti-mail"></i>
                        </span>
                        <p>darleeo@example.com</p>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <span className="avatar avatar-xs bg-light-300 p-0 flex-shrink-0 rounded-circle text-dark me-2">
                          <i className="ti ti-phone"></i>
                        </span>
                        <p>+1 12445-47878</p>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <span className="avatar avatar-xs bg-light-300 p-0 flex-shrink-0 rounded-circle text-dark me-2">
                          <i className="ti ti-map-pin"></i>
                        </span>
                        <p>22, Ave Street, Newyork, USA</p>
                      </div>
                      <div className="d-flex align-items-center mb-3">
                        <span className="avatar avatar-xs bg-light-300 p-0 flex-shrink-0 rounded-circle text-dark me-2">
                          <i className="ti ti-calendar-exclamation"></i>
                        </span>
                        <p>Created on 5 Jan 2024, 10:30 am</p>
                      </div>
                    </div>
                    <hr />
                    <h6 className="mb-3 fw-semibold">Other Information</h6>
                    <ul>
                      <li className="row mb-3">
                        <span className="col-6">Language</span>
                        <span className="col-6">English</span>
                      </li>
                      <li className="row mb-3">
                        <span className="col-6">Currency</span>
                        <span className="col-6">United States dollar</span>
                      </li>
                      <li className="row mb-3">
                        <span className="col-6">Last Modified</span>
                        <span className="col-6">27 Sep 2023, 11:45 pm</span>
                      </li>
                      <li className="row mb-3">
                        <span className="col-6">Source</span>
                        <span className="col-6">Paid Campaign</span>
                      </li>
                    </ul>
                    <hr />
                    <h6 className="mb-3 fw-semibold">Tags</h6>
                    <div className="mb-3">
                      <a
                        href="javascript:void(0);"
                        className="badge badge-soft-success fw-medium me-2"
                      >
                        Collab
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="badge badge-soft-warning fw-medium mb-0"
                      >
                        Rated
                      </a>
                    </div>
                    <hr />
                    <div className="d-flex align-items-center justify-content-between flex-wrap">
                      <h6 className="mb-3 fw-semibold">Company</h6>
                      <a
                        href="javascript:void(0);"
                        className="link-purple mb-3"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvas_add"
                      >
                        <i className="ti ti-circle-plus me-1"></i>Add New
                      </a>
                    </div>
                    <div className="mb-3">
                      <div className="d-flex align-items-center">
                        <span className="avatar avatar-lg rounded me-2 border">
                          <img
                            src="/img/icons/google-icon.svg"
                            alt="img"
                            className="img-fluid w-auto h-auto"
                          />
                        </span>
                        <div>
                          <h6 className="fw-medium mb-1">
                            Google. Inc{" "}
                            <i className="fa-solid fa-circle-check text-success"></i>
                          </h6>
                          <p>www.google.com</p>
                        </div>
                      </div>
                    </div>
                    <hr />
                    <h6 className="mb-3 fw-semibold">Social Profile</h6>
                    <ul className="social-info">
                      <li>
                        <a href="javascript:void(0);">
                          <i className="fa-brands fa-youtube"></i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <i className="fa-brands fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <i className="fa-brands fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <i className="fa-brands fa-whatsapp"></i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <i className="fa-brands fa-pinterest"></i>
                        </a>
                      </li>
                      <li>
                        <a href="javascript:void(0);">
                          <i className="fa-brands fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                    <hr />
                    <h6 className="mb-3 fw-semibold">Settings</h6>
                    <div className="mb-0">
                      <a href="javascript:void(0);" className="d-block mb-3">
                        <i className="ti ti-share-2 me-1"></i>Share Contact
                      </a>
                      <a href="javascript:void(0);" className="d-block mb-3">
                        <i className="ti ti-star me-1"></i>Add to Favourite
                      </a>
                      <a
                        href="javascript:void(0);"
                        className="d-block mb-0"
                        data-bs-toggle="modal"
                        data-bs-target="#delete_contact"
                      >
                        <i className="ti ti-trash-x me-1"></i>Delete Contact
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- /Contact Sidebar --> */}

              {/* <!-- Contact Details --> */}
              <div className="col-xl-9">
                <div className="card mb-3">
                  <div className="card-body pb-0 pt-2">
                    <ul className="nav nav-tabs nav-tabs-bottom" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a
                          href="#"
                          data-bs-toggle="tab"
                          data-bs-target="#activities"
                          className="nav-link active"
                        >
                          <i className="ti ti-alarm-minus me-1"></i>Activities
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          href="#"
                          data-bs-toggle="tab"
                          data-bs-target="#notes"
                          className="nav-link"
                        >
                          <i className="ti ti-notes me-1"></i>Notes
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          href="#"
                          data-bs-toggle="tab"
                          data-bs-target="#calls"
                          className="nav-link"
                        >
                          <i className="ti ti-phone me-1"></i>Calls
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          href="#"
                          data-bs-toggle="tab"
                          data-bs-target="#files"
                          className="nav-link"
                        >
                          <i className="ti ti-file me-1"></i>Files
                        </a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a
                          href="#"
                          data-bs-toggle="tab"
                          data-bs-target="#email"
                          className="nav-link"
                        >
                          <i className="ti ti-mail-check me-1"></i>Email
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <!-- Tab Content --> */}
                <div className="tab-content pt-0">
                  {/* <!-- Activities --> */}
                  <div className="tab-pane active show" id="activities">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                        <h4 className="fw-semibold">Activities</h4>
                        <div>
                          <div className="form-sort mt-0">
                            <i className="ti ti-sort-ascending-2"></i>
                            <select className="select">
                              <option>Sort By Date</option>
                              <option>Ascending</option>
                              <option>Descending</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="badge badge-soft-purple fs-14 fw-normal shadow-none mb-3">
                          <i className="ti ti-calendar-check me-1"></i>29 Aug
                          2023
                        </div>
                        <div className="card border shadow-none mb-3">
                          <div className="card-body p-3">
                            <div className="d-flex">
                              <span className="avatar avatar-md flex-shrink-0 rounded me-2 bg-pending">
                                <i className="ti ti-mail-code"></i>
                              </span>
                              <div>
                                <h6 className="fw-medium mb-1">
                                  You sent 1 Message to the contact.
                                </h6>
                                <p>10:25 pm</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card border shadow-none mb-3">
                          <div className="card-body p-3">
                            <div className="d-flex">
                              <span className="avatar avatar-md flex-shrink-0 rounded me-2 bg-secondary-success">
                                <i className="ti ti-phone"></i>
                              </span>
                              <div>
                                <h6 className="fw-medium mb-1">
                                  Denwar responded to your appointment schedule
                                  question by call at 09:30pm.
                                </h6>
                                <p>09:25 pm</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card border shadow-none mb-3">
                          <div className="card-body p-3">
                            <div className="d-flex">
                              <span className="avatar avatar-md flex-shrink-0 rounded me-2 bg-orange">
                                <i className="ti ti-notes"></i>
                              </span>
                              <div>
                                <h6 className="fw-medium mb-1">
                                  Notes added by Antony
                                </h6>
                                <p className="mb-1">
                                  Please accept my apologies for the
                                  inconvenience caused. It would be much
                                  appreciated if it's possible to reschedule to
                                  6:00 PM, or any other day that week.
                                </p>
                                <p>10.00 pm</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="badge badge-soft-purple fs-14 fw-normal shadow-none mb-3">
                          <i className="ti ti-calendar-check me-1"></i>28 Feb
                          2024
                        </div>
                        <div className="card border shadow-none mb-3">
                          <div className="card-body p-3">
                            <div className="d-flex">
                              <span className="avatar avatar-md flex-shrink-0 rounded me-2 bg-info">
                                <i className="ti ti-user-pin"></i>
                              </span>
                              <div>
                                <h6 className="fw-medium mb-1 d-inline-flex align-items-center flex-wrap">
                                  Meeting With{" "}
                                  <span className="avatar avatar-xs mx-2">
                                    <img
                                      src="/img/profiles/avatar-19.jpg"
                                      alt="img"
                                    />
                                  </span>{" "}
                                  Abraham
                                </h6>
                                <p>Schedueled on 05:00 pm</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card border shadow-none mb-3">
                          <div className="card-body p-3">
                            <div className="d-flex">
                              <span className="avatar avatar-md flex-shrink-0 rounded me-2 bg-secondary-success">
                                <i className="ti ti-notes"></i>
                              </span>
                              <div>
                                <h6 className="fw-medium mb-1">
                                  Drain responded to your appointment schedule
                                  question.
                                </h6>
                                <p>09:25 pm</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="badge badge-soft-purple fs-14 fw-normal shadow-none mb-3">
                          <i className="ti ti-calendar-check me-1"></i>Upcoming
                          Activity
                        </div>
                        <div className="card border shadow-none mb-0">
                          <div className="card-body p-3">
                            <div className="d-flex">
                              <span className="avatar avatar-md flex-shrink-0 rounded me-2 bg-info">
                                <i className="ti ti-user-pin"></i>
                              </span>
                              <div>
                                <h6 className="fw-medium mb-1">
                                  Product Meeting
                                </h6>
                                <p className="mb-1">
                                  A product team meeting is a gathering of the
                                  cross-functional product team — ideally
                                  including team members from product,
                                  engineering, marketing, and customer support.
                                </p>
                                <p>25 Jul 2023, 05:00 pm</p>
                                <div className="upcoming-info">
                                  <div className="row">
                                    <div className="col-sm-4">
                                      <p>Reminder</p>
                                      <div className="dropdown">
                                        <a
                                          href="#"
                                          className="dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="ti ti-clock-edit me-1"></i>
                                          Reminder
                                          <i className="ti ti-chevron-down ms-1"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            Remainder
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            1 hr
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            10 hr
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <p>Task Priority</p>
                                      <div className="dropdown">
                                        <a
                                          href="#"
                                          className="dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <i className="ti ti-square-rounded-filled me-1 text-danger circle"></i>
                                          High
                                          <i className="ti ti-chevron-down ms-1"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ti ti-square-rounded-filled me-1 text-danger circle"></i>
                                            High
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <i className="ti ti-square-rounded-filled me-1 text-success circle"></i>
                                            Low
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-sm-4">
                                      <p>Assigned to</p>
                                      <div className="dropdown">
                                        <a
                                          href="#"
                                          className="dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img
                                            src="/img/profiles/avatar-19.jpg"
                                            alt="img"
                                            className="avatar-xs"
                                          />
                                          John
                                          <i className="ti ti-chevron-down ms-1"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right">
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <img
                                              src="/img/profiles/avatar-19.jpg"
                                              alt="img"
                                              className="avatar-xs"
                                            />
                                            John
                                          </a>
                                          <a
                                            className="dropdown-item"
                                            href="javascript:void(0);"
                                          >
                                            <img
                                              src="/img/profiles/avatar-15.jpg"
                                              alt="img"
                                              className="avatar-xs"
                                            />
                                            Peter
                                          </a>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /Activities --> */}

                  {/* <!-- Notes --> */}
                  <div className="tab-pane fade" id="notes">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                        <h4 className="fw-semibold">Notes</h4>
                        <div className="d-inline-flex align-items-center">
                          <div className="form-sort me-3 mt-0">
                            <i className="ti ti-sort-ascending-2"></i>
                            <select className="select">
                              <option>Sort By Date</option>
                              <option>Ascending</option>
                              <option>Descending</option>
                            </select>
                          </div>
                          <a
                            href="javascript:void(0);"
                            data-bs-toggle="modal"
                            data-bs-target="#add_notes"
                            className="link-purple fw-medium"
                          >
                            <i className="ti ti-circle-plus me-1"></i>Add New
                          </a>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="notes-activity">
                          <div className="card mb-3">
                            <div className="card-body">
                              <div className="d-flex align-items-center justify-content-between pb-2">
                                <div className="d-inline-flex align-items-center mb-2">
                                  <span className="avatar avatar-md me-2 flex-shrink-0">
                                    <img
                                      src="/img/profiles/avatar-19.jpg"
                                      alt="img"
                                    />
                                  </span>
                                  <div>
                                    <h6 className="fw-medium mb-1">
                                      Darlee Robertson
                                    </h6>
                                    <p className="mb-0">
                                      15 Sep 2023, 12:10 pm
                                    </p>
                                  </div>
                                </div>
                                <div className="mb-2">
                                  <div className="dropdown">
                                    <a
                                      href="#"
                                      className="p-0 btn btn-icon btn-sm d-flex align-items-center justify-content-center"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="ti ti-dots-vertical"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-edit text-blue me-1"></i>
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-trash text-danger me-1"></i>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <h5 className="fw-medium mb-1">
                                Notes added by Antony
                              </h5>
                              <p>
                                A project review evaluates the success of an
                                initiative and identifies areas for improvement.
                                It can also evaluate a current project to
                                determine whether it's on the right track. Or,
                                it can determine the success of a completed
                                project.{" "}
                              </p>
                              <div className="d-inline-flex align-items-center flex-wrap">
                                <div className="note-download me-3">
                                  <div className="note-info">
                                    <span className="note-icon bg-secondary-success">
                                      <i className="ti ti-file-spreadsheet"></i>
                                    </span>
                                    <div>
                                      <h6 className="fw-medium mb-1">
                                        Project Specs.xls
                                      </h6>
                                      <p>365 KB</p>
                                    </div>
                                  </div>
                                  <a href="javascript:void(0);">
                                    <i className="ti ti-arrow-down"></i>
                                  </a>
                                </div>
                                <div className="note-download">
                                  <div className="note-info">
                                    <span className="note-icon">
                                      <img
                                        src="/img/media/media-35.jpg"
                                        alt="img"
                                      />
                                    </span>
                                    <div>
                                      <h6 className="fw-medium mb-1">
                                        090224.jpg
                                      </h6>
                                      <p>365 KB</p>
                                    </div>
                                  </div>
                                  <a href="javascript:void(0);">
                                    <i className="ti ti-arrow-down"></i>
                                  </a>
                                </div>
                              </div>
                              <div className="notes-editor">
                                <div className="note-edit-wrap">
                                  <div className="summernote">
                                    Write a new comment, send your team
                                    notification by typing @ followed by their
                                    name
                                  </div>
                                  <div className="text-end note-btns">
                                    <a
                                      href="javascript:void(0);"
                                      className="btn btn-light add-cancel"
                                    >
                                      Cancel
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="btn btn-primary"
                                    >
                                      Save
                                    </a>
                                  </div>
                                </div>
                                <div className="text-end">
                                  <a
                                    href="javascript:void(0);"
                                    className="add-comment link-purple fw-medium"
                                  >
                                    <i className="ti ti-square-plus me-1"></i>
                                    Add Comment
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card mb-3">
                            <div className="card-body">
                              <div className="d-flex align-items-center justify-content-between pb-2">
                                <div className="d-inline-flex align-items-center mb-2">
                                  <span className="avatar avatar-md me-2 flex-shrink-0">
                                    <img
                                      src="/img/profiles/avatar-20.jpg"
                                      alt="img"
                                    />
                                  </span>
                                  <div>
                                    <h6 className="fw-medium mb-1">
                                      Sharon Roy
                                    </h6>
                                    <p className="mb-0">
                                      18 Sep 2023, 09:52 am
                                    </p>
                                  </div>
                                </div>
                                <div className="mb-2">
                                  <div className="dropdown">
                                    <a
                                      href="#"
                                      className="p-0 btn btn-icon btn-sm d-flex align-items-center justify-content-center"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="ti ti-dots-vertical"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-edit text-blue me-1"></i>
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-trash text-danger me-1"></i>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <h5 className="fw-medium mb-1">
                                Notes added by Antony
                              </h5>
                              <p>
                                A project plan typically contains a list of the
                                essential elements of a project, such as
                                stakeholders, scope, timelines, estimated cost
                                and communication methods. The project manager
                                typically lists the information based on the
                                assignment.
                              </p>
                              <div className="d-inline-flex align-items-center flex-wrap">
                                <div className="note-download me-3">
                                  <div className="note-info">
                                    <span className="note-icon bg-secondary-success">
                                      <i className="ti ti-file-text"></i>
                                    </span>
                                    <div>
                                      <h6 className="fw-medium mb-1">
                                        Andrewpass.txt
                                      </h6>
                                      <p>365 KB</p>
                                    </div>
                                  </div>
                                  <a href="javascript:void(0);">
                                    <i className="ti ti-arrow-down"></i>
                                  </a>
                                </div>
                              </div>
                              <div className="reply-box">
                                <p>
                                  The best way to get a project done faster is
                                  to start sooner. A goal without a timeline is
                                  just a dream.The goal you set must be
                                  challenging. At the same time, it should be
                                  realistic and attainable, not impossible to
                                  reach.
                                </p>
                                <p>
                                  Commented by{" "}
                                  <span className="text-purple">Aeron</span> on
                                  15 Sep 2023, 11:15 pm
                                </p>
                                <a href="#" className="btn">
                                  <i className="ti ti-arrow-back-up-double"></i>
                                  Reply
                                </a>
                              </div>
                              <div className="notes-editor">
                                <div className="note-edit-wrap">
                                  <div className="summernote">
                                    Write a new comment, send your team
                                    notification by typing @ followed by their
                                    name
                                  </div>
                                  <div className="text-end note-btns">
                                    <a
                                      href="javascript:void(0);"
                                      className="btn btn-light add-cancel"
                                    >
                                      Cancel
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="btn btn-primary"
                                    >
                                      Save
                                    </a>
                                  </div>
                                </div>
                                <div className="text-end">
                                  <a
                                    href="javascript:void(0);"
                                    className="add-comment link-purple fw-medium"
                                  >
                                    <i className="ti ti-square-plus me-1"></i>
                                    Add Comment
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card mb-0">
                            <div className="card-body">
                              <div className="d-flex align-items-center justify-content-between pb-2">
                                <div className="d-inline-flex align-items-center mb-2">
                                  <span className="avatar avatar-md me-2 flex-shrink-0">
                                    <img
                                      src="/img/profiles/avatar-21.jpg"
                                      alt="img"
                                    />
                                  </span>
                                  <div>
                                    <h6 className="fw-medium mb-1">Vaughan</h6>
                                    <p className="mb-0">
                                      20 Sep 2023, 10:26 pm
                                    </p>
                                  </div>
                                </div>
                                <div className="mb-2">
                                  <div className="dropdown">
                                    <a
                                      href="#"
                                      className="p-0 btn btn-icon btn-sm d-flex align-items-center justify-content-center"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="ti ti-dots-vertical"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-edit text-blue me-1"></i>
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-trash text-danger me-1"></i>
                                        Delete
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p>
                                Projects play a crucial role in the success of
                                organizations, and their importance cannot be
                                overstated. Whether it's launching a new
                                product, improving an existing
                              </p>
                              <div className="notes-editor">
                                <div className="note-edit-wrap">
                                  <div className="summernote">
                                    Write a new comment, send your team
                                    notification by typing @ followed by their
                                    name
                                  </div>
                                  <div className="text-end note-btns">
                                    <a
                                      href="javascript:void(0);"
                                      className="btn btn-light add-cancel"
                                    >
                                      Cancel
                                    </a>
                                    <a
                                      href="javascript:void(0);"
                                      className="btn btn-primary"
                                    >
                                      Save
                                    </a>
                                  </div>
                                </div>
                                <div className="text-end">
                                  <a
                                    href="javascript:void(0);"
                                    className="add-comment link-purple fw-medium"
                                  >
                                    <i className="ti ti-square-plus me-1"></i>
                                    Add Comment
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /Notes --> */}

                  {/* <!-- Calls --> */}
                  <div className="tab-pane fade" id="calls">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                        <h4 className="fw-semibold">Calls</h4>
                        <div className="d-inline-flex align-items-center">
                          <a
                            href="javascript:void(0);"
                            data-bs-toggle="modal"
                            data-bs-target="#create_call"
                            className="link-purple fw-medium"
                          >
                            <i className="ti ti-circle-plus me-1"></i>Add New
                          </a>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="card mb-3">
                          <div className="card-body">
                            <div className="d-sm-flex align-items-center justify-content-between pb-2">
                              <div className="d-flex align-items-center mb-2">
                                <span className="avatar avatar-md me-2 flex-shrink-0">
                                  <img
                                    src="/img/profiles/avatar-19.jpg"
                                    alt="img"
                                  />
                                </span>
                                <p>
                                  <span className="text-dark fw-medium">
                                    Darlee Robertson
                                  </span>
                                  logged a call on 23 Jul 2023, 10:00 pm
                                </p>
                              </div>
                              <div className="d-inline-flex align-items-center mb-2">
                                <div className="dropdown me-2">
                                  <a
                                    href="#"
                                    className="bg-danger py-1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Busy
                                    <i className="ti ti-chevron-down ms-2"></i>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Busy
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      No Answer
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Unavailable
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Wrong Number
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Left Voice Message
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Moving Forward
                                    </a>
                                  </div>
                                </div>
                                <div className="dropdown">
                                  <a
                                    href="#"
                                    className="p-0 btn btn-icon btn-sm d-flex align-items-center justify-content-center"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="ti ti-dots-vertical"></i>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="ti ti-edit text-blue me-1"></i>
                                      Edit
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="ti ti-trash text-danger me-1"></i>
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p>
                              A project review evaluates the success of an
                              initiative and identifies areas for improvement.
                              It can also evaluate a current project to
                              determine whether it's on the right track. Or, it
                              can determine the success of a completed project.{" "}
                            </p>
                          </div>
                        </div>
                        <div className="card mb-3">
                          <div className="card-body">
                            <div className="d-sm-flex align-items-center justify-content-between pb-2">
                              <div className="d-flex align-items-center mb-2">
                                <span className="avatar avatar-md me-2 flex-shrink-0">
                                  <img
                                    src="/img/profiles/avatar-20.jpg"
                                    alt="img"
                                  />
                                </span>
                                <p>
                                  <span className="text-dark fw-medium">
                                    Sharon Roy
                                  </span>{" "}
                                  logged a call on 28 Jul 2023, 09:00 pm
                                </p>
                              </div>
                              <div className="d-inline-flex align-items-center mb-2">
                                <div className="dropdown me-2">
                                  <a
                                    href="#"
                                    className="bg-pending py-1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    No Answer
                                    <i className="ti ti-chevron-down ms-2"></i>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Busy
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      No Answer
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Unavailable
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Wrong Number
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Left Voice Message
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Moving Forward
                                    </a>
                                  </div>
                                </div>
                                <div className="dropdown">
                                  <a
                                    href="#"
                                    className="p-0 btn btn-icon btn-sm d-flex align-items-center justify-content-center"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="ti ti-dots-vertical"></i>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="ti ti-edit text-blue me-1"></i>
                                      Edit
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="ti ti-trash text-danger me-1"></i>
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p>
                              A project plan typically contains a list of the
                              essential elements of a project, such as
                              stakeholders, scope, timelines, estimated cost and
                              communication methods. The project manager
                              typically lists the information based on the
                              assignment.
                            </p>
                          </div>
                        </div>
                        <div className="card mb-0">
                          <div className="card-body">
                            <div className="d-sm-flex align-items-center justify-content-between pb-2">
                              <div className="d-flex align-items-center mb-2">
                                <span className="avatar avatar-md me-2 flex-shrink-0">
                                  <img
                                    src="/img/profiles/avatar-21.jpg"
                                    alt="img"
                                  />
                                </span>
                                <p>
                                  <span className="text-dark fw-medium">
                                    Vaughan
                                  </span>{" "}
                                  logged a call on 30 Jul 2023, 08:00 pm
                                </p>
                              </div>
                              <div className="d-inline-flex align-items-center mb-2">
                                <div className="dropdown me-2">
                                  <a
                                    href="#"
                                    className="bg-pending py-1"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    No Answer
                                    <i className="ti ti-chevron-down ms-2"></i>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Busy
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      No Answer
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Unavailable
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Wrong Number
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Left Voice Message
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      Moving Forward
                                    </a>
                                  </div>
                                </div>
                                <div className="dropdown">
                                  <a
                                    href="#"
                                    className="p-0 btn btn-icon btn-sm d-flex align-items-center justify-content-center"
                                    data-bs-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    <i className="ti ti-dots-vertical"></i>
                                  </a>
                                  <div className="dropdown-menu dropdown-menu-right">
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="ti ti-edit text-blue me-1"></i>
                                      Edit
                                    </a>
                                    <a
                                      className="dropdown-item"
                                      href="javascript:void(0);"
                                    >
                                      <i className="ti ti-trash text-danger me-1"></i>
                                      Delete
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <p>
                              Projects play a crucial role in the success of
                              organizations, and their importance cannot be
                              overstated. Whether it's launching a new product,
                              improving an existing
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /Calls --> */}

                  {/* <!-- Files --> */}
                  <div className="tab-pane fade" id="files">
                    <div className="card">
                      <div className="card-header">
                        <h4 className="fw-semibold">Files</h4>
                      </div>
                      <div className="card-body">
                        <div className="card border mb-3">
                          <div className="card-body pb-0">
                            <div className="row align-items-center">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h4 className="fw-medium mb-1">
                                    Manage Documents
                                  </h4>
                                  <p>
                                    Send customizable quotes, proposals and
                                    contracts to close deals faster.
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 text-md-end">
                                <div className="mb-3">
                                  <a
                                    href="#"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#new_file"
                                  >
                                    Create Document
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card border shadow-none mb-3">
                          <div className="card-body pb-0">
                            <div className="row align-items-center">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h4 className="fw-medium mb-1">
                                    Collier-Turner Proposal
                                  </h4>
                                  <p>
                                    Send customizable quotes, proposals and
                                    contracts to close deals faster.
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <span className="avatar avatar-md me-2 flex-shrink-0">
                                      <img
                                        src="/img/profiles/avatar-21.jpg"
                                        alt="img"
                                        className="rounded-circle"
                                      />
                                    </span>
                                    <div>
                                      <span className="fs-12">Owner</span>
                                      <p>Vaughan</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 text-md-end">
                                <div className="mb-3 d-inline-flex align-items-center">
                                  <span className="badge badge-danger-light me-1">
                                    Proposal
                                  </span>
                                  <span className="badge bg-pending priority-badge me-1">
                                    Draft
                                  </span>
                                  <div className="dropdown">
                                    <a
                                      href="#"
                                      className="p-0 btn btn-icon btn-sm d-flex align-items-center justify-content-center"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="ti ti-dots-vertical"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-edit text-blue me-1"></i>
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-trash text-danger me-1"></i>
                                        Delete
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-download text-info me-1"></i>
                                        Download
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card border shadow-none mb-3">
                          <div className="card-body pb-0">
                            <div className="row align-items-center">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h4 className="fw-medium mb-1">
                                    Collier-Turner Proposal
                                  </h4>
                                  <p>
                                    Send customizable quotes, proposals and
                                    contracts to close deals faster.
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <span className="avatar avatar-md me-2 flex-shrink-0">
                                      <img
                                        src="/img/profiles/avatar-01.jpg"
                                        alt="img"
                                        className="rounded-circle"
                                      />
                                    </span>
                                    <div>
                                      <span className="fs-12">Owner</span>
                                      <p>Jessica</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 text-md-end">
                                <div className="mb-3 d-inline-flex align-items-center">
                                  <span className="badge badge-purple-light me-1">
                                    Quote
                                  </span>
                                  <span className="badge bg-success me-1">
                                    Sent
                                  </span>
                                  <div className="dropdown">
                                    <a
                                      href="#"
                                      className="p-0 btn btn-icon btn-sm d-flex align-items-center justify-content-center"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="ti ti-dots-vertical"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-edit text-blue me-1"></i>
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-trash text-danger me-1"></i>
                                        Delete
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-download text-info me-1"></i>
                                        Download
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card border shadow-none mb-0">
                          <div className="card-body pb-0">
                            <div className="row align-items-center">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h4 className="fw-medium mb-1">
                                    Collier-Turner Proposal
                                  </h4>
                                  <p>
                                    Send customizable quotes, proposals and
                                    contracts to close deals faster.
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <span className="avatar avatar-md me-2 flex-shrink-0">
                                      <img
                                        src="/img/profiles/avatar-22.jpg"
                                        alt="img"
                                        className="rounded-circle"
                                      />
                                    </span>
                                    <div>
                                      <span className="fs-12">Owner</span>
                                      <p>Dawn Merhca</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 text-md-end">
                                <div className="mb-3 d-inline-flex align-items-center">
                                  <span className="badge badge-danger-light me-1">
                                    Proposal
                                  </span>
                                  <span className="badge bg-pending priority-badge me-1">
                                    Draft
                                  </span>
                                  <div className="dropdown">
                                    <a
                                      href="#"
                                      className="p-0 btn btn-icon btn-sm d-flex align-items-center justify-content-center"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i className="ti ti-dots-vertical"></i>
                                    </a>
                                    <div className="dropdown-menu dropdown-menu-right">
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-edit text-blue me-1"></i>
                                        Edit
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-trash text-danger me-1"></i>
                                        Delete
                                      </a>
                                      <a
                                        className="dropdown-item"
                                        href="javascript:void(0);"
                                      >
                                        <i className="ti ti-download text-info me-1"></i>
                                        Download
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /Files --> */}

                  {/* <!-- Email --> */}
                  <div className="tab-pane fade" id="email">
                    <div className="card">
                      <div className="card-header d-flex align-items-center justify-content-between flex-wrap row-gap-3">
                        <h4 className="fw-semibold">Email</h4>
                        <div className="d-inline-flex align-items-center">
                          <a
                            href="javascript:void(0);"
                            className="link-purple fw-medium"
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            data-bs-custom-className="tooltip-dark"
                            data-bs-original-title="There are no email accounts configured, Please configured your email account in order to Send/ Create EMails"
                          >
                            <i className="ti ti-circle-plus me-1"></i>Create
                            Email
                          </a>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="card border mb-0">
                          <div className="card-body pb-0">
                            <div className="row align-items-center">
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <h4 className="fw-medium mb-1">
                                    Manage Emails
                                  </h4>
                                  <p>
                                    You can send and reply to emails directly
                                    via this section.
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4 text-md-end">
                                <div className="mb-3">
                                  <a
                                    href="#"
                                    className="btn btn-primary"
                                    data-bs-toggle="modal"
                                    data-bs-target="#create_email"
                                  >
                                    Connect Account
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /Email --> */}
                </div>
                {/* <!-- /Tab Content --> */}
              </div>
              {/* <!-- /Contact Details --> */}
            </div>
          </div>
        </div>

        {/* <!-- Add Compose --> */}
        <div className="modal custom-modal fade" id="add_compose" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Compose</h5>
                <button
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x"></i>
                </button>
              </div>
              <div className="modal-body">
                <form action="#">
                  <div className="mb-3">
                    <input
                      type="email"
                      placeholder="To"
                      className="form-control"
                    />
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mb-3">
                        <input
                          type="email"
                          placeholder="Cc"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mb-3">
                        <input
                          type="email"
                          placeholder="Bcc"
                          className="form-control"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-3">
                    <input
                      type="text"
                      placeholder="Subject"
                      className="form-control"
                    />
                  </div>
                  <div className="mb-3">
                    <div className="summernote"></div>
                  </div>
                  <div className="mb-3">
                    <div className="text-center">
                      <button className="btn btn-primary">
                        <span>Send</span>
                        <i className="fa-solid fa-paper-plane ms-1"></i>
                      </button>
                      <button className="btn btn-primary" type="button">
                        <span>Draft</span>{" "}
                        <i className="fa-regular fa-floppy-disk ms-1"></i>
                      </button>
                      <button className="btn btn-primary" type="button">
                        <span>Delete</span>{" "}
                        <i className="fa-regular fa-trash-can ms-1"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Add Compose --> */}
      </div>
    </>
  )
}

export default CallLogDetail

import { useEffect, useState } from "react"
import { Constant } from "../../../utils"
import CustomAlert from "../../../components/custom-alerts/CustomAlert"

 const SourceForm = ({selectedData, formType}) => {

  const [loading, setLoading] = useState(false)
  const [sourceName, setSourceName] = useState("")
  const [customAlertType, setCustomAlertType] = useState("")
  const [isActive, setIsActive] = useState("active")
  const onActiveStatus = (e) => setIsActive(e.target.value)

  useEffect(() => {
    if (selectedData) {
      setSourceName(selectedData?.contacts_name)
    }
  }, [selectedData])

  const handlesourceName = (event) => setSourceName(event.target.value)

  const clearInputValues = () => {
    setSourceName("")
  }
  const [alertTitle, setAlertTitle] = useState("")
  const [alertDesc, setAlertDesc] = useState("")
  const [isALert, setIsAlert] = useState(false)

  const CustomAlertTexts = (title, desc, alertType) => {
    setAlertTitle(title ?? '')
    setAlertDesc(desc ?? '')
    setCustomAlertType(alertType)
    setIsAlert(true)
  }

  const checkValidations = async () => {
    if (!sourceName) {
      CustomAlertTexts("Error", "Brand Name is required", "error")
      return false
    }
    submitData()
  }

  const submitData = () =>{
    console.log()
  }

  return(
    <>
      <div className="offcanvas offcanvas-end offcanvas-large" tabindex="-1" id="offcanvas_add">
         {isALert && (
                <CustomAlert
                alertTitle={alertTitle}
                alertDesc={alertDesc}
                onOkClick={() => setIsAlert(false)}
                customAlertType={customAlertType}
                />
            )}

    <div className="outer-modal-contentbox">
          <div className="offcanvas-header border-bottom">
            <h5 className="fw-semibold">
              {formType === Constant.FORM_TYPE_EDIT
                ? "Update Source"
                : formType === Constant.FORM_TYPE_NEW
                ? "Add Source"
                : formType === Constant.FORM_TYPE_VIEW
                ? "Source Details"
                : ""}
            </h5>
       
              <button
                type="button"
                className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
                data-bs-dismiss="offcanvas"
                onClick={() => {
                  clearInputValues()
                  return false
                }}
                aria-label="Close"
              >
                <i className="ti ti-x"></i>
              </button>       

          </div>

          <div className="modal-content">
            <div className={`modal-body`}>
              <form>
                <div className="mb-3">
                  <label className="col-form-label">Source Name:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Source Name"
                    onChange={handlesourceName}
                    value={sourceName}
                    disabled={formType == Constant.FORM_TYPE_VIEW}
                  /> 

                </div>

                <div className="mb-3">
                  <label htmlFor="recipient-name" className="col-form-label">
                    Status:
                  </label>
                  <select
                    class="form-select"
                    onChange={onActiveStatus}
                    value={isActive}
                    disabled
                  >
                    <option value="active">Active</option>
                    <option value="isactive">In Active</option>
                  </select>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              {formType !== Constant.FORM_TYPE_VIEW && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={checkValidations}
                  disabled={formType === Constant.FORM_TYPE_VIEW}
                >
                  {formType === Constant.FORM_TYPE_EDIT ? "Update" : "Submit"}
                </button>
              )}
            </div>
          </div>
          
      </div>

            </div>
        </>
    )
 }

 export default SourceForm
import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"

export const emailListAdapter = createEntityAdapter()

export const fetchEmailList = createAsyncThunk(
    Preferences.EMAIL_LIST,
    async (thunkApi) => {
        const params = `?fk_UsrOrgDirId=${
                JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
            }&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getEmailList, params)
        if (response?.result?.response_Status == 'success') {
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status === 'error') {
            alert(response?.result?.response_Message)
        } else {
            alert('Something went wrong with getEmailData')
        }
    }
)

export const initialAppState = emailListAdapter.getInitialState({
    loading: 'idel',
    emailListData: [],
    filterData: [],
    error: []
})

export const emailListSlice = createSlice({
    name: Preferences.EMAIL_LIST,
    initialState: initialAppState,
    reducers: {
        resetEmailListFilter: state => {
            state.filterData = state.emailListData
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchEmailList.pending, state => {
            state.loading = 'loading'
        })
        builder.addCase(fetchEmailList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.emailListData = action.payload ?? []
            state.error = null
        })
        builder.addCase(fetchEmailList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    }
})

export const emailListReducer = emailListSlice.reducer

export const getEmailListLoading = state => {
    return state.emailListReducer.loading === 'loading' ? true : false
}

export const getEmailListError = state => {
    return state.emailListReducer.error
}

export const getEmailListFilterData = state => {
    return state.emailListReducer.filterData
}

export const getEmailListUniqueData = state => {
    return [...new Set(state.emailListReducer.emailListData.map(item => item.emailId))].map(emailId => {
        return state.emailListReducer.emailListData.find(item => item.emailId === emailId)
    })
}

export const getEmailListData = state => {
    return state.emailListReducer.emailListData
}

export const getEmailListDataByID = (state, {emailID}) => {
    return state.emailListReducer.filterData.filter(e => e?.pk_EngEmailId == emailID)
}
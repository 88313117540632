import React,{useState} from 'react'
import VerticalMenuComponent from '../../components/VerticalMenuComponent'
import TopMenuComponent from '../../components/TopMenuComponent'

export default function ProjectReports() {

	const [isOpen, setIsOpen] = useState(false)
	const openSideNav = () => {
	  setIsOpen(!isOpen)
	}

  return (
    <>
    
    <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
                    {/* header component  */}
                    <VerticalMenuComponent/>
                    <TopMenuComponent openSideNav={openSideNav} />

                    {/* page wrapper  */}
                    <div className='page-wrapper'>
                            <div className='content'>
                                    

                            <div className="row">
					<div className="col-md-12">

						{/* <!-- Page Header --> */}
						<div className="page-header">
							<div className="row align-items-center">
								<div className="col-sm-8">
									<h4 className="page-title">Project Report <span className="count-title">123</span></h4>
								</div>
								<div className="col-sm-4 text-sm-end">
									<div className="head-icons">
										<a href="project-reports.html" data-bs-toggle="tooltip" data-bs-placement="top"
											data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
										<a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top"
											data-bs-original-title="Collapse" id="collapse-header"><i
												className="ti ti-chevrons-up"></i></a>
									</div>
								</div>
							</div>
						</div>
						{/* <!-- /Page Header --> */}

						<div className="card ">
							<div className="card-header">

								{/* <!-- Search --> */}
								<div className="row">
									<div className="col-md-5 col-sm-4">
										<div className="icon-form mb-3 mb-sm-0">
											<span className="form-icon"><i className="ti ti-search"></i></span>
											<input type="text" className="form-control" placeholder="Search Projects" />
										</div>
									</div>
									<div className="col-md-7 col-sm-8">
										<div className="text-sm-end">
											<a href="download_report" className="btn btn-primary" data-bs-toggle="modal"
												data-bs-target="#download_report"><i
													className="ti ti-file-download me-2"></i>Download Report</a>
										</div>
									</div>
								</div>

								{/* <!-- Search --> */}

							</div>
							<div className="card-body">
								<div className="row">
									<div className="col-md-7 d-flex">
										<div className="card shadow flex-fill">
											<div
												className="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-2">
												<h4>Projects by Year</h4>
												<div className="icon-form">
													<span className="form-icon"><i className="ti ti-calendar"></i></span>
													<input type="text" className="form-control bookingrange" placeholder=""/>
												</div>
											</div>
											<div className="card-body">
												<div id="project-year"></div>
											</div>
										</div>
									</div>
									<div className="col-md-5 d-flex">
										<div className="card shadow flex-fill">
											<div
												className="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-2">
												<h4>Projects by Stage</h4>
												<div className="icon-form ">
													<span className="form-icon"><i className="ti ti-calendar"></i></span>
													<input type="text" className="form-control bookingrange" placeholder=""/>
												</div>
											</div>
											<div className="card-body">
												<div id="project-type"></div>
											</div>
										</div>
									</div>
								</div>

								{/* <!-- Filter --> */}
								<div className="d-flex align-items-center justify-content-between flex-wrap mb-4 row-gap-2">
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a href="javascript:void(0);" className="dropdown-toggle"
												data-bs-toggle="dropdown"><i
													className="ti ti-sort-ascending-2 me-2"></i>Sort </a>
											<div className="dropdown-menu  dropdown-menu-start">
												<ul>
													<li>
														<a href="javascript:void(0);" className="dropdown-item">
															<i className="ti ti-circle-chevron-right me-1"></i>Ascending
														</a>
													</li>
													<li>
														<a href="javascript:void(0);" className="dropdown-item">
															<i className="ti ti-circle-chevron-right me-1"></i>Descending
														</a>
													</li>
													<li>
														<a href="javascript:void(0);" className="dropdown-item">
															<i className="ti ti-circle-chevron-right me-1"></i>Recently
															Viewed
														</a>
													</li>
													<li>
														<a href="javascript:void(0);" className="dropdown-item">
															<i className="ti ti-circle-chevron-right me-1"></i>Recently
															Added
														</a>
													</li>
												</ul>
											</div>
										</div>
										<div className="icon-form">
											<span className="form-icon"><i className="ti ti-calendar"></i></span>
											<input type="text" className="form-control bookingrange" placeholder=""/>
										</div>
									</div>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a href="javascript:void(0);" className="btn bg-soft-purple text-purple"
												data-bs-toggle="dropdown" data-bs-auto-close="outside"><i
													className="ti ti-columns-3 me-2"></i>Manage Columns</a>
											<div className="dropdown-menu  dropdown-menu-md-end dropdown-md p-3">
												<h4 className="mb-2 fw-semibold">Want to manage datatables?</h4>
												<p className="mb-3">Please drag and drop your column to reorder your table
													and enable see option as you want.</p>
												<div className="border-top pt-3">
													<div className="d-flex align-items-center justify-content-between mb-3">
														<p className="mb-0 d-flex align-items-center"><i
																className="ti ti-grip-vertical me-2"></i>Name</p>
														<div className="status-toggle">
															<input type="checkbox" id="col-name" className="check"/>
															<label for="col-name" className="checktoggle"></label>
														</div>
													</div>
													<div className="d-flex align-items-center justify-content-between mb-3">
														<p className="mb-0 d-flex align-items-center"><i
																className="ti ti-grip-vertical me-2"></i>Client</p>
														<div className="status-toggle">
															<input type="checkbox" id="col-phone" className="check"/>
															<label for="col-phone" className="checktoggle"></label>
														</div>
													</div>
													<div className="d-flex align-items-center justify-content-between mb-3">
														<p className="mb-0 d-flex align-items-center"><i
																className="ti ti-grip-vertical me-2"></i>Priority</p>
														<div className="status-toggle">
															<input type="checkbox" id="col-email" className="check"/>
															<label for="col-email" className="checktoggle"></label>
														</div>
													</div>
													<div className="d-flex align-items-center justify-content-between mb-3">
														<p className="mb-0 d-flex align-items-center"><i
																className="ti ti-grip-vertical me-2"></i>Start Date</p>
														<div className="status-toggle">
															<input type="checkbox" id="col-tag" className="check"/>
															<label for="col-tag" className="checktoggle"></label>
														</div>
													</div>
													<div className="d-flex align-items-center justify-content-between mb-3">
														<p className="mb-0 d-flex align-items-center"><i
																className="ti ti-grip-vertical me-2"></i>Due Date</p>
														<div className="status-toggle">
															<input type="checkbox" id="col-loc" className="check"/>
															<label for="col-loc" className="checktoggle"></label>
														</div>
													</div>
													<div className="d-flex align-items-center justify-content-between mb-3">
														<p className="mb-0 d-flex align-items-center"><i
																className="ti ti-grip-vertical me-2"></i>Type</p>
														<div className="status-toggle">
															<input type="checkbox" id="col-rate" className="check"/>
															<label for="col-rate" className="checktoggle"></label>
														</div>
													</div>
													<div className="d-flex align-items-center justify-content-between mb-3">
														<p className="mb-0 d-flex align-items-center"><i
																className="ti ti-grip-vertical me-2"></i>Pipeline Stage</p>
														<div className="status-toggle">
															<input type="checkbox" id="col-owner" className="check"/>
															<label for="col-owner" className="checktoggle"></label>
														</div>
													</div>
													<div className="d-flex align-items-center justify-content-between mb-3">
														<p className="mb-0 d-flex align-items-center"><i
																className="ti ti-grip-vertical me-2"></i>Budget Value</p>
														<div className="status-toggle">
															<input type="checkbox" id="col-status" className="check"
																checked/>
															<label for="col-status" className="checktoggle"></label>
														</div>
													</div>
													<div className="d-flex align-items-center justify-content-between mb-3">
														<p className="mb-0 d-flex align-items-center"><i
																className="ti ti-grip-vertical me-2"></i>Currently Spend</p>
														<div className="status-toggle">
															<input type="checkbox" id="col-owner" className="check"/>
															<label for="col-owner" className="checktoggle"></label>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="form-sorts dropdown">
											<a href="javascript:void(0);" data-bs-toggle="dropdown"
												data-bs-auto-close="outside"><i
													className="ti ti-filter-share"></i>Filter</a>
											<div className="filter-dropdown-menu dropdown-menu  dropdown-menu-md-end p-3">
												<div className="filter-set-view">
													<div className="filter-set-head">
														<h4><i className="ti ti-filter-share"></i>Filter</h4>
													</div>
													<div className="accordion" id="accordionExample">
														<div className="filter-set-content">
															<div className="filter-set-content-head">
																<a href="#" data-bs-toggle="collapse"
																	data-bs-target="#collapseTwo" aria-expanded="true"
																	aria-controls="collapseTwo">Project Name</a>
															</div>
															<div className="filter-set-contents accordion-collapse collapse show"
																id="collapseTwo" data-bs-parent="#accordionExample">
																<div className="filter-content-list">
																	<div className="mb-2 icon-form">
																		<span className="form-icon"><i
																				className="ti ti-search"></i></span>
																		<input type="text" className="form-control"
																			placeholder="Search Name"/>
																	</div>
																	<ul>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox" checked/>
																					<span className="checkmarks"></span>
																					Truelysell
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					Dreamschat
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					Servbook
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					Doccure
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					Dreamsports
																				</label>
																			</div>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="filter-set-content">
															<div className="filter-set-content-head">
																<a href="#" className="collapsed" data-bs-toggle="collapse"
																	data-bs-target="#client" aria-expanded="false"
																	aria-controls="client">Client Name</a>
															</div>
															<div className="filter-set-contents accordion-collapse collapse"
																id="client" data-bs-parent="#accordionExample">
																<div className="filter-content-list">
																	<div className="mb-2 icon-form">
																		<span className="form-icon"><i
																				className="ti ti-search"></i></span>
																		<input type="text" className="form-control"
																			placeholder="Search Client"/>
																	</div>
																	<ul>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox" checked/>
																					<span className="checkmarks"></span>
																					NovaWave LLC
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					BlueSky Industries
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					Silver Hawk
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					Summit Peak
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					CoastalStar Co.
																				</label>
																			</div>
																		</li>

																	</ul>
																</div>
															</div>
														</div>
														<div className="filter-set-content">
															<div className="filter-set-content-head">
																<a href="#" className="collapsed" data-bs-toggle="collapse"
																	data-bs-target="#type" aria-expanded="false"
																	aria-controls="type">Type</a>
															</div>
															<div className="filter-set-contents accordion-collapse collapse"
																id="type" data-bs-parent="#accordionExample">
																<div className="filter-content-list">
																	<ul>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox" checked/>
																					<span className="checkmarks"></span>
																					Web App
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					Meeting
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					Mobile App
																				</label>
																			</div>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="filter-set-content">
															<div className="filter-set-content-head">
																<a href="#" className="collapsed" data-bs-toggle="collapse"
																	data-bs-target="#collapseOne" aria-expanded="false"
																	aria-controls="collapseOne">Start Date</a>
															</div>
															<div className="filter-set-contents accordion-collapse collapse"
																id="collapseOne" data-bs-parent="#accordionExample">
																<div className="filter-content-list">
																	<ul>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox" checked/>
																					<span className="checkmarks"></span>
																					25 Sep 2023
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					29 Sep 2023
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					29 Sep 2023
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					14 Oct 2023
																				</label>
																			</div>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="filter-set-content">
															<div className="filter-set-content-head">
																<a href="#" className="collapsed" data-bs-toggle="collapse"
																	data-bs-target="#collapsetwo" aria-expanded="false"
																	aria-controls="collapsetwo">End Date</a>
															</div>
															<div className="filter-set-contents accordion-collapse collapse"
																id="collapsetwo" data-bs-parent="#accordionExample">
																<div className="filter-content-list">
																	<ul>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox" checked/>
																					<span className="checkmarks"></span>
																					19 Oct 2023
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					23 Nov 2023
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					14 Dec 2023
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					09 Dec 2023
																				</label>
																			</div>
																		</li>

																	</ul>
																</div>
															</div>
														</div>
														<div className="filter-set-content">
															<div className="filter-set-content-head">
																<a href="#" className="collapsed" data-bs-toggle="collapse"
																	data-bs-target="#Stage" aria-expanded="false"
																	aria-controls="Stage">Pipeline Stage</a>
															</div>
															<div className="filter-set-contents accordion-collapse collapse"
																id="Stage" data-bs-parent="#accordionExample">
																<div className="filter-content-list">
																	<ul>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox" checked/>
																					<span className="checkmarks"></span>
																					Plan
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					Develop
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					Completed
																				</label>
																			</div>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
														<div className="filter-set-content">
															<div className="filter-set-content-head">
																<a href="#" className="collapsed" data-bs-toggle="collapse"
																	data-bs-target="#Status" aria-expanded="false"
																	aria-controls="Status">Status</a>
															</div>
															<div className="filter-set-contents accordion-collapse collapse"
																id="Status" data-bs-parent="#accordionExample">
																<div className="filter-content-list">
																	<ul>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox" checked/>
																					<span className="checkmarks"></span>
																					Active
																				</label>
																			</div>
																		</li>
																		<li>
																			<div className="filter-checks">
																				<label className="checkboxs">
																					<input type="checkbox"/>
																					<span className="checkmarks"></span>
																					Inactive
																				</label>
																			</div>
																		</li>
																	</ul>
																</div>
															</div>
														</div>
													</div>
													<div className="filter-reset-btns">
														<div className="row">
															<div className="col-6">
																<a href="#" className="btn btn-light">Reset</a>
															</div>
															<div className="col-6">
																<a href="project-reports.html"
																	className="btn btn-primary">Filter</a>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								{/* <!-- /Filter --> */}

								{/* <!-- Report List --> */}
								<div className="table-responsive custom-table">
									<table className="table" id="project-reports">
										<thead className="thead-light">
											<tr>
												<th className="no-sort">
													<label className="checkboxs">
														<input type="checkbox" id="select-all"/><span
															className="checkmarks"></span>
													</label>
												</th>
												<th className="no-sort"></th>
												<th>Name</th>
												<th>Client</th>
												<th>Priority</th>
												<th>Start Date</th>
												<th>End Date</th>
												<th>Type</th>
												<th>Pipeline Stage</th>
												<th>Budget Value</th>
												<th>Currently Spend</th>
											</tr>
										</thead>
										<tbody>

										</tbody>
									</table>
								</div>
								<div className="row align-items-center">
									<div className="col-md-6">
										<div className="datatable-length"></div>
									</div>
									<div className="col-md-6">
										<div className="datatable-paginate"></div>
									</div>
								</div>
								{/* <!-- /Report List --> */}

							</div>
						</div>

					</div>
				</div>


                            </div>
                    </div>


                    {/* <!-- Download Report --> */}
		<div className="modal custom-modal fade" id="download_report" role="dialog">
			<div className="modal-dialog modal-dialog-centered" role="document">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Download Report</h5>
						<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><span
								aria-hidden="true">×</span></button>
					</div>
					<div className="modal-body">
						<form action="#">
							<div className="mb-3">
								<label className="form-label">File Type <span className="text-danger">*</span></label>
								<select className="select">
									<option>Download as PDF</option>
									<option>Download as Excel</option>
								</select>
							</div>
							<div className="mb-3">
								<h5>Filters</h5>
							</div>
							<div className="mb-3">
								<label className="form-label">File Type <span className="text-danger">*</span></label>
								<select className="select">
									<option>All Fields</option>
									<option>Name</option>
									<option>Client</option>
									<option>Owner</option>
									<option>Priority</option>
									<option>Date Created</option>
								</select>
							</div>
							<div className="mb-3">
								<label className="form-label">Position<span className="text-danger">*</span></label>
								<select className="select">
									<option>All Position</option>
									<option>Installer</option>
									<option>Senior Manager</option>
									<option>Test Engineer</option>
									<option>UI /UX Designer</option>
								</select>
							</div>
							<div className="mb-3">
								<label className="form-label">Source<span className="text-danger">*</span></label>
								<select className="select">
									<option>All Source</option>
									<option>Google</option>
									<option>Campaigns </option>
									<option>Referrals</option>
									<option>Paid Social</option>
								</select>
							</div>
							<div className="mb-3">
								<label className="form-label">Select Year<span className="text-danger">*</span></label>
								<select className="select">
									<option>2023</option>
									<option>2022</option>
									<option>2021</option>
								</select>
							</div>
							<div className="col-lg-12 text-end modal-btn">
								<button type="submit" className="btn btn-light" data-bs-dismiss="modal">Cancel</button>
								<button type="submit" className="btn btn-primary">Download Now</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Download Report --> */}



        </div>


    
    
    </>
  )
}

import React,{useState} from 'react'
import VerticalMenuComponent from '../../components/VerticalMenuComponent'
import TopMenuComponent from '../../components/TopMenuComponent'
export default function Prefixes() {

	const [isOpen, setIsOpen] = useState(false)
	const openSideNav = () => {
	  setIsOpen(!isOpen)
	}

  return (
    <>
    <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
                    {/* header component  */}
                    <VerticalMenuComponent/>
                    <TopMenuComponent openSideNav={openSideNav} />

                    {/* page wrapper  */}
                    <div className='page-wrapper'>
                            <div className='content'>
                                   
             <div className="row">
					<div className="col-md-12">

						{/* <!-- Page Header --> */}
						<div className="page-header">
							<div className="row align-items-center">
								<div className="col-8">
									<h4 className="page-title">Settings</h4>
								</div>
								<div className="col-4 text-end">
									<div className="head-icons">
										<a href="prefixes.html" data-bs-toggle="tooltip" data-bs-placement="top"
											data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
										<a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top"
											data-bs-original-title="Collapse" id="collapse-header"><i
												className="ti ti-chevrons-up"></i></a>
									</div>
								</div>
							</div>
						</div>
						{/* <!-- /Page Header --> */}

						{/* <!-- Settings Menu --> */}
						<div className="card">
							<div className="card-body pb-0 pt-2">
								<ul className="nav nav-tabs nav-tabs-bottom">
									<li className="nav-item me-3">
										<a href="profile.html" className="nav-link px-0">
											<i className="ti ti-settings-cog me-2"></i>General Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="company-settings.html" className="nav-link px-0 active">
											<i className="ti ti-world-cog me-2"></i>Website Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="invoice-settings.html" className="nav-link px-0">
											<i className="ti ti-apps me-2"></i>App Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="email-settings.html" className="nav-link px-0">
											<i className="ti ti-device-laptop me-2"></i>System Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="payment-gateways.html" className="nav-link px-0">
											<i className="ti ti-moneybag me-2"></i>Financial Settings
										</a>
									</li>
									<li className="nav-item">
										<a href="storage.html" className="nav-link px-0">
											<i className="ti ti-flag-cog me-2"></i>Other Settings
										</a>
									</li>
								</ul>
							</div>
						</div>
						{/* <!-- /Settings Menu --> */}

						<div className="row">
							<div className="col-xl-3 col-lg-12 theiaStickySidebar">

								{/* <!-- Settings Sidebar --> */}
								<div className="card">
									<div className="card-body">
										<div className="settings-sidebar">
											<h4 className="fw-semibold mb-3">Website Settings</h4>
											<div className="list-group list-group-flush settings-sidebar">
												<a href="company-settings.html" className="fw-medium">Company Settings</a>
												<a href="localization.html" className="fw-medium">Localization</a>
												<a href="prefixes.html" className="fw-medium active">Prefixes</a>
												<a href="preference.html" className="fw-medium">Preference</a>
												<a href="appearance.html" className="fw-medium">Appearance</a>
												<a href="language.html" className="fw-medium">Language</a>
											</div>
										</div>
									</div>
								</div>
								{/* <!-- /Settings Sidebar --> */}

							</div>

							<div className="col-xl-9 col-lg-12">

								{/* <!-- Prefixes --> */}
								<div className="card">
									<div className="card-body">
										<h4 className="fw-semibold mb-3">Prefixes</h4>
										<form action="prefixes.html">
											<div className="row">
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Products(SKU)</label>
														<input type="text" className="form-control" value="SKU - "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Supplier</label>
														<input type="text" className="form-control" value="SUP - "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Purchase</label>
														<input type="text" className="form-control" value="PU - "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Purchase Return</label>
														<input type="text" className="form-control" value="PR - "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Sales</label>
														<input type="text" className="form-control" value="SA - "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Sales Return</label>
														<input type="text" className="form-control" value="SR -  "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Customer</label>
														<input type="text" className="form-control" value="CT - "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Expense</label>
														<input type="text" className="form-control" value="EX - "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Stock Transfer</label>
														<input type="text" className="form-control" value="ST -  "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Stock Adjustment</label>
														<input type="text" className="form-control" value="SA -  "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Sales Order</label>
														<input type="text" className="form-control" value="SO - "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Invoice</label>
														<input type="text" className="form-control" value="INV -  "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Estimation</label>
														<input type="text" className="form-control" value="EST - "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Transaction</label>
														<input type="text" className="form-control" value="TRN - "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Employee</label>
														<input type="text" className="form-control" value="EMP -  "/>
													</div>
												</div>
												<div className="col-md-3 col-sm-6">
													<div className="mb-3">
														<label className="form-label">Purchase Return</label>
														<input type="text" className="form-control" value="PR -  "/>
													</div>
												</div>
											</div>
											<div>
												<a href="#" className="btn btn-light me-2">Cancel</a>
												<button type="submit" className="btn btn-primary">Save Changes</button>
											</div>
										</form>
									</div>
								</div>
								{/* <!-- /Prefixes --> */}

							</div>
						</div>
					</div>
				</div>


                            </div>
                    </div>


                    {/* <!-- Add Translation --> */}
		<div className="modal custom-modal fade" id="add_translation" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Add Translation</h5>
						<button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<div className="modal-body">
						<form action="language.html">
							<div className="mb-3">
								<label className="form-label">Language <span className="text-danger">*</span></label>
								<input type="text" className="form-control" />
							</div>
							<div className="mb-3">
								<label className="form-label">Code <span className="text-danger">*</span></label>
								<input type="text" className="form-control" />
							</div>
							<div className="mb-3">
								<label className="form-label">Status</label>
								<div className="radio-wrap">
									<div className="d-flex flex-wrap">
										<div className="radio-btn">
											<input type="radio" className="status-radio" id="add-active" name="status"
												checked="" />
											<label for="add-active">Active</label>
										</div>
										<div className="radio-btn">
											<input type="radio" className="status-radio" id="add-inactive" name="status" />
											<label for="add-inactive">Inactive</label>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-btn">
								<a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Save</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Add Translation --> */}

		{/* <!-- Edit Translation --> */}
		<div className="modal custom-modal fade" id="edit_translation" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Edit Translation</h5>
						<button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<div className="modal-body">
						<form action="language.html">
							<div className="mb-3">
								<label className="form-label">Language <span className="text-danger">*</span></label>
								<input type="text" className="form-control" value="English" />
							</div>
							<div className="mb-3">
								<label className="form-label">Code <span className="text-danger">*</span></label>
								<input type="text" className="form-control" value="en" />
							</div>
							<div className="mb-3">
								<label className="form-label">Status</label>
								<div className="radio-wrap">
									<div className="d-flex flex-wrap">
										<div className="radio-btn">
											<input type="radio" className="status-radio" id="edit-active" name="status"
												checked="" />
											<label for="edit-active">Active</label>
										</div>
										<div className="radio-btn">
											<input type="radio" className="status-radio" id="edit-inactive" name="status" />
											<label for="edit-inactive">Inactive</label>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-btn">
								<a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Save</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Edit Translation --> */}

		{/* <!-- Import Sample --> */}
		<div className="modal custom-modal fade" id="import_sample" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Import Sample</h5>
						<button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<div className="modal-body">
						<form action="language.html">
							<div className="mb-3">
								<label className="form-label">File <span className="text-danger">*</span></label>
								<select className="select">
									<option>Inventory</option>
									<option>Expense</option>
									<option>Product</option>
								</select>
							</div>
							<div className="mb-3">
								<label className="form-label">Language <span className="text-danger">*</span></label>
								<select className="select">
									<option>English</option>
									<option>Hindi</option>
									<option>Chinese</option>
								</select>
							</div>
							<div className="mb-3">
								<div className="drag-attach">
									<input type="file" />
									<div className="img-upload">
										<i className="ti ti-file-broken"></i>Upload File
									</div>
								</div>
							</div>
							<div className="mb-3">
								<label className="form-label">Uploaded Files</label>
								<div className="upload-file">
									<h6>Projectneonals teyys.xls</h6>
									<p>4.25 MB</p>
									<div className="progress">
										<div className="progress-bar bg-success" role="progressbar" style={{width:"25%"}}
											aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
									</div>
									<p className="black-text">45%</p>
								</div>
								<div className="upload-file upload-list">
									<div>
										<h6>tes.txt</h6>
										<p>4.25 MB</p>
									</div>
									<a href="javascript:void(0);" className="text-danger"><i className="ti ti-trash-x"></i></a>
								</div>
							</div>
							<div className="modal-btn">
								<a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Save</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Import Sample --> */}

		{/* <!-- Delete Translation --> */}
		<div className="modal custom-modal fade" id="delete_translation" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header border-0 m-0 justify-content-end">
						<button className="btn-close" data-bs-dismiss="modal" aria-label="Close">
							<i className="ti ti-x"></i>
						</button>
					</div>
					<div className="modal-body">
						<form action="language.html">
							<div className="success-message text-center">
								<div className="success-popup-icon">
									<i className="ti ti-trash-x"></i>
								</div>
								<h3>Remove Translation?</h3>
								<p className="del-info">Are you sure you want to remove it.</p>
								<div className="col-lg-12 text-center modal-btn">
									<a href="#" className="btn btn-light" data-bs-dismiss="modal">Cancel</a>
									<button type="submit" className="btn btn-danger">Yes, Delete it</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Delete Translation --> */}



        </div>

    
    
    </>
  )

  
}

import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"

export const cityListAdapter = createEntityAdapter()

export const fetchCityList = createAsyncThunk(
    Preferences.CITY_LIST,
    async ({countryID, stateID}, thunkApi) => {
        let countryParam = countryID ? `&fk_countryId=${countryID}` : ''
        let stateParam = stateID ? `&fk_stateId=${stateID}` : ''
        console.log('stateID in citySlice - ', stateID)
        const params = `?fk_UsrOrgDirId=${
                JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
              }${countryParam}${stateParam}&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getCityList, params)
        if (response?.result?.response_Status === 'success') {
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status == 'error') {
            alert(response?.result?.response_Message) 
        } else {
            alert('Something went wrong with getCityData')
        }
    }
)

export const initialAppState = cityListAdapter.getInitialState({
    loading: 'idel',
    cityListData: [],
    filterData: [],
    error: []
})

export const cityListSlice = createSlice({
    name: Preferences.CITY_LIST,
    initialState: initialAppState,
    reducers: {
        resetCityListFilter: state => {
            state.filterData = state.cityListData
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchCityList.pending, state => {
            state.loading = 'loading'
        })
        builder.addCase(fetchCityList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.cityListData = action.payload ?? []
            state.error = null
        })
        builder.addCase(fetchCityList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    }
})

export const cityListReducer = cityListSlice.reducer

export const getCityListLoading = state => {
    return state.cityListReducer.loading === 'loading' ? true : false
}

export const getCityListError = state => {
    return state.cityListReducer.error
}

export const getCityListFilterData = state => {
    return state.cityListReducer.filterData
}

export const getCityListData = state => {
    return state.cityListReducer.cityListData
}

export const getCityListDataByID = (state, {cityID}) => {
    return state.cityListReducer.filterData.filter(e => e?.pk_cityId == cityID)
}
import React from "react"
import "./custom-alert.style.css"
import { Constant } from "../../utils"

const CustomAlert = ({
  alertTitle,
  alertDesc,
  showCloseIcon=true,
  onCloseClick = () => {},
  leftButtonName,
  onLeftButtonClick = () => {},
  rightButtonName,
  onRightButtonClick = () => {},
  customAlertType,
}) => {

  let errorClass = "alert-primary"
  let successClass = "alert-success"
  let warningClass = "alert-secondary"

  let errorIconClass = "feather-info"
  let successIconClass = "feather-check-circle"
  let warningIconClass = "feather-alert-triangle"

  return (
    <>
      <div className="custom-modal" id="Modal-box">
        <div className="inner-modal-content">
          <div className="custom-alert">
            <div className="card bg-white border-0">
              <div
                className={`alert custom-alert1 ${
                  customAlertType === Constant.STATUS_ERROR
                    ? errorClass
                    : customAlertType === Constant.STATUS_SUCCESS
                    ? successClass
                    : customAlertType === Constant.STATUS_WARNING
                    ? warningClass
                    : ""
                }`}
              >
                {showCloseIcon == true && (
                  <button
                    type="button"
                    class="btn-close ms-auto"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={onCloseClick}
                  >
                    <i class="fas fa-xmark"></i>
                  </button>
                )} 
                <div className="text-center  px-5 pb-0">
                  <div className="custom-alert-icon">
                      <i className={`${   
                        customAlertType === Constant.STATUS_ERROR
                          ? errorIconClass
                          : customAlertType === Constant.STATUS_SUCCESS
                          ? successIconClass
                          : customAlertType === Constant.STATUS_WARNING
                          ? warningIconClass
                          : ""} flex-shrink-0`}/>
                  </div>
                  
                  <h5 className="mb-4"> {alertTitle ?? ""} </h5>
                  <p className="">{alertDesc ?? ""}</p>
                  <div className="">
                    {leftButtonName && (<button className="btn btn-sm btn-outline-danger m-1" onClick={onLeftButtonClick}>
                      {leftButtonName}
                    </button>)}
                    {rightButtonName && (<button className="btn btn-sm btn-primary m-1" onClick={onRightButtonClick}>
                      {rightButtonName}
                    </button>)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CustomAlert

import React, { useState } from "react"
import VerticalMenuComponent from "../../../components/VerticalMenuComponent"
import TopMenuComponent from "../../../components/TopMenuComponent"
import CustomDataTableComponent from "../../../components/page-components/CustomDataTableComponent"
import Data from "../../../utils/dummy-data"
import SourceForm from "../source-form"
import { Constant } from "../../../utils"
import TopPageHeader from "../../../components/TopPageHeader"

export default function SourcesList() {

  const [isOpen, setIsOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")
  const [formType, setFormType] = useState(Constant.FORM_TYPE_NEW)
  const [selectedData, setSelectedData] = useState(null)

  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  const columns = [
    {
      name: "Source Name",
      selector: (row) => row.contacts_name,
      cell: (row) => (
        <div className="d-flex flex-column">
          <span className="text-default">{row.contacts_name}</span>
        </div>
      ),
      sortable: true,
    },
 
    {
      name: "Source Status",
      selector: (row) => row.status,
      sortable: true,
      cell: (row) => {
        let statusClass = ""
        let statusName = ""
        if (row.status === "0") {
          statusClass = "badge-success-light"
          statusName = "Closed"
        } else if (row.status === "1") {
          statusClass = "badge-purple-light"
          statusName = "Lost"
        } else if (row.status === "2") {
          statusClass = "badge-danger-light"
          statusName = "Not Contacted"
        } else {
          statusClass = ["badge-tag", "bg-warning-light"]
          statusName = "Contacted"
        }
        return (
          <span className={`badge badge-tag ${statusClass}`}>{statusName}</span>
        )
      },
    },
    {
      name: "Created By",
      selector: (row) => row.created_date,
      sortable: true,
    },
    {
      name: "Created Date",
      selector: (row) => row.created_date,
      sortable: true,
    },
    {
      name: "Action",
      button: true,
      sortable: true,
      cell: (row) => (
        <div className="outer-action-box">
          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-original-title="Edit"
          >
            <a
              className="dropdown-item"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas_add"
              href="#!"
              onClick={() => {
                setSelectedData({ ...row })
                setFormType(Constant.FORM_TYPE_EDIT)
              }}
            >
              <i className="ti ti-edit text-blue"></i>
            </a>
          </div>

          <div
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-original-title="View"
          >
            <a
              className="dropdown-item"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvas_add"
              href="#"
              onClick={() => {
                setSelectedData({ ...row })
                setFormType(Constant.FORM_TYPE_VIEW)
              }}
            >
              <i class="ti ti-eye text-blue-light"></i>
            </a>
          </div>
        </div>
      ),
    },
  ]

  const filterTable = (event) => {
    setSearchValue(event.target.value)
  }

  let rowData = Data.filter((input) => {
    const lowerSearchTerm = searchValue.toLowerCase()
    const leadName = input.contacts_name
      .toLocaleLowerCase()
      .includes(lowerSearchTerm)
    const companyName = input.contacts_name
      .toLocaleLowerCase()
      .includes(lowerSearchTerm)
    const leadCreated = input.created_date
      .toLocaleLowerCase()
      .includes(lowerSearchTerm)

    const leadEmail = Array.isArray(input.email)
      ? input.email.some((email) =>
          email.toLowerCase().includes(lowerSearchTerm)
        )
      : input.email.toLowerCase().includes(lowerSearchTerm)
    const leadPhone = Array.isArray(input.name)
      ? input.phone.some((phone) =>
          phone.toLowerCase().includes(lowerSearchTerm)
        )
      : input.phone.toLowerCase().includes(lowerSearchTerm)

    return leadName || companyName || leadCreated || leadEmail || leadPhone
  })

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <TopPageHeader
                    pageTitle={"Source"}
                    countTitle={110}
                  />                  
                </div>
                {/* <!-- /Page Header --> */}

                <div className="card">
                  <div className="card-header">
                    {/* <!-- Search --> */}
                    <div className="row align-items-center">
                      <div className="col-sm-4">
                        <div className="icon-form mb-3 mb-sm-0">
                          <span className="form-icon">
                            <i className="ti ti-search"></i>
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search Source"
                            onChange={filterTable}
                          />
                        </div>
                      </div>
                      <div className="col-sm-8">
                        <div className="text-sm-end">
                          <a
                            href="javascript:void(0);"
                            className="btn btn-primary "
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvas_add"
                            onClick={() => setFormType(Constant.FORM_TYPE_NEW)}                            
                          > 
                            <i className="ti ti-square-rounded-plus me-2"></i>
                            Add New Source
                          </a> 
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Search --> */}
                  </div>
                  <div className="card-body">
                    {/* <!-- Contact List --> */}                    
                      <CustomDataTableComponent
                        rowData={rowData}
                        columns={columns}
                      />
                    {/* <!-- /Contact List --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add New Source --> */}
          <SourceForm 
            selectedData={selectedData}
            formType={formType}
          />

        {/* <!-- Add New Source --> */}
      </div>
    </>
  )
}

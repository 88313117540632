import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"


export const sourceListAdapter = createEntityAdapter()

export const fetchSourceList = createAsyncThunk(
    Preferences.SOURCE_LIST,
    async (thunkApi) => {
        const params = `?fk_UsrOrgDirId=${
            JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
        }&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getSourceList, params)
        if (response?.result?.response_Status === 'success') {
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status == 'error') {
            alert(response?.result?.response_Message)
        } else {
            alert('Something went wrong with getSourceData')
        }
    }
)

export const initialAppState = sourceListAdapter.getInitialState({
    loading: 'idel',
    sourceListData: [],
    filterData: [],
    error: []
})

export const sourceListSlice = createSlice({
    name: Preferences.SOURCE_LIST,
    initialState: initialAppState,
    reducers: {
        resetSourceListFilter: state => {
            state.filterData = state.sourceListData
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchSourceList.pending, state => {
            state.loading = 'loading'
        })
        builder.addCase(fetchSourceList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.sourceListData = action.payload ?? []
            state.error
        })
        builder.addCase(fetchSourceList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    }
})

export const sourceListReducer = sourceListSlice.reducer

export const getSourceListLoading = state => {
    return state.sourceListReducer.loading === 'loading' ? true : false
}

export const getSourceListError = state => {
    return state.sourceListReducer.error
}

export const getSourceListFilterData = state => {
    return state.sourceListReducer.filterData
}

export const getSourceListData = state => {
    return state.sourceListReducer.sourceListData
}

export const getSourceListDataByID = (state, {sourceID}) => {
    return state.sourceListReducer.filterData.filter(e => e?.pk_sourceId == sourceID)
}
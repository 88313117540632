import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"

export default function Currencies() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h4 className="page-title">Settings</h4>
                    </div>
                    <div className="col-4 text-end">
                      <div className="head-icons">
                        <a
                          href="currencies.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                {/* <!-- Settings Menu --> */}
                <div className="card">
                  <div className="card-body pb-0 pt-2">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item me-3">
                        <a href="profile.html" className="nav-link px-0">
                          <i className="ti ti-settings-cog me-2"></i>General
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="company-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-world-cog me-2"></i>Website
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="invoice-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-apps me-2"></i>App Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a href="email-settings.html" className="nav-link px-0">
                          <i className="ti ti-device-laptop me-2"></i>System
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="payment-gateways.html"
                          className="nav-link px-0 active"
                        >
                          <i className="ti ti-moneybag me-2"></i>Financial
                          Settings
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="storage.html" className="nav-link px-0">
                          <i className="ti ti-flag-cog me-2"></i>Other Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /Settings Menu --> */}

                <div className="row">
                  <div className="col-xl-3 col-lg-12 theiaStickySidebar">
                    {/* <!-- Settings Sidebar --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="settings-sidebar">
                          <h4 className="fw-semibold mb-3">
                            Financial Settings
                          </h4>
                          <div className="list-group list-group-flush settings-sidebar">
                            <a
                              href="payment-gateways.html"
                              className="fw-medium"
                            >
                              Payment Gateways
                            </a>
                            <a href="bank-accounts.html" className="fw-medium">
                              Bank Accounts
                            </a>
                            <a href="tax-rates.html" className="fw-medium">
                              Tax Rates
                            </a>
                            <a
                              href="currencies.html"
                              className="fw-medium active"
                            >
                              Currencies
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Sidebar --> */}
                  </div>

                  <div className="col-xl-9 col-lg-12">
                    {/* <!-- Currencies --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <h4>Currencies</h4>
                          <a
                            href="javascript:void(0)"
                            className="btn btn-sm btn-icon border rounded"
                            data-bs-toggle="modal"
                            data-bs-target="#add_currency"
                          >
                            <i className="ti ti-plus"></i>
                          </a>
                        </div>

                        <form action="currencies.html">
                          {/* <!-- Euro --> */}
                          <div className="d-flex align-items-center justify-content-between flex-wrap border-bottom mb-3 pb-3 row-gap-2">
                            <div>
                              <h5 className="mb-1 fw-semibold">Euro</h5>
                              <p>Shows the details of Euro currency</p>
                            </div>
                            <div className="dropdown table-action">
                              <a
                                href="#"
                                className="action-icon bg-white"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_currency"
                                >
                                  <i className="fa-solid fa-pencil text-blue"></i>{" "}
                                  Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_currency"
                                >
                                  <i className="fa-regular fa-trash-can text-danger"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="border-bottom mb-3">
                            <div className="row">
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Currency Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Euro"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">Code</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="EUR"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Symbol
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="€"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Exchange Rate
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Default"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /Euro --> */}

                          {/* <!-- England Pound --> */}
                          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3 pb-3 border-bottom row-gap-2">
                            <div>
                              <h5 className="fw-semibold mb-1">
                                England Pound
                              </h5>
                              <p>Shows the details of England Pound currency</p>
                            </div>
                            <div className="dropdown table-action">
                              <a
                                href="#"
                                className="action-icon bg-white"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_currency"
                                >
                                  <i className="fa-solid fa-pencil text-blue"></i>{" "}
                                  Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_currency"
                                >
                                  <i className="fa-regular fa-trash-can text-danger"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3 border-bottom">
                            <div className="row">
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Currency Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="England Pound"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">Code</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="GBP"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Symbol
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="€"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Exchange Rate
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Default"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /England Pound --> */}

                          {/* <!-- Indian Rupee --> */}
                          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3 pb-3 border-bottom row-gap-2">
                            <div>
                              <h5 className="fw-semibold mb-1">Indian Rupee</h5>
                              <p>Shows the details of Indian Rupee currency</p>
                            </div>
                            <div className="dropdown table-action">
                              <a
                                href="#"
                                className="action-icon bg-white"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_currency"
                                >
                                  <i className="fa-solid fa-pencil text-blue"></i>{" "}
                                  Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_currency"
                                >
                                  <i className="fa-regular fa-trash-can text-danger"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="border-bottom mb-3">
                            <div className="row">
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Currency Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Indian Rupee"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">Code</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="INR"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Symbol
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="₹"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Exchange Rate
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="83.11"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /Indian Rupee --> */}

                          {/* <!-- US Dollar --> */}
                          <div className="d-flex align-items-center justify-content-between flex-wrap mb-3 pb-3 border-bottom row-gap-2">
                            <div>
                              <h5 className="fw-semibold mb-1">US Dollar</h5>
                              <p>Shows the details of US Dollar currency</p>
                            </div>
                            <div className="dropdown table-action">
                              <a
                                href="#"
                                className="action-icon bg-white"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_currency"
                                >
                                  <i className="fa-solid fa-pencil text-blue"></i>{" "}
                                  Edit
                                </a>
                                <a
                                  className="dropdown-item"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_currency"
                                >
                                  <i className="fa-regular fa-trash-can text-danger"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="row">
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Currency Name
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="US Dollar"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">Code</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="USD"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Symbol
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="$"
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Exchange Rate
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Default"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /US Dollar --> */}

                          <div className="d-flex align-items-center">
                            <a href="#" className="btn btn-light me-2">
                              Cancel
                            </a>
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!-- /Currencies --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add Currency --> */}
        <div className="modal fade" id="add_currency" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Currency</h5>
                <div className="d-flex align-items-center">
                  <div className="status-toggle me-2">
                    <input
                      type="checkbox"
                      id="toggle"
                      className="check"
                      checked=""
                    />
                    <label for="toggle" className="checktoggle"></label>
                  </div>
                  <button
                    className="btn-close custom-btn-close border p-1 me-0 text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>
              </div>
              <form action="currencies.html">
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Currency Name <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Code <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Symbol <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-0">
                    <label className="col-form-label">
                      Exchange Rate <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex align-items-center justify-content-end m-0">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- /Add Currency --> */}

        {/* <!-- Edit Currency --> */}
        <div className="modal fade" id="edit_currency" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Currency</h5>
                <div className="d-flex align-items-center">
                  <div className="status-toggle me-2">
                    <input
                      type="checkbox"
                      id="toggle1"
                      className="check"
                      checked=""
                    />
                    <label for="toggle1" className="checktoggle"></label>
                  </div>
                  <button
                    className="btn-close custom-btn-close border p-1 me-0 text-dark"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>
              </div>
              <form action="currencies.html">
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Currency Name <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" value="Euro" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Code <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" value="EUR" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Symbol <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" value="€" />
                  </div>
                  <div className="mb-0">
                    <label className="col-form-label">
                      Exchange Rate <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value="Default"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex align-items-center justify-content-end m-0">
                    <a
                      href="#"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- /Edit Currency --> */}

        {/* <!-- Delete Account --> */}
        <div
          className="modal custom-modal fade"
          id="delete_currency"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="text-center">
                  <div className="avatar avatar-xl bg-danger-light rounded-circle mb-3">
                    <i className="ti ti-trash-x fs-36 text-danger"></i>
                  </div>
                  <h4 className="mb-2">Remove Currency?</h4>
                  <p className="mb-0">Are you sure you want to remove it.</p>
                  <div className="d-flex align-items-center justify-content-center mt-4">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <a href="bank-accounts.html" className="btn btn-danger">
                      Yes, Delete it
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Delete Account --> */}
      </div>
    </>
  )
}

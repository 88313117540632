import { useEffect, useState } from "react"
import { connect, useDispatch } from "react-redux"
import { Common, Constant } from "../../utils"
import CustomDropdown from "../custom-dropdown"
import {
  fetchProductTypeList,
  getProductTypeListData,
} from "../../store/slice/product-type-slice"
import {
  fetchProductCategoryList,
  getProductCategoryListData,
} from "../../store/slice/product-category-slice"
import API from "../../apis"
import { urls } from "../../apis/urls"
import CustomAlert from "../custom-alerts/CustomAlert"
import CustomModal from "../custom-modal"
import AddProductType from "./AddProductType"
import AddProductCategory from "./AddProductCategoryType"

const AddProduct = ({
  navigate,
  formType,
  selectedData,
  productTypeData,
  productCategoryData,
  showAddProductForm,
  hideProductForm,
}) => {
  const [loading, setLoading] = useState(false)
  const [selectedProductTypeData, setSelectedProductTypeData] = useState(null)
  const [selectedProductCategoryData, setSelectedProductCategoryData] =
    useState(null)
  const [productName, setProductName] = useState("")
  const [isActive, setIsActive] = useState("active")
  const [alertTitle, setAlertTitle] = useState("")
  const [alertDesc, setAlertDesc] = useState("")
  const [isAlert, setIsAlert] = useState(false)
  const [customAlertType, setCustomAlertType] = useState("")

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProductTypeList())
    dispatch(fetchProductCategoryList())
  }, [dispatch])

  useEffect(() => {
    if (selectedData) {
      const selectedType = productTypeData.find(
        (e) => e.pk_ProductTypeId === selectedData?.fk_ProductTypeId
      )
      const selectedCategory = productCategoryData.find((e) => e.pk_ProductCategoryId === selectedData?.fk_ProductCategoryId)
      console.log("selectedCategory ::", selectedCategory)
      setSelectedProductTypeData(selectedType || null)
      setSelectedProductCategoryData(selectedCategory || null)
      setProductName(selectedData?.productName || "")
    }
  }, [selectedData, productTypeData, productCategoryData])

  const handleProductName = (event) => setProductName(event.target.value)

  const clearInputValues = () => {
    setSelectedProductTypeData(null)
    setSelectedProductCategoryData(null)
    setProductName("")
  }

  const CustomAlertTexts = (title, desc, alertType) => {
    setAlertTitle(title)
    setAlertDesc(desc)
    setCustomAlertType(alertType)
    setIsAlert(true)
  }

  const checkValidations = async () => {
    if (!selectedProductTypeData) {
      CustomAlertTexts("Error", "Product Type is required", "error")
      return false
    } else if (!selectedProductCategoryData) {
      CustomAlertTexts("Error", "Product Category is required", "error")
      return false
    } else if (!productName) {
      CustomAlertTexts("Error", "Product Name is required", "error")
      return false
    }
    await submitData()
  }

  const submitData = async () => {
    setLoading(true)
    const params = {
      pk_ProductId:
        formType === Constant.FORM_TYPE_NEW ? 0 : selectedData?.pk_ProductId,
      fk_ProductTypeId: selectedProductTypeData.pk_ProductTypeId,
      fk_ProductCategoryId: selectedProductCategoryData.pk_ProductCategoryId,
      productName,
      fk_UsrOrgDirId: Common.getOrganisationID(),
      isActive: isActive === "active",
      createdBy: Common.getLoggedInUserID(),
      isFlag: 0,
    }
    console.log("params - ", params)
    API.postRequestWithJsonForOnline(
      urls.createProduct + `?tockenKey=${Common.getTokenKey()}`,
      params
    )
      .then(handleResponse)
      .catch(() => {
        setLoading(false)
      })
  }

  const handleResponse = (response) => {
    setLoading(false)
    if (response.result.response_Status === Constant.STATUS_SUCCESS) {
      clearInputValues()
      CustomAlertTexts(
        response.result.response_Status,
        response.result.response_Message,
        response.result.response_Status
      )
      location.reload()
    } else {
      CustomAlertTexts(
        response.result.response_Status,
        response.result.response_Message,
        response.result.response_Status
      )
    }
  }

  const onActiveStatus = (e) => setIsActive(e.target.value)

  const [showAddProductTypeForm, setShowAddProductTypeForm] = useState(false)
  const [showAddProductCategoryForm, setShowAddProductCategoryForm] = useState(false)

  const hideAddProductTypeForm = () => setShowAddProductTypeForm(false)
  const hideAddProductCategoryForm = () => setShowAddProductCategoryForm(false)

  return (
    <>
      {isAlert && (
        <CustomAlert
          alertTitle={alertTitle}
          alertDesc={alertDesc}
          onOkClick={() => setIsAlert(false)}
          customAlertType={customAlertType}
        />
      )}

      <div className="outer-modal-contentbox">
        <div className="offcanvas-header border-bottom">
          <h5 className="fw-semibold">
            {formType === Constant.FORM_TYPE_EDIT
              ? "Update Product"
              : formType === Constant.FORM_TYPE_NEW
              ? "Add Product"
              : "Product Details"}
          </h5>
          {showAddProductForm === true ? (
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              onClick={hideProductForm}
            >
              <i className="ti ti-x"></i>
            </button>
          ) : (
            <button
              type="button"
              className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
              data-bs-dismiss="offcanvas"
              onClick={() => {
                clearInputValues()
                return false
              }}
              aria-label="Close"
            >
              <i className="ti ti-x"></i>
            </button>
          )}
        </div>

        <div className="modal-content">
          <div
            className={`modal-body ${!showAddProductForm ? "customModal" : ""}`}
          >
            <form>
              <div className="mb-3">
                <label className="col-form-label">Product Type:</label>
                {formType != Constant.FORM_TYPE_VIEW && (
                  <span
                    className="add-options"
                    onClick={() => setShowAddProductTypeForm(true)}
                  >
                    Add Product Type
                  </span>
                )}
                <CustomDropdown
                  options={productTypeData}
                  label="productType"
                  id="pk_ProductTypeId"
                  selectedValue={selectedProductTypeData}
                  setSelectedValue={setSelectedProductTypeData}
                  placeholder="Product Type"
                  isDisabled={formType === Constant.FORM_TYPE_VIEW}
                />
              </div>

              {showAddProductTypeForm && (
                <CustomModal
                  children={
                    <AddProductType
                    formType={Constant.FORM_TYPE_NEW}
                    hideAddProductTypeForm={hideAddProductTypeForm}
                    showAddProductTypeForm={showAddProductTypeForm}
                    />
                  }
                />
              )}

              <div className="mb-3">
                <label className="col-form-label">Product Category:</label>
                {formType != Constant.FORM_TYPE_VIEW && (
                  <span
                    className="add-options"
                    onClick={() => setShowAddProductCategoryForm(true)}
                  >
                    Add Product Category
                  </span>
                )}
                <CustomDropdown
                  options={productCategoryData}
                  label="productCategory"
                  id="pk_ProductCategoryId"
                  selectedValue={selectedProductCategoryData}
                  setSelectedValue={setSelectedProductCategoryData}
                  placeholder="Product Category"
                  isDisabled={formType === Constant.FORM_TYPE_VIEW}
                />
              </div>

              {showAddProductCategoryForm && (
                <CustomModal
                  children={
                    <AddProductCategory
                    formType={Constant.FORM_TYPE_NEW}
                    hideAddProductCategoryForm={hideAddProductCategoryForm}                    
                    showAddProductCategoryForm={showAddProductCategoryForm}
                    />
                  }
                />
              )}

              <div className="mb-3">
                <label className="col-form-label">Product Name:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Product Name"
                  onChange={handleProductName}
                  value={productName}
                  disabled={formType === Constant.FORM_TYPE_VIEW}
                />
              </div>
              <div className="mb-3">
                <label className="col-form-label">Status:</label>
                <select
                  className="form-select"
                  onChange={onActiveStatus}
                  value={isActive}
                  disabled
                >
                  <option value="active">Active</option>
                  <option value="isactive">Inactive</option>
                </select>
              </div>
            </form>
          </div>
          <div className="modal-footer">
            {formType !== Constant.FORM_TYPE_VIEW && (
              <button
                type="button"
                className="btn btn-primary"
                onClick={checkValidations}
                disabled={formType === Constant.FORM_TYPE_VIEW}
              >
                {formType === Constant.FORM_TYPE_EDIT ? "Update" : "Submit"}
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  productTypeData: getProductTypeListData(state),
  productCategoryData: getProductCategoryListData(state),
})

export default connect(mapStateToProps)(AddProduct)

import React, {useState} from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"

export default function Appearance() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav" }`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h4 className="page-title">Settings</h4>
                    </div>
                    <div className="col-4 text-end">
                      <div className="head-icons">
                        <a
                          href="appearance.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                {/* <!-- Settings Menu --> */}
                <div className="card">
                  <div className="card-body pb-0 pt-2">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item me-3">
                        <a href="profile.html" className="nav-link px-0">
                          <i className="ti ti-settings-cog me-2"></i>General
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="company-settings.html"
                          className="nav-link px-0 active"
                        >
                          <i className="ti ti-world-cog me-2"></i>Website
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="invoice-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-apps me-2"></i>App Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a href="email-settings.html" className="nav-link px-0">
                          <i className="ti ti-device-laptop me-2"></i>System
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="payment-gateways.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-moneybag me-2"></i>Financial
                          Settings
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="storage.html" className="nav-link px-0">
                          <i className="ti ti-flag-cog me-2"></i>Other Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /Settings Menu --> */}

                <div className="row">
                  <div className="col-xl-3 col-lg-12 theiaStickySidebar">
                    {/* <!-- Settings Sidebar --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="settings-sidebar">
                          <h4 className="fw-semibold mb-3">Website Settings</h4>
                          <div className="list-group list-group-flush settings-sidebar">
                            <a
                              href="company-settings.html"
                              className="fw-medium"
                            >
                              Company Settings
                            </a>
                            <a href="localization.html" className="fw-medium">
                              Localization
                            </a>
                            <a href="prefixes.html" className="fw-medium">
                              Prefixes
                            </a>
                            <a href="preference.html" className="fw-medium">
                              Preference
                            </a>
                            <a
                              href="appearance.html"
                              className="fw-medium active"
                            >
                              Appearance
                            </a>
                            <a href="language.html" className="fw-medium">
                              Language
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Sidebar --> */}
                  </div>

                  <div className="col-xl-9 col-lg-12">
                    {/* <!-- Appearance --> */}
                    <div className="card">
                      <div className="card-body">
                        <h4 className="fw-semibold mb-3">Appearance</h4>
                        <form action="appearance.html">
                          <div className="mb-0">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <h6 className="fw-medium">Select Theme</h6>
                                  <p>Select theme of the website</p>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="mb-3 mb-0">
                                  <div className="theme-type-images d-flex align-items-center">
                                    <div className="theme-image active">
                                      <div className="theme-image-set">
                                        <img
                                          src="assets/img/theme/theme-01.jpg"
                                          alt=""
                                        />
                                      </div>
                                      <span>Light</span>
                                    </div>
                                    <div className="theme-image">
                                      <div className="theme-image-set">
                                        <img
                                          src="assets/img/theme/theme-02.jpg"
                                          alt=""
                                        />
                                      </div>
                                      <span>Dark</span>
                                    </div>
                                    <div className="theme-image">
                                      <div className="theme-image-set">
                                        <img
                                          src="assets/img/theme/theme-03.jpg"
                                          alt=""
                                        />
                                      </div>
                                      <span>Automatic</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <h6 className="fw-medium">Accent Color</h6>
                                  <p>Select accent color of website</p>
                                </div>
                              </div>
                              <div className="col-md-8">
                                <div className="mb-3">
                                  <div className="theme-colors">
                                    <ul>
                                      <li>
                                        <span className="themecolorset defaultcolor active"></span>
                                      </li>
                                      <li>
                                        <span className="themecolorset theme-secondary"></span>
                                      </li>
                                      <li>
                                        <span className="themecolorset theme-violet"></span>
                                      </li>
                                      <li>
                                        <span className="themecolorset theme-blue"></span>
                                      </li>
                                      <li>
                                        <span className="themecolorset theme-brown"></span>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <h6 className="fw-medium">Expand Sidebar</h6>
                                  <p>To display in all the pages</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <div className="form-check form-switch">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <h6 className="fw-medium">Sidebar Size</h6>
                                  <p>Select size of sidebar to display</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>Large - 250px</option>
                                    <option>Small - 85px</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <h6 className="fw-medium">Font Family</h6>
                                  <p>Select font family of website</p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <select className="select">
                                    <option selected>Noto Sans</option>
                                    <option>Nunito</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <a href="#" className="btn btn-light me-2">
                              Cancel
                            </a>
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!-- /Appearance --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

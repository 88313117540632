import React from "react"
import "./custom-loader.css"
export default function CustomLoader({loaderAlignmensCss, loaderStyle }) {

  return (
    <div>
      <div className={`main-loader-outer ${loaderAlignmensCss ?? 'main-loader-outer'}`} >      
        {/* <div className={`${loaderStyle ? loaderStyle : 'loader'}`}></div> */}
        <div className={`${loaderStyle ??'loader'}`}></div>
      </div>
    </div>
  )
}

import { AgCharts } from "ag-charts-react"
import React, {useState} from 'react'

const YearChart = () =>{

    const getData = () => {
        return [
          { month: "January", max: 8.5, min: 2.6 },
          { month: "February", max: 10.4, min: 3.0 },
          { month: "March", max: 10.9, min: 4.7 },
          { month: "April", max: 13.7, min: 5.0 },
          { month: "May", max: 18.2, min: 8.4 },
          { month: "June", max: 23.6, min: 12.2 },
          { month: "July", max: 21.3, min: 13.0 },
          { month: "August", max: 21.9, min: 13.1 },
          { month: "September", max: 22.6, min: 13.2 },
          { month: "October", max: 17.0, min: 9.7 },
          { month: "November", max: 11.1, min: 4.9 },
          { month: "December", max: 10.2, min: 5.2 },
        ];
      }

    const [options, setOptions] = useState({
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        data: getData(),
        series: [
          {
            type: "line",
            xKey: "month",
            xName: "Month",
            yKey: "min",
            yName: "Min Temperature",
            interpolation: { type: "smooth" },
          },
          {
            type: "line",
            xKey: "month",
            xName: "Month",
            yKey: "max",
            yName: "Max Temperature",
            interpolation: { type: "smooth" },
          },
        ],
      });
    return(
        <>
            <AgCharts options={options}/>        
        </>
    )
}

export default YearChart
import AddProductCategory from "../../../../components/page-components/AddProductCategoryType";

const ProductCategoryForm = ({ selectedData, formType }) => {
  return (
    <>
      <div
        className="offcanvas offcanvas-end offcanvas-large"
        tabindex="-1"
        id="offcanvas_add"
      >
        <AddProductCategory
          selectedData={selectedData}
          formType={formType}
        />
      </div>
    </>
  )
}

export default ProductCategoryForm

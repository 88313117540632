
import { useEffect, useRef, useState } from "react"
import "./style.css"

const CustomDropdown = ({
  options,
  label,
  id,
  selectedValue,
  placeholder,
  isDisabled,
  isSubmitData,
  clearInputStyle,
  inputType,
  setSelectedValue = () => {}
}) => {
  const [query, setQuery] = useState("")
  const [isOpen, setIsOpen] = useState(false)

  const inputRef = useRef(null)

  useEffect(() => {
    document.addEventListener("click", toggle)
    return () => document.removeEventListener("click", toggle)
  }, [])

  const selectOption = (option) => {
    setQuery("")
    setSelectedValue(option)
    setIsOpen(false)
  }

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current)
  }

  const getDisplayValue = () => {
    if (query) return query
    if (selectedValue) {
      return selectedValue[label]
    }
    return ""
  }

  const filter = (options) => {
    return options.filter(
      (option) => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1
    )
  }

  const handleClearClick = () => {
    setQuery("")
    setSelectedValue(null)
  }
   if (!isSubmitData){
    useEffect(() =>{
      if(!isOpen){
        setQuery("")
      }     
    },[isOpen])
  }

  const filteredOptions = filter(options? options:[])
  // style={{display: isSubmitData === true ? "none" :""}}
  return (
    <div className="Customdropdown">
      <div className="control">
        <div className={` ${isDisabled ? "disabledInput" : ""}`}>
          <input
            ref={inputRef}
            type={inputType ?? "text"}
            value={getDisplayValue()}
            name="searchTerm"
            onChange={(e) => {
              setQuery(e.target.value)
              setSelectedValue(null)
            }}
            onClick={toggle}
            placeholder={placeholder}
            disabled={isDisabled}
            className="form-control"
            autoFocus
            autoComplete="off"
          />          
        </div>        
        {
          <div className="clear-box">
            {
              !query ? (
                <div className={`arrow ${isOpen ? "open" : ""}`}></div>
              ):(
                <div className={`${clearInputStyle ? "clearInput" :"cross-icons"}`} onClick={handleClearClick}>&#10005;</div>
              ) 
            }
          </div>          
        }
      </div>

      <div className={`options ${isOpen ? "open" : ""}`} style={{display: isSubmitData && filteredOptions.length === 0 ? "none" :"" }} >
        {filteredOptions.length > 0 ? (
          filteredOptions.map((option, index) => (
            <div
              onClick={() => selectOption(option)}
              className={`option ${option[label] === selectedValue ? "selected" : ""}`}
              key={`${id}-${index}`}
            >
              {option[label]}
            </div>
          ))
        ) : (                  
          <div className="option-box">                                    
            <div className="no-records">No records found</div>            
          </div>
        )}
      </div>
    </div>
  )
}

export default CustomDropdown


import AddProductType from "../../../../components/page-components/AddProductType"

const ProductTypeForm = ({selectedData, formType}) => {  

  return (
    <>
      <div
        className="offcanvas offcanvas-end offcanvas-large"
        tabindex="-1"
        id="offcanvas_add">         

       <AddProductType
          formType={formType}
          selectedData={selectedData}         
       />
      </div>
    </>
  )
}

export default ProductTypeForm

import React, {useState} from 'react'
import VerticalMenuComponent from '../../components/VerticalMenuComponent'
import TopMenuComponent from '../../components/TopMenuComponent'
export default function BankAccounts() {

	const [isOpen, setIsOpen] = useState(false)
	const openSideNav = () => {
	  setIsOpen(!isOpen)
	}

  return (
    <>
    

     <div className={`main-wrapper ${isOpen && "slide-nav" }`}>
                    {/* header component  */}
                    <VerticalMenuComponent/>
                    <TopMenuComponent openSideNav={openSideNav}  />

                    {/* page wrapper  */}
                    <div className='page-wrapper'>
                            <div className='content'>

                            <div className="row">
					<div className="col-md-12">

						{/* <!-- Page Header --> */}
						<div className="page-header">
							<div className="row align-items-center">
								<div className="col-8">
									<h4 className="page-title">Settings</h4>
								</div>
								<div className="col-4 text-end">
									<div className="head-icons">
										<a href="bank-accounts.html" data-bs-toggle="tooltip" data-bs-placement="top"
											data-bs-original-title="Refresh"><i className="ti ti-refresh-dot"></i></a>
										<a href="javascript:void(0);" data-bs-toggle="tooltip" data-bs-placement="top"
											data-bs-original-title="Collapse" id="collapse-header"><i
												className="ti ti-chevrons-up"></i></a>
									</div>
								</div>
							</div>
						</div>
						{/* <!-- /Page Header --> */}

						{/* <!-- Settings Menu --> */}
						<div className="card">
							<div className="card-body pb-0 pt-2">
								<ul className="nav nav-tabs nav-tabs-bottom">
									<li className="nav-item me-3">
										<a href="profile.html" className="nav-link px-0">
											<i className="ti ti-settings-cog me-2"></i>General Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="company-settings.html" className="nav-link px-0">
											<i className="ti ti-world-cog me-2"></i>Website Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="invoice-settings.html" className="nav-link px-0">
											<i className="ti ti-apps me-2"></i>App Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="email-settings.html" className="nav-link px-0">
											<i className="ti ti-device-laptop me-2"></i>System Settings
										</a>
									</li>
									<li className="nav-item me-3">
										<a href="payment-gateways.html" className="nav-link px-0 active">
											<i className="ti ti-moneybag me-2"></i>Financial Settings
										</a>
									</li>
									<li className="nav-item">
										<a href="storage.html" className="nav-link px-0">
											<i className="ti ti-flag-cog me-2"></i>Other Settings
										</a>
									</li>
								</ul>
							</div>
						</div>
						{/* <!-- /Settings Menu --> */}

						<div className="row">
							<div className="col-xl-3 col-lg-12 theiaStickySidebar">

								{/* <!-- Settings Sidebar --> */}
								<div className="card">
									<div className="card-body">
										<div className="settings-sidebar">
											<h4 className="fw-semibold mb-3">Financial Settings</h4>
											<div className="list-group list-group-flush settings-sidebar">
												<a href="payment-gateways.html" className="fw-medium">Payment Gateways</a>
												<a href="bank-accounts.html" className="fw-medium active">Bank Accounts</a>
												<a href="tax-rates.html" className="fw-medium">Tax Rates</a>
												<a href="currencies.html" className="fw-medium">Currencies</a>
											</div>
										</div>
									</div>
								</div>
								{/* <!-- /Settings Sidebar --> */}

							</div>

							<div className="col-xl-9 col-lg-12">

								{/* <!-- Settings Info --> */}
								<div className="card">
									<div className="card-body pb-0">
										<div className="d-flex align-items-center justify-content-between mb-4">
											<h4 className="fs-20">Bank Accounts</h4>
											<a href="javascript:void(0)" className="btn btn-sm btn-icon rounded border"
												data-bs-toggle="modal" data-bs-target="#add_bank"><i
													className="ti ti-plus"></i></a>
										</div>
										<div className="row">

											{/* <!-- Bank Account --> */}
											<div className="col-xxl-4 col-sm-6">
												<div className="bank-box active">
													<div className="mb-4">
														<h5 className="fw-semibold mb-1">HDFC</h5>
														<p className="fw-medium">**** **** 4872</p>
													</div>
													<div className="d-flex align-items-center justify-content-between">
														<div>
															<h6 className="fw-medium mb-1">Holder Name</h6>
															<p className="fs-12">Darlee Robertson</p>
														</div>
														<div className="dropdown table-action">
															<a href="#" className="action-icon" data-bs-toggle="dropdown"
																aria-expanded="false"><i
																	className="fa fa-ellipsis-v"></i></a>
															<div className="dropdown-menu dropdown-menu-right">
																<a className="dropdown-item" href="#" data-bs-toggle="modal"
																	data-bs-target="#edit_bank"><i
																		className="fa-solid fa-pencil text-blue"></i>
																	Edit</a>
																<a className="dropdown-item" href="#" data-bs-toggle="modal"
																	data-bs-target="#delete_bank"><i
																		className="fa-regular fa-trash-can text-danger"></i>
																	Delete</a>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/* <!-- /Bank Account --> */}

											{/* <!-- Bank Account --> */}
											<div className="col-xxl-4 col-sm-6">
												<div className="bank-box">
													<div className="mb-4">
														<h5 className="fw-semibold mb-1">SBI</h5>
														<p className="fw-medium">**** **** 2495</p>
													</div>
													<div className="d-flex align-items-center justify-content-between">
														<div>
															<h6 className="fw-medium mb-1">Holder Name</h6>
															<p className="fs-12">Sharon Roy</p>
														</div>
														<div className="dropdown table-action">
															<a href="#" className="action-icon" data-bs-toggle="dropdown"
																aria-expanded="false"><i
																	className="fa fa-ellipsis-v"></i></a>
															<div className="dropdown-menu dropdown-menu-right">
																<a className="dropdown-item" href="#" data-bs-toggle="modal"
																	data-bs-target="#edit_bank"><i
																		className="fa-solid fa-pencil text-blue"></i>
																	Edit</a>
																<a className="dropdown-item" href="#" data-bs-toggle="modal"
																	data-bs-target="#delete_bank"><i
																		className="fa-regular fa-trash-can text-danger"></i>
																	Delete</a>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/* <!-- /Bank Account --> */}

											{/* <!-- Bank Account --> */}
											<div className="col-xxl-4 col-sm-6">
												<div className="bank-box">
													<div className="mb-4">
														<h5 className="fw-semibold mb-1">KVB</h5>
														<p className="fw-medium">**** **** 3948</p>
													</div>
													<div className="d-flex align-items-center justify-content-between">
														<div>
															<h6 className="fw-medium mb-1">Holder Name</h6>
															<p className="fs-12">Vaughan</p>
														</div>
														<div className="dropdown table-action">
															<a href="#" className="action-icon" data-bs-toggle="dropdown"
																aria-expanded="false"><i
																	className="fa fa-ellipsis-v"></i></a>
															<div className="dropdown-menu dropdown-menu-right">
																<a className="dropdown-item" href="#" data-bs-toggle="modal"
																	data-bs-target="#edit_bank"><i
																		className="fa-solid fa-pencil text-blue"></i>
																	Edit</a>
																<a className="dropdown-item" href="#" data-bs-toggle="modal"
																	data-bs-target="#delete_bank"><i
																		className="fa-regular fa-trash-can text-danger"></i>
																	Delete</a>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/* <!-- /Bank Account --> */}

										</div>
									</div>
								</div>
								{/* <!-- /Settings Info --> */}

							</div>
						</div>

					</div>
				</div>





                            </div>
                    </div>



                    {/* <!-- Add Bank Account --> */}
		<div className="modal fade" id="add_bank" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Add Bank Account</h5>
						<div className="d-flex align-items-center">
							<div className="status-toggle me-2">
								<input type="checkbox" id="toggle" className="check" checked="" />
								<label for="toggle" className="checktoggle"></label>
							</div>
							<button className="btn-close custom-btn-close border p-1 me-0 text-dark" data-bs-dismiss="modal"
								aria-label="Close">
								<i className="ti ti-x"></i>
							</button>
						</div>
					</div>
					<form action="bank-accounts.html">
						<div className="modal-body">
							<div className="mb-3">
								<label className="col-form-label">Name <span className="text-danger">*</span></label>
								<input type="text" className="form-control"/>
							</div>
							<div className="mb-3">
								<label className="col-form-label">Bank <span className="text-danger">*</span></label>
								<input type="text" className="form-control"/>
							</div>
							<div className="mb-3">
								<label className="col-form-label">Branch <span className="text-danger">*</span></label>
								<input type="text" className="form-control"/>
							</div>
							<div className="mb-3">
								<label className="col-form-label">Account Number <span className="text-danger">*</span></label>
								<input type="text" className="form-control"/>
							</div>
							<div className="mb-0">
								<label className="col-form-label">IFSC Code <span className="text-danger">*</span></label>
								<input type="text" className="form-control"/>
							</div>
						</div>
						<div className="modal-footer">
							<div className="d-flex align-items-center justify-content-end m-0">
								<a href="#" className="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Save</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		{/* <!-- /Add Bank Account --> */}

		{/* <!-- Edit Bank Account --> */}
		<div className="modal fade" id="edit_bank" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Edit Bank Account</h5>
						<div className="d-flex align-items-center">
							<div className="status-toggle me-2">
								<input type="checkbox" id="toggle1" className="check" checked=""/>
								<label for="toggle1" className="checktoggle"></label>
							</div>
							<button className="btn-close custom-btn-close border p-1 me-0 text-dark" data-bs-dismiss="modal"
								aria-label="Close">
								<i className="ti ti-x"></i>
							</button>
						</div>
					</div>
					<form action="bank-accounts.html">
						<div className="modal-body">
							<div className="mb-3">
								<label className="col-form-label">Name <span className="text-danger">*</span></label>
								<input type="text" className="form-control" value="Darlee Robertson"/>
							</div>
							<div className="mb-3">
								<label className="col-form-label">Bank <span className="text-danger">*</span></label>
								<input type="text" className="form-control" value="HDFC" />
							</div>
							<div className="mb-3">
								<label className="col-form-label">Branch <span className="text-danger">*</span></label>
								<input type="text" className="form-control" value="Bringham" />
							</div>
							<div className="mb-3">
								<label className="col-form-label">Account Number <span className="text-danger">*</span></label>
								<input type="text" className="form-control" value="**** **** 4872" />
							</div>
							<div className="mb-0">
								<label className="col-form-label">IFSC Code <span className="text-danger">*</span></label>
								<input type="text" className="form-control" value="198367" />
							</div>
						</div>
						<div className="modal-footer">
							<div className="d-flex align-items-center justify-content-end m-0">
								<a href="#" className="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
								<button type="submit" className="btn btn-primary">Save</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
		{/* <!-- /Edit Bank Account --> */}

		{/* <!-- Delete Contact --> */}
		<div className="modal fade" id="delete_bank" role="dialog">
			<div className="modal-dialog modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-body">
						<div className="text-center">
							<div className="avatar avatar-xl bg-danger-light rounded-circle mb-3">
								<i className="ti ti-trash-x fs-36 text-danger"></i>
							</div>
							<h4 className="mb-2">Remove Bank</h4>
							<p className="mb-0">Are you sure you want to remove <br/> bank you selected.</p>
							<div className="d-flex align-items-center justify-content-center mt-4">
								<a href="#" className="btn btn-light me-2" data-bs-dismiss="modal">Cancel</a>
								<a href="bank-accounts.html" className="btn btn-danger">Yes, Delete it</a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		{/* <!-- /Delete Contact --> */}





        </div>

    
    
    
    </>
  )
}

import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"
export default function SmsGateway() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h4 className="page-title">Settings</h4>
                    </div>
                    <div className="col-4 text-end">
                      <div className="head-icons">
                        <a
                          href="sms-gateways.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                {/* <!-- Settings Menu --> */}
                <div className="card">
                  <div className="card-body pb-0 pt-2">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item me-3">
                        <a href="profile.html" className="nav-link px-0">
                          <i className="ti ti-settings-cog me-2"></i>General
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="company-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-world-cog me-2"></i>Website
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="invoice-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-apps me-2"></i>App Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="email-settings.html"
                          className="nav-link px-0 active"
                        >
                          <i className="ti ti-device-laptop me-2"></i>System
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="payment-gateways.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-moneybag me-2"></i>Financial
                          Settings
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="storage.html" className="nav-link px-0">
                          <i className="ti ti-flag-cog me-2"></i>Other Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /Settings Menu --> */}

                <div className="row">
                  <div className="col-xl-3 col-lg-12 theiaStickySidebar">
                    {/* <!-- Settings Sidebar --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="settings-sidebar">
                          <h4 className="fw-semibold mb-3">Other Settings</h4>
                          <div className="list-group list-group-flush settings-sidebar">
                            <a href="email-settings.html" className="fw-medium">
                              Email Settings
                            </a>
                            <a
                              href="sms-gateways.html"
                              className="fw-medium active"
                            >
                              SMS Gateways
                            </a>
                            <a href="gdpr-cookies.html" className="fw-medium">
                              GDPR Cookies
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Sidebar --> */}
                  </div>

                  <div className="col-xl-9 col-lg-12">
                    {/* <!-- Settings Info --> */}
                    <div className="card">
                      <div className="card-body pb-0">
                        <div className="mb-4">
                          <h4 className="fs-20">SMS Gateways</h4>
                        </div>
                        <div className="row">
                          {/* <!-- Gateway Wrap --> */}
                          <div className="col-xxl-4 col-sm-6">
                            <div className="border rounded d-flex align-items-center justify-content-between p-3 mb-3">
                              <div>
                                <img
                                  src="/img/icons/gateway-01.svg"
                                  alt="img"
                                />
                              </div>
                              <div className="d-flex align-items-center">
                                <a
                                  href="javascript:void(0);"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add_nexmo"
                                >
                                  <i className="ti ti-settings fs-20"></i>
                                </a>
                                <div className="status-toggle ms-3">
                                  <input
                                    type="checkbox"
                                    id="sms"
                                    className="check"
                                    checked=""
                                  />
                                  <label for="sms" className="checktoggle">
                                    {" "}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /Gateway Wrap --> */}

                          {/* <!-- Gateway Wrap --> */}
                          <div className="col-xxl-4 col-sm-6">
                            <div className="border rounded d-flex align-items-center justify-content-between p-3 mb-3">
                              <div>
                                <img
                                  src="/img/icons/gateway-02.svg"
                                  alt="img"
                                />
                              </div>
                              <div className="d-flex align-items-center">
                                <a
                                  href="javascript:void(0);"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add_factor"
                                >
                                  <i className="ti ti-settings fs-20"></i>
                                </a>
                                <div className="status-toggle ms-3">
                                  <input
                                    type="checkbox"
                                    id="sms1"
                                    className="check"
                                    checked=""
                                  />
                                  <label for="sms1" className="checktoggle">
                                    {" "}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /Gateway Wrap --> */}

                          {/* <!-- Gateway Wrap --> */}
                          <div className="col-xxl-4 col-sm-6">
                            <div className="border rounded d-flex align-items-center justify-content-between p-3 mb-3">
                              <div>
                                <img
                                  src="/img/icons/gateway-03.svg"
                                  alt="img"
                                />
                              </div>
                              <div className="d-flex align-items-center">
                                <a
                                  href="javascript:void(0);"
                                  data-bs-toggle="modal"
                                  data-bs-target="#add_twilio"
                                >
                                  <i className="ti ti-settings fs-20"></i>
                                </a>
                                <div className="status-toggle ms-3">
                                  <input
                                    type="checkbox"
                                    id="loc"
                                    className="check"
                                    checked=""
                                  />
                                  <label for="loc" className="checktoggle">
                                    {" "}
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /Gateway Wrap --> */}
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Info --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Nexmo --> */}
        <div className="modal custom-modal fade" id="add_nexmo" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Nexmo</h5>
                <div className="d-flex align-items-center mod-toggle">
                  <div className="status-toggle">
                    <input
                      type="checkbox"
                      id="toggle"
                      className="check"
                      checked=""
                    />
                    <label for="toggle" className="checktoggle"></label>
                  </div>
                  <button
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <form action="sms-gateways.html">
                  <div className="mb-3">
                    <label className="col-form-label">
                      API Key <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      API Secret Key <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Sender ID <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="modal-btn">
                    <a
                      href="#"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Nexmo --> */}

        {/* <!-- Add 2Factor --> */}
        <div className="modal custom-modal fade" id="add_factor" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">2Factor</h5>
                <div className="d-flex align-items-center mod-toggle">
                  <div className="status-toggle">
                    <input
                      type="checkbox"
                      id="toggle1"
                      className="check"
                      checked=""
                    />
                    <label for="toggle1" className="checktoggle"></label>
                  </div>
                  <button
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    <i className="ti ti-x"></i>
                  </button>
                </div>
              </div>
              <div className="modal-body">
                <form action="sms-gateways.html">
                  <div className="mb-3">
                    <label className="col-form-label">
                      API Key <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      API Secret Key <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Sender ID <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="modal-btn">
                    <a
                      href="#"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Add 2Factor --> */}

        {/* <!-- Add Twilio --> */}
        <div className="modal custom-modal fade" id="add_twilio" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Twilio</h5>
                <button
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x"></i>
                </button>
              </div>
              <div className="modal-body">
                <form action="sms-gateways.html">
                  <div className="mb-3">
                    <label className="col-form-label">
                      API Key <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      API Secret Key <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Sender ID <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="modal-btn">
                    <a
                      href="#"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Add Twilio --> */}
      </div>
    </>
  )
}

import React,{useState} from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"
export default function CompanySettings() {

  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav" }`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h4 className="page-title">Settings</h4>
                    </div>
                    <div className="col-4 text-end">
                      <div className="head-icons">
                        <a
                          href="company-settings.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                {/* <!-- Settings Menu --> */}
                <div className="card">
                  <div className="card-body pb-0 pt-2">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item me-3">
                        <a href="profile.html" className="nav-link px-0">
                          <i className="ti ti-settings-cog me-2"></i>General
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="company-settings.html"
                          className="nav-link px-0 active"
                        >
                          <i className="ti ti-world-cog me-2"></i>Website
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="invoice-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-apps me-2"></i>App Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a href="email-settings.html" className="nav-link px-0">
                          <i className="ti ti-device-laptop me-2"></i>System
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="payment-gateways.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-moneybag me-2"></i>Financial
                          Settings
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="storage.html" className="nav-link px-0">
                          <i className="ti ti-flag-cog me-2"></i>Other Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /Settings Menu --> */}

                <div className="row">
                  <div className="col-xl-3 col-lg-12 theiaStickySidebar">
                    {/* <!-- Settings Sidebar --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="settings-sidebar">
                          <h4 className="fw-semibold mb-3">Website Settings</h4>
                          <div className="list-group list-group-flush settings-sidebar">
                            <a
                              href="company-settings.html"
                              className="fw-medium active"
                            >
                              Company Settings
                            </a>
                            <a href="localization.html" className="fw-medium">
                              Localization
                            </a>
                            <a href="prefixes.html" className="fw-medium">
                              Prefixes
                            </a>
                            <a href="preference.html" className="fw-medium">
                              Preference
                            </a>
                            <a href="appearance.html" className="fw-medium">
                              Appearance
                            </a>
                            <a href="language.html" className="fw-medium">
                              Language
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Sidebar --> */}
                  </div>

                  <div className="col-xl-9 col-lg-12">
                    {/* <!-- Company Settings --> */}
                    <div className="card">
                      <div className="card-body">
                        <h4 className="fw-semibold mb-3">Company Settings</h4>
                        <form action="company-settings.html">
                          <div className="border-bottom mb-3 pb-3">
                            <h5 className="fw-semibold mb-1">
                              Company Information
                            </h5>
                            <p>Provide the company information below</p>
                          </div>
                          <div className="border-bottom mb-3 ">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Company Name{" "}
                                  </label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Company Email Address
                                  </label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Phone Number
                                  </label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">Fax</label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">Website</label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-bottom mb-3 pb-3">
                            <h5 className="fw-semibold mb-1">Company Images</h5>
                            <p>Provide the company images</p>
                          </div>
                          <div className="border-bottom mb-3">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <div className="profile-upload">
                                    <div className="profile-upload-img">
                                      <span>
                                        <i className="ti ti-photo"></i>
                                      </span>
                                      <img
                                        src="/img/icons/company-icon-03.svg"
                                        alt="img"
                                        className="preview1"
                                      />
                                      <button
                                        type="button"
                                        className="profile-remove"
                                      >
                                        <i className="feather-x"></i>
                                      </button>
                                    </div>
                                    <div className="profile-upload-content">
                                      <label className="profile-upload-btn">
                                        <i className="ti ti-file-broken"></i>{" "}
                                        Upload File
                                        <input
                                          type="file"
                                          className="input-img"
                                        />
                                      </label>
                                      <p>
                                        Upload Logo of your company to display
                                        in website. JPG or PNG. Max size of 800K
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <div className="profile-upload">
                                    <div className="profile-upload-img">
                                      <span>
                                        <i className="ti ti-photo"></i>
                                      </span>
                                      <img
                                        src="/img/icons/company-icon-03.svg"
                                        alt="img"
                                        className="preview1"
                                      />
                                      <button
                                        type="button"
                                        className="profile-remove"
                                      >
                                        <i className="feather-x"></i>
                                      </button>
                                    </div>
                                    <div className="profile-upload-content">
                                      <label className="profile-upload-btn">
                                        <i className="ti ti-file-broken"></i>{" "}
                                        Upload File
                                        <input
                                          type="file"
                                          className="input-img"
                                        />
                                      </label>
                                      <p>
                                        Upload Logo of your company to display
                                        in website. JPG or PNG. Max size of 800K
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <div className="profile-upload">
                                    <div className="profile-upload-img">
                                      <span>
                                        <i className="ti ti-photo"></i>
                                      </span>
                                      <img
                                        src="/img/icons/company-icon-03.svg"
                                        alt="img"
                                        className="preview1"
                                      />
                                      <button
                                        type="button"
                                        className="profile-remove"
                                      >
                                        <i className="feather-x"></i>
                                      </button>
                                    </div>
                                    <div className="profile-upload-content">
                                      <label className="profile-upload-btn">
                                        <i className="ti ti-file-broken"></i>{" "}
                                        Upload File
                                        <input
                                          type="file"
                                          className="input-img"
                                        />
                                      </label>
                                      <p>
                                        Upload Logo of your company to display
                                        in website. JPG or PNG. Max size of 800K
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-3">
                                  <div className="profile-upload">
                                    <div className="profile-upload-img">
                                      <span>
                                        <i className="ti ti-photo"></i>
                                      </span>
                                      <img
                                        src="/img/icons/company-icon-03.svg"
                                        alt="img"
                                        className="preview1"
                                      />
                                      <button
                                        type="button"
                                        className="profile-remove"
                                      >
                                        <i className="feather-x"></i>
                                      </button>
                                    </div>
                                    <div className="profile-upload-content">
                                      <label className="profile-upload-btn">
                                        <i className="ti ti-file-broken"></i>{" "}
                                        Upload File
                                        <input
                                          type="file"
                                          className="input-img"
                                        />
                                      </label>
                                      <p>
                                        Upload Logo of your company to display
                                        in website. JPG or PNG. Max size of 800K
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="border-bottom mb-3 pb-3">
                            <h5 className="fw-semibold mb-1">Address</h5>
                            <p>Please enter the company address details</p>
                          </div>
                          <div className="border-bottom mb-3">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="mb-3">
                                  <label className="form-label">Address</label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                              <div className="col-xl-3 col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">Country</label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                              <div className="col-xl-3 col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">
                                    State / Province
                                  </label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                              <div className="col-xl-3 col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">Fax</label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                              <div className="col-xl-3 col-md-4">
                                <div className="mb-3">
                                  <label className="form-label">
                                    Postal Code
                                  </label>
                                  <input type="text" className="form-control" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <a href="#" className="btn btn-light me-2">
                              Cancel
                            </a>
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!-- /Company Settings --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

import React,{useState} from 'react'
import VerticalMenuComponent from '../../components/VerticalMenuComponent'
import TopMenuComponent from '../../components/TopMenuComponent'
import CustomDataTableComponent from '../../components/page-components/CustomDataTableComponent'
import { AgCharts } from 'ag-charts-react'
import HorizontalStageChart from '../../components/page-components/charts/HorizontalStageChart'
import TopPageHeader from '../../components/TopPageHeader'

export default function  ProjectDashboard() {

	const [isOpen, setIsOpen] = useState(false)
	const openSideNav = () => {
	  setIsOpen(!isOpen)
	}

	const Data = [
		{
		  id: 1,
		  contacts_name: "Collins",
		  company_name: "NovaWave LLC",
		  company_image: "assets/img/icons/company-icon-01.svg",
		  company_address: "Newyork, USA",
		  phone: "+1 875455453",
		  email: "robertson@example.com",
		  created_date: "25 Sep 2023, 01:22 pm",
		  owner: ["Hendry", "Two", "Three"],
		  source: "Paid Social",
		  status: "0",
		  teamMember:["Hendry", "Two", "Three"]
		},
		{
		  id: 2,
		  contacts_name: "Konopelski",
		  company_name: "BlueSky Industries",
		  company_image: "assets/img/icons/company-icon-02.svg",
		  company_address: "Winchester, KY",
		  phone: "+1 989757485",
		  email: "sharon@example.com",
		  created_date: "29 Sep 2023, 04:15 pm",
		  owner: "Guillory",
		  source: "Referrals",
		  status: "2",
		  teamMember:["asdasHendry", "asfTwo", "Three"]
	  
		},
	  
		{
		  id: 3,
		  contacts_name: "ravi one",
		  company_name: "BlueSky Industries",
		  company_image: "assets/img/icons/company-icon-02.svg",
		  company_address: "Winchester, KY",
		  phone: "+1 989757485",
		  email: "sharon@example.com",
		  created_date: "29 Sep 2023, 04:15 pm",
		  owner: "Guillory",
		  source: "Referrals",
		  status: "2",
		  teamMember:["asdasHendry", "asfTwo"]
		},
	  
		{
		  id: 4,
		  contacts_name: "ravi two",
		  company_name: "BlueSky Industries",
		  company_image: "assets/img/icons/company-icon-02.svg",
		  company_address: "Winchester, KY",
		  phone: "+1 989757485",
		  email: "sharon@example.com",
		  created_date: "29 Sep 2023, 04:15 pm",
		  owner: "Guillory",
		  source: "Referrals",
		  status: "2",
		  teamMember:["asdasHendry", "Team Member"]
		},
	  
		{
		  id: 5,
		  contacts_name: "ravi three",
		  company_name: "BlueSky Industries",
		  company_image: "assets/img/icons/company-icon-02.svg",
		  company_address: "Winchester, KY",
		  phone: "+1 989757485",
		  email: "sharon@example.com",
		  created_date: "29 Sep 2023, 04:15 pm",
		  owner: "Guillory",
		  source: "Referrals",
		  status: "2",
		  teamMember:["asdasHendry", "Team Member"]
	  
		},
	  
		{
		  id: 6,
		  contacts_name: "ravi four",
		  company_name: "BlueSky Industries",
		  company_image: "assets/img/icons/company-icon-02.svg",
		  company_address: "Winchester, KY",
		  phone: "+1 989757485",
		  email: "sharon@example.com",
		  created_date: "29 Sep 2023, 04:15 pm",
		  owner: "Guillory",
		  source: "Referrals",
		  status: "2",
		  teamMember:["asdasHendry", "Team Member"]
	  
		},
	  ]

	  const columns = [    
		{
		  name: "Priority",
		  selector: (row) => row.contacts_name,
		  cell: (row) => (
			<div className="d-flex flex-column">
			  <span className="text-default">{row.contacts_name}</span>
			</div>
		  ),
		  sortable: true,
		},
	
		{
		  name: "Name",
		  selector: (row) => row.company_name,
		  cell: (row) => (
			<div className="d-flex flex-column">
			  <span className="text-default">{row.company_name}</span>            
			</div>
		  ),
		  sortable: true,
		},
	
		{
		  name: "Client",
		  selector: (row) => row.contacts_name,
		  sortable: true,
		},	
		{
			name: "Due Date",
			selector: (row) => row.created_date,
			sortable: true,
		  },	
	
    
	  ]

	  const projectStageData = [
		{ asset: "Campaign", amount: 44 },
		{ asset: "Google", amount: 55 },
		{ asset: "Referrals", amount: 41 },
		{ asset: "Paid Socials", amount: 17 },		
	  ]

	  const [options, setOptions] = useState({
		data: projectStageData,
		title: {
		  text: "",
		},
		series: [
		  {
			type: "donut",
			calloutLabelKey: "asset",
			angleKey: "amount",
			innerRadiusRatio: 0.7,
		  },
		],
	  })

	  function getData() {
			return [
			{
				age: 20,
			},
			{
				age: 25,
			},
			{
				age: 19,
			},
			{
				age: 28,
			},
			{
				age: 31,
			},
			{
				age: 24,
			},
			{
				age: 21,
			},
			{
				age: 21,
			},
			{
				age: 22,
			},
			{
				age: 27,
			},
			{
				age: 25,
			},
			{
				age: 25,
			},
			{
				age: 24,
			},
			{
				age: 21,
			},
			{
				age: 19,
			},
			{
				age: 25,
			},
			{
				age: 27,
			},
			{
				age: 22,
			},
			{
				age: 26,
			},
			{
				age: 20,
			},
			{
				age: 21,
			},
			{
				age: 33,
			},
			{
				age: 27,
			},
			{
				age: 23,
			},
			{
				age: 29,
			},
			{
				age: 24,
			},
			{
				age: 23,
			},
			{
				age: 20,
			},
			{
				age: 25,
			},
			{
				age: 22,
			},
			{
				age: 21,
			},
			]
	}

	  const [histogramOptions, setHistogramOptions] = useState({
		title: {
		  text: "Race demographics",
		},
		data: getData(),
		series: [
		  {
			type: "histogram",
			xKey: "age",
			xName: "Participant Age",
		  },
		],
		axes: [
		  {
			type: "number",
			position: "bottom",
			title: { text: "Age band (years)" },
			interval: { step: 2 },
		  },
		  {
			type: "number",
			position: "left",
			title: { text: "Number of participants" },
		  },
		],
	  })

  return (
    
    <>
    
    <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
                    {/* header component  */}
                    <VerticalMenuComponent/>
                    <TopMenuComponent openSideNav={openSideNav} />
                    {/* page wrapper  */}
        <div className='page-wrapper'>
            <div className='content'>   

			<div className="row">
                <div className="col-md-12">
                    <div className="page-header">
                        <TopPageHeader pageTitle="Project Dashboard"/>
                    </div>
                </div>
            </div>
                

				<div className="row">
					<div className="col-md-7">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Recent Projects</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-3">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												<i className="ti ti-calendar-check me-2"></i>Last 7 days
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 days
												</a>
											</div>
										</div>
										<a className="btn btn-primary" href="javascript:void(0);" data-bs-toggle="offcanvas"
											data-bs-target="#offcanvas_add">
											<i className="ti ti-square-rounded-plus me-1"></i>Add Project
										</a>
									</div>
								</div>

							</div>
							<div className="card-body">
								<CustomDataTableComponent rowData={Data} columns={columns}/>								
							</div>
						</div>
					</div>
					<div className="col-md-5 d-flex">
						<div className="card w-100">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Project By Stage</h4>
									<div className="dropdown">
										<a className="dropdown-toggle" data-bs-toggle="dropdown" href="javascript:void(0);">
											<i className="ti ti-calendar-check me-2"></i>Last 30 days
										</a>
										<div className="dropdown-menu dropdown-menu-end">
											<a href="javascript:void(0);" className="dropdown-item">
												Last 15 days
											</a>
											<a href="javascript:void(0);" className="dropdown-item">
												Last 30 days
											</a>
										</div>
									</div>
								</div>

							</div>
							<div className="card-body">
									<AgCharts options={options}/>								
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-md-6 h-50">
						<div className="card w-100">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Projects By Stage</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Sales Pipeline
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Marketing Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Sales Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Email
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Chats
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Operational
												</a>
											</div>
										</div>
										<div className="dropdown">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 3 months
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
								<AgCharts options={histogramOptions} />
									{/* <AgCharts options={reversePyramid}/> */}
							</div>
						</div>
					</div>
					<div className="col-md-6 ">
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Leads By Stage</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Marketing Pipeline
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Marketing Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Sales Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Email
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Chats
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Operational
												</a>
											</div>
										</div>
										<div className="dropdown">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 3 months
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
								<HorizontalStageChart/>
							</div>
						</div>
						<div className="card">
							<div className="card-header border-0 pb-0">
								<div className="d-flex align-items-center justify-content-between flex-wrap row-gap-3">
									<h4><i className="ti ti-grip-vertical me-1"></i>Won Deals Stage</h4>
									<div className="d-flex align-items-center flex-wrap row-gap-2">
										<div className="dropdown me-2">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Marketing Pipeline
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Marketing Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Sales Pipeline
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Email
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Chats
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Operational
												</a>
											</div>
										</div>
										<div className="dropdown">
											<a className="dropdown-toggle" data-bs-toggle="dropdown"
												href="javascript:void(0);">
												Last 3 months
											</a>
											<div className="dropdown-menu dropdown-menu-end">
												<a href="javascript:void(0);" className="dropdown-item">
													Last 30 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 15 Days
												</a>
												<a href="javascript:void(0);" className="dropdown-item">
													Last 7 Days
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="card-body">
								<HorizontalStageChart/>
							</div>
						</div>
					</div>
				</div>




                            </div>
                    </div>


                    {/* <!-- Add New Project --> */}
		<div className="offcanvas offcanvas-end offcanvas-large" tabindex="-1" id="offcanvas_add">
			<div className="offcanvas-header border-bottom">
				<h4>Add New Project</h4>
				<button type="button"
					className="btn-close custom-btn-close border p-1 me-0 d-flex align-items-center justify-content-center rounded-circle"
					data-bs-dismiss="offcanvas" aria-label="Close">
					<i className="ti ti-x"></i>
				</button>
			</div>
			<div className="offcanvas-body">
				<form action="project-dashboard.html">
					<div>
						<div className="row">
							<div className="col-md-12">
								<div className="mb-3">
									<label className="col-form-label">Name <span className="text-danger">*</span></label>
									<input type="text" className="form-control"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Project ID<span className="text-danger"> *</span></label>
									<input className="form-control" type="text"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Project Type <span
											className="text-danger">*</span></label>
									<select className="select2">
										<option>Choose</option>
										<option>Mobile App</option>
										<option>Meeting</option>
									</select>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Client <span className="text-danger">*</span></label>
									<select className="select">
										<option>Select</option>
										<option>NovaWave LLC</option>
										<option>Silver Hawk</option>
										<option>Harbor View</option>
									</select>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Category <span className="text-danger">*</span></label>
									<select className="select">
										<option>Select</option>
										<option>Harbor View</option>
										<option>LLC</option>
									</select>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Project Timing <span
											className="text-danger">*</span></label>
									<select className="select">
										<option>Select</option>
										<option>Hourly</option>
										<option>Weekly</option>
										<option>Monthly</option>
										<option>Less than 1 Month</option>
										<option>Less than 3 months</option>
									</select>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Price <span className="text-danger">*</span></label>
									<input className="form-control" type="text"/>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Amount <span className="text-danger">*</span></label>
									<input className="form-control" type="text"/>
								</div>
							</div>
							<div className="col-lg-3 col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Total <span className="text-danger">*</span></label>
									<input className="form-control" type="text"/>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Responsible Persons <span
											className="text-danger">*</span></label>
									<select className="multiple-img" multiple="multiple">
										<option data-image="/img/profiles/avatar-19.jpg" selected>Darlee Robertson
										</option>
										<option data-image="/img/profiles/avatar-20.jpg">Sharon Roy</option>
										<option data-image="/img/profiles/avatar-21.jpg">Vaughan</option>
										<option data-image="/img/profiles/avatar-23.jpg">Jessica</option>
										<option data-image="/img/profiles/avatar-16.jpg">Carol Thomas</option>
									</select>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Team Leader <span className="text-danger">*</span></label>
									<select className="multiple-img" multiple="multiple">
										<option data-image="/img/profiles/avatar-19.jpg">Darlee Robertson</option>
										<option data-image="/img/profiles/avatar-20.jpg" selected>Sharon Roy
										</option>
										<option data-image="/img/profiles/avatar-21.jpg">Vaughan</option>
										<option data-image="/img/profiles/avatar-23.jpg">Jessica</option>
										<option data-image="/img/profiles/avatar-16.jpg">Carol Thomas</option>
									</select>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Start Date <span className="text-danger">*</span></label>
									<div className="icon-form">
										<span className="form-icon"><i className="ti ti-calendar-event"></i></span>
										<input type="text" className="form-control datetimepicker" value="29-02-2020"/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Due Date <span className="text-danger">*</span></label>
									<div className="icon-form">
										<span className="form-icon"><i className="ti ti-calendar-event"></i></span>
										<input type="text" className="form-control datetimepicker" value="29-02-2020"/>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Priority</label>
									<select className="select">
										<option>Select</option>
										<option>High</option>
										<option>Low</option>
										<option>Medium</option>
									</select>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="col-form-label">Status</label>
									<select className="select">
										<option>Select</option>
										<option>Active</option>
										<option>Inactive</option>
									</select>
								</div>
							</div>
							<div className="col-md-12">
								<div className="mb-3">
									<label className="col-form-label">Description <span className="text-danger">*</span></label>
									<textarea className="form-control" rows="4"></textarea>
								</div>
							</div>
						</div>
					</div>
					<div className="d-flex align-items-center justify-content-end">
						<button type="button" data-bs-dismiss="offcanvas" className="btn btn-light me-2">Cancel</button>
						<button type="submit" className="btn btn-primary">Create</button>
					</div>
				</form>
			</div>
		</div>
		{/* <!-- /Add New Project --> */}





        </div>
    
    
    
    
    </>


  )
}

import React, { useState } from "react"
import VerticalMenuComponent from "../../components/VerticalMenuComponent"
import TopMenuComponent from "../../components/TopMenuComponent"
export default function CustomFields() {
  const [isOpen, setIsOpen] = useState(false)
  const openSideNav = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <div className={`main-wrapper ${isOpen && "slide-nav"}`}>
        {/* header component  */}
        <VerticalMenuComponent />
        <TopMenuComponent openSideNav={openSideNav} />

        {/* page wrapper  */}
        <div className="page-wrapper">
          <div className="content">
            <div className="row">
              <div className="col-md-12">
                {/* <!-- Page Header --> */}
                <div className="page-header">
                  <div className="row align-items-center">
                    <div className="col-8">
                      <h4 className="page-title">Settings</h4>
                    </div>
                    <div className="col-4 text-end">
                      <div className="head-icons">
                        <a
                          href="custom-fields.html"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Refresh"
                        >
                          <i className="ti ti-refresh-dot"></i>
                        </a>
                        <a
                          href="javascript:void(0);"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          data-bs-original-title="Collapse"
                          id="collapse-header"
                        >
                          <i className="ti ti-chevrons-up"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Page Header --> */}

                {/* <!-- Settings Menu --> */}
                <div className="card">
                  <div className="card-body pb-0 pt-2">
                    <ul className="nav nav-tabs nav-tabs-bottom">
                      <li className="nav-item me-3">
                        <a href="profile.html" className="nav-link px-0">
                          <i className="ti ti-settings-cog me-2"></i>General
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="company-settings.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-world-cog me-2"></i>Website
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="invoice-settings.html"
                          className="nav-link px-0 active"
                        >
                          <i className="ti ti-apps me-2"></i>App Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a href="email-settings.html" className="nav-link px-0">
                          <i className="ti ti-device-laptop me-2"></i>System
                          Settings
                        </a>
                      </li>
                      <li className="nav-item me-3">
                        <a
                          href="payment-gateways.html"
                          className="nav-link px-0"
                        >
                          <i className="ti ti-moneybag me-2"></i>Financial
                          Settings
                        </a>
                      </li>
                      <li className="nav-item">
                        <a href="storage.html" className="nav-link px-0">
                          <i className="ti ti-flag-cog me-2"></i>Other Settings
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* <!-- /Settings Menu --> */}

                <div className="row">
                  <div className="col-xl-3 col-lg-12 theiaStickySidebar">
                    {/* <!-- Settings Sidebar --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="settings-sidebar">
                          <h4 className="fw-semibold mb-3">App Settings</h4>
                          <div className="list-group list-group-flush settings-sidebar">
                            <a
                              href="invoice-settings.html"
                              className="fw-medium"
                            >
                              Invoice Settings
                            </a>
                            <a href="printers.html" className="fw-medium">
                              Printer
                            </a>
                            <a
                              href="custom-fields.html"
                              className="fw-medium active"
                            >
                              Custom Fields
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <!-- /Settings Sidebar --> */}
                  </div>

                  <div className="col-xl-9 col-lg-12">
                    {/* <!-- Custom Fields --> */}
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                          <h4>Custom Fields</h4>
                          <a
                            href="javascript:void(0)"
                            className="btn btn-icon btn-sm border btn-outline-light"
                            data-bs-toggle="modal"
                            data-bs-target="#add_fields"
                          >
                            <i className="ti ti-plus"></i>
                          </a>
                        </div>
                        <form action="custom-fields.html">
                          {/* <!-- Expense --> */}
                          <div className="d-flex align-items-center justify-content-between border-bottom mb-3 pb-3">
                            <div>
                              <h5 className="fw-semibold mb-1">Expense</h5>
                              <p>Shows the fields of Expense</p>
                            </div>
                            <div className="dropdown">
                              <a
                                href="#"
                                className="btn btn-icon btn-sm border btn-outline-light d-inline-flex align-items-center justify-content-center"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item d-flex align-items-center"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_fields"
                                >
                                  <i className="fa-solid fa-pencil text-blue me-2"></i>
                                  Edit
                                </a>
                                <a
                                  className="dropdown-item d-flex align-items-center"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_fields"
                                >
                                  <i className="fa-regular fa-trash-can text-danger me-2"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="border-bottom mb-3">
                            <div className="row">
                              <div className="col-md-4 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Label
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Name"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">Type</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Text"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Default Value
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Name"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Required
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Required"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Status
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Active"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /Expense --> */}

                          {/* <!-- Transaction --> */}
                          <div className="d-flex align-items-center justify-content-between border-bottom mb-3 pb-3">
                            <div>
                              <h5 className="fw-semibold mb-1">Transaction</h5>
                              <p>Shows the fields of Transaction</p>
                            </div>
                            <div className="dropdown">
                              <a
                                href="#"
                                className="btn btn-icon btn-sm border btn-outline-light d-inline-flex align-items-center justify-content-center"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <i className="fa fa-ellipsis-v"></i>
                              </a>
                              <div className="dropdown-menu dropdown-menu-right">
                                <a
                                  className="dropdown-item d-flex align-items-center"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#edit_fields"
                                >
                                  <i className="fa-solid fa-pencil text-blue me-2"></i>
                                  Edit
                                </a>
                                <a
                                  className="dropdown-item d-flex align-items-center"
                                  href="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#delete_fields"
                                >
                                  <i className="fa-regular fa-trash-can text-danger me-2"></i>
                                  Delete
                                </a>
                              </div>
                            </div>
                          </div>
                          <div className="mb-0">
                            <div className="row">
                              <div className="col-md-4 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Label
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Comment"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">Type</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Textarea"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Default Value
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Enter Comments"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Required
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Required"
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 col-sm-6">
                                <div className="mb-3">
                                  <label className="col-form-label">
                                    Status
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value="Active"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <!-- /Transaction --> */}

                          <div>
                            <a href="#" className="btn btn-light me-2">
                              Cancel
                            </a>
                            <button type="submit" className="btn btn-primary">
                              Save Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                    {/* <!-- /Custom Fields --> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Add Custom Fields --> */}
        <div className="modal fade" id="add_fields" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Add Custom Fields</h5>
                <button
                  className="btn-close custom-btn-close border p-1 me-0 text-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x"></i>
                </button>
              </div>
              <form action="custom-fields.html">
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Custom Fields For <span className="text-danger">*</span>
                    </label>
                    <select className="select">
                      <option>Choose</option>
                      <option>Expense</option>
                      <option>Transaction</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Label <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Type <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">Default Value</label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Required <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                  <div className="mb-0">
                    <label className="col-form-label">
                      Status <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" />
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex align-items-center">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- /Add Custom Fields --> */}

        {/* <!-- Edit Custom Fields --> */}
        <div className="modal fade" id="edit_fields" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Edit Custom Fields</h5>
                <button
                  className="btn-close custom-btn-close border p-1 me-0 text-dark"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <i className="ti ti-x"></i>
                </button>
              </div>
              <form action="custom-fields.html">
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Custom Fields For <span className="text-danger">*</span>
                    </label>
                    <select className="select">
                      <option>Choose</option>
                      <option selected>Expense</option>
                      <option>Transaction</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Label <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" value="Name" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Type <span className="text-danger">*</span>
                    </label>
                    <input type="text" className="form-control" value="Text" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">Default Value</label>
                    <input type="text" className="form-control" value="Name" />
                  </div>
                  <div className="mb-3">
                    <label className="col-form-label">
                      Required <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value="Required"
                    />
                  </div>
                  <div className="mb-0">
                    <label className="col-form-label">
                      Status <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value="Active"
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <div className="d-flex align-items-center">
                    <a
                      href="#"
                      className="btn btn-light me-2"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <button type="submit" className="btn btn-primary">
                      Save
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* <!-- /Edit Custom Fields --> */}

        {/* <!-- Delete Fields --> */}
        <div className="modal fade" id="delete_fields" role="dialog">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <form action="custom-fields.html">
                  <div className="text-center">
                    <div className="avatar avatar-xl bg-danger-light rounded-circle mb-3">
                      <i className="ti ti-trash-x fs-36 text-danger"></i>
                    </div>
                    <h4 className="mb-2">Remove Field?</h4>
                    <p className="mb-0">Are you sure you want to remove it.</p>
                    <div className="d-flex align-items-center justify-content-center mt-4">
                      <a
                        href="#"
                        className="btn btn-light me-2"
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </a>
                      <button type="submit" className="btn btn-danger">
                        Yes, Delete it
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- /Delete Fields --> */}
      </div>
    </>
  )
}

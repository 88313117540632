import { createAsyncThunk, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { Preferences } from "../../utils"
import { getSessionStorageData } from "../../utils/preferences"
import API from "../../apis"
import { urls } from "../../apis/urls"

export const contactListAdapter = createEntityAdapter()

export const fetchContactList = createAsyncThunk(
    Preferences.CONTACT_LIST,
    async (thunkApi) => {
        const params = `?fk_UsrOrgDirId=${
                JSON.parse(getSessionStorageData(Preferences.COMPANY_DATA)).pk_UsrOrgDirId
              }&tockenKey=${getSessionStorageData(Preferences.TOKEN_KEY)}`
        const response = await API.getRequestWithJsonForOnline(urls.getContactList, params)
        if (response?.result?.response_Status === 'success') {
            return (response?.result?.returnClass)
        } else if (response?.result?.response_Status == 'error') {
            alert(response?.result?.response_Message) 
        } else {
            alert('Something went wrong with getContactData')
        }
    }
)

export const initialAppState = contactListAdapter.getInitialState({
    loading: 'idel',
    contactListData: [],
    filterData: [],
    error: []
})

export const contactListSlice = createSlice({
    name: Preferences.CONTACT_LIST,
    initialState: initialAppState,
    reducers: {
        resetContactListData: state => {
            state.filterData = state.contactListData
        },
        filterContactListData: (state, action) => {
            const {
                filterContactName,
                filterContactEmail,
                filterContactPhone,
                filterContactStatus,
            } = action.payload

            state.filterData = state.filterData.filter((item) => {
                return (
                    (filterContactName && filterContactName.some((filterItem) => filterItem.contactName === item.contactName)) ||
                    (filterContactEmail && filterContactEmail.some((filterItem) => item.emailIds.split(',').some((emailId) => emailId.trim() === filterItem.emailId.trim()))) ||
                    (filterContactPhone && filterContactPhone.some((filterItem) => item.phoneNumbers.split(',').some((phoneNumber) => phoneNumber === filterItem.phoneNumber))) ||
                    (filterContactStatus && filterContactStatus.some((filterItem) => filterItem.statusName === item.statusName))
                )
            })
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchContactList.pending, state => {
            state.loading = 'loading'
        })
        builder.addCase(fetchContactList.fulfilled, (state, action) => {
            state.loading = 'success'
            state.contactListData = action.payload ?? []
            state.filterData = action.payload ?? []
            state.error = null
        })
        builder.addCase(fetchContactList.rejected, (state, action) => {
            state.loading = 'error'
            state.error = action.payload
        })
    },
})

export const contactListReducer = contactListSlice.reducer

export const {resetContactListData, filterContactListData} = contactListSlice.actions

export const getContactListLoading = state => {
    return state.contactListReducer.loading === 'loading' ? true : false
}

export const getContactListError = state => {
    return state.contactListReducer.error
}

export const getContactListFilterData = state => {
    return state.contactListReducer.filterData
}

export const getContactListUniqueData = state => {
    return [...new Set(state.contactListReducer.contactListData.map(item => item.contactName))].map(contactName => {
        return state.contactListReducer.contactListData.find(item => item.contactName === contactName)
    })
}

export const getContactListData = state => {
    return state.contactListReducer.contactListData
}

export const getContactListDataByID = (state, {contactID}) => {
    return state.contactListReducer.filterData.filter(e => e?.pk_ContactId == contactID)
}